import React, { Component } from 'react';
import { connect } from 'react-redux';

// Layout Dependencies
import LayoutTitleBar from '../layouts/title-bar';
import { navigationConfig } from '../configurations/dashboard-navigation';
import styled from 'styled-components';

const StyledContainer = styled.div`
    ${props => props.theme.boxShadow(2)}
    width: 100%;
    position: fixed;
    z-index: 20;
    padding-right: ${props => (props.sidebarExpanded ? '300px' : '90px')};
`;

class ViewMenu extends Component {
    constructor(props) {
        super(props);
        this.optionList = navigationConfig;
    }
    render() {
        return (
            <StyledContainer sidebarExpanded={this.props.sidebarExpanded}>
                <LayoutTitleBar
                    title={this.props.title}
                    description={this.props.description}
                    className={this.props.upperMenuClassName}
                    visible={this.props.upperMenuVisible}
                    history={this.props.history}
                />
            </StyledContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        title: state.pageMeta.title,
        displayMenu: state.pageMeta.displayMenu,
        description: state.pageMeta.description,
    };
};

export default connect(mapStateToProps)(ViewMenu);
