import React, { Component } from 'react';
import moment from 'moment';
import * as fields from './../../configurations/fields';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// CSS
import styled from 'styled-components';
import '../../sass/table.scss';
import '../../sass/widgets/table-graph.scss';

// Redux
import { connect } from 'react-redux';
import { setTitle, setDescription } from '../../redux/actions/page-meta';

// Charts
import LineChart from '../components/chart/line-chart';
import TrendLineChart from '../components/chart/trend-line-chart';
import DonutChart from '../components/chart/donut-chart';
import NetworkGraph from '../components/chart/network-graph';
import PieChart from '../components/chart/pie-chart';
import BarChart from '../components/chart/bar-chart';
import { ChartDateGranularity, ChartOptions, ChartSelectionLimit } from '../../configurations/common/chart-types';
import { generateGraphRequests } from '../components/chart/helpers/chart-helper';
import ChartSelector from '../components/chart/chart-selector/chart-selector';

// Table
import WidgetDataTable from '../../components/tables/widgets/widget-data-table';
import { generateTableParams, getTotals, getProportions } from '../../components/tables/helpers/table-helper';

// SelectionLegend
import SelectionLegend from './../../components/selection-legend';

// Breadcrumbs
import BreadcrumbsWidget from '../../breadcrumbs/widgets/breadcrumbs';

// Dropdown
import Dropdown from './../../components/dropdown-component';

// Validation
import { validateConfig } from '../../configurations/validators/validate-config';
import ValidationMessage from '../../section-dashboard/components/validation-message';

// Helpers
import { formatDates } from '../../helpers/format-dates';
import { mapDataToSeries, createArrayOfDates, setUniqueArray } from '../../helpers/utils';
import { newColourAsRgba } from '../../helpers/colours';
import { formatSalesTitle } from '../../helpers/formatGlobalSaleTitle';
import { excludeBreadcrumbs } from '../../helpers/exclude-breadcrumbs';
import Request from './../../helpers/request';
import dataTypes from '../../filter-bar/enums/data-types';
import { getDateGranularity } from '../../helpers/get-date-granularity';

// Context
import { FilterBarContext } from '../../filter-bar/context/filter-bar-context';
import TableGraphProvider from '../context/table-graph-context';

const StyledChartSelectorContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledChartContainer = styled.div`
    display: flex;
`;

class LayoutTableGraph extends Component {
    static contextType = FilterBarContext;

    /*
		Used as the basis for all frontend reporting.
			hash: location.hash (not used)
			fetchProducts: function from owner (not used anywhere)
			startdate, enddate: vars from calendar
			config: owner config
			selectedProducts:
	*/
    constructor(props) {
        super(props);

        this.state = {
            htmlLoaded: false,
            resource: this.props.config.resources[0],
            dateFormatted: formatDates(
                this.props.startDate,
                this.props.endDate,
                this.state ? this.state.resource : this.props.config.resources[0]
            ),
            tableError: null,
            graphError: null,
            tableLoading: false,
            chartNoData: false,
            tableNoData: false,
            tableRequestId: 1,
            tableData: null,
            chartLoading: false,
            paginationClicked: false, // store whether the user has clicked Pagination and thus we shouldnt reset page=1
            chartSeriesList: [],
            tableOrderBy: props.config.resources[0].defaultOrderBy,
            tableOrderDir: props.config.resources[0].defaultOrderBy.defaultOrderDir,
            tableGroupBy: props.config.resources[0].defaultDimensions,
            downloadParams: [],
            loadingProducts: true,
            products: [],
            selectedProducts: [],
            selectedMetrics: [],
            showProductDropdown: false,
            selectedRows: [],
            page: 1,
            tabKey: 0,
            rowCount: props.config.pagination.rowDefault,
            dimensions: [],
            allDimensions: [],
            dimensionFilters: [],
            tableTotals: 0,
            searchValue: '',
            tableLoadType: 3,
            chartYMetrics: this.props.config.chart?.initialYMetrics || [],
            chartXMetric:
                this.props.config.chart?.initialXMetric || this.props.config.resources[0].dateDimension || null,
            maxXAxisPoints: this.props.config.chart?.maxXAxisPoints || [],
            breadcrumbResources: this.props.config.resources,
            validationMessages: validateConfig(this.props.config),
            chartType: null,
            chartDateGranularity: ChartDateGranularity.Day,
            disableDateGranularity: true,
            config: this.props.config,
            chartSelectionLimit: ChartSelectionLimit,
            chartOptions: ChartOptions,
            previousColumn: [],
        };
        this.tempTableData = null;
        this.scatterPlotDataList = null;
        this.handleTableDataTotal = this.handleTableDataTotal.bind(this);
        this.handleTableDataBody = this.handleTableDataBody.bind(this);
        this.handleTableDataSuccess = this.handleTableDataSuccess.bind(this);

        this.state.dimensions = this.state.resource.defaultDimensions;
        this.tableRequestHandler = {
            1: new Request(),
            2: new Request(),
            3: new Request(),
        };
        this.graphRequestHandler = new Request();
        this.fetchTableThrottled = _.throttle(this.fetchTableData, 2000, { leading: false });
        this.fetchGraphThrottled = _.throttle(this.fetchGraphData, 2500, { leading: false });

        /*
			While waiting for throttle(), prepare the page and tell the user we're fetching data
			startFetchTableData
				fetchTableThrottled
					fetchTableData
        */
    }

    setChartYMetric = (value, metricIndex) => {
        if (this.state.config.chart) {
            let thisMetric = this.state.chartYMetrics[metricIndex];
            for (let metric of this.state.config.chart.allYMetrics) {
                if (metric.rawName === value) {
                    thisMetric = metric;
                }
            }

            let newMetrics = [...this.state.chartYMetrics];
            newMetrics[metricIndex] = thisMetric;
            this.setState({
                chartYMetrics: newMetrics,
            });
        }
    };

    setChartXMetric = value => {
        let thisMetric = this.state.config.chart.allXMetrics.filter(metric => {
            return metric.rawName === value;
        });

        this.setState({ chartXMetric: thisMetric[0] });
    };

    handleLocationHash = () => {
        if (this.props.hash) {
            if (this.props.hash.length > 0) {
                this.handleDimensionClick(
                    null,
                    fields.REFERER,
                    { value: decodeURIComponent(this.props.hash.split('=')[1]) },
                    true
                );
            }
        }
    };
    assignAllChildren = () => {
        for (let resource of this.state.config.resources) {
            for (let field of resource.dimensions) {
                field.dataOverrides = this.state.config.table.fieldDataOverrides[field.id];
                field.parentOverride = this.state.config.table.fieldParentOverrides[field.id];
                field.children = this.state.config.table.fieldChildren[field.id];
            }
        }
        this.setState({ resources: this.state.config.resources });
    };

    handleBreadcrumbHomeClick = () => {
        this.setState({
            selectedRows: [],
            resource: this.state.config.resources[0],
            tableOrderBy: this.state.config.resources[0].defaultOrderBy,
            tableOrderDir: this.state.config.resources[0].defaultOrderBy.defaultOrderDir,
            tableGroupBy: this.state.config.resources[0].defaultDimensions,
            page: 1,
            dimensionFilters: [],
            searchValue: '',
        });
    };

    setResourceAndField = (resource, field, filter) => {
        if (filter && filter.resource) {
            resource = filter.resource;
        }

        //let validFilters = this.state.dimensionFilters.filter(filter => resource.dimensions.includes(filter.field));
        let validFilters = [];

        // traverse the current dimensionFilters - reset the lowest point to current selected Field
        for (var i = 0; i < this.state.dimensionFilters.length; i++) {
            let filter = this.state.dimensionFilters[i];
            if (filter.field.rawName === field.rawName) {
                break;
            }
            validFilters.push(filter);
        }

        const previousOrderByColumnMatches = this.state.previousColumn.some(item => {
            if (item.displayName === this.state.tableOrderBy.displayName) {
                return true;
            }
            return false;
        });

        if (!previousOrderByColumnMatches) {
            this.setState({
                tableOrderBy: resource.defaultOrderBy,
                tableOrderDir: resource.defaultOrderBy.defaultOrderDir,
            });
        }

        this.setState({
            resource: resource,
            tableGroupBy: [field],
            dimensionFilters: [...validFilters],
            selectedRows: [],
            searchValue: '',
            page: 1,
        });
    };
    findChildDimension = (children, value, filters) => {
        const childDimension = children[value] || children['default'];
        const filterFields = filters.map(filter => filter.field);
        for (let res of childDimension) {
            if (!filterFields.includes(res.field)) return res;
        }
        return null;
    };
    getNextDimension = (event, field, data) => {
        //@TODO - if nextDimension is already in Dimension filters
        if (field.dataOverrides) {
            for (let override of field.dataOverrides) {
                if (override.condition(data[override.property])) {
                    return { resource: override['resource'], field: override['field'] };
                }
            }
        }
        if (field.parentOverride) {
            const currentResource = this.state.resource;
            const filters = this.state.dimensionFilters.filter(filter =>
                currentResource.dimensions.includes(filter.field)
            );
            return this.findChildDimension(field.parentOverride.children, filters[0].value, [
                ...this.state.dimensionFilters,
            ]);
        } else {
            return this.findChildDimension(field.children, data.value, [...this.state.dimensionFilters]);
        }
    };
    handleDimensionClick = (event, field, properties, clearFilters) => {
        this.setState({
            previousColumn: [
                ...this.state.tableGroupBy,
                ...this.state.config.table.tabListItems[this.state.tabKey].metrics,
            ],
        });

        let nextDimension = this.getNextDimension(event, field, properties);
        if (!nextDimension) return;

        let mainFieldValue = properties.raw_value !== undefined ? properties.raw_value : properties.value;

        let mainField = {
            field: field,
            value: mainFieldValue,
            fuzzy: false,
            resource: this.state.resource,
            displayValue: properties.value,
        };

        let orderField = field !== this.state.tableOrderBy ? this.state.tableOrderBy : nextDimension.field;

        if (nextDimension.resources?.forceDefaultOrderBy) {
            orderField = nextDimension.resources.defaultOrderBy;
        }

        let orderDir =
            field !== this.state.tableOrderBy ? this.state.tableOrderDir : nextDimension.field.defaultOrderDir;

        if (nextDimension.resources?.forceDefaultOrderDir) {
            orderDir = orderField.defaultOrderDir;
        }

        this.setState({ tableLoading: true });
        //if our nextDimension has no resource (for dimensions that may belong to multiple resources, then just use current resource. Otherwise, use the dimension's resource)
        let nextResource = nextDimension.resource || this.state.resource;
        //Remove any filters that do not fit with our current dimensions.
        let validFilters = this.state.dimensionFilters.filter(filter => {
            return nextResource.dimensions.find(dimension => dimension.rawName === filter.field.rawName);
        });

        this.setState({
            resource: nextResource,
            tableGroupBy: [nextDimension.field],
            tableOrderBy: orderField,
            tableOrderDir: orderDir,
            dimensionFilters: clearFilters ? [mainField] : [...validFilters, mainField],
            selectedRows: [],
            searchValue: '',
        });
    };

    setDimensionFilters = (filters, resource) => {
        const res = resource || this.state.resource;

        let validFilters = filters.filter(filter => res.dimensions.includes(filter.field));

        this.setState({
            dimensionFilters: validFilters,
            selectedRows: [],
        });
    };
    setTableGroupBy = nextFields => {
        // nextFields is an array
        this.setState({
            tableGroupBy: nextFields,
        });
    };

    generateDownloadParams = (resourceGroup, resourceName, params) => {
        let formattedParams = [...params];
        let dateDim = this.state.resource.dateDimension;
        let filename = resourceName;
        if (dateDim) {
            let startDate = formattedParams.filter(item => item.key === `${dateDim.rawName}__gte`)[0];
            let endDate = formattedParams.filter(item => item.key === `${dateDim.rawName}__lte`)[0];
            filename += `_${moment(startDate.value).format('DD-MM-YYYY')}_${moment(endDate.value).format(
                'DD-MM-YYYY'
            )}`;
        }
        this.setState({
            downloadParams: {
                resourceGroup,
                resourceName,
                params: [{ key: 'filename', value: filename }, ...formattedParams],
            },
        });
    };

    setOwnerState = (key, value) => {
        let obj = {};
        obj[key] = value;

        if (key === 'rowCount') {
            this.setState({
                selectedCount: value,
            });
        }
        this.setState(obj);
    };

    startFetchGraphData = () => {
        this.setState({ chartLoading: true, graphError: null });
        this.fetchGraphThrottled();
    };

    startFetchTableData = paginationClicked => {
        this.tempTableData = null;
        this.setState({ tableNoData: false, tableLoading: true, selectedRows: [], tableError: null, error: null });

        if (!paginationClicked && !this.state.paginationClicked) {
            // always reset page to 1 unless pagination has been used
            this.setState({ page: 1 });
        } else {
            this.setState({ paginationClicked: true });
        }

        this.fetchTableThrottled();
    };
    fetchTableData = () => {
        /*
			loadType tells us what data set we should fetch:
			3 - totals, pagination, table data
			2 - pagination, table data
			1 - table data
		*/

        let loadType = this.state.tableLoadType;
        let params = generateTableParams(
            this.state.config,
            this.state.dimensionFilters,
            this.state.page,
            this.state.resource,
            this.state.rowCount,
            this.state.searchValue,
            this.props.selectedProducts,
            this.state.tableGroupBy,
            this.state.tableOrderBy,
            this.state.tableOrderDir,
            this.props.startDate,
            this.props.endDate,
            this.props.selectedMetrics
        );
        const resource = this.state.resource;
        this.setState({ tableNoData: false, tableLoading: true, selectedRows: [], tableError: null, error: null });
        this.generateDownloadParams(resource.category, resource.id, params);

        while (loadType > 0) {
            if (loadType === 1) {
                // @todo i have not been able to split out TP's calculating pagination and spliced table data
                break;
            }
            let getTotals = false;
            let callback = this.handleTableDataBody;
            let chartCallBack = this.startFetchGraphData;

            if (loadType === 3) {
                callback = this.handleTableDataTotal;
                chartCallBack = this.startFetchGraphData;
                getTotals = true;
            }

            var qpTotals = [
                {
                    key: 'totals',
                    value: getTotals,
                },
            ];

            this.tableRequestHandler[loadType].cancelRequest('aborted');
            this.tableRequestHandler[loadType]
                .get(resource.category, resource.id, [...params, ...qpTotals])
                .then(response => callback(response.data), chartCallBack())
                .catch(error => {
                    if (error.message !== 'aborted') {
                        this.setState({
                            tableData: null,
                            tableNoData: true,
                            tableLoading: false,
                            tableError: error,
                        });
                    }
                });
            loadType--;
        }
    };
    handleTableDataTotal = data => {
        if (!this.tempTableData) this.tempTableData = { data: { meta: {} } };
        this.tempTableData.data.meta.totals = data.meta.totals;

        if (this.tempTableData.data.objects) {
            this.handleTableDataSuccess();
        }
    };
    handleTableDataBody = data => {
        if (!this.tempTableData) this.tempTableData = { data: {} };
        if (!this.tempTableData.data.objects || this.tempTableData.data.meta.dimensions !== data.meta.dimensions) {
            this.tempTableData.data.objects = data.objects;
        }
        if (!this.tempTableData.data.meta) this.tempTableData.data.meta = {};

        for (var key in data.meta) {
            this.tempTableData.data.meta[key] = data.meta[key];
        }

        // grab current totals if we're not pulling them
        let loadType = this.state.tableLoadType;
        if (loadType < 3) {
            this.tempTableData.data.meta.totals = this.state.tableData.meta.totals;
        }
        if (this.tempTableData.data.meta.totals) {
            this.handleTableDataSuccess();
        }
    };
    handleTableDataSuccess = () => {
        let data = this.tempTableData;
        if (data && data.objects) {
            for (var i = 0; i < data.objects.length; i++) {
                var obj = data.objects[i];
                for (var key in obj) {
                    var val = obj[key];
                    if (typeof val !== 'object') {
                        obj[key] = { value: val };
                    }
                }
            }
        }
        const modifiedTableData = {
            objects: data.data.objects.map(object => {
                return {
                    ...object,
                    id: uuidv4(),
                };
            }),
            meta: data.data.meta,
        };
        let totals = getTotals(
            modifiedTableData,
            this.state.tableGroupBy,
            this.state.config.table.tabListItems[this.state.tabKey].metrics
        );
        let newData = getProportions(
            modifiedTableData,
            totals,
            this.state.tableGroupBy,
            this.state.config.table.tabListItems[this.state.tabKey].metrics
        );
        let totalCount = modifiedTableData.meta ? modifiedTableData.meta.total_count : 0;
        let hasData = totalCount === 0;
        this.setState({
            tableData: newData,
            tableNoData: hasData,
            tableLoading: false,
            tableTotals: totalCount,
            paginationClicked: false,
        });
    };
    shouldFetchGraphData = (prevProps, prevState) => {
        const state = this.state;
        const props = this.props;
        const triggers = [
            'resource',
            'selectedProducts',
            'dimensionFilters',
            'dateFormatted',
            'chartYMetrics',
            'chartXMetric',
        ];
        for (let key in state) {
            if (prevState[key] !== state[key] && triggers.includes(key)) {
                return true;
            }
        }
        for (let key in props) {
            if (prevProps[key] !== props[key] && triggers.includes(key)) {
                return true;
            }
        }
        return false;
    };

    fetchGraphData = () => {
        if (this.state.config.chart) {
            this.setState({ chartLoading: true, graphError: null });
            const requests = generateGraphRequests(
                this.state.config,
                this.state.dimensionFilters,
                this.state.page,
                this.state.resource,
                this.state.rowCount,
                this.state.searchValue,
                this.props.selectedProducts,
                this.props.selectedMetrics,
                this.state.selectedRows,
                this.state.tableGroupBy,
                this.state.tableOrderBy,
                this.state.tableOrderDir,
                this.state.chartXMetric,
                this.state.chartYMetrics,
                this.props.startDate,
                this.props.endDate,
                this.state.chartDateGranularity
            );

            this.graphRequestHandler
                .all(requests)
                .then(responses => {
                    let seriesList = [];

                    responses.forEach((response, index) => {
                        const thisConfig = {
                            ...this.state.config.chart.options.seriesConfig,
                        };

                        let xAxisDataPoints;

                        if ([dataTypes.DATE, dataTypes.DATE_TIME].includes(this.state.chartXMetric.dataType)) {
                            xAxisDataPoints = createArrayOfDates(
                                this.props.startDate,
                                this.props.endDate,
                                this.state.chartDateGranularity
                            );
                        } else {
                            xAxisDataPoints = response.data.objects.map(object => {
                                return object[this.state.chartXMetric.rawName];
                            });
                        }

                        let graphData = [];

                        if (this.state.config.chart.dataFormatter) {
                            let groupbyOveride = this.state.resource.graphGroupByOverride;
                            let dim = this.state.config.dateDimension;
                            if (groupbyOveride) {
                                dim = groupbyOveride[0];
                            }

                            this.state.chartYMetrics.forEach(metric => {
                                const seriesGraphData = this.state.config.chart.dataFormatter(
                                    xAxisDataPoints,
                                    response.data.objects,
                                    dim,
                                    metric.rawName
                                );

                                if (this.state.config.chart.skippedPlotNullValues) {
                                    seriesGraphData.forEach(element => {
                                        if (element[1] === 0) {
                                            return [...element, (element[1] = null)];
                                        }
                                    });
                                }

                                graphData.push(seriesGraphData);
                            });
                        } else {
                            this.state.chartYMetrics.forEach(metric => {
                                graphData.push(
                                    mapDataToSeries(
                                        xAxisDataPoints,
                                        response.data.objects,
                                        this.state.chartXMetric,
                                        metric.rawName,
                                        this.state.chartDateGranularity
                                    )
                                );
                            });
                        }

                        const alpha = this.state.chartYMetrics.length > 1 ? 0.3 : 0.5;

                        this.state.chartType === 'line' || this.state.chartType === 'areaSpline'
                            ? (thisConfig.fillColor = {
                                  linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                  stops: [
                                      [0, newColourAsRgba(requests[index].colour, alpha)],
                                      [1, newColourAsRgba(requests[index].colour, 0)],
                                  ],
                              })
                            : (thisConfig.fillColor = null);

                        this.state.chartType === 'line' || this.state.chartType === 'areaSpline'
                            ? (thisConfig.color = newColourAsRgba(requests[index].colour, 0.5))
                            : (thisConfig.color = null);

                        this.state.chartYMetrics.forEach((metric, metricIndex) => {
                            let fillColor =
                                this.state.chartType === 'line' || this.state.chartType === 'areaSpline'
                                    ? metricIndex > 0
                                        ? {
                                              linearGradient: {
                                                  x1: 0,
                                                  y1: 0,
                                                  x2: 0,
                                                  y2: 0,
                                              },
                                              stops: [
                                                  [0, 'rgba(255,0,0,0)'],
                                                  [1, 'rgba(255,0,0,0)'],
                                              ],
                                          }
                                        : thisConfig.fillColor
                                    : null;

                            seriesList.push({
                                ...thisConfig,
                                data: graphData[metricIndex],
                                filter: requests[index].filter,
                                name: formatSalesTitle(metric.displayName, this.props.globalSaleTitle),
                                dashStyle: metricIndex > 0 ? 'Dash' : null,
                                fillColor: fillColor,
                            });
                        });
                    });

                    if (
                        (this.state.config.chart.toggleGraph &&
                            this.state.config.chart.toggleGraph(this.props, this.state.tableGroupBy)) ||
                        seriesList[0].data.length < 1
                    ) {
                        this.setState({ chartLoading: false, chartNoData: true });
                    } else {
                        this.setState({
                            chartLoading: false,
                            chartNoData: false,
                            chartSeriesList: seriesList,
                        });
                    }
                })
                .catch(error => {
                    if (error.message !== 'aborted') {
                        this.setState({
                            chartNoData: true,
                        });
                    }
                });
        }
    };

    shouldFetchTableData = (prevProps, prevState) => {
        /*
			Check what has changed and if we should fetch data for table.
			We need to set which data sets to pull at this point:
				3 - totals (2 + 1)
				2 - pagination ( +1)
				1 - table data
			
			order by, pagination:
				table data
			row limit:
				table data, pagination
			search, product change, daterange change:
				table data, pagination, totals

			return 1,2,3 (loadType)
		*/

        const state = this.state;
        const props = this.props;

        const triggerTable = ['tableOrderDir', 'tableOrderBy', 'page'];

        const triggerPagination = ['rowCount', 'limit'];

        const triggerTotals = [
            'dateFormatted',
            'tableGroupBy',
            'dimensions',
            'resource',
            'searchValue',
            'selectedProducts',
            'selectedMetrics',
            'dimensionFilters',
        ];
        // 3,2,1(tots + pag + table)
        let loadType = 0;

        for (let key in state) {
            if (prevState[key] !== state[key]) {
                if (triggerTotals.includes(key)) loadType = 3;
                if (triggerPagination.includes(key)) loadType = 2;
                if (triggerTable.includes(key)) loadType = 2; // @todo should be 1 once paginaton+table are split
            }

            // if we've already flagged to get all data, dont bother checking more
            if (loadType === 3) break;
        }

        // only bother to check for key in props if we didnt find in state
        if (loadType === 0) {
            for (let key in props) {
                if (prevProps[key] !== props[key]) {
                    if (triggerTotals.includes(key)) loadType = 3;
                    if (triggerPagination.includes(key)) loadType = 2;
                    if (triggerTable.includes(key)) loadType = 2; // @todo should be 1 once paginaton+table are split
                }

                // if we've already flagged to get all data, dont bother checking more
                if (loadType === 3) break;
            }
        }

        return loadType;
    };

    setGraphGranularity = () => {
        const graphGranularity = getDateGranularity(this.props.startDate, this.props.endDate);

        if (graphGranularity === ChartDateGranularity.Hour) {
            this.setState({
                chartDateGranularity: ChartDateGranularity.Hour,
                disableDateGranularity: true,
            });
        } else {
            this.setState({
                chartDateGranularity: ChartDateGranularity.Day,
                disableDateGranularity: this.props.startDate.format('YYYY-MM') === this.props.endDate.format('YYYY-MM'),
            });
        }
    };

    componentDidMount() {
        this.props.setTitle(this.state.config.pageTitle);
        this.props.setDescription(this.state.config.reportDescription);
        this.setGraphGranularity();

        if (this.state.config?.table?.fieldChildren) {
            this.assignAllChildren();
        }
        this.handleLocationHash();
        if (this.state.config.chart) {
            this.startFetchGraphData();
        }
        this.setState({
            dateFormatted: formatDates(
                this.props.startDate,
                this.props.endDate,
                this.state ? this.state.resource : this.state.config.resources[0]
            ),
        });
        this.startFetchTableData();

        // Set filtration data
        let metricData = [];
        this.props.config.table.tabListItems.forEach(data => {
            data.metrics.forEach(metric => {
                metricData.push({
                    ...metric,
                    value: metric.rawName,
                    label: metric.displayName,
                });
            });
        });
        this.context.setFilterStatus(this.props.config.filters.status);
        this.context.setFilterMetricsOptions([...setUniqueArray(metricData, 'rawName')]);
        this.context.setDatePickerConfig(this.props.config.filters.datePicker || {});

        if (this.state.config.chart && this.state.config.chart.chartType) {
            this.setChartConfig(this.state.config.chart.chartType);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let paginationClicked = prevState.page !== this.state.page;

        if (this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate) {
            this.setState({
                dateFormatted: formatDates(
                    this.props.startDate,
                    this.props.endDate,
                    this.state ? this.state.resource : this.state.config.resources[0]
                ),
                searchValue: '',
            });
            this.setGraphGranularity();
        }

        var loadType = this.shouldFetchTableData(prevProps, prevState);
        if (loadType > 0) {
            this.setState({
                tableLoadType: loadType,
            });
            this.startFetchTableData(paginationClicked);
        }
        if (this.state.config.chart) {
            // if the selected rows change, fetch the graph data without throttling
            if (prevState.selectedRows.length !== this.state.selectedRows.length) {
                this.setState({ chartLoading: true, graphError: null });
                this.fetchGraphData();
            }
            if (this.shouldFetchGraphData(prevProps, prevState)) {
                this.startFetchGraphData();
            }
        }

        if (prevProps.hash !== this.props.hash) {
            this.handleLocationHash();
            if (this.state.config.chart) {
                this.fetchGraphThrottled();
            }
            this.startFetchTableData(paginationClicked);
        }
        // exclude breadcrumbs from list as required
        if (prevState.dimensionFilters !== this.state.dimensionFilters) {
            const updatedResources = excludeBreadcrumbs(
                this.state.dimensionFilters,
                this.state.config.resources,
                this.state.resource
            );

            this.setState({
                breadcrumbResources: updatedResources,
            });
        }
    }

    handleUpdateChartDateGranularity = type => {
        const start = moment.utc(this.props.startDate.format('YYYY-MM-DD'));
        const end = moment.utc(this.props.endDate.format('YYYY-MM-DD'));

        if (type === ChartDateGranularity.Day && start.isSame(end, 'date')) {
            type = ChartDateGranularity.Hour;
        }
        this.setState(
            {
                chartDateGranularity: type,
            },
            () => this.fetchGraphData()
        );
    };

    handleSearchChange = event => {
        this.setState({ searchValue: event.target.value });
    };

    handleClearSearch = () => {
        this.setState({ searchValue: '' });
    };

    handleUpdateSelectedRows = updatedRows => {
        this.setState({ selectedRows: updatedRows });
    };

    setChartConfig = chartType => {
        const config = this.state.config;
        let options, selectionLimit;

        options =
            config.chart.options && config.chart.options[chartType]
                ? { ...config.chart.options[chartType].options }
                : { ...this.state.chartOptions[chartType].options };

        if ((chartType === 'line' || chartType === 'trendLine') && this.state.config.chart.skippedPlotNullValues) {
            options.plotOptions.series.connectNulls = this.state.config.chart.skippedPlotNullValues;
        }

        selectionLimit =
            config.chart.selectionLimit && config.chart.selectionLimit[chartType]
                ? config.chart.chartSelectionLimit[chartType]
                : this.state.chartSelectionLimit[chartType];

        if (this.state?.selectedRows.length > selectionLimit) {
            this.setState({ selectedRows: [] });
        }

        this.setState({
            chartType: chartType,
            config: {
                ...this.state.config,
                chart: {
                    ...this.state.config.chart,
                    options: options,
                    selectionLimit: selectionLimit,
                    chartType: chartType,
                },
            },
        });
    };
    renderTableWidget = () => {
        const tableData = this.state.tableData;
        return (
            <div className="table-graph">
                <div className="table-graph__options">
                    <BreadcrumbsWidget
                        resource={this.state.resource}
                        homeClickHandler={this.handleBreadcrumbHomeClick}
                        allResources={this.state.breadcrumbResources}
                        dimensionFilters={this.state.dimensionFilters}
                        dimension={this.state.tableGroupBy}
                        setDimensionFilters={this.setDimensionFilters}
                        setResourceAndField={this.setResourceAndField}
                        startDate={this.state.dateFormatted.startDate}
                        endDate={this.state.dateFormatted.endDate}
                        dateDimension={this.state.resource.dateDimension}
                        removeFilterOverride={this.state.config.removeFilterOverride}
                        showCancel={this.state.config.breadcrumbsShowCancel}
                        setTableGroupBy={this.setTableGroupBy}
                    />
                </div>
                {this.state.config.chart ? (
                    <div className="table-graph__graph-holder">
                        {!this.state.config.hideDropdown ? (
                            <div
                                style={{
                                    position: 'relative',
                                    width: '320px',
                                    float: 'left',
                                    zIndex: 2,
                                }}
                            >
                                {this.state.chartYMetrics.map((metric, index) => {
                                    return (
                                        <Dropdown
                                            key={index}
                                            indexId={index}
                                            defaultValue={metric.rawName}
                                            options={this.state.config.chart.allYMetrics}
                                            ownerCallback={this.setChartYMetric}
                                            isMetric={true}
                                            showDropdownLegend={this.state.config.showDropdownLegend}
                                            legendStyle={index > 0 ? 'dashed' : 'solid'}
                                            showTitle={this.state.config.chart.allXMetrics ? true : false}
                                            title={'Y-Axis'}
                                        />
                                    );
                                })}

                                {this.state.config.chart.allXMetrics && (
                                    <Dropdown
                                        key={this.state.chartXMetric.rawName}
                                        indexId={this.state.chartXMetric.rawName}
                                        defaultValue={this.state.chartXMetric.rawName}
                                        options={this.state.config.chart.allXMetrics}
                                        ownerCallback={this.setChartXMetric}
                                        isMetric={true}
                                        showTitle={this.state.config.chart.allXMetrics ? true : false}
                                        title={'X-Axis'}
                                    />
                                )}

                                <SelectionLegend
                                    dimension={this.state.tableGroupBy}
                                    selectedRows={this.state.selectedRows}
                                    onUpdateRows={this.handleUpdateSelectedRows}
                                />
                                <div className="clear"></div>
                            </div>
                        ) : null}
                        <StyledChartSelectorContainer>
                            <ChartSelector
                                allowedChartTypes={this.state.config.chart.allowedChartTypes}
                                selectedChartType={this.state.chartType}
                                setChartConfig={this.setChartConfig}
                                onUpdateChartDateGranularity={this.handleUpdateChartDateGranularity}
                                chartDateGranularity={this.state.chartDateGranularity}
                                disableDateGranularity={this.state.disableDateGranularity}
                            />
                        </StyledChartSelectorContainer>
                        <StyledChartContainer>
                            {(this.state.chartType === 'line' || this.state.chartType === 'areaSpline') && (
                                <LineChart
                                    chartSeriesList={this.state.chartSeriesList}
                                    config={this.state.config}
                                    chartLoading={this.state.chartLoading}
                                    chartDateGranularity={this.state.chartDateGranularity}
                                    noData={this.state.chartNoData}
                                    xMetric={this.state.chartXMetric}
                                    chartYMetrics={this.state.chartYMetrics}
                                    messageTitle={
                                        this.state.tableNoData
                                            ? 'No Data Available.'
                                            : this.state.config.chart.graphTitle
                                    }
                                    messageCopy={
                                        this.state.tableNoData
                                            ? 'Try adjusting your filters.'
                                            : this.state.config.chart.graphMessageCopy
                                    }
                                    messageType={this.state.config.chart.graphMessageType}
                                    scatterPlotList={this.scatterPlotDataList}
                                    dates={this.state.dateFormatted}
                                />
                            )}
                            {this.state.chartType === 'trendLine' && (
                                <TrendLineChart
                                    chartSeriesList={this.state.chartSeriesList}
                                    config={this.state.config}
                                    chartLoading={this.state.chartLoading}
                                    chartDateGranularity={this.state.chartDateGranularity}
                                    noData={this.state.chartNoData}
                                    xMetric={this.state.chartXMetric}
                                    chartYMetrics={this.state.chartYMetrics}
                                    messageTitle={this.state.config.graphTitle}
                                    messageCopy={this.state.config.chart.graphMessageCopy}
                                    messageType={this.state.config.chart.graphMessageType}
                                    dates={this.state.dateFormatted}
                                />
                            )}
                            {this.state.chartType === 'network' && (
                                <NetworkGraph
                                    chartSeriesList={this.state.chartSeriesList}
                                    chartLoading={this.state.chartLoading}
                                    config={this.state.config}
                                    noData={this.state.chartNoData}
                                    messageTitle={this.state.config.graphTitle}
                                    messageCopy={this.state.config.chart.graphMessageCopy}
                                    messageType={this.state.config.chart.graphMessageType}
                                    tableData={tableData ? tableData : []}
                                />
                            )}
                            {this.state.chartType === 'donut' && (
                                <DonutChart
                                    data={tableData ? tableData : []}
                                    config={this.state.config}
                                    noData={this.state.tableNoData}
                                    selectedRows={this.state.selectedRows}
                                    tableGroupBy={this.state.tableGroupBy}
                                    dimensions={this.state.tableGroupBy}
                                    selectedMetric={this.state.chartYMetrics}
                                    metrics={this.state.config.table.tabListItems[this.state.tabKey]?.metrics}
                                    dimensionFilters={this.state.dimensionFilters}
                                    messageTitle={this.state.config.graphTitle}
                                    messageCopy={this.state.config.chart.graphMessageCopy}
                                    messageType={this.state.config.chart.graphMessageType}
                                    loading={this.state.tableLoading}
                                />
                            )}
                            {(this.state.chartType === 'bar' || this.state.chartType === 'barHorizontal') && (
                                <BarChart
                                    data={tableData ? tableData : []}
                                    config={this.state.config}
                                    noData={this.state.tableNoData}
                                    selectedRows={this.state.selectedRows}
                                    tableGroupBy={this.state.tableGroupBy}
                                    dimensions={this.state.tableGroupBy}
                                    selectedMetric={this.state.chartYMetrics}
                                    metrics={this.state.config.table.tabListItems[this.state.tabKey]?.metrics}
                                    dimensionFilters={this.state.dimensionFilters}
                                    messageTitle={this.state.config.graphTitle}
                                    messageCopy={this.state.config.chart.graphMessageCopy}
                                    messageType={this.state.config.chart.graphMessageType}
                                    loading={this.state.tableLoading}
                                />
                            )}
                            {this.state.chartType === 'pie' && (
                                <PieChart
                                    data={tableData ? tableData : []}
                                    config={this.state.config}
                                    noData={this.state.tableNoData}
                                    selectedRows={this.state.selectedRows}
                                    tableGroupBy={this.state.tableGroupBy}
                                    dimensions={this.state.tableGroupBy}
                                    selectedMetric={this.state.chartYMetrics}
                                    metrics={this.state.config.table.tabListItems[this.state.tabKey]?.metrics}
                                    dimensionFilters={this.state.dimensionFilters}
                                    messageTitle={this.state.config.graphTitle}
                                    messageCopy={this.state.config.chart.graphMessageCopy}
                                    messageType={this.state.config.chart.graphMessageType}
                                    loading={this.state.tableLoading}
                                />
                            )}
                        </StyledChartContainer>
                        <div className="clear"></div>
                    </div>
                ) : null}

                <WidgetDataTable
                    loading={this.state.tableLoading}
                    noData={this.state.tableNoData}
                    error={this.state.tableError}
                    dimensions={this.state.tableGroupBy}
                    data={tableData ? tableData : []}
                    page={this.state.page}
                    tabKey={this.state.tabKey}
                    rowCount={this.state.rowCount}
                    selectedRows={this.state.selectedRows}
                    pageCount={
                        tableData
                            ? Math.ceil(this.state.tableData.meta.total_count / this.state.tableData.meta.limit)
                            : 0
                    }
                    totalResults={this.state.tableTotals}
                    orderBy={this.state.tableOrderBy}
                    orderByDir={this.state.tableOrderDir}
                    setOwnerState={this.setOwnerState}
                    tabConfig={this.state.config.table.tabListItems}
                    paginationConfig={this.state.config.pagination}
                    selectedRowCount={this.state.selectedCount}
                    handleDimensionClick={this.handleDimensionClick}
                    downloadParams={this.state.downloadParams}
                    hideTotals={this.state.config.table.hideTotals}
                    disableRowClick={this.state.config.table.disableRowClick}
                    searchValue={this.state.searchValue}
                    calculateHeatmap={this.state.config.calculateHeatmap}
                    config={this.state.config}
                    handleSearchChange={this.handleSearchChange}
                    handleClearSearch={this.handleClearSearch}
                />
            </div>
        );
    };
    render() {
        if (this.state.validationMessages.length > 0) {
            const messages =
                process.env.NODE_ENV === 'development'
                    ? this.state.validationMessages
                    : ['Configuration Failed, Please contact a member of the Cubed team'];
            return <ValidationMessage messages={messages} />;
        }
        return (
            <TableGraphProvider
                annotationConfig={this.props.config?.chart?.annotations || null}
                chartData={this.state.chartSeriesList}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                chartDateGranularity={this.state.chartDateGranularity}
            >
                <div className="content__holder">{this.renderTableWidget()}</div>
            </TableGraphProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        globalSaleTitle: state.saleTitle.globalSalesTitle,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTitle: title => {
            dispatch(setTitle(title));
        },
        setDescription: description => {
            dispatch(setDescription(description));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutTableGraph);
