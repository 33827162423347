// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { generateUrl, generatePath, generateUrlDetail } from '../../helpers/request-builder';
import { isIp } from '../../helpers/validator';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import WidgetSelectableSimpleTable from '../../components/tables/widgets/widget-selectable-simple-table';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class LayoutModalManageIpBlacklist extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accordionVisible: false,
            isLoading: true,
            pageError: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            removeButtonState: true,
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: false,
            editRuleAccordionOpen: false,
            editButtonDisabled: true,
            removeButtonDisabled: true,
            updateButtonDisabled: true,
            disableRuleSelect: false,
            levelOptions: [],
            rules: [],
            selectedRules: [],
            removedRules: [],
            updatedRules: [],
            levelForEdit: '',
            minIpForEdit: '',
            maxIpForEdit: '',
            levelForEditErrorMessage: '',
            minIpForEditErrorMessage: '',
            maxIpForEditErrorMessage: '',
        };

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onMinIpChange = this.onMinIpChange.bind(this);
        this.onMaxIpChange = this.onMaxIpChange.bind(this);
        this.onLevelChange = this.onLevelChange.bind(this);
        this.formValidator = this.formValidator.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onSelectRule = this.onSelectRule.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.handleOnCreate = this.handleOnCreate.bind(this);

        this.renderAccordion = this.renderAccordion.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderTable = this.renderTable.bind(this);
    }

    componentDidMount() {
        const config = [
            {
                resourceGroup: 'config',
                resourceName: 'blacklist-level',
            },
            {
                resourceGroup: 'config',
                resourceName: 'blacklist-ip',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
        ];

        this.props.getRequest(config);
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // Sorting Out Roles
                const levelOptions = this.props.request.data[0].objects.map(role => {
                    return {
                        keyValue: `level-option_${role.name.replace(/ /g, '')}`,
                        value: role.id,
                        name: role.name,
                    };
                });

                // Sorting Out Roles
                const rules = this.props.request.data[1].objects.map(rule => {
                    return {
                        id: rule.id,
                        minIp: rule['ip_min'],
                        maxIp: rule['ip_max'],
                        level: {
                            id: rule.level.id,
                            name: rule.level.name,
                        },
                    };
                });

                this.setState({
                    isLoading: false,
                    levelOptions: levelOptions,
                    rules: rules,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }

            this.props.delRequest();
        }

        // Enable the save changes and cancel when a user is added into either the revoked or updated arrays
        if (
            this.state.saveChangesButtonHidden &&
            (this.state.updatedRules.length > 0 || this.state.removedRules.length > 0)
        ) {
            this.setState({
                saveChangesButtonHidden: false,
                closeButtonState: 'cancel',
            });
        }
    }

    formValidator() {
        this.setState({
            minIpForEditErrorMessage: '',
            maxIpForEditErrorMessage: '',
        });

        let hasFormError = false;
        const errorMessageObject = {
            minIpForEditErrorMessage: '',
            maxIpForEditErrorMessage: '',
        };

        if (!isIp(this.state.minIpForEdit) || this.state.minIpForEdit.length === 0) {
            hasFormError = true;
            errorMessageObject.minIpForEditErrorMessage = 'Please enter a valid IP Address.';
        }

        if (!isIp(this.state.maxIpForEdit) || this.state.maxIpForEdit.length === 0) {
            hasFormError = true;
            errorMessageObject.maxIpForEditErrorMessage = 'Please enter a valid IP Address.';
        }

        if (hasFormError) {
            this.setState({
                minIpForEditErrorMessage: errorMessageObject.minIpForEditErrorMessage,
                maxIpForEditErrorMessage: errorMessageObject.maxIpForEditErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    }

    onSaveChangesClick() {
        this.setState({
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        const requests = [];

        if (this.state.updatedRules.length > 0) {
            requests.push(
                Axios({
                    method: 'PATCH',
                    url: generateUrl('config', 'blacklist-ip'),
                    data: {
                        objects: this.state.updatedRules.map(rule => {
                            return {
                                resource_uri: generatePath('config', 'blacklist-ip', rule.id),
                                level: generatePath('config', 'blacklist-level', rule.level.id),
                                ip_max: rule.maxIp,
                                ip_min: rule.minIp,
                            };
                        }),
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        }

        if (this.state.removedRules.length > 0) {
            this.state.removedRules.forEach(rule => {
                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrlDetail('config', 'blacklist-ip', rule.id),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        Axios.all(requests)
            .then(
                Axios.spread((...requestRes) => {
                    this.setState({
                        saveChangesButtonHidden: false,
                        saveChangesButtonLoading: false,
                    });

                    this.props.removeModal();
                    this.props.addNotification({
                        copy: 'The changes to the IP rules have been successfully made.',
                        type: NotificationMessageType.Success,
                    });
                })
            )
            .catch(error => {
                this.props.addNotification({
                    copy: 'There was an issue trying to update these IP Rules. Please try again later',
                    type: NotificationMessageType.Error,
                });
                this.setState({
                    saveChangesButtonHidden: false,
                    saveChangesButtonLoading: false,
                    closeButtonDisabled: false,
                });
            });
    }

    onMinIpChange(event) {
        this.setState({
            minIpForEdit: event.target.value,
        });
    }
    onMaxIpChange(event) {
        this.setState({
            maxIpForEdit: event.target.value,
        });
    }
    onLevelChange(event) {
        this.setState({
            levelForEdit: event.target.value,
        });
    }

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.props.removeModal();
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    }

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.props.removeModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    onUpdateClick() {
        if (!this.formValidator()) {
            return;
        }

        const newLevelId = parseInt(this.state.levelForEdit);
        const newLevelName = this.state.levelOptions.filter(level => level.value === newLevelId)[0].name;
        const newMinIp = this.state.minIpForEdit;
        const newMaxIp = this.state.maxIpForEdit;

        const updatedRules = [].concat(this.state.updatedRules);

        this.state.selectedRules.forEach(rule => {
            // Check if a change was actually made.
            if (rule.minIp !== newMinIp || rule.level.id !== newLevelId || rule.maxIp !== newMaxIp) {
                const ruleIndex = updatedRules.indexOf(rule);

                if (ruleIndex >= 0) {
                    updatedRules[ruleIndex].minIp = newMinIp;
                    updatedRules[ruleIndex].maxIp = newMaxIp;
                    updatedRules[ruleIndex].level.id = newLevelId;
                    updatedRules[ruleIndex].level.name = newLevelName;
                } else {
                    rule.minIp = newMinIp;
                    rule.maxIp = newMaxIp;
                    rule.level.id = newLevelId;
                    rule.level.name = newLevelName;

                    updatedRules.push(rule);
                }
            }
        });

        this.setState({
            accordionVisible: false,
            updatedRules: updatedRules,
            editRuleAccordionOpen: false,
            updateButtonDisabled: true,
            disableRuleSelect: false,
            selectedRules: [],
            levelForEdit: '',
            minIpForEdit: '',
            maxIpForEdit: '',
        });
    }

    onCancelClick() {
        this.setState({
            accordionVisible: false,
            editRuleAccordionOpen: false,
            updateButtonDisabled: true,
            disableRuleSelect: false,
            selectedRules: [],
        });
    }

    handleOnCreate() {
        this.props.setModal('CreateIPBlacklistRule', {});
    }

    onEditClick() {
        this.setState({
            accordionVisible: true,
            editRuleAccordionOpen: true,
            updateButtonDisabled: false,
            disableRuleSelect: true,
            editButtonDisabled: true,
            removeButtonDisabled: true,
            levelForEdit: this.state.selectedRules[0].level.id,
            minIpForEdit: this.state.selectedRules[0].minIp,
            maxIpForEdit: this.state.selectedRules[0].maxIp,
        });
    }

    onRemoveClick() {
        const removedIps = [].concat(this.state.removedRules);
        const updatedRules = [].concat(this.state.updatedRules);

        this.state.selectedRules.forEach(rule => {
            if (this.state.removeButtonState) {
                removedIps.push(rule);
            } else {
                removedIps.splice(removedIps.indexOf(rule), 1);
            }

            // If a user was edited and then revoked in the same instance then the revoke should take priority
            const updatedIpIndex = updatedRules.indexOf(rule);

            if (updatedIpIndex >= 0) {
                updatedRules.splice(updatedIpIndex, 1);
            }
        });

        this.setState({
            removedRules: removedIps,
            updatedRules: updatedRules,
            selectedRules: [],
            removeButtonState: true,
            editButtonDisabled: true,
            removeButtonDisabled: true,
        });
    }

    onSelectRule(selectedRules) {
        const selectedIps = selectedRules.map(item => {
            return this.state.rules.filter(rule => rule.id === item.dataValue)[0];
        });

        // Check the status of the items that have been selected.
        let containsRemoved = false;
        let containsNonRemoved = false;
        let containsEdited = false;

        selectedIps.forEach(rule => {
            if (this.state.removedRules.indexOf(rule) >= 0) {
                containsRemoved = true;
            } else {
                containsNonRemoved = true;
            }

            if (this.state.updatedRules.indexOf(rule) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;

        if (selectedIps.length > 0) {
            editButtonDisabled = false;
            removeButtonDisabled = false;
        }

        if (selectedIps.length > 1) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRemoved && !containsNonRemoved && !containsEdited) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRemoved) {
            editButtonDisabled = true;
        }

        if (containsRemoved && containsNonRemoved) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        this.setState({
            selectedRules: selectedIps,
            editButtonDisabled: editButtonDisabled,
            removeButtonDisabled: removeButtonDisabled,
            removeButtonState: removeButtonState,
            updateButtonDisabled: true,
            editRuleAccordionOpen: false,
        });
    }

    renderModalNavigation() {
        let numberOfChanges = this.state.removedRules.length + this.state.updatedRules.length;

        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: this.onSaveChangesClick,
                hidden: this.state.saveChangesButtonHidden,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    }

    renderAccordion() {
        if (this.state.accordionVisible === false) {
            return null;
        }

        let introText = `<p>More information about how what each level will affect your data can be found on our <a href="http://tag.docs.withcubed.com/onboarding/general/" target="_blank" rel="noopener noreferrer">support page</a>.</p>`;

        if (!this.state.updateButtonDisabled) {
            introText = introText.concat(
                `<p>Currently Editing:</p><ul> ${this.state.selectedRules
                    .map(rule => '<li>' + rule.minIp + ' - ' + rule.maxIp + '</li>')
                    .join('')} </ul>`
            );
        }

        const accordions = [
            {
                header: 'Edit Rule',
                required: false,
                open: this.state.editRuleAccordionOpen,
                type: 'form',
                intro: introText,
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Minimum IP:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'This will determine at what point the Cubed system will ignore hits made from this IP range.',
                                inputKeyValue: 'edit-rule__minimum-ip',
                                inputValue: this.state.minIpForEdit,
                                inputOnChange: this.onMinIpChange,
                                errorMessage: this.state.minIpForEditErrorMessage,
                            },
                            {
                                label: 'Maximum IP:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy:
                                    'This will determine at what point the Cubed system will ignore hits made from this IP range.',
                                inputKeyValue: 'edit-rule__maximum-ip',
                                inputValue: this.state.maxIpForEdit,
                                inputOnChange: this.onMaxIpChange,
                                errorMessage: this.state.maxIpForEditErrorMessage,
                            },
                            {
                                label: 'Level:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Define what to do with visits from your defined blacklist.',
                                inputKeyValue: 'edit-rule__level',
                                inputOptions: this.state.levelOptions,
                                inputValue: this.state.levelForEdit,
                                inputOnChange: this.onLevelChange,
                                errorMessage: this.state.levelForEditErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: 'APPLY',
                                onClick: this.onUpdateClick,
                                disabled: this.state.updateButtonDisabled,
                            },
                            {
                                value: 'CANCEL',
                                buttonTheme: ButtonThemes.Secondary,
                                onClick: this.onCancelClick,
                                disabled: false,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    renderTable() {
        const header = {
            columns: [
                {
                    title: 'Minimum IP',
                },
                {
                    title: 'Maximum IP',
                },
                {
                    title: 'Level',
                },
            ],
        };

        const rows = this.state.rules.map(rule => {
            const rowProperty = {
                selected: this.state.selectedRules.includes(rule),
                deleted: this.state.removedRules.includes(rule),
                edited: this.state.updatedRules.includes(rule),
                disabled: this.state.disableRuleSelect,
            };

            return {
                id: rule.id,
                keyValue: `rules__${rule.id}`,
                dataValue: rule.id,
                rowProperty,
                columns: [
                    {
                        copy: rule.minIp,
                    },
                    {
                        copy: rule.maxIp,
                    },
                    {
                        copy: rule.level.name,
                    },
                ],
            };
        });

        const tableConfig = {
            enableSearch: true,
            searchableColumns: [0, 1, 2],
            hasIcons: true,
            errorMessageOverride: "There are no IP's that have been blacklisted on this account.",
            header: header,
            rows: rows,
            disabled: this.state.disableRuleSelect,
            onSelectedItemsChange: this.onSelectRule,
            isScrollable: false,
        };

        // return ( <SimpleTable header={header} rows={rows} errorMessageOverride={errorMessageOverride} hasIcons={true} enableSearch={true} searchableColumns={[0]} enableSearch={true} searchableColumns={[0,1,2]} /> )
        return <WidgetSelectableSimpleTable config={tableConfig} />;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__manage-ip-blacklist manage-modals">
                    <this.renderModalNavigation />
                    <h2>Manage IP Blacklist</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__manage-ip-blacklist manage-modals">
                    <this.renderModalNavigation />
                    <h2>Manage IP Blacklist</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__manage-ip-blacklist manage-modals">
                <this.renderModalNavigation />
                <h2>Manage IP Blacklist</h2>
                <p>Manage previously configured blacklist rules below.</p>
                <div className="modal__side-panel__manage-ip-blacklist__rules">
                    <this.renderTable />
                    <InputButtonWrapper>
                        <InputButton value="Create a Blacklist Rule" onClick={this.handleOnCreate} />
                        <InputButton value="EDIT" disabled={this.state.editButtonDisabled} onClick={this.onEditClick} />
                        <InputButton
                            buttonTheme={this.state.removeButtonState && ButtonThemes.Red}
                            value={this.state.removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                            disabled={this.state.removeButtonDisabled}
                            onClick={this.onRemoveClick}
                        />
                    </InputButtonWrapper>
                </div>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalManageIpBlacklist);
