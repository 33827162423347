import { v4 as uuidv4 } from 'uuid';
import {
    ComparisonTableData,
    DataType,
    EmptyData,
    ResourceComparisonData,
    SectionDashboardRequest,
    TableComparisonTableRow,
} from '../../../types';
import { unknownErrorData } from '../../../helpers/errors';
import { CubedField } from '../../../../types';
import { calculateVariance, formatVariance } from '../../../helpers/variance-helpers';

type UseResourceTableComparisonArgs = {
    resourceData: ResourceComparisonData;
    request?: SectionDashboardRequest;
    dimension: CubedField;
};

const useResourceTableComparison = ({ resourceData, dimension }: UseResourceTableComparisonArgs) => {
    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        if (!resourceData?.request?.fields) {
            throw new Error('Expected fields in the request');
        }

        if (new Set(resourceData.request.fields).size !== resourceData.request.fields.length) {
            throw new Error('Duplicate table columns detected - please check the fields array in the request');
        }

        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        if (
            resource.status === 'success' &&
            resourceData.request.groupBy &&
            resourceData.request.groupBy === dimension
        ) {
            let data = {
                type: 'tableComparison' as DataType,
                status: 'success',
                totalRows: 0,
                request: resourceData.request,
                resource: resource.resource,
                columns: resourceData.request.fields.map(field => {
                    return {
                        __id: uuidv4(),
                        ...field,
                    };
                }),
                rows: [] as TableComparisonTableRow[],
            };

            resource.objects.forEach(object => {
                data.rows.push({
                    __id: object.__id,
                    dimension: {
                        values: {
                            [dimension.rawName]: {
                                ...object.values[dimension.rawName],
                            },
                        },
                    },
                    data: object,
                });
            });
            data.totalRows = resource.meta.total_count;

            if (comparisonResource.status === 'success') {
                comparisonResource.objects.forEach(object => {
                    let rowIndex = data.rows.findIndex(
                        row =>
                            row.dimension.values[dimension.rawName]?.value === object.values[dimension.rawName]?.value
                    );

                    if (!(rowIndex === -1)) {
                        data.rows[rowIndex] = {
                            ...data.rows[rowIndex],
                            comparisonData: object,
                        };
                    }
                });
            }

            // Calculate the variance for each table row
            if (data.rows.length > 0) {
                data.rows = data.rows.map(row => {
                    if (row.data?.values && row.comparisonData?.values) {
                        let values = {};
                        data.columns.forEach(column => {
                            if (!column.isDimension) {
                                const value = row.data?.values[column.rawName]?.rawValue || 0;
                                const comparisonValue = row.comparisonData?.values[column.rawName]?.rawValue || 0;
                                const variance = calculateVariance(+value, +comparisonValue);

                                values = {
                                    ...values,
                                    [column.rawName]: {
                                        value: formatVariance(variance),
                                        rawValue: variance,
                                    },
                                };
                            }
                        });

                        row.variance = { values: values };
                    }

                    return row;
                });
            }

            return data as ComparisonTableData;
        }

        return {
            ...resourceData,
            status: 'empty',
        } as EmptyData;
    }

    return unknownErrorData();
};

export default useResourceTableComparison;
