import React from 'react';
import styled from 'styled-components';
import { inputStyles } from './styles/input-styles';

// Context
import { FieldError, useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Validation
import buildValidators from '../validation/helpers/build-validators';
import { Validator } from '../types';

// Components & Types
import { DropdownOption } from '../../../section-dashboard/types';
import InputContainer from './components/input-container';
import InputErrorMessage from './components/input-error-message';

const StyledInput = styled.select<{ disabled: boolean }>`
    ${inputStyles}
    border-right: 5px solid transparent;

    &:focus {
        outline: none;
    }
    &::placeholder {
        font-weight: 400;
        font-size: 0.9em;
        color: ${props => props.theme.forms.inputs.placeHolderColor};
        opacity: 1;
    }

    ::-ms-input-placeholder {
        font-weight: 400;
        font-size: 0.9em;
        color: ${props => props.theme.forms.inputs.placeHolderColor};
    }

    cursor: pointer;
`;

const StyledOption = styled.option`
    font-family: inherit;
`;

export type InputSelectProps = {
    name: string;
    options: DropdownOption[];
    placeholder?: string;
    disabled?: boolean;
    validators?: Validator[];
};

const InputSelect = ({
    name,
    options,
    placeholder = 'Select an option',
    disabled,
    validators = [],
}: InputSelectProps) => {
    const { status } = useCubedFormContext();
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <InputContainer>
            <StyledInput
                disabled={!!disabled || status === 'disabled' || status === 'loading'}
                {...register(name, { ...buildValidators(validators) })}
            >
                <StyledOption value="" hidden>
                    {placeholder}
                </StyledOption>
                {options.map(option => (
                    <StyledOption key={option.value} value={option.value}>
                        {option.label}
                    </StyledOption>
                ))}
            </StyledInput>

            {errors[name] && <InputErrorMessage error={errors[name] as FieldError} />}
        </InputContainer>
    );
};

export default InputSelect;
