// Helpers
import { unknownErrorData } from '../../../helpers/errors';
import { getRawValueAsNumber, getValue } from '../../../helpers/resource-data';
import dateStringToUnix from '../../../../helpers/date-string-to-unix';

// Types
import { CubedField } from '../../../../types';
import { ResourceData, LineGraphData, LineGraphLegendOrder, LineGraphSeries } from '../../../types';
import { ResourceDataObject } from '../../../../react-query/types';
import { graphColours } from '../../../helpers/graph-colours';

export type UseResourceLineGraphArgs = {
    resourceData: ResourceData;
    dateDimension: CubedField;
    seriesField: CubedField;
    yAxis: CubedField;
    legendOrder?: LineGraphLegendOrder;
};

const getLatestValue = (series: LineGraphSeries) => {
    return series.data.slice(-1)[0].y;
};

const useResourceLineGraph = ({
    resourceData,
    dateDimension,
    seriesField,
    yAxis,
    legendOrder,
}: UseResourceLineGraphArgs): LineGraphData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const lineGraphData: LineGraphData = {
            type: 'lineGraph',
            status: 'success',
            metric: yAxis.displayName,
            series: [],
        };

        // Find the unique series names
        let uniqueSeriesNames = [];

        if (seriesField.isDimension) {
            uniqueSeriesNames = [
                ...new Set(
                    resourceData.objects.map(data => {
                        return getValue(data, seriesField.rawName);
                    })
                ),
            ];
        } else {
            uniqueSeriesNames.push(seriesField.displayName);
        }

        // create a series object for each series to be displayed in the graph
        uniqueSeriesNames.forEach((value, index) => {
            lineGraphData.series.push({
                name: value,
                data: [],
                total: 0,
                colour: graphColours[index % graphColours.length].solidGraphColour,
            });
        });

        // insert data for each series
        lineGraphData.series.forEach(series => {
            let rawValue: number;

            const pushData = (data: ResourceDataObject) => {
                series.data.push({
                    x: dateStringToUnix(getValue(data, dateDimension.rawName)),
                    y: rawValue,
                });
                series.total += rawValue;
            };

            resourceData.objects.forEach(data => {
                if (seriesField.isDimension) {
                    if (series.name === getValue(data, seriesField.rawName)) {
                        rawValue = getRawValueAsNumber(data, yAxis.rawName);
                        pushData(data);
                    }
                } else {
                    rawValue = getRawValueAsNumber(data, yAxis.rawName);
                    pushData(data);
                }
            });
        });

        if (legendOrder?.type === 'lastValue') {
            lineGraphData.series = lineGraphData.series.sort((a, b) => getLatestValue(b) - getLatestValue(a));
        }

        return lineGraphData;
    }

    return unknownErrorData();
};

export default useResourceLineGraph;
