import { v4 as uuidv4 } from 'uuid';

// Helpers
import { unknownErrorData } from '../../../helpers/errors';
import { graphColours } from '../../../helpers/graph-colours';
import { getRawValueAsNumber, getValue } from '../../../helpers/resource-data';

// Types
import { CubedField } from '../../../../types';
import { PieTableData, ResourceData } from '../../../types';

export type UseResourcePieChartTableArgs = {
    resourceData: ResourceData;
    chartDimension: CubedField;
};

const useResourcePieChartTable = ({ resourceData, chartDimension }: UseResourcePieChartTableArgs): PieTableData => {
    if (!resourceData) {
        throw new Error('Expected resourceData');
    }

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        if (!resourceData?.request?.fields) {
            throw new Error('Expected fields in the request');
        }

        if (!resourceData.request?.chartField) {
            throw new Error('Expected chartField in the request');
        }

        const chartField = resourceData.request.chartField;

        const total = resourceData.objects
            .map(data => getRawValueAsNumber(data, chartField.rawName))
            .reduce((a, b) => a + b);

        const seriesData = resourceData.objects.map((object, index) => {
            const colour = graphColours[index % graphColours.length].solidGraphColour;

            return {
                name: getValue(object, chartDimension.rawName),
                value: getRawValueAsNumber(object, chartField.rawName),
                color: colour,
            };
        });

        seriesData.sort((a, b) => b.value - a.value);

        const series = [
            {
                name: chartDimension.rawName,
                data: seriesData,
                total: total,
            },
        ];

        const rows = resourceData.objects.map(object => {
            const dimensionToMatch = getValue(object, chartDimension.rawName);
            const matchedGraphSeries = series[0].data.find(series => series.name === dimensionToMatch);

            return {
                ...object,
                colour: matchedGraphSeries?.color,
            };
        });

        return {
            type: 'pieChartTable',
            status: 'success',
            request: resourceData.request,
            series: series,
            columns: resourceData.request.fields.map(field => {
                return {
                    __id: uuidv4(),
                    ...field,
                };
            }),
            rows: rows,
            totalRows: resourceData.meta.total_count,
        };
    }
    return unknownErrorData();
};

export default useResourcePieChartTable;
