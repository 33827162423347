/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import Papa from 'papaparse';

// Types & ENUMS
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { RootState } from '../../redux/reducers/core';

// Components
import ModalNavigation from '../../components/modal-navigation';
import InputButton from '../../components/inputs/input-button';
import ProgressBar from '../../components/progress-bar';
import WarningMessage from '../../components/warning-message';
import FormFileUpload from '../../components/form-fields/form-file-upload';

// Helpers
import { generateUrl } from '../../helpers/request-builder';
import LoadingSpinner from '../../components/loading-spinner';

// Resources
import {
    CONFIG_PAGE_SEGMENTS_CSV_RESOURCE,
    PAGE_SEGMENTS_FILE_UPLOAD_RESOURCE,
} from '../../configurations/resources-config';

// Queries
import useCSVUploadResource from '../../react-query/hooks/use-csv-upload-resource';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../../react-query/types';

// Helpers
import { validateFileType, validatePageTypeGroupingCsvFile } from '../../helpers/validate-csv-file';

const StyledContainer = styled.div`
    margin-top: 20px;
    padding: 1px 20px 20px 20px;
    margin-right: auto;
`;
const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;

const StyledUploadSegmentButton = styled.div`
    margin-top: 7px;
    margin-left: 10px;
`;
const StyledContainerDivider = styled.div`
    height: 0.8px;
    background: grey;
    width: 100%;
    opacity: 0.2;
    padding: 0;
    margin-top: 15px;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
`;
const StyledItemContainer = styled.div`
    padding: 5px;
`;

const StyledButtonWithMargin = styled.div`
    margin-top: 20px;
`;

type PageSegment = {
    category: string;
    content_length: string;
    content_type: string;
    geography: string;
    homepage: string;
    language: string;
    market: string;
    topic: string;
    url: string;
};

type CSVHeaders = [
    'category',
    'content_length',
    'content_type',
    'geography',
    'homepage',
    'language',
    'market',
    'topic',
    'url'
];

const LayoutModalAddPageSegments = () => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [fileValidationMessage, setFileValidationMessage] = useState('');
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [formData, setFormData] = useState<FormData | null>(null);
    const [selectedFileIsValid, setSelectedFileIsValid] = useState(false);
    const [exportedUrlSet, setExportedUrlSet] = useState<Set<string>>(new Set()); // Set performs better than array for checking if a value exists - O(1) vs O(n)

    const accountId: string = useSelector((state: RootState) => state.account.id);
    const accountToken: string = useSelector((state: RootState) => state.account.token);
    const dispatch = useDispatch();

    const {
        isLoading,
        isUploading,
        uploadComplete,
        uploadProgress,
        returningMessage,
        uploadError,
        pageError,
        resetStateFunction,
    } = useCSVUploadResource({
        fileUploadProgressResource: PAGE_SEGMENTS_FILE_UPLOAD_RESOURCE,
        formData,
    });

    const exportedUrlQuery = useFetchResource({
        resource: CONFIG_PAGE_SEGMENTS_CSV_RESOURCE,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(data => {
                return {
                    ...data,
                };
            });
        },
    });

    useEffect(() => {
        if (exportedUrlQuery.data && exportedUrlQuery.data.length > 0) {
            setExportedUrlSet(new Set(exportedUrlQuery.data.map(data => data.url)));
        } else {
            setExportedUrlSet(new Set());
        }
    }, [exportedUrlQuery.data]);

    const onUploadPageSegmentsCSVClick = () => {
        const data = new FormData();
        data.append('upload_type', 'page_segments');
        const blob = new Blob([selectedFile!], { type: selectedFile?.type });
        data.append('file', blob);
        data.append('account_id', accountId);

        setFormData(data);
    };

    const downloadPageSegmentsCSV = async () => {
        setIsDownloading(true);
        const downloadUrl = generateUrl(
            'config',
            'page-segments-csv',
            [
                { key: 'limit', value: 0 },
                { key: 'format', value: 'csv' },
            ],
            true
        )!;
        const filename = `page_segments-${accountToken}.csv`;
        await Axios({
            method: 'GET',
            url: downloadUrl,
            responseType: 'blob',
            withCredentials: true,
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                setIsDownloading(false);
            })
            .catch(() => {
                setIsDownloading(false);
                dispatch(addNotification({ copy: 'Export has failed.', type: NotificationMessageType.Error }));
            });
    };
    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileValidationMessage('');
        setSelectedFileIsValid(false);
        setUploadButtonDisabled(true);

        const file = event.target.files?.[0] as File;

        // Check the file extension
        const { isValid, selectedFile, message } = validateFileType(file, 'csv');
        if (!isValid) {
            setSelectedFileIsValid(isValid);
            setSelectedFile(selectedFile);
            setFileValidationMessage(message);

            return;
        }

        const reader = new FileReader();
        let firstRowData: CSVHeaders | undefined;
        let dataCsv: PageSegment[];
        try {
            reader.readAsText(file);
            reader.onload = fileEvent => {
                const csvData: string = fileEvent.target?.result as string;
                Papa.parse(csvData, {
                    skipEmptyLines: true,
                    header: true,
                    complete: result => {
                        firstRowData = result.data[0] as CSVHeaders;
                        dataCsv = result.data as PageSegment[];
                    },
                });

                if (firstRowData === undefined) {
                    setSelectedFile(null);
                    setSelectedFileIsValid(false);
                    setFileValidationMessage(
                        "Sorry, this file doesn't appear to have data. Please check it and try again."
                    );
                    return;
                }
                if (!validateCSVFileHeaders(firstRowData)) {
                    setSelectedFile(null);
                    setSelectedFileIsValid(false);
                    return;
                }

                const { isValidated, fileValidationMessage } = validatePageTypeGroupingCsvFile(
                    firstRowData,
                    dataCsv,
                    exportedUrlSet
                );

                setFileValidationMessage(fileValidationMessage);

                if (!isValidated) {
                    setSelectedFileIsValid(false);
                    setSelectedFile(null);
                    return;
                }

                setSelectedFile(file);
                setSelectedFileIsValid(true);
                setUploadButtonDisabled(false);
            };
            reader.onerror = () => {
                setSelectedFile(null);
                setSelectedFileIsValid(false);
                setFileValidationMessage(
                    'Sorry, there was a problem reading this file. Please check it and try again.'
                );
            };
        } catch (error) {
            setSelectedFile(null);
            setSelectedFileIsValid(false);
            setFileValidationMessage('Sorry, there was a problem reading this file. Please check it and try again.');
        }
    };

    const validateCSVFileHeaders = (headerData: string[]) => {
        const sortedHeaders = Object.keys(headerData).sort();

        const preSortedFileHeaders = [
            'category',
            'content_length',
            'content_type',
            'geography',
            'homepage',
            'language',
            'market',
            'topic',
            'url',
        ];
        if (sortedHeaders.length !== preSortedFileHeaders.length) {
            setFileValidationMessage('The file does not contain the correct number of columns.');
            return false;
        }
        for (let i = 0; i < sortedHeaders.length; i++) {
            if (sortedHeaders[i] !== preSortedFileHeaders[i]) {
                setFileValidationMessage('The file does not contain the correct columns.');
                return false;
            }
        }
        return true;
    };

    const cancelOrFinishUpload = () => {
        setIsDownloading(false);
        setFileValidationMessage('');
        setUploadButtonDisabled(true);
        setSelectedFile(null);
        setSelectedFileIsValid(false);
        resetStateFunction();
    };

    const PageLayout = ({ children }: { children: React.ReactNode }) => {
        const dispatch = useDispatch();

        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: () => dispatch(removeModal()),
                disabled: false,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return (
            <div>
                <ModalNavigation buttons={modalNavigationButtons} />
                <h2>Add or Export Page Type Groupings</h2>
                <p>
                    Import your page type groupings data into Cubed to enable advanced content reporting. It is
                    important to use the defined CSV format - you can download it{' '}
                    <a href="/files/page_segments.csv">here</a>.
                </p>
                {children}
            </div>
        );
    };

    const UploadSegment = () => {
        return (
            <StyledItemContainer>
                <h3>Upload Page Type Groupings</h3>
                <StyledButtonContainer>
                    <FormFileUpload
                        onChange={onFileInputChange}
                        inputKeyValue={'add-page-segment_file-upload'}
                        errorMessage={fileValidationMessage}
                        isValid={selectedFileIsValid}
                        uploadedFile={selectedFile?.name}
                    />
                    <StyledUploadSegmentButton>
                        <InputButton
                            buttonTheme={ButtonThemes.Primary}
                            value={'Upload'}
                            onClick={onUploadPageSegmentsCSVClick}
                            disabled={uploadButtonDisabled}
                            hidden={!selectedFileIsValid}
                        />
                    </StyledUploadSegmentButton>
                    <StyledUploadSegmentButton>
                        <InputButton
                            buttonTheme={ButtonThemes.Primary}
                            value={'Cancel'}
                            onClick={cancelOrFinishUpload}
                            disabled={uploadButtonDisabled}
                            hidden={!selectedFileIsValid}
                        />
                    </StyledUploadSegmentButton>
                </StyledButtonContainer>
            </StyledItemContainer>
        );
    };

    const DownloadSegment = () => {
        return (
            <StyledItemContainer>
                <h3>Export Page Type Groupings</h3>
                <StyledButtonContainer>
                    <InputButton value={'Download'} onClick={downloadPageSegmentsCSV} isLoading={isDownloading} />
                </StyledButtonContainer>
            </StyledItemContainer>
        );
    };

    const HomePage = () => {
        return (
            <>
                <UploadSegment />
                <StyledContainerDivider />
                <DownloadSegment />
            </>
        );
    };
    const UploadProgressBar = () => {
        return (
            <div className="file-upload-progress">
                {returningMessage && <p>{returningMessage}</p>}
                <p>{'Uploading page segments.'}</p>
                <ProgressBar value={parseFloat(uploadProgress) / 100} difference={100} label="Upload Progress" />
                <StyledButtonWithMargin>
                    <InputButton
                        buttonTheme={ButtonThemes.Primary}
                        value={'Done'}
                        hidden={uploadProgress !== '100.00'}
                        onClick={cancelOrFinishUpload}
                    />
                </StyledButtonWithMargin>
            </div>
        );
    };
    const Error = () => {
        return (
            <WarningMessage
                copy={
                    'There was a server issue getting this page ready. Please try again later or contact support@cubed.com.'
                }
            />
        );
    };

    return (
        <PageLayout>
            <StyledContainer>
                {isLoading ? (
                    <LoadingSpinner />
                ) : (isUploading || uploadComplete) && !pageError ? (
                    <UploadProgressBar />
                ) : pageError || uploadError ? (
                    <Error />
                ) : (
                    <HomePage />
                )}
            </StyledContainer>
        </PageLayout>
    );
};

export default LayoutModalAddPageSegments;
