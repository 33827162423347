// Helpers
import { unknownErrorData } from '../../../helpers/errors';
import { getRawValueAsNumber, getValue } from '../../../helpers/resource-data';

// Types
import { CubedField } from '../../../../types';
import { ResourceData, PieData } from '../../../types';
import { graphColours } from '../../../helpers/graph-colours';

export type UseResourcePieChartArgs = {
    resourceData: ResourceData;
    dimension: CubedField;
};

const useResourcePieChart = ({ resourceData, dimension }: UseResourcePieChartArgs): PieData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (!resourceData.request?.chartField) {
        throw new Error('Expected chartField in the request');
    }

    if (resourceData.status === 'success') {
        const field = resourceData.request.chartField;

        const total = resourceData.objects
            .map(data => getRawValueAsNumber(data, field.rawName))
            .reduce((a, b) => a + b);

        const seriesData = resourceData.objects.map((object, index) => {
            const colour = graphColours[index % graphColours.length].solidGraphColour;

            return {
                name: getValue(object, dimension.rawName),
                value: getRawValueAsNumber(object, field.rawName),
                color: colour,
            };
        });

        seriesData.sort((a, b) => b.value - a.value);

        const series = [
            {
                name: dimension.rawName,
                data: seriesData,
                total: total,
            },
        ];

        return {
            type: 'pieChart',
            status: 'success',
            request: resourceData.request,
            series: series,
        };
    }

    return unknownErrorData();
};

export default useResourcePieChart;
