/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import styled from 'styled-components';
import * as Accordion from '@radix-ui/react-accordion';

import IconChevronLeft from '../../../components/icons/chevron-left';

const StyledMenuContainer = styled.div`
    & button {
        all: inherit;
        cursor: 'pointer';
    }
`;

const StyledContainer = styled(Accordion.Header)<{ $sectionIndex: number }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: ${props => (props.$sectionIndex === 0 ? '0px 0px 8px 0px' : '8px 0px')};
    padding: 0px;
    height: 45px;

    &[data-state='closed'] {
        margin-bottom: 0px;
    }

    &[data-state='closed'] ${StyledMenuContainer} {
        display: none;
    }
`;

const StyledChevron = styled(IconChevronLeft)`
    width: 10px;
    min-width: 10spx;
    position: relative;
    transform: rotate(90deg);
    color: ${props => props.theme.sectionDashboard.section.iconColor};
`;

const StyledTrigger = styled(Accordion.Trigger)`
    font-family: inherit;
    display: flex;
    align-items: center;
    gap: 10px;
    border: 0;
    padding: 0;
    background-color: unset;
    min-width: 0;

    & button {
        all: inherit;
    }

    &[data-state='closed'] ${StyledChevron} {
        transform: rotate(270deg);
        position: relative;
    }
`;

const StyledTitle = styled.h1`
    font-size: 0.95rem;
    font-weight: ${props => props.theme.sectionDashboard.section.headingFontWeight};
    color: ${props => props.theme.sectionDashboard.section.headingTextColor};
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: ${props => props.theme.sectionDashboard.grid.breakpoints.sm}) {
        font-size: 0.8rem;
    }
`;

export type SectionDashboardSectionAccordionProps = {
    sectionIndex: number;
    title: string | (() => string);
    menu?: () => JSX.Element;
    defaultValues: string[];
    children: React.ReactElement;
};

const SectionDashboardSectionAccordion = ({
    sectionIndex,
    title,
    menu,
    defaultValues,
    children,
}: SectionDashboardSectionAccordionProps) => {
    const displayMenu = typeof menu === 'function' ? menu() : null;
    const displayTitle = typeof title === 'function' ? title() : title;

    const [renderedSections, setRenderedSections] = useState<string[]>(defaultValues);

    const handleAccordionChange = (value: string[]) => {
        value.forEach((section: string) => {
            if (!renderedSections.includes(section)) {
                setRenderedSections([...renderedSections, section]);
            }
        });
    };

    return (
        <Accordion.Root type="multiple" defaultValue={defaultValues} onValueChange={handleAccordionChange}>
            <Accordion.Item
                value={`sd-section-${sectionIndex}`}
                key={`${displayTitle}${sectionIndex}`}
                data-testid={`sd-section-${sectionIndex}`}
            >
                <StyledContainer $sectionIndex={sectionIndex}>
                    <StyledTrigger>
                        <StyledChevron />
                        <StyledTitle title={displayTitle}>{displayTitle}</StyledTitle>
                    </StyledTrigger>
                    {displayMenu && <StyledMenuContainer>{displayMenu}</StyledMenuContainer>}
                </StyledContainer>

                <Accordion.Content>
                    {renderedSections.includes(`sd-section-${sectionIndex}`) && children}
                </Accordion.Content>
            </Accordion.Item>
        </Accordion.Root>
    );
};

export default SectionDashboardSectionAccordion;
