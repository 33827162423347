import { isEqual } from 'lodash';

// React Dependencies
import axios from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

// enums
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { removePopup, setPopup } from '../../redux/actions/popup';

// Helpers
import { generatePath, generateUrl, generateUrlDetail } from '../../helpers/request-builder';

// Components
import ModalNavigation from '../../components/modal-navigation';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import WidgetAccordion from '../../widgets/accordion';
import { DropdownOption } from '../../types';

const StyledTablePages = styled.div`
    display: flex;
`;
type BrandSplitSectorResponse = {
    id: number;
    sector: string;
};
type BrandSplitPurchasedResponse = {
    id: number;
    purchased: string;
};
type BrandSplitPriceResponse = {
    id: number;
    price: string;
};
type BrandSplitInnovationResponse = {
    id: number;
    innovation: string;
};
type BrandSplitCategoryResponse = {
    id: number;
    category: string;
};
type BrandSplitSizeResponse = {
    id: number;
    size: string;
};
type BrandSplitConfigsResponse = {
    id: number;
    sector: BrandSplitSectorResponse;
    purchased: BrandSplitPurchasedResponse;
    price: BrandSplitPriceResponse;
    innovation: BrandSplitInnovationResponse;
    category: BrandSplitCategoryResponse;
    size: BrandSplitSizeResponse;
};
type BrandSplitConfigData = {
    id: number;
    sector?: string;
    purchased?: string;
    price?: string;
    innovation?: string;
    category?: string;
    size?: string;
};
type BrandConfigRelatedFieldFormData = {
    sector?: string | number;
    purchased?: string | number;
    price?: string | number;
    innovation?: string | number;
    category?: string | number;
    size?: string | number;
};

type RelatedFields = 'sector' | 'purchased' | 'price' | 'innovation' | 'category' | 'size';
type RelatedFieldsObjectWithId = {
    id: number;
    sector?: string;
    purchased?: string;
    price?: string;
    innovation?: string;
    category?: string;
    size?: string;
};

const LayoutModalManageBrandSplitConfig = () => {
    // flags
    const [isLoading, setIsLoading] = useState(true);
    const [pageError, setPageError] = useState(false);

    const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
    const [removeButtonLoading, setRemoveButtonLoading] = useState(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState(true);

    // edit brand split config accordion
    const [onEdit, setOnEdit] = useState(false);
    const [editBrandSplitConfigAccordionOpen, setEditBrandSplitConfigAccordionOpen] = useState(false);
    const [editBrandSplitConfigAccordionVisible, setEditBrandSplitConfigAccordionVisible] = useState(false);
    const [applyEditButtonLoading, setApplyEditButtonLoading] = useState(false);
    const [editCancelDisabled, setEditCancelDisabled] = useState(false);

    // add brand split config accordion
    const [onCreate, setOnCreate] = useState(false);
    const [addBrandSplitConfigAccordionOpen, setAddBrandSplitConfigAccordionOpen] = useState(false);
    const [addBrandSplitConfigAccordionVisible, setAddBrandSplitConfigAccordionVisible] = useState(false);
    const [createBrandConfigLoading, setCreateBrandConfigLoading] = useState(false);
    const [createCancelDisabled, setCreateCancelDisabled] = useState(false);
    const [createBrandSplitConfigDisabled, setCreateBrandSplitConfigDisabled] = useState(false);

    const [brandSplitConfigs, setBrandSplitConfigs] = useState<BrandSplitConfigData[]>([]);
    const [selectedBrandSplitConfig, setSelectedBrandSplitConfig] = useState<BrandSplitConfigData[]>([]);

    // dropdown options data
    const [brandSplitSectors, setBrandSplitSectors] = useState<DropdownOption[]>([]);
    const [brandSplitPurchases, setBrandSplitPurchases] = useState<DropdownOption[]>([]);
    const [brandSplitPrices, setBrandSplitPrices] = useState<DropdownOption[]>([]);
    const [brandSplitInnovations, setBrandSplitInnovations] = useState<DropdownOption[]>([]);
    const [brandSplitCategories, setBrandSplitCategories] = useState<DropdownOption[]>([]);
    const [brandSplitSizes, setBrandSplitSizes] = useState<DropdownOption[]>([]);

    // form select states
    const [brandSplitFormElements, setBrandSplitFormElements] = useState<BrandConfigRelatedFieldFormData>({
        sector: undefined,
        purchased: undefined,
        price: undefined,
        innovation: undefined,
        category: undefined,
        size: undefined,
    });
    const [brandSplitConfigBeforeEdit, setBrandSplitConfigBeforeEdit] = useState<BrandConfigRelatedFieldFormData>({
        sector: undefined,
        purchased: undefined,
        price: undefined,
        innovation: undefined,
        category: undefined,
        size: undefined,
    });

    // Form Error States
    const [brandSplitSectorFormError, setBrandSplitSectorFormError] = useState('');
    const [brandSplitPurchasedFormError, setBrandSplitPurchasedFormError] = useState('');
    const [brandSplitPriceFormError, setBrandSplitPriceFormError] = useState('');
    const [brandSplitInnovationFormError, setBrandSplitInnovationFormError] = useState('');
    const [brandSplitCategoryFormError, setBrandSplitCategoryFormError] = useState('');
    const [brandSplitSizeFormError, setBrandSplitSizeFormError] = useState('');

    const dispatch = useDispatch();
    const modalHeading = 'Manage Brand Split Config';

    useEffect(() => {
        fetchBrandSplitConfigAndRelatedFields();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onCloseClick = () => {
        if (onCreate || onEdit || removeButtonLoading) {
            const message = removeButtonLoading
                ? "Are you sure you want to exit? Applied delete request won't be reverted."
                : createBrandConfigLoading
                ? "Are you sure you want to exit? Applied request to create new brand split config won't be reverted."
                : applyEditButtonLoading
                ? "Are you sure you want to exit? Applied request to edit old brand split config won't be reverted."
                : 'Are you sure you want to exit? Unsaved changes will be lost.';
            dispatch(
                setPopup({
                    title: 'Confirm Close?',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: message,
                    },
                    buttons: [
                        {
                            onClick: closePopupAndModal,
                            value: 'CONFIRM EXIT',
                        },
                        {
                            onClick: () => dispatch(removePopup()),
                            value: 'CANCEL',
                            style: 'secondary',
                        },
                    ],
                })
            );
            return;
        }
        dispatch(removeModal());
    };

    const closePopupAndModal = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const RenderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: onCloseClick,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const fetchBrandSplitRelatedFieldData = async (
        relatedFieldName: RelatedFields,
        setStateFunction: Dispatch<SetStateAction<DropdownOption[]>>
    ) => {
        try {
            axios({
                method: 'GET',
                url: generateUrl('config', `brand-split-${relatedFieldName}`, [
                    { key: 'active', value: 1 },
                    { key: 'limit', value: 0 },
                ]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(response => {
                const responseData = response.data.objects.map((data: RelatedFieldsObjectWithId) => ({
                    value: data.id,
                    label: data[relatedFieldName],
                    name: data[relatedFieldName],
                }));

                setStateFunction(responseData);
            });
        } catch (error) {
            console.error(error);
            setPageError(true);
        }
    };

    const fetchBrandSplitConfigAndRelatedFields = () => {
        try {
            Promise.all([
                fetchBrandSplitRelatedFieldData('sector', setBrandSplitSectors),
                fetchBrandSplitRelatedFieldData('purchased', setBrandSplitPurchases),
                fetchBrandSplitRelatedFieldData('price', setBrandSplitPrices),
                fetchBrandSplitRelatedFieldData('innovation', setBrandSplitInnovations),
                fetchBrandSplitRelatedFieldData('category', setBrandSplitCategories),
                fetchBrandSplitRelatedFieldData('size', setBrandSplitSizes),
                fetchBrandSplitConfig(),
            ]).then(() => {
                setIsLoading(false);
            });
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setPageError(true);
        }
    };

    const fetchBrandSplitConfig = async () => {
        try {
            const brandSplitConfigUrl = generateUrl('config', 'brand-split-config', [
                { key: 'active', value: 1 },
                { key: 'limit', value: 0 },
            ]);
            const brandSplitConfigResponse = await axios.get(brandSplitConfigUrl, { withCredentials: true });
            const configs = brandSplitConfigResponse.data.objects.map((data: BrandSplitConfigsResponse) => {
                return {
                    id: data.id,
                    sector: data.sector.sector,
                    purchased: data.purchased.purchased,
                    price: data.price.price,
                    innovation: data.innovation.innovation,
                    category: data.category.category,
                    size: data.size.size,
                };
            });
            setBrandSplitConfigs(configs);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setPageError(true);
        }
    };

    const onRemoveBrandSplitConfigClick = () => {
        dispatch(
            setPopup({
                title: 'Confirm delete?',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you want to delete.',
                },
                buttons: [
                    {
                        onClick: deleteSelectedBrandSplitConfig,
                        value: 'CONFIRM DELETE',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'CANCEL',
                        style: 'secondary',
                    },
                ],
            })
        );
    };

    const deleteSelectedBrandSplitConfig = () => {
        dispatch(removePopup());
        setRemoveButtonLoading(true);
        setEditButtonDisabled(true);

        const itemToDeletePayload = {
            objects: [],
            deleted_objects: selectedBrandSplitConfig.map((item: BrandSplitConfigData) => {
                return `/api/config/brand-split-config/${item.id}/`;
            }),
        };

        axios({
            method: 'PATCH',
            url: generateUrl('config', 'brand-split-config'),
            data: itemToDeletePayload,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(() => {
                setRemoveButtonLoading(false);
                dispatch(
                    addNotification({
                        copy: 'Delete Successful.',
                        type: NotificationMessageType.Success,
                    })
                );
                setIsLoading(true);
                setSelectedBrandSplitConfig([]);
                setEditButtonDisabled(true);
                setRemoveButtonDisabled(true);
                fetchBrandSplitConfig().then(() => setIsLoading(false));
            })
            .catch(error => {
                console.error(error);
                setPageError(true);
            });
    };

    const onBrandSplitConfigClick = (brandSplitConfig: BrandSplitConfigData) => {
        const isSelected = selectedBrandSplitConfig.some(
            (item: BrandSplitConfigData) => item.id === brandSplitConfig.id
        );

        let tempSelectedBrandConfig: BrandSplitConfigData[] = isSelected
            ? selectedBrandSplitConfig.filter((item: BrandSplitConfigData) => item.id !== brandSplitConfig.id)
            : [...selectedBrandSplitConfig, brandSplitConfig];

        const selectedLength = tempSelectedBrandConfig.length;
        const tempEditButtonDisabled = selectedLength === 0 || selectedLength > 1;
        const tempRemoveButtonDisabled = selectedLength === 0;

        setSelectedBrandSplitConfig(tempSelectedBrandConfig);
        setEditButtonDisabled(tempEditButtonDisabled);
        setRemoveButtonDisabled(tempRemoveButtonDisabled);
    };

    const renderTable = () => {
        const row = brandSplitConfigs.map((brandSplitConfig: BrandSplitConfigData) => {
            const selected = selectedBrandSplitConfig.includes(brandSplitConfig)!;
            const rowProperty = {
                selected: selected,
                disabled: onEdit || onCreate || removeButtonLoading,
            };

            return {
                columns: [
                    {
                        copy: brandSplitConfig.sector || '',
                    },
                    {
                        copy: brandSplitConfig.purchased || '',
                    },
                    {
                        copy: brandSplitConfig.price || '',
                    },
                    {
                        copy: brandSplitConfig.innovation || '',
                    },
                    {
                        copy: brandSplitConfig.category || '',
                    },
                    {
                        copy: brandSplitConfig.size || '',
                    },
                ],
                key: `brand-split-config__${brandSplitConfig.id}`,
                keyValue: `brand-split-config__${brandSplitConfig.id}`,
                dataValue: brandSplitConfig.id,
                ...rowProperty,
                onClick: () => onBrandSplitConfigClick(brandSplitConfig),
            };
        });
        const header = {
            columns: [
                {
                    title: 'Sector',
                },
                {
                    title: 'Purchased',
                },
                {
                    title: 'Price',
                },
                {
                    title: 'Innovation',
                },
                {
                    title: 'Category',
                },
                {
                    title: 'Size',
                },
            ],
        };
        return (
            <SimpleTable
                isLoading={isLoading}
                header={header}
                rows={row}
                hasIcons={false}
                selectDisabled={onEdit || onCreate || removeButtonLoading}
                isScrollable={true}
                errorMessageOverride={'Could not find any Brand Split Config.'}
            />
        );
    };

    const resetFormErrors = () => {
        setBrandSplitSectorFormError('');
        setBrandSplitPurchasedFormError('');
        setBrandSplitPriceFormError('');
        setBrandSplitInnovationFormError('');
        setBrandSplitCategoryFormError('');
        setBrandSplitSizeFormError('');
    };

    const onCancelEditClick = () => {
        setOnEdit(false);
        setEditBrandSplitConfigAccordionOpen(false);
        setEditBrandSplitConfigAccordionVisible(false);
        setApplyEditButtonLoading(false);
        resetFormErrors();
        resetFormItem();
        setRemoveButtonDisabled(false);
        setEditButtonDisabled(false);
    };

    const onEditBrandSplitConfigClick = () => {
        const tempFormElements: BrandConfigRelatedFieldFormData = {
            sector: brandSplitSectors.find(item => item.label === selectedBrandSplitConfig[0].sector)?.value,
            purchased: brandSplitPurchases.find(item => item.label === selectedBrandSplitConfig[0].purchased)?.value,
            price: brandSplitPrices.find(item => item.label === selectedBrandSplitConfig[0].price)?.value,
            innovation: brandSplitInnovations.find(item => item.label === selectedBrandSplitConfig[0].innovation)
                ?.value,
            category: brandSplitCategories.find(item => item.label === selectedBrandSplitConfig[0].category)?.value,
            size: brandSplitSizes.find(item => item.label === selectedBrandSplitConfig[0].size)?.value,
        };

        if (
            tempFormElements.sector &&
            tempFormElements.purchased &&
            tempFormElements.price &&
            tempFormElements.innovation &&
            tempFormElements.category &&
            tempFormElements.size
        ) {
            setBrandSplitFormElements({ ...tempFormElements });
            setBrandSplitConfigBeforeEdit({ ...tempFormElements });
            setOnEdit(true);
            setEditCancelDisabled(false);
            setEditBrandSplitConfigAccordionOpen(true);
            setEditBrandSplitConfigAccordionVisible(true);
            setEditButtonDisabled(true);
            setRemoveButtonDisabled(true);
        }
    };

    const resetFormItem = () => {
        setBrandSplitFormElements({
            sector: '',
            purchased: '',
            price: '',
            innovation: '',
            category: '',
            size: '',
        });
    };

    const generatePathForField = (configType: string, field: string | number, formElements: DropdownOption[]) => {
        const selectedItem = formElements.find((item: DropdownOption) => item.value === field);
        const value = selectedItem ? selectedItem.value : '';
        return generatePath('config', `brand-split-${configType}`, value.toString());
    };

    const onApplyEditClick = () => {
        if (isEqual(brandSplitFormElements, brandSplitConfigBeforeEdit)) {
            setOnEdit(false);
            setEditBrandSplitConfigAccordionOpen(false);
            setEditBrandSplitConfigAccordionVisible(false);
            setApplyEditButtonLoading(false);
            resetFormErrors();
            setSelectedBrandSplitConfig([]);
            setRemoveButtonDisabled(true);
            setEditButtonDisabled(true);
            dispatch(
                addNotification({
                    copy: 'Nothing changed to update.',
                    type: NotificationMessageType.Warn,
                })
            );
            return;
        }

        setApplyEditButtonLoading(true);
        setEditCancelDisabled(true);

        if (
            brandSplitFormElements.category &&
            brandSplitFormElements.innovation &&
            brandSplitFormElements.price &&
            brandSplitFormElements.purchased &&
            brandSplitFormElements.sector &&
            brandSplitFormElements.size
        ) {
            const data = {
                category: generatePathForField('category', brandSplitFormElements.category, brandSplitCategories),
                innovation: generatePathForField(
                    'innovation',
                    brandSplitFormElements.innovation,
                    brandSplitInnovations
                ),
                price: generatePathForField('price', brandSplitFormElements.price, brandSplitPrices),
                purchased: generatePathForField('purchased', brandSplitFormElements.purchased, brandSplitPurchases),
                sector: generatePathForField('sector', brandSplitFormElements.sector, brandSplitSectors),
                size: generatePathForField('size', brandSplitFormElements.size, brandSplitSizes),
            };

            axios({
                method: 'PATCH',
                url: generateUrlDetail('config', 'brand-split-config', String(selectedBrandSplitConfig[0].id))!,
                withCredentials: true,
                data: data,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(() => {
                    setOnEdit(false);
                    setEditBrandSplitConfigAccordionOpen(false);
                    setEditBrandSplitConfigAccordionVisible(false);
                    setIsLoading(true);
                    setSelectedBrandSplitConfig([]);
                    setApplyEditButtonLoading(false);
                    resetFormItem();
                    resetFormErrors();
                    setEditCancelDisabled(false);
                    dispatch(
                        addNotification({
                            copy: 'Record updated successfully',
                            type: NotificationMessageType.Success,
                        })
                    );
                    fetchBrandSplitConfig().then(() => setIsLoading(false));
                })
                .catch(error => {
                    console.error(error);
                    setIsLoading(false);
                    setPageError(true);
                });
        }
    };

    const onCancelAddClick = () => {
        setEditButtonDisabled(selectedBrandSplitConfig.length !== 1);
        setRemoveButtonDisabled(selectedBrandSplitConfig.length === 0);
        setAddBrandSplitConfigAccordionOpen(false);
        setAddBrandSplitConfigAccordionVisible(false);

        resetFormItem();
        resetFormErrors();
        setOnCreate(false);
    };

    const onAddBrandSplitConfigClick = () => {
        setOnCreate(true);
        setAddBrandSplitConfigAccordionOpen(true);
        setAddBrandSplitConfigAccordionVisible(true);
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);
    };

    const onCreateNewBrandSplitClick = () => {
        setCreateBrandConfigLoading(true);
        setCreateCancelDisabled(true);

        setBrandSplitSectorFormError(brandSplitFormElements.sector ? '' : 'Please select valid sector.');
        setBrandSplitPurchasedFormError(brandSplitFormElements.purchased ? '' : 'Please select valid purchased.');
        setBrandSplitPriceFormError(brandSplitFormElements.price ? '' : 'Please select valid price.');
        setBrandSplitInnovationFormError(brandSplitFormElements.innovation ? '' : 'Please select valid innovation.');
        setBrandSplitCategoryFormError(brandSplitFormElements.category ? '' : 'Please select valid category.');
        setBrandSplitSizeFormError(brandSplitFormElements.size ? '' : 'Please select valid size.');

        if (
            brandSplitFormElements.category &&
            brandSplitFormElements.innovation &&
            brandSplitFormElements.price &&
            brandSplitFormElements.purchased &&
            brandSplitFormElements.sector &&
            brandSplitFormElements.size
        ) {
            const data = {
                category: generatePath('config', 'brand-split-category', brandSplitFormElements.category.toString()),
                innovation: generatePath(
                    'config',
                    'brand-split-innovation',
                    brandSplitFormElements.innovation.toString()
                ),
                price: generatePath('config', 'brand-split-price', brandSplitFormElements.price.toString()),
                purchased: generatePath('config', 'brand-split-purchased', brandSplitFormElements.purchased.toString()),
                sector: generatePath('config', 'brand-split-sector', brandSplitFormElements.sector.toString()),
                size: generatePath('config', 'brand-split-size', brandSplitFormElements.size.toString()),
            };
            axios({
                method: 'POST',
                url: generateUrl('config', 'brand-split-config'),
                data: data,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(() => {
                setCreateBrandConfigLoading(false);
                setCreateCancelDisabled(false);
                setIsLoading(true);
                setAddBrandSplitConfigAccordionOpen(false);
                setAddBrandSplitConfigAccordionVisible(false);
                fetchBrandSplitConfig().then(() => setIsLoading(false));
                setOnCreate(false);
                setSelectedBrandSplitConfig([]);
                setCreateBrandSplitConfigDisabled(false);
            });
        } else {
            setCreateBrandConfigLoading(false);
            setCreateCancelDisabled(false);
        }
    };

    const renderEditAccordion = () => {
        if (!editBrandSplitConfigAccordionVisible) {
            return null;
        }
        const accordionButtons = [
            {
                value: 'APPLY',
                onClick: onApplyEditClick,
                isLoading: applyEditButtonLoading,
            },
            {
                value: 'CANCEL',
                onClick: onCancelEditClick,
                disabled: editCancelDisabled,
            },
        ];
        const accordion = [
            {
                header: 'Edit Brand Split Config',
                required: false,
                open: editBrandSplitConfigAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Sector',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a sector for the brand split.',
                                inputOptions: brandSplitSectors,
                                inputKeyValue: 'editBrandSplit__sectors',
                                inputValue: brandSplitFormElements.sector,
                                customPlaceholder: selectedBrandSplitConfig[0].sector,
                                errorMessage: '',
                                inputOnChange: handleSelectSector,
                            },
                            {
                                label: 'Purchased',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a purchased for the brand split.',
                                inputOptions: brandSplitPurchases,
                                inputKeyValue: 'editBrandSplit__purchased',
                                inputValue: brandSplitFormElements.purchased,
                                customPlaceholder: selectedBrandSplitConfig[0].purchased,
                                errorMessage: '',
                                inputOnChange: handleSelectPurchased,
                            },
                            {
                                label: 'Price',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a price for the brand split.',
                                inputOptions: brandSplitPrices,
                                inputKeyValue: 'editBrandSplit__price',
                                inputValue: brandSplitFormElements.price,
                                customPlaceholder: selectedBrandSplitConfig[0].price,
                                errorMessage: '',
                                inputOnChange: handleSelectPrice,
                            },
                            {
                                label: 'Innovation',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a innovation for the brand split.',
                                inputOptions: brandSplitInnovations,
                                inputKeyValue: 'editBrandSplit__innovation',
                                inputValue: brandSplitFormElements.innovation,
                                customPlaceholder: selectedBrandSplitConfig[0].innovation,
                                errorMessage: '',
                                inputOnChange: handleSelectInnovation,
                            },
                            {
                                label: 'Category',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a category for the brand split.',
                                inputOptions: brandSplitCategories,
                                inputKeyValue: 'editBrandSplit__category',
                                inputValue: brandSplitFormElements.category,
                                customPlaceholder: selectedBrandSplitConfig[0].category,
                                errorMessage: '',
                                inputOnChange: handleSelectCategory,
                            },
                            {
                                label: 'Size',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a size for the brand split.',
                                inputOptions: brandSplitSizes,
                                inputKeyValue: 'editBrandSplit__size',
                                inputValue: brandSplitFormElements.size,
                                customPlaceholder: selectedBrandSplitConfig[0].size,
                                errorMessage: '',
                                inputOnChange: handleSelectSize,
                            },
                        ],
                        buttons: accordionButtons,
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordion} />;
    };

    const handleSelectSector = (event: React.ChangeEvent<HTMLInputElement>) => {
        let sector = parseInt(event?.target?.value);
        let tempBrandSplit: BrandConfigRelatedFieldFormData = brandSplitFormElements;
        tempBrandSplit.sector = sector;
        setBrandSplitFormElements(tempBrandSplit);
    };
    const handleSelectPurchased = (event: React.ChangeEvent<HTMLInputElement>) => {
        let purchased = parseInt(event?.target?.value);
        let tempBrandSplit: BrandConfigRelatedFieldFormData = brandSplitFormElements;
        tempBrandSplit.purchased = purchased;
        setBrandSplitFormElements(tempBrandSplit);
    };
    const handleSelectPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        let price = parseInt(event?.target?.value);
        let tempBrandSplit: BrandConfigRelatedFieldFormData = brandSplitFormElements;
        tempBrandSplit.price = price;

        setBrandSplitFormElements(tempBrandSplit);
    };
    const handleSelectInnovation = (event: React.ChangeEvent<HTMLInputElement>) => {
        let innovation = parseInt(event?.target?.value);
        let tempBrandSplit: BrandConfigRelatedFieldFormData = brandSplitFormElements;
        tempBrandSplit.innovation = innovation;
        setBrandSplitFormElements(tempBrandSplit);
    };
    const handleSelectCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
        let category = parseInt(event?.target?.value);
        let tempBrandSplit: BrandConfigRelatedFieldFormData = brandSplitFormElements;
        tempBrandSplit.category = category;
        setBrandSplitFormElements(tempBrandSplit);
    };
    const handleSelectSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        let size = parseInt(event?.target?.value);
        let tempBrandSplit: BrandConfigRelatedFieldFormData = brandSplitFormElements;
        tempBrandSplit.size = size;
        setBrandSplitFormElements(tempBrandSplit);
    };

    const renderAddAccordion = () => {
        if (!addBrandSplitConfigAccordionVisible) {
            return null;
        }

        const accordion = [
            {
                header: 'Add New Brand Split Config',
                required: false,
                open: addBrandSplitConfigAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Sector',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a sector for the brand split.',
                                inputOptions: brandSplitSectors,
                                inputKeyValue: 'createBrandSplit__sectors',
                                customPlaceholder: 'Select a Sector',
                                errorMessage: brandSplitSectorFormError,
                                inputOnChange: handleSelectSector,
                            },
                            {
                                label: 'Purchased',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a purchased for the brand split.',
                                inputOptions: brandSplitPurchases,
                                inputKeyValue: 'createBrandSplit__purchased',
                                customPlaceholder: 'Select a Purchased',
                                errorMessage: brandSplitPurchasedFormError,
                                inputOnChange: handleSelectPurchased,
                            },
                            {
                                label: 'Price',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a price for the brand split.',
                                inputOptions: brandSplitPrices,
                                inputKeyValue: 'createBrandSplit__price',
                                customPlaceholder: 'Select a Price',
                                errorMessage: brandSplitPriceFormError,
                                inputOnChange: handleSelectPrice,
                            },
                            {
                                label: 'Innovation',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a innovation for the brand split.',
                                inputOptions: brandSplitInnovations,
                                inputKeyValue: 'createBrandSplit__innovation',
                                customPlaceholder: 'Select an Innovation',
                                errorMessage: brandSplitInnovationFormError,
                                inputOnChange: handleSelectInnovation,
                            },
                            {
                                label: 'Category',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a category for the brand split.',
                                inputOptions: brandSplitCategories,
                                inputKeyValue: 'createBrandSplit__category',
                                customPlaceholder: 'Select a Category',
                                errorMessage: brandSplitCategoryFormError,
                                inputOnChange: handleSelectCategory,
                            },
                            {
                                label: 'Size',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Please choose a size for the brand split.',
                                inputOptions: brandSplitSizes,
                                inputKeyValue: 'createBrandSplit__size',
                                customPlaceholder: 'Select a Size',
                                errorMessage: brandSplitSizeFormError,
                                inputOnChange: handleSelectSize,
                            },
                        ],
                        buttons: [
                            {
                                value: 'CREATE',
                                onClick: onCreateNewBrandSplitClick,
                                disabled: createBrandSplitConfigDisabled,
                                buttonMessage: 'Please select all the options.',
                                isLoading: createBrandConfigLoading,
                            },
                            {
                                value: 'CANCEL',
                                onClick: onCancelAddClick,
                                disabled: createCancelDisabled,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordion} />;
    };

    if (pageError) {
        return (
            <div>
                <RenderModalNavigation />
                <h2>{modalHeading}</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <>
            <RenderModalNavigation />
            <h2>{modalHeading}</h2>
            <p>You can manage the Brand Split Config here. You can create, edit, remove the records.</p>
            {renderTable()}

            <StyledTablePages>
                <InputButtonWrapper>
                    <InputButton
                        value="ADD BRAND SPLIT CONFIG"
                        disabled={onEdit || onCreate || removeButtonLoading || isLoading}
                        onClick={onAddBrandSplitConfigClick}
                    />
                    <InputButton value="EDIT" disabled={editButtonDisabled} onClick={onEditBrandSplitConfigClick} />
                    <InputButton
                        buttonTheme={ButtonThemes.Red}
                        value="REMOVE"
                        disabled={removeButtonDisabled}
                        onClick={onRemoveBrandSplitConfigClick}
                        isLoading={removeButtonLoading}
                    />
                </InputButtonWrapper>
            </StyledTablePages>

            {renderEditAccordion()}
            {renderAddAccordion()}
        </>
    );
};

export default LayoutModalManageBrandSplitConfig;
