import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Required Actions
import { signOut } from '../redux/actions/sign-out';

// Required Views
import ViewLoadingSplash from './loading-splash';

const ViewSignOut = () => {
    const { user } = useSelector(state => state);

    const dispatch = useDispatch();

    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(signOut()); // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!user.isLoggedIn) {
            setIsLoading(false);
            setIsLoggedOut(true);
        }
    }, [user.isLoggedIn]);

    if (isLoading) {
        return <ViewLoadingSplash />;
    } else if (isLoggedOut) {
        return <Navigate push to="/" />;
    }

    return <ViewLoadingSplash />;
};

export default ViewSignOut;
