import { useEffect, useState } from 'react';
import { RequestSort, RequestFilter, SectionDashboardRequest } from '../../types';
import { useAppSelector } from '../../../redux/hooks';
import { defaultRequestLimit } from '../../configurations/default-request-limit';
import { isEqual } from 'lodash';

const useRequestTable = (request: SectionDashboardRequest) => {
    const dates = useAppSelector(state => state.date);
    const limit = request.rowsPerPage || defaultRequestLimit;

    const [tableRequest, setTableRequest] = useState<SectionDashboardRequest>({ ...request, offset: 0, limit: limit });

    const actions = () => {
        return {
            setOrderBy: (currentFilters: RequestSort[]) => {
                setTableRequest(prevTableRequest => ({ ...prevTableRequest, orderBy: currentFilters }));
            },
            setFilters: (currentFilters: RequestFilter[]) => {
                setTableRequest(prevTableRequest => ({ ...prevTableRequest, tableFilters: currentFilters }));
            },
            increaseOffset: () => {
                setTableRequest(prevTableRequest => ({
                    ...prevTableRequest,
                    offset: prevTableRequest.offset ? prevTableRequest.offset + limit : limit,
                    limit: limit,
                }));
            },
            decreaseOffset: () => {
                setTableRequest(prevTableRequest => ({
                    ...prevTableRequest,
                    offset: prevTableRequest.offset && prevTableRequest.offset - limit,
                    limit: limit,
                }));
            },
            jumpToFirst: () => {
                setTableRequest(prevTableRequest => ({ ...prevTableRequest, offset: 0, limit: limit }));
            },
            jumpToLast: (totalPages: number) => {
                setTableRequest(prevTableRequest => ({
                    ...prevTableRequest,
                    offset: limit * totalPages - limit,
                    limit: limit,
                }));
            },
        };
    };

    useEffect(() => {
        setTableRequest({ ...tableRequest, ...actions() });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (request.sectionFilters) {
            if (!isEqual(request.sectionFilters, tableRequest.sectionFilters)) {
                setTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }

        if (request.pageFilters) {
            if (!isEqual(request.pageFilters, tableRequest.pageFilters)) {
                setTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }

        if (request.showEmpty !== tableRequest.showEmpty) {
            setTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
        }

        if (request.groupBy) {
            if (!isEqual(request.groupBy, tableRequest.groupBy)) {
                setTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }

        if (request.rowsPerPage) {
            if (!isEqual(request.rowsPerPage, tableRequest.rowsPerPage)) {
                setTableRequest({ ...request, ...actions(), offset: 0, limit: limit });
            }
        }
    }, [request]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setTableRequest(prevTableRequest => ({ ...prevTableRequest, offset: 0, limit: limit }));
    }, [tableRequest.filters, tableRequest.orderBy, dates, limit]);

    return tableRequest;
};

export default useRequestTable;
