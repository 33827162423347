import React from 'react';
import styled from 'styled-components';

// Context
import { useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
`;

const StyledCheckbox = styled.input`
    width: 18px;
    height: 18px;
    margin: 0;
    accent-color: ${props => props.theme.forms.inputs.inputCheckbox.accentColor};
    outline: none;
    cursor: pointer;
    border-radius: 2px;
`;

const StyledLabel = styled.label`
    margin: 0;
    font-size: 0.9em;
    font-weight: 500;
    color: ${props => props.theme.forms.labels.color};
`;

export type InputCheckboxProps = {
    name: string;
    label: string;
    checked?: boolean;
    disabled?: boolean;
};

const InputCheckbox = ({ name, label, checked, disabled }: InputCheckboxProps) => {
    const { status } = useCubedFormContext();
    const { register } = useFormContext();

    return (
        <StyledContainer>
            <StyledLabel htmlFor={name}>{label}:</StyledLabel>
            <StyledCheckbox
                type="checkbox"
                defaultChecked={checked}
                disabled={!!disabled || status === 'disabled' || status === 'loading'}
                {...register(name)}
            />
        </StyledContainer>
    );
};

export default InputCheckbox;
