import React, { Fragment } from 'react';
import styled from 'styled-components';

// Icons
import IconArrowUpLong from '../../../components/icons/arrow-up-long';
import IconArrowDownLong from '../../../components/icons/arrow-down-long';
import IconFilter from '../../../components/icons/filter';

// Helpers
import { columnDataTypeIsNumber, getValue } from '../../helpers/table-data';
import sortByVariance from '../../helpers/sort-by-variance';

// Types
import { TableComparisonTableRow, TableColumn, TotalComparisonData, SectionDashboardRequest } from '../../types';
import { formatVariance } from '../../helpers/variance-helpers';
import { CubedField } from '../../../types';

// Styles
const StyledTable = styled.table<{ height: number; width: number }>`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: ${props => (props.height > 1 && props.width > 4 ? '0.9rem' : '0.8rem')};
    color: ${props => props.theme.sectionDashboard.widget.table.textColor};
`;

const StyledHeaderTR = styled.tr`
    th:not(:last-child) {
        border-right: 1px solid ${props => props.theme.sectionDashboard.widget.table.column.borderColor};
    }
`;

const StyledTR = styled.tr`
    th {
        border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.header.borderColor};
    }
    td:not(:last-child):nth-child(3n + 1) {
        border-right: 1px solid ${props => props.theme.sectionDashboard.widget.table.column.borderColor};
    }
`;

const StyledTHead = styled.thead`
    position: sticky;
    top: 0;
    z-index: 3;

    th:not(:last-child):nth-child(3n + 1) {
        border-right: 1px solid ${props => props.theme.sectionDashboard.widget.table.column.borderColor};
    }
`;

const StyledTH = styled.th<{ height: number; width: number }>`
    background: ${props => props.theme.sectionDashboard.widget.table.header.backgroundColor};
    text-align: left;
    font-weight: 700;
    position: sticky;
    z-index: 2;
    text-align: right;
    white-space: nowrap;

    &:first-child {
        z-index: 3;
        left: 0;
    }
`;

const StyledSubTH = styled(StyledTH)<{ isDimension?: boolean; height: number; width: number }>`
    font-size: ${props => (props.width < 5 ? '0.7rem' : props.isDimension ? '0.9rem' : '0.8rem')};
    text-transform: ${props => !props.isDimension && 'uppercase'};
    font-weight: ${props => (props.isDimension ? '700' : '500')};
    text-align: ${props => props.isDimension && 'left'};
    white-space: ${props => props.width < 7 && 'wrap'};
`;

const StyledHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`;

const StyledHeaderTitleContainer = styled.div`
    padding: 10px;
`;

const StyledSubHeaderContainer = styled(StyledHeaderContainer)<{ clickDisabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    width: 100%;
    height: 100%;
    padding: 10px;
    cursor: ${props => !props.clickDisabled && 'pointer'};

    &:hover {
        background-color: ${props => !props.clickDisabled && props.theme.sectionDashboard.widget.menu.backgroundHover};
    }
`;

const StyledIconContainer = styled.div`
    & svg {
        width: 10px;
    }
    & path {
        fill: ${props => props.theme.sectionDashboard.widget.table.header.iconColor};
    }
`;

const StyledTD = styled.td<{
    width: number;
    height: number;
    colCount: number;
    isTotal?: boolean;
    dataType?: number;
    variance?: number;
}>`
    padding: 10px;
    border-bottom: 1px solid ${props => props.theme.sectionDashboard.widget.table.cell.borderColor};
    background: ${props =>
        props.isTotal
            ? props.theme.sectionDashboard.widget.table.totals.backgroundColor
            : props.theme.sectionDashboard.widget.table.cell.backgroundColor};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
    text-align: ${props => (props.dataType && columnDataTypeIsNumber(props.dataType) ? 'right' : 'left')};
    color: ${props =>
        props.variance
            ? props.variance > 0
                ? props.theme.sectionDashboard.widget.variance.textColorPositive
                : props.variance < 0
                ? props.theme.sectionDashboard.widget.variance.textColorNegative
                : props.theme.sectionDashboard.widget.table.textColor
            : props.theme.sectionDashboard.widget.table.textColor};
    font-weight: ${props => props.variance && 600};

    &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
    }
`;

const StyledValue = styled.span<{ width: number; height: number; value: string }>`
    color: ${props =>
        (props.value === 'No Data' || props.value === 'N/A') &&
        props.theme.sectionDashboard.widget.table.cell.secondaryTextColor};
    font-size: ${props =>
        (props.value === 'No Data' || props.value === 'N/A') &&
        (props.height > 1 && props.width > 1 ? '0.8rem' : '0.7rem')};
`;

const StyledGraphColourIndicator = styled.div<{ colour: string }>`
    display: inline-block;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    background-color: ${props => props.colour};
    border-radius: 50%;
`;

const StyledTotalTR = styled.tr`
    position: sticky;
    bottom: 0;
    z-index: 1;
    font-weight: 600;

    td:not(:last-child):nth-child(3n + 1) {
        border-right: 1px solid ${props => props.theme.sectionDashboard.widget.table.column.borderColor};
    }
`;

export type SectionDashboardTableComparisonProps = {
    request?: SectionDashboardRequest;
    columns: TableColumn[];
    rows: TableComparisonTableRow[];
    totalData?: TotalComparisonData;
    width: number;
    height: number;
    ascendingSorts: string[];
    descendingSorts: string[];
    withFilters: string[];
};

const SectionDashboardTableComparison = ({
    request,
    columns,
    rows,
    totalData,
    width,
    height,
    ascendingSorts,
    descendingSorts,
    withFilters,
}: SectionDashboardTableComparisonProps) => {
    const excludeOrderBy = request?.excludeOrderBy?.map(field => field.rawName) || [];

    const sortedByVariance = request?.orderBy && request?.orderBy[0].sortByVariance;
    if (sortedByVariance) {
        rows = sortByVariance(request, rows);
    }

    const onHeaderClick = (type: string, column: TableColumn) => {
        if (request?.orderBy && request.setOrderBy) {
            if (column.rawName === request?.orderBy[0].field.rawName) {
                if (request.orderBy[0].orderByDirection === 'asc') {
                    request.setOrderBy([
                        { ...request.orderBy[0], sortByVariance: type === 'variance', orderByDirection: 'desc' },
                    ]);
                } else {
                    request.setOrderBy([
                        { ...request.orderBy[0], sortByVariance: type === 'variance', orderByDirection: 'asc' },
                    ]);
                }
            } else {
                request.setOrderBy([
                    {
                        field: column as unknown as CubedField,
                        sortByVariance: type === 'variance',
                        orderByDirection: 'desc',
                    },
                ]);
            }
        }
    };

    return (
        <StyledTable width={width} height={height}>
            <StyledTHead>
                <StyledHeaderTR>
                    {columns.map(column => {
                        return (
                            <StyledTH
                                key={`${column.__id}-header`}
                                width={width}
                                height={height}
                                colSpan={column.isDimension ? 1 : 3}
                            >
                                {!column.isDimension && (
                                    <StyledHeaderTitleContainer>
                                        <StyledHeaderContainer>{column.displayName} </StyledHeaderContainer>
                                    </StyledHeaderTitleContainer>
                                )}
                            </StyledTH>
                        );
                    })}
                </StyledHeaderTR>
                <StyledTR>
                    {columns.map(column => {
                        if (column.isDimension) {
                            return (
                                <StyledSubTH
                                    isDimension={true}
                                    key={`${column.__id}-dimension`}
                                    width={width}
                                    height={height}
                                >
                                    {!column.isDimension ? column.displayName : ''}
                                    {column.isDimension && (
                                        <StyledHeaderTitleContainer>
                                            <StyledHeaderContainer>{column.displayName}</StyledHeaderContainer>
                                        </StyledHeaderTitleContainer>
                                    )}
                                </StyledSubTH>
                            );
                        } else {
                            return (
                                <Fragment key={`${column.__id}-metric-fragment`}>
                                    <StyledSubTH key={`${column.__id}-this-period`} width={width} height={height}>
                                        <StyledSubHeaderContainer
                                            onClick={() =>
                                                !excludeOrderBy.includes(column.rawName) &&
                                                onHeaderClick('thisPeriod', column)
                                            }
                                        >
                                            This Period
                                            {withFilters.includes(column.rawName) && (
                                                <StyledIconContainer>
                                                    <IconFilter />
                                                </StyledIconContainer>
                                            )}
                                            {ascendingSorts.includes(column.rawName) && !sortedByVariance && (
                                                <StyledIconContainer>
                                                    <IconArrowUpLong />
                                                </StyledIconContainer>
                                            )}
                                            {descendingSorts.includes(column.rawName) && !sortedByVariance && (
                                                <StyledIconContainer>
                                                    <IconArrowDownLong />
                                                </StyledIconContainer>
                                            )}
                                        </StyledSubHeaderContainer>
                                    </StyledSubTH>
                                    <StyledSubTH key={`${column.__id}-comparison-period`} width={width} height={height}>
                                        <StyledSubHeaderContainer clickDisabled={true}>
                                            Comparison Period
                                        </StyledSubHeaderContainer>
                                    </StyledSubTH>
                                    <StyledSubTH key={`${column.__id}-variance`} width={width} height={height}>
                                        <StyledSubHeaderContainer
                                            onClick={() =>
                                                !excludeOrderBy.includes(column.rawName) &&
                                                onHeaderClick('variance', column)
                                            }
                                        >
                                            Variance %
                                            {ascendingSorts.includes(column.rawName) && sortedByVariance && (
                                                <StyledIconContainer>
                                                    <IconArrowUpLong />
                                                </StyledIconContainer>
                                            )}
                                            {descendingSorts.includes(column.rawName) && sortedByVariance && (
                                                <StyledIconContainer>
                                                    <IconArrowDownLong />
                                                </StyledIconContainer>
                                            )}
                                        </StyledSubHeaderContainer>
                                    </StyledSubTH>
                                </Fragment>
                            );
                        }
                    })}
                </StyledTR>
            </StyledTHead>
            <tbody>
                {rows.map((row: TableComparisonTableRow) => {
                    return (
                        <StyledTR key={row.__id}>
                            {columns.map(column => {
                                if (column.isDimension) {
                                    let value = 'Unknown';
                                    if (row.dimension) {
                                        value = getValue(row.dimension, column.rawName);
                                    }

                                    return (
                                        <StyledTD
                                            key={column.__id}
                                            width={width}
                                            height={height}
                                            colCount={columns.length}
                                            title={value}
                                        >
                                            {row.colour ? <StyledGraphColourIndicator colour={row.colour} /> : null}
                                            {value}
                                        </StyledTD>
                                    );
                                } else {
                                    let thisPeriod = 'No Data';
                                    let comparisonPeriod = 'No Data';
                                    let variance = 'No Data';

                                    if (row.data) {
                                        thisPeriod = getValue(row.data, column.rawName);
                                    }
                                    if (row.comparisonData) {
                                        comparisonPeriod = getValue(row.comparisonData, column.rawName);
                                    }

                                    if (row.variance) {
                                        variance = getValue(row.variance, column.rawName);
                                    }
                                    return (
                                        <Fragment key={`${column.__id}-fragment`}>
                                            <StyledTD
                                                key={`${column.__id}-this-period`}
                                                width={width}
                                                height={height}
                                                colCount={columns.length}
                                                title={thisPeriod}
                                                dataType={column.dataType}
                                            >
                                                <StyledValue width={width} height={height} value={thisPeriod}>
                                                    {thisPeriod}
                                                </StyledValue>
                                            </StyledTD>
                                            <StyledTD
                                                key={`${column.__id}-comparison-period`}
                                                width={width}
                                                height={height}
                                                colCount={columns.length}
                                                title={comparisonPeriod}
                                                dataType={column.dataType}
                                            >
                                                <StyledValue width={width} height={height} value={comparisonPeriod}>
                                                    {comparisonPeriod}
                                                </StyledValue>
                                            </StyledTD>
                                            <StyledTD
                                                key={`${column.__id}-variance`}
                                                width={width}
                                                height={height}
                                                colCount={columns.length}
                                                title={variance}
                                                dataType={column.dataType}
                                                variance={+variance}
                                            >
                                                <StyledValue width={width} height={height} value={variance}>
                                                    {variance}
                                                </StyledValue>
                                            </StyledTD>
                                        </Fragment>
                                    );
                                }
                            })}
                        </StyledTR>
                    );
                })}
                {totalData && totalData.status === 'success' && (
                    <StyledTotalTR key={`${columns[0].__id}-total-row`}>
                        {columns.map((column, index) => {
                            if (column.isDimension) {
                                return (
                                    <StyledTD
                                        key={`total-${column.rawName}`}
                                        width={width}
                                        height={height}
                                        colCount={columns.length}
                                        title={index === 0 ? 'total' : getValue(totalData.totals!, column.rawName)}
                                        isTotal={true}
                                    >
                                        {index === 0 ? 'Total' : getValue(totalData.totals!, column.rawName)}
                                    </StyledTD>
                                );
                            } else {
                                let total = 'No Data';
                                let comparisonTotal = 'No Data';
                                let variance = 'No Data';

                                if (totalData.totals) {
                                    total = index === 0 ? 'Total' : getValue(totalData.totals, column.rawName);
                                }

                                if (totalData.comparisonTotals) {
                                    comparisonTotal = getValue(totalData.comparisonTotals, column.rawName);
                                }

                                if (totalData.varianceTotals) {
                                    variance = getValue(totalData.varianceTotals, column.rawName);
                                }

                                return (
                                    <Fragment key={`${column.__id}-total-fragment`}>
                                        <StyledTD
                                            key={`${column.__id}-total`}
                                            width={width}
                                            height={height}
                                            colCount={columns.length}
                                            title={total}
                                            isTotal={true}
                                            dataType={column.dataType}
                                        >
                                            {total}
                                        </StyledTD>
                                        <StyledTD
                                            key={`${column.__id}-comparison-total`}
                                            width={width}
                                            height={height}
                                            colCount={columns.length}
                                            title={comparisonTotal}
                                            isTotal={true}
                                            dataType={column.dataType}
                                        >
                                            {comparisonTotal}
                                        </StyledTD>
                                        <StyledTD
                                            key={`${column.__id}-var-total`}
                                            width={width}
                                            height={height}
                                            colCount={columns.length}
                                            title={variance}
                                            isTotal={true}
                                            dataType={column.dataType}
                                            variance={+variance}
                                        >
                                            {formatVariance(+variance)}
                                        </StyledTD>
                                    </Fragment>
                                );
                            }
                        })}
                    </StyledTotalTR>
                )}
            </tbody>
        </StyledTable>
    );
};

export default SectionDashboardTableComparison;
