// Helpers
import { unknownErrorData } from '../../helpers/errors';
import { sparkLineDataSeries } from '../../helpers/create-data-series';

// Types
import { CubedField } from '../../../types';
import { ResourceData, SparkLineData } from '../../types';

export type UseResourceSparkLineArgs = {
    resourceData: ResourceData;
    metric: CubedField;
    dateDimension: CubedField;
};

const useResourceSparkLine = ({ resourceData, metric, dateDimension }: UseResourceSparkLineArgs): SparkLineData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const sparkLineData: SparkLineData = {
            type: 'sparkLine',
            status: 'success',
            metric: metric,
            series: [],
            mostRecentTotal:
                resourceData.objects.length > 0
                    ? (resourceData.objects[resourceData.objects.length - 1].values[metric.rawName].rawValue as number)
                    : 0,
        };

        sparkLineData.series = sparkLineDataSeries(resourceData.objects, metric, dateDimension);

        return sparkLineData;
    }

    return unknownErrorData();
};

export default useResourceSparkLine;
