// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { generateUrl, generatePath, generateUrlDetail } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { isEmail } from '../../helpers/validator';
import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';
import WidgetAccordion from '../../widgets/accordion';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class LayoutModalManageUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            revokeButtonState: true,
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: false,
            editUserAccordionOpen: false,
            editUserAccordionVisible: false,
            editButtonDisabled: true,
            updateButtonDisabled: true,
            revokeButtonDisabled: true,
            disableUserSelect: false,
            roleOptions: [],
            users: [],
            selectedUsers: [],
            revokedUsers: [],
            updatedUsers: [],
            userRoleForEdit: '',
            userRoleForEditErrorMessage: '',
            inviteExistingUserAccordionVisible: false,
            inviteExistingUserAccordionOpen: false,
            inviteExistingUserButtonDisabled: true,
            userRoleForInvite: '',
            userRoleForInviteErrorMessage: '',
            userEmailForInvite: '',
            userEmailForInviteErrorMessage: '',
            isLoadingInviteExistingUser: false,
        };

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onEditUserChange = this.onEditUserChange.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.handleOnCreate = this.handleOnCreate.bind(this);
        this.handleOnInvite = this.handleOnInvite.bind(this);

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onRevokeAccessClick = this.onRevokeAccessClick.bind(this);
        this.onSelectUser = this.onSelectUser.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.onUserEmailForInviteChange = this.onUserEmailForInviteChange.bind(this);
        this.onUserRoleForInviteChange = this.onUserRoleForInviteChange.bind(this);
        this.onInviteExistingUserClick = this.onInviteExistingUserClick.bind(this);
        this.onCancelInviteExistingUserClick = this.onCancelInviteExistingUserClick.bind(this);

        this.renderAccordion = this.renderAccordion.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderInviteExistingUsersAccordion = this.renderInviteExistingUsersAccordion.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.handleOnInviteExistingUser = this.handleOnInviteExistingUser.bind(this);
    }

    componentDidMount() {
        const config = [
            {
                resourceGroup: 'config',
                resourceName: 'groups',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
            {
                resourceGroup: 'config',
                resourceName: 'account-user',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                    {
                        key: 'account__id',
                        value: this.props.account.id,
                    },
                    {
                        key: 'user__is_active',
                        value: 1,
                    },
                ],
            },
        ];

        this.props.getRequest(config);
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // Sorting Out Roles
                const roleOptions = [
                    {
                        keyValue: `role-option_Admin`,
                        value: 1,
                        name: 'Admin',
                    },
                    {
                        keyValue: `role-option_API`,
                        value: 5,
                        name: 'API',
                    },
                    {
                        keyValue: `role-option_Viewer`,
                        value: 3,
                        name: 'Viewer',
                    },
                ];

                const users = this.props.request.data[1].objects
                    .filter(user => user.user.id !== this.props.user.id)
                    .map(user => {
                        return {
                            id: user.id,
                            user: {
                                id: user.user.id,
                                name: `${user.user.first_name} ${user.user.last_name}`,
                                username: user.user.username,
                            },
                            role: {
                                id: user.group.id,
                                name: user.group.name,
                            },
                        };
                    });

                this.setState({
                    isLoading: false,
                    roleOptions: roleOptions,
                    users: users,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }

            this.props.delRequest();
        }

        // Enable the save changes and cancel when a user is added into either the revoked or updated arrays
        if (
            this.state.saveChangesButtonHidden &&
            (this.state.updatedUsers.length > 0 || this.state.revokedUsers.length > 0)
        ) {
            this.setState({
                saveChangesButtonHidden: false,
                closeButtonState: 'cancel',
            });
        }
    }

    onSaveChangesClick() {
        this.setState({
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        const requests = [];

        if (this.state.updatedUsers.length > 0) {
            requests.push(
                Axios({
                    method: 'PATCH',
                    url: generateUrl('config', 'account-user'),
                    data: {
                        objects: this.state.updatedUsers.map(user => {
                            return {
                                resource_uri: generatePath('config', 'account-user', user.id),
                                group: generatePath('config', 'groups', user.role.id),
                                user: generatePath('config', 'lite-users', user.user.id),
                            };
                        }),
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        }

        if (this.state.revokedUsers.length > 0) {
            this.state.revokedUsers.forEach(user => {
                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrlDetail('config', 'account-user', user.id),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        Axios.all(requests)
            .then(
                Axios.spread((...requestRes) => {
                    this.props.removeModal();
                    this.props.addNotification({
                        copy: 'The changes to the users have been successfully made.',
                        type: NotificationMessageType.Success,
                    });
                })
            )
            .catch(error => {
                this.props.addNotification({
                    copy: 'There was an issue trying to update these users. Please try again later',
                    type: NotificationMessageType.Error,
                });
                this.setState({
                    saveChangesButtonHidden: false,
                    saveChangesButtonLoading: false,
                    closeButtonDisabled: false,
                });
            });
    }

    onEditUserChange(event) {
        this.setState({
            userRoleForEdit: event.target.value,
            updateButtonDisabled: false,
        });
    }

    onUserEmailForInviteChange(event) {
        const email = event.target.value;
        this.setState({
            userEmailForInvite: email,
            inviteExistingUserButtonDisabled: !email || !this.state.userRoleForInvite,
        });
    }
    onUserRoleForInviteChange(event) {
        const role = event.target.value;
        this.setState({
            userRoleForInvite: role,
            inviteExistingUserButtonDisabled: !this.state.userEmailForInvite || !role,
        });
    }
    onInviteExistingUserClick() {
        if (!isEmail(this.state.userEmailForInvite)) {
            this.setState({
                userEmailForInviteErrorMessage: 'Please enter a valid email address.',
            });
            return;
        }
        this.setState({
            isLoadingInviteExistingUser: true,
        });
        Axios({
            method: 'POST',
            url: generateUrlDetail(this.props.account.token, 'users', 'invite-existing', [], false),
            data: {
                username: this.state.userEmailForInvite,
                group: this.state.userRoleForInvite,
            },

            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                this.onCancelInviteExistingUserClick();
                this.props.addNotification({
                    copy: 'User has been invited successfully.',
                    type: NotificationMessageType.Success,
                });
            })
            .catch(error => {
                // error.response.data.message can be undefined
                const errorMessage = error.response.data.message || 'There was an issue inviting this user.';
                this.setState({
                    userEmailForInviteErrorMessage: errorMessage,
                    isLoadingInviteExistingUser: false,
                });
            });
    }
    handleOnInviteExistingUser() {
        this.setState({
            inviteExistingUserAccordionVisible: true,
            inviteExistingUserAccordionOpen: true,
            updateButtonDisabled: true,
            disableUserSelect: true,
            editButtonDisabled: true,
            revokeButtonDisabled: true,
            userRoleForInvite: '',
            userEmailForInvite: '',
        });
    }
    onCancelInviteExistingUserClick(event) {
        this.setState({
            inviteExistingUserAccordionVisible: false,
            inviteExistingUserAccordionOpen: false,
            updateButtonDisabled: true,
            disableUserSelect: false,
            editButtonDisabled: true,
            revokeButtonDisabled: true,
            userRoleForInvite: '',
            userEmailForInvite: '',
            userEmailForInviteErrorMessage: '',
            userRoleForInviteErrorMessage: '',
            isLoadingInviteExistingUser: false,
        });
    }

    handleOnCreate() {
        this.props.setModal('CreateUser', {});
    }

    handleOnInvite() {
        this.props.setModal('InviteUser', {});
    }

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
            this.props.removeModal();
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to proceed without saving your changes? Doing so means you will lose any details entered.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    }

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.props.removeModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    onUpdateClick() {
        const roleId = parseInt(this.state.userRoleForEdit);
        const roleName = this.state.roleOptions.filter(role => role.value === roleId)[0].name;

        const updatedUsers = [].concat(this.state.updatedUsers);

        this.state.selectedUsers.forEach(user => {
            if (user.role.id !== roleId) {
                const userIndex = updatedUsers.indexOf(user);

                if (userIndex >= 0) {
                    updatedUsers[userIndex].role.id = roleId;
                    updatedUsers[userIndex].role.name = roleName;
                } else {
                    user.role.id = roleId;
                    user.role.name = roleName;

                    updatedUsers.push(user);
                }
            }
        });

        this.setState({
            updatedUsers: updatedUsers,
            editUserAccordionOpen: false,
            editUserAccordionVisible: false,
            updateButtonDisabled: true,
            selectedUsers: [],
            userRoleForEdit: '',
            disableUserSelect: false,
        });
    }

    onEditClick() {
        this.setState({
            editUserAccordionOpen: true,
            editUserAccordionVisible: true,
            updateButtonDisabled: true,
            disableUserSelect: true,
            editButtonDisabled: true,
            revokeButtonDisabled: true,
            userRoleForEdit: '',
        });
    }

    onCancelClick() {
        this.setState({
            editUserAccordionOpen: false,
            editUserAccordionVisible: false,
            disableUserSelect: false,
            editButtonDisabled: true,
            revokeButtonDisabled: true,
            selectedUsers: [],
        });
    }

    onRevokeAccessClick() {
        let revokedUsers = [].concat(this.state.revokedUsers);
        let updatedUsers = [].concat(this.state.updatedUsers);

        this.state.selectedUsers.forEach(user => {
            if (this.state.revokeButtonState) {
                revokedUsers.push(user);
            } else {
                revokedUsers.splice(revokedUsers.indexOf(user), 1);
            }

            // If a user was edited and then revoked in the same instance then the revoke should take priority
            const updatedUserIndex = updatedUsers.indexOf(user);

            if (updatedUserIndex >= 0) {
                updatedUsers.splice(updatedUserIndex, 1);
            }
        });

        this.setState({
            revokedUsers: revokedUsers,
            updatedUsers: updatedUsers,
            selectedUsers: [],
            editButtonDisabled: true,
            revokeButtonDisabled: true,
            revokeButtonState: true,
        });
    }

    onSelectUser(event) {
        if (this.state.disableUserSelect === true) {
            return;
        }

        const selectedUserId = parseInt(event.currentTarget.getAttribute('data-value'));
        let selectedUsers = [];

        if (this.state.selectedUsers.filter(user => user.user.id === selectedUserId).length > 0) {
            selectedUsers = this.state.selectedUsers.filter(user => user.user.id !== selectedUserId);
            this.setState({
                selectedUsers: selectedUsers,
                editButtonDisabled: selectedUsers === 0,
                revokeButtonDisabled: selectedUsers === 0,
            });
        } else {
            selectedUsers = this.state.selectedUsers.concat(
                this.state.users.filter(user => user.user.id === selectedUserId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRevoked = false;
        let containsNonRevoked = false;
        let containsEdited = false;

        selectedUsers.forEach(user => {
            if (this.state.revokedUsers.indexOf(user) >= 0) {
                containsRevoked = true;
            } else {
                containsNonRevoked = true;
            }

            if (this.state.updatedUsers.indexOf(user) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let revokeButtonDisabled = true;
        let revokeButtonState = true;

        if (selectedUsers.length > 0) {
            editButtonDisabled = false;
            revokeButtonDisabled = false;
        }

        if (containsRevoked && !containsNonRevoked && !containsEdited) {
            editButtonDisabled = true;
            revokeButtonDisabled = false;
            revokeButtonState = false;
        }

        if (containsRevoked) {
            editButtonDisabled = true;
        }

        if (containsRevoked && containsNonRevoked) {
            revokeButtonDisabled = true;
            revokeButtonState = true;
        }

        this.setState({
            selectedUsers: selectedUsers,
            editButtonDisabled: editButtonDisabled,
            revokeButtonDisabled: revokeButtonDisabled,
            revokeButtonState: revokeButtonState,
            updateButtonDisabled: true,
            editUserAccordionOpen: false,
        });
    }

    renderModalNavigation() {
        let numberOfChanges = this.state.updatedUsers.length + this.state.revokedUsers.length;

        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: this.onSaveChangesClick,
                hidden: this.state.saveChangesButtonHidden,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    }

    renderAccordion() {
        if (this.state.editUserAccordionVisible === false) {
            return null;
        }

        let introText = `<p>More details about each role can be found on our <a href="http://tag.docs.withcubed.com/onboarding/general/" target="_blank" rel="noopener noreferrer">support page</a>.</p>`;
        introText = introText.concat(
            `<p>Currently Editing:</p><ul> ${this.state.selectedUsers
                .map(user => '<li>' + user.user.username + '</li>')
                .join('')} </ul>`
        );

        const accordions = [
            {
                header: 'Edit User',
                required: false,
                open: this.state.editUserAccordionOpen,
                type: 'form',
                intro: introText,
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Role:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy:
                                    'This will determine how much access a given user has to the Cubed Dashboard.',
                                inputKeyValue: 'edit-user__role',
                                inputOptions: this.state.roleOptions,
                                inputValue: this.state.userRoleForEdit,
                                inputOnChange: this.onEditUserChange,
                                errorMessage: this.state.userRoleForEditErrorMessage,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'UPDATE',
                                onClick: this.onUpdateClick,
                                disabled: this.state.updateButtonDisabled,
                            },
                            {
                                value: 'CANCEL',
                                onClick: this.onCancelClick,
                                buttonTheme: ButtonThemes.Secondary,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    renderInviteExistingUsersAccordion() {
        if (this.state.inviteExistingUserAccordionVisible === false) {
            return null;
        }

        let introText = `<p>Invite users from another account to your account.</p>`;
        const accordion = [
            {
                header: 'Invite Existing User',
                required: false,
                open: this.state.inviteExistingUserAccordionOpen,
                type: 'form',
                intro: introText,
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Email Address:',
                                type: 'email',
                                requiredField: true,
                                toolTipCopy: 'Enter the email address of the user you would like to invite.',
                                inputKeyValue: 'invite-existing-user__email',
                                inputValue: this.state.userEmailForInvite,
                                inputOnChange: this.onUserEmailForInviteChange,
                                errorMessage: this.state.userEmailForInviteErrorMessage,
                            },
                            {
                                label: 'Role:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy:
                                    'This will determine how much access a given user has to the Cubed Dashboard.',
                                inputKeyValue: 'invite-existing-user__role',
                                inputOptions: this.state.roleOptions,
                                inputValue: this.state.userRoleForInvite,
                                inputOnChange: this.onUserRoleForInviteChange,
                                errorMessage: this.state.userRoleForInviteErrorMessage,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'INVITE',
                                onClick: this.onInviteExistingUserClick,
                                disabled: this.state.inviteExistingUserButtonDisabled,
                                isLoading: this.state.isLoadingInviteExistingUser,
                            },
                            {
                                value: 'CANCEL',
                                onClick: this.onCancelInviteExistingUserClick,
                                buttonTheme: ButtonThemes.Secondary,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordion} />;
    }

    renderTable() {
        const errorMessageOverride = 'There are no users assigned to this account.';

        const header = {
            columns: [
                {
                    title: 'User',
                },
                {
                    title: 'Role',
                },
            ],
        };

        const rows = this.state.users.map(user => {
            const rowProperty = {
                selected: this.state.selectedUsers.includes(user),
                revokeUser: this.state.revokedUsers.includes(user),
                edited: this.state.updatedUsers.includes(user),
                disabled: this.state.disableUserSelect,
            };

            return {
                onClick: this.onSelectUser,
                keyValue: `users__${user.user.username}`,
                dataValue: user.user.id,
                rowProperty,
                columns: [
                    {
                        copy: user.user.username,
                    },
                    {
                        copy: user.role.name,
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__manage-users">
                    <this.renderModalNavigation />
                    <h2>Manage Users</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__manage-users">
                    <this.renderModalNavigation />
                    <h2>Manage Users</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__manage-users">
                <this.renderModalNavigation />
                <h2>Manage Users</h2>
                <p>Create, invite or manage existing users that have access to your account.</p>
                <div className="modal__side-panel__manage-users__users">
                    <this.renderTable />
                    <InputButtonWrapper>
                        <InputButton value="Create New User" onClick={this.handleOnCreate} />
                        <InputButton value="invite existing user" onClick={this.handleOnInviteExistingUser} />
                        <InputButton value="Invite" onClick={this.handleOnInvite} />
                        <InputButton value="EDIT" disabled={this.state.editButtonDisabled} onClick={this.onEditClick} />
                        <InputButton
                            buttonTheme={this.state.revokeButtonState && ButtonThemes.Red}
                            value={this.state.revokeButtonState ? 'REVOKE ACCESS' : 'ALLOW ACCESS'}
                            disabled={this.state.revokeButtonDisabled}
                            onClick={this.onRevokeAccessClick}
                        />
                    </InputButtonWrapper>
                </div>
                <this.renderAccordion />
                <this.renderInviteExistingUsersAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalManageUsers);
