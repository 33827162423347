import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isString } from '../../helpers/validator';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import {
    CONFIG_KWR_BLACKLISTED_KEYWORD,
    CONFIG_SEOGD_COMPETITOR,
    CONFIG_SEOGD_MARKET,
} from '../../configurations/resources-config';
import { ConfigDataSuccess } from '../../react-query/types';
import usePostResource from '../../react-query/hooks/use-post-resource';

type ErrorMessageObject = {
    brandNameErrorMessage: string;
    domainNameErrorMessage: string;
    marketErrorMessage: string;
};

const LayoutAddCompetitors = () => {
    const dispatch = useDispatch();

    const [closeButtonState, setCloseButtonState] = useState('close');
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState(true);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);

    const [market, setMarket] = useState('');
    const [brandName, setBrandName] = useState('');
    const [domainName, setDomainName] = useState('');

    const [errorMessageObject, setErrorMessageObject] = useState<ErrorMessageObject>({
        brandNameErrorMessage: '',
        domainNameErrorMessage: '',
        marketErrorMessage: '',
    });

    // Queries and Mutations
    const marketQuery = useFetchResource({
        resource: CONFIG_SEOGD_MARKET,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
            { key: 'order_by', value: 'country' },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(market => {
                return {
                    label: market.alpha2,
                    value: market.id,
                    name: market.country,
                    ...market,
                };
            });
        },
    });

    const competitorPostMutation = usePostResource({
        resource: CONFIG_SEOGD_COMPETITOR,
        data: {
            brand_name: brandName,
            domain: domainName,
            client_domain: 0,
            market: generatePath('config', 'seogd-market', market),
        },
        handleOnSuccess: () => {
            keywordPostMutation.mutate();
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue adding Competitors.',
                    type: NotificationMessageType.Error,
                })
            );

            setSaveChangesButtonLoading(false);
            setSaveChangesButtonDisabled(false);
            setCloseButtonDisabled(false);
        },
    });

    const keywordPostMutation = usePostResource({
        resource: CONFIG_KWR_BLACKLISTED_KEYWORD,
        data: {
            keyword: brandName,
            market: generatePath('config', 'seogd-market', market),
            type: generatePath('config', 'keyword-type', '2'),
        },
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'Competitors added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
            setSaveChangesButtonLoading(false);
            setSaveChangesButtonDisabled(false);
            setCloseButtonDisabled(false);
            handleNavigateManageCompetitors();
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue adding Competitors.',
                    type: NotificationMessageType.Error,
                })
            );

            setSaveChangesButtonLoading(false);
            setSaveChangesButtonDisabled(false);
            setCloseButtonDisabled(false);
        },
    });

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        setCloseButtonState('close');
        if (brandName || domainName || market) {
            setSaveChangesButtonDisabled(false);
            setErrorMessageObject({
                brandNameErrorMessage: '',
                domainNameErrorMessage: '',
                marketErrorMessage: '',
            });
            setCloseButtonState('cancel');
        }
    }, [brandName, domainName, market]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            brandNameErrorMessage: '',
            domainNameErrorMessage: '',
            marketErrorMessage: '',
        };

        if (!isString(brandName) || brandName.length === 0) {
            hasFormError = true;
            errorMessageObj.brandNameErrorMessage = 'Please enter valid brand name.';
        }

        if (!isString(domainName) || domainName.length === 0) {
            hasFormError = true;
            errorMessageObj.domainNameErrorMessage = 'Please enter valid domain name.';
        }

        if (!isString(market) || market.length === 0) {
            hasFormError = true;
            errorMessageObj.marketErrorMessage = 'Please select a market.';
        }

        if (hasFormError) {
            setSaveChangesButtonDisabled(true);
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const handleBrandName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBrandName(event?.target?.value);
    };

    const handleDomainName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDomainName(event?.target?.value);
    };

    const handleMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMarket(event?.target?.value);
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(setModal('ManageCompetitors', {}));
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const handleNavigateManageCompetitors = () => {
        dispatch(setModal('ManageCompetitors', {}));
    };

    const onSaveChangesClick = () => {
        // If there is an error reopen the goals details tab as the error will be there
        if (!formValidator()) {
            return;
        }

        setSaveChangesButtonLoading(true);
        setSaveChangesButtonDisabled(true);
        setCloseButtonDisabled(true);

        competitorPostMutation.mutate();
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: 'Add Competitors',
                required: false,
                open: true,
                type: 'form',
                intro: '',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Brand Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'The brand name of your competitor',
                                inputKeyValue: 'brandName',
                                inputValue: brandName,
                                inputOnChange: handleBrandName,
                                errorMessage: errorMessageObject.brandNameErrorMessage,
                            },
                            {
                                label: 'Domain Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'The domain of your competitor in format competitor.com.',
                                inputKeyValue: 'kwrDomainName',
                                inputValue: domainName,
                                inputOnChange: handleDomainName,
                                errorMessage: errorMessageObject.domainNameErrorMessage,
                            },
                            {
                                label: 'Market:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a Market',
                                inputKeyValue: 'market',
                                inputOptions: marketQuery.data,
                                inputOnChange: handleMarket,
                                errorMessage: errorMessageObject.marketErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    if (marketQuery.isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Add Competitors</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (marketQuery.isError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Add Competitors</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Add Competitors</h2>
            <p>Add the competitors you'd like us to use to further optimize the automated KWR process.</p>
            {renderAccordion()}
        </div>
    );
};

export default LayoutAddCompetitors;
