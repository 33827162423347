//React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import WidgetAccordion from '../../widgets/accordion';
import ModalNavigation from '../../components/modal-navigation';
// Redux Actions
import { setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
//helpers
import { isEmail } from '../../helpers/validator';
import { generateUrlDetail } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';
//Dependencies
import Axios from 'axios';

class LayoutModalInviteUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            adminEmails: [],
            viewerEmails: [],
            apiEmails: [],
            apiErrorMessage: '',
            viewerErrorMessage: '',
            adminErrorMessage: '',
            closeButtonState: 'close',
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: false,
            adminError: false,
            apiError: false,
            viewerError: false,
        };

        this.renderAccordion = this.renderAccordion.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.validator = this.validator.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.onEmailInputChange = this.onEmailInputChange.bind(this);
        this.handleNavigateInviteUserModal = this.handleNavigateInviteUserModal.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const prevCombinedArray = [...prevState.apiEmails, ...prevState.adminEmails, ...prevState.viewerEmails];
        const currentCombinedArray = [...this.state.apiEmails, ...this.state.adminEmails, ...this.state.viewerEmails];

        if (this.arraysAreEqual(prevCombinedArray, currentCombinedArray) === false) {
            this.updateTags(currentCombinedArray);
        }
    }

    arraysAreEqual(prevArray, currentArray) {
        if (prevArray.length !== currentArray.length) {
            return false;
        }
        let length = currentArray.length;

        for (let i = 0; i < length; i++) {
            if (prevArray[i].value !== currentArray[i].value) {
                return false;
            }
        }
        return true;
    }

    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.props.handleNavigateInviteUserModal();
    }

    onPopupStayHereClick() {
        this.props.removePopup();
    }

    handleNavigateInviteUserModal() {
        this.props.setModal('ManageUsers', {});
    }

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.handleNavigateInviteUserModal();
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        value: 'DISCARD CHANGES',
                        onClick: this.onPopupDiscardChangesClick,
                    },
                    {
                        value: 'STAY HERE',
                        style: 'secondary',
                        onClick: this.onPopupStayHereClick,
                    },
                ],
            });
        }
    }

    updateTags(tags) {
        // watch for updates, if there are updates. Check them, if there are errors or duplicates change as appropriate.
        // set state with updated values. send down updated values as props.
        const tempApiEmails = [...this.state.apiEmails];
        const tempAdminEmails = [...this.state.adminEmails];
        const tempViewerEmails = [...this.state.viewerEmails];
        const emailValues = tags.map(item => item.value);
        const allDuplicatedValues = emailValues.filter((item, index) => {
            return emailValues.indexOf(item) !== index;
            // indexOf - returns the first index at which a given element can be found.
        });

        let apiError = false,
            adminError = false,
            viewerError = false,
            disableInviteButton = false;
        // assume all tags are correct until we check below
        tags.forEach(tag => {
            return (tag.hasErrors = false);
        });
        tags.forEach(tag => {
            return (tag.isDuplicate = false);
        });

        // although we check for errors  here etc, validation occures in validator()

        tempApiEmails.forEach(tag => {
            if (isEmail(tag.value) === false) {
                tag.hasErrors = true;
                apiError = true;
            }

            if (allDuplicatedValues.includes(tag.value) === true) {
                tag.isDuplicate = true;
                apiError = true;
            }
        });

        tempAdminEmails.forEach(tag => {
            if (isEmail(tag.value) === false) {
                tag.hasErrors = true;
                adminError = true;
            }

            if (allDuplicatedValues.includes(tag.value) === true) {
                tag.isDuplicate = true;
                adminError = true;
            }
        });

        tempViewerEmails.forEach(tag => {
            if (isEmail(tag.value) === false) {
                tag.hasErrors = true;
                viewerError = true;
            }

            if (allDuplicatedValues.includes(tag.value) === true) {
                tag.isDuplicate = true;
                viewerError = true;
            }
        });

        if (viewerError === true || apiError === true || adminError === true || tags.length === 0) {
            // disable invite button if there are errors or no tags.
            disableInviteButton = true;
        }

        this.setState({
            //api/viewer/admin error state handles tags.js error state
            apiEmails: tempApiEmails,
            adminEmails: tempAdminEmails,
            viewerEmails: tempViewerEmails,
            apiError: apiError,
            adminError: adminError,
            viewerError: viewerError,
            saveChangesButtonDisabled: disableInviteButton,
        });
    }

    onEmailInputChange(tags, type) {
        // Based on the type setState with most up to date tags
        const id = `${type}Emails`;
        this.setState({
            [id]: tags,
        });
    }

    emailTagSplitOverride(value) {
        return value.split(/,|;|\b \b/);
    }

    validator(tags) {
        // return true if tags pass validation
        let failValidation = false;
        tags.forEach(tag => {
            if (tag.hasErrors === true || tag.isDuplicate === true) {
                failValidation = true;
                return;
            }
        });

        if (failValidation === true) {
            return false;
        }

        return true;
    }

    inviteUsers() {
        const allEmails = [];
        const requests = [];

        this.state.apiEmails.forEach(item => {
            allEmails.push({ email: item.value, group: 5 });
        });

        this.state.adminEmails.forEach(item => {
            allEmails.push({ email: item.value, group: 1 });
        });

        this.state.viewerEmails.forEach(item => {
            allEmails.push({ email: item.value, group: 3 });
        });

        allEmails.forEach(emailObject => {
            requests.push(
                Axios({
                    method: 'POST',
                    url: generateUrlDetail(this.props.account.token, 'users', 'invite', [], false),
                    data: emailObject,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        });

        return Promise.all(requests);
    }

    onSaveChangesClick() {
        const tags = [...this.state.adminEmails, ...this.state.viewerEmails, ...this.state.apiEmails];

        if (this.validator(tags) === true) {
            this.inviteUsers()

                .then(response => {
                    let errors = false;
                    let specificUserErrors = [];
                    response.forEach(res => {
                        switch (res.data.status) {
                            case 500:
                                specificUserErrors.push(res.data.message);
                                errors = true;
                                break;

                            default:
                                break;
                        }
                    });

                    if (errors === true) {
                        // Request successful but there were non network type errors
                        // Example : 5 users invited but one already as access to this account.
                        specificUserErrors.forEach(message => {
                            this.props.addNotification({ copy: 'Emails sent but  ' + message, type: 'warning' });
                        });
                        this.handleNavigateInviteUserModal();
                    } else {
                        this.props.addNotification({
                            copy: 'All emails have been successfully sent.',
                            type: NotificationMessageType.Success,
                        });
                        this.handleNavigateInviteUserModal();
                    }
                })

                .catch(e => {
                    // Handles actual request failure
                    this.props.addNotification({
                        copy: 'There was a problem sending the invites, if the problem persists contact support@cubed.email.',
                        type: NotificationMessageType.Error,
                    });
                    this.setState({
                        saveChangesButtonLoading: false,
                    });
                });
        }

        this.setState({
            saveChangesButtonLoading: true,
        });
    }

    renderModalNavigation() {
        const modalNavigationButtons = [
            {
                value: 'INVITE USERS',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    }

    renderAccordion() {
        const accordions = [
            {
                header: 'Users',
                required: false,
                open: true,
                type: 'form',
                config: {
                    formConfig: {
                        columns: 1,
                        fields: [
                            {
                                label: 'Admin Users:',
                                type: 'tags',
                                requiredField: true,
                                inputKeyValue: 'adminEmails',
                                validatorOverride: isEmail,
                                errorMessage: 'Some of the emails are invalid or duplicated',
                                inputPlaceholder: 'Enter Admin users',
                                copy: 'An admin user has full access to the platform to both view and modify any account settings or configurations.',
                                onChange: this.onEmailInputChange,
                                tags: this.state.adminEmails,
                                splitOnOverride: this.emailTagSplitOverride,
                                errors: this.state.adminError,
                                fieldName: 'admin',
                            },
                            {
                                label: 'Viewer Users:',
                                type: 'tags',
                                inputKeyValue: 'viewerEmails',
                                validatorOverride: isEmail,
                                errorMessage: 'Some of the emails are invalid or duplicated',
                                inputPlaceholder: 'Enter Viewer users',
                                copy: 'A user with the viewer role can only view the dashboards and has no permission to view or modify any account settings or configurations.',
                                onChange: this.onEmailInputChange,
                                tags: this.state.viewerEmails,
                                splitOnOverride: this.emailTagSplitOverride,
                                errors: this.state.viewerError,
                                fieldName: 'viewer',
                            },
                            {
                                label: 'API Users:',
                                type: 'tags',
                                inputKeyValue: 'apiEmails',
                                validatorOverride: isEmail,
                                errorMessage: 'Some of the emails are invalid or duplicated',
                                inputPlaceholder: 'Enter API users',
                                copy: 'A user with the API role can only use the API section of the platform.  ',
                                onChange: this.onEmailInputChange,
                                tags: this.state.apiEmails,
                                splitOnOverride: this.emailTagSplitOverride,
                                errors: this.state.apiError,
                                fieldName: 'api',
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    render() {
        return (
            <div className="invite-users">
                <this.renderModalNavigation />
                <h2>Invite Users</h2>
                <p>
                    Invite new or existing users to have access to your cubed account. Please ensure you enter the
                    correct email addresses and give the user adequate access by selecting the appropriate role. More
                    details about each role can be found on our support page. Simply type individual emails or paste a
                    list of emails separated by commas, semi-colons or spaces.
                </p>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalInviteUsers);
