// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { isPrice, isAlphaNumericString } from '../../helpers/validator';
import { generateUrl, generatePath } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Import Widgets
import WidgetAccordion from '../../widgets/accordion';
// Import Components
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

class LayoutModalCreateGoalWizard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            events: [],
            selectedEvents: [],
            goalsDetailsAccordionOpen: true,
            assignEventAccordionOpen: false,
            goalName: '',
            goalType: '',
            goalTypeOptions: [],
            revenueOverride: '',
            revenueTitle: '',
            saleTitle: '',
            averageValueTitle: '',
            goalNameErrorMessage: '',
            goalTypeErrorMessage: '',
            revenueOverrideErrorMessage: '',
            defaultGoalChecked: false,
            assignGoalToMarketChecked: false,
            marketList: [],
            selectedMarketList: [],
            marketListFieldErrorMessage: '',
        };

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onGoalNameChange = this.onGoalNameChange.bind(this);
        this.onGoalTypeChange = this.onGoalTypeChange.bind(this);
        this.onRevenueOverrideChange = this.onRevenueOverrideChange.bind(this);
        this.onEventSalesToggle = this.onEventSalesToggle.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.onGoalDetailsNextClick = this.onGoalDetailsNextClick.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.handleNavigateGoalManageModal = this.handleNavigateGoalManageModal.bind(this);

        this.saveGoal = this.saveGoal.bind(this);

        this.renderAccordion = this.renderAccordion.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);

        this.handleAssignGoalToMarket = this.handleAssignGoalToMarket.bind(this);
        this.fetchMarketList = this.fetchMarketList.bind(this);
        this.handleMarketListOnchange = this.handleMarketListOnchange.bind(this);
    }

    componentDidMount() {
        const config = [
            {
                resourceGroup: 'config',
                resourceName: 'product-type',
            },
            {
                resourceGroup: 'config',
                resourceName: 'event',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
        ];

        this.props.getRequest(config);
        this.fetchMarketList();
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // Manipulate the ProductType to work with a select field
                const productOptions = this.props.request.data[0].objects
                    .filter(object => object.active === true)
                    .map(type => {
                        return {
                            keyValue: `product-type-option_${type.name.replace(/ /g, '')}`,
                            value: type.id,
                            name: type.name,
                        };
                    });

                // Manipulate the Events to work with a list on CheckCopy's
                // NOTE :: Currently using 'display_name' to display to the user instead of name.
                const events = this.props.request.data[1].objects.map(event => {
                    return {
                        id: event.id,
                        name: event.display_name !== '' ? event.display_name : event.name,
                        sale: false,
                    };
                });

                this.setState({
                    isLoading: false,
                    goalTypeOptions: productOptions,
                    events: events,
                });
            } else {
                this.setState({
                    pageError: true,
                    isLoading: false,
                });
            }

            this.props.delRequest();
        }

        // Checks to see if a change has been made and if so switch the button to being
        if (this.state.closeButtonState === 'close') {
            if (
                this.state.goalName !== '' ||
                this.state.goalType !== '' ||
                this.state.revenueOverride !== '' ||
                this.state.revenueTitle !== '' ||
                this.state.saleTitle !== '' ||
                this.state.averageValueTitle !== ''
            ) {
                this.setState({
                    closeButtonState: 'cancel',
                    saveChangesButtonDisabled: false,
                });
            }
        }
    }

    handleNavigateGoalManageModal() {
        this.props.setModal('ManageGoals', {});
    }

    // CLOSE - CANCEL click handler
    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.handleNavigateGoalManageModal();
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD GOAL',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        style: 'secondary',
                    },
                ],
            });
        }
    }

    // Checks if the form fields have valid details
    formValidator() {
        let hasFormError = false;
        let errorMessageObject = {
            goalNameErrorMessage: '',
            goalTypeErrorMessage: '',
            revenueOverrideErrorMessage: '',
            marketListFieldErrorMessage: '',
        };

        // Validate and ensure goal-details__product-name and check that it is populated
        if (!isAlphaNumericString(this.state.goalName) || this.state.goalName.length === 0) {
            hasFormError = true;
            errorMessageObject.goalNameErrorMessage = 'Please enter a valid Goal Name.';
        }

        // Validate and ensure goal-details__product-type and check that it is populated
        if (!isAlphaNumericString(this.state.goalType) || this.state.goalType.length === 0) {
            hasFormError = true;
            errorMessageObject.goalTypeErrorMessage = 'Please select Goal Type from the dropdown.';
        }

        // Validate and ensure goal-details__product-type
        if (!isPrice(this.state.revenueOverride) && this.state.revenueOverride !== '') {
            hasFormError = true;
            errorMessageObject.revenueOverrideErrorMessage = `Please enter a valid Revenue Override ( excluding the ${this.props.currency.symbol} symbol ).`;
        }

        if (this.state.assignGoalToMarketChecked && this.state.selectedMarketList.length === 0) {
            hasFormError = true;
            errorMessageObject.marketListFieldErrorMessage = 'Please select at least one market place';
        }

        if (hasFormError) {
            this.setState({
                goalNameErrorMessage: errorMessageObject.goalNameErrorMessage,
                goalTypeErrorMessage: errorMessageObject.goalTypeErrorMessage,
                revenueOverrideErrorMessage: errorMessageObject.revenueOverrideErrorMessage,
                marketListFieldErrorMessage: errorMessageObject.marketListFieldErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    }

    // Makes a POST request to the API with the data entered by the user.
    saveGoal() {
        return new Promise(async (resolve, reject) => {
            const productPayload = {
                name: this.state.goalName,
                revenue_override: Number(this.state.revenueOverride) * 100,
                sale_title: this.state.saleTitle,
                revenue_title: this.state.revenueTitle,
                avg_title: this.state.averageValueTitle,
                default:
                    parseInt(this.state.goalType, 10) === 4 || parseInt(this.state.goalType, 10) === 5
                        ? false
                        : this.state.defaultGoalChecked,
                product_type: generatePath('config', 'product-type', Number(this.state.goalType)),
            };

            const productConfig = {
                method: 'POST',
                url: generateUrl('config', 'product'),
                data: productPayload,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            try {
                const productRequest = await Axios(productConfig);
                if (productRequest.status !== 201) {
                    throw new Error('Status not 201');
                }

                const productURI = generatePath('config', 'product', Number(productRequest.data.id));

                const productLookbackConfig = {
                    method: 'POST',
                    url: generateUrl('config', 'product-lookback'),
                    data: {
                        product: productURI,
                        active: true,
                        model_lookback: 120, // set default lookback to 120 days when creating a goal
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                await Axios(productLookbackConfig);

                this.state.assignGoalToMarketChecked &&
                    this.state.selectedMarketList.length > 0 &&
                    this.state.selectedMarketList.forEach(async market => {
                        const selectedMarketListPayload = {
                            product: productURI,
                            market: generatePath('config', 'seogd-market', Number(market.id)),
                        };

                        const marketListConfig = {
                            method: 'POST',
                            url: generateUrl('config', 'product-market'),
                            data: selectedMarketListPayload,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        };

                        await Axios(marketListConfig);
                    });

                if (this.state.selectedEvents.length > 0) {
                    const requests = [];

                    this.state.selectedEvents.forEach(event => {
                        requests.push(
                            Axios({
                                method: 'POST',
                                url: generateUrl('config', 'product-event'),
                                data: {
                                    event: generatePath('config', 'event', event.id),
                                    product: productURI,
                                    weight: 1,
                                    sale: event.sale,
                                    active: 1,
                                    reach: 0,
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    });
                }
            } catch (error) {
                reject('Error making a connection to API.');
            }

            resolve('Goal Created');
        });
    }

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.handleNavigateGoalManageModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    // Saved changes clicked handler
    onSaveChangesClick() {
        // If there is an error reopen the goals details tab as the error will be there
        if (!this.formValidator()) {
            this.setState({
                goalsDetailsAccordionOpen: true,
                assignEventAccordionOpen: false,
            });
            return;
        }

        this.setState({
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        this.saveGoal()
            .then(() => {
                this.props.addNotification({
                    copy: 'A goal was successfully created.',
                    type: NotificationMessageType.Success,
                });
                this.handleNavigateGoalManageModal();
            })
            .catch(err => {
                this.props.addNotification({
                    copy: 'There was an issue adding this Goal, please try again later.',
                    type: NotificationMessageType.Error,
                });

                this.setState({
                    saveChangesButtonDisabled: false,
                    saveChangesButtonLoading: false,
                    closeButtonDisabled: false,
                });
            });
    }

    // Manage the checkbox input fields
    onCheckboxChange() {
        let checked = !this.state.defaultGoalChecked;
        this.setState({
            defaultGoalChecked: checked,
        });
    }

    // Manage the Assign Goal to Market check box
    handleAssignGoalToMarket() {
        this.setState({
            assignGoalToMarketChecked: !this.state.assignGoalToMarketChecked,
        });
    }

    // Manage the input fields changes and updating the state with the new value entered by the user
    onGoalNameChange(event) {
        this.setState({
            goalName: event.target.value,
        });
    }
    onGoalTypeChange(event) {
        this.setState({
            defaultGoalChecked: false,
            goalType: event.target.value,
        });
    }
    onRevenueOverrideChange(event) {
        this.setState({
            revenueOverride: event.target.value,
        });
    }

    // On the accordion the next button click handler
    onGoalDetailsNextClick() {
        if (this.formValidator()) {
            this.setState({
                goalsDetailsAccordionOpen: false,
                assignEventAccordionOpen: true,
            });
        }
    }

    // The click event handler for an event in the list accordion
    onEventSalesToggle(event) {
        const selectedEventId = parseInt(event.currentTarget.getAttribute('data-name'));

        let selectedEvents = [...this.state.selectedEvents];

        if (this.state.selectedEvents.filter(event => event.id === selectedEventId).length === 0) {
            selectedEvents.push(this.state.events.filter(event => event.id === selectedEventId)[0]);
        }

        selectedEvents.forEach(event => {
            if (event.id === selectedEventId) {
                event.sale = !event.sale;
            }
        });

        this.setState({
            selectedEvents: selectedEvents,
        });
    }

    // Fetch Market list
    async fetchMarketList() {
        try {
            const response = await Axios({
                method: 'GET',
                url: generateUrl('config', 'seogd-market', [
                    { key: 'active', value: 1 },
                    { key: 'limit', value: 0 },
                    { key: 'order_by', value: 'country' },
                ]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const marketList =
                response &&
                response.data.objects.map(data => {
                    return {
                        id: data.id,
                        label: data.country,
                        value: data.id,
                        ...data,
                    };
                });

            this.setState({
                marketList,
            });
        } catch (error) {
            throw new Error('Response Error', error);
        }
    }

    // Manage the Market list dropdown
    handleMarketListOnchange = value => {
        this.setState({
            selectedMarketList: value,
            marketListFieldErrorMessage: value.length === 0 ? 'Please select at least one market place' : '',
        });
    };

    renderModalNavigation() {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    }

    renderAccordion() {
        const eventRows = this.state.events.map(event => {
            return {
                columns: [
                    {
                        copy: event.name,
                    },
                    {
                        type: 'toggle',
                        toggleChecked: event.sale,
                        toggleOnClick: this.onEventSalesToggle,
                        toggleDataName: event.id,
                    },
                ],
            };
        });

        const filters = [
            {
                name: 'Active',
                filter: row => {
                    return row.columns[1].toggleChecked === true;
                },
            },
            {
                name: 'Sale Event',
                filter: row => {
                    return row.columns[2].toggleChecked === true;
                },
            },
        ];

        const accordions = [
            {
                header: 'Goal Details',
                required: true,
                open: this.state.goalsDetailsAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Goal Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter your desired goal name.',
                                inputKeyValue: 'goal-details__product-name',
                                inputPlaceholder: 'Goal Name...',
                                inputValue: this.state.goalName,
                                inputOnChange: this.onGoalNameChange,
                                errorMessage: this.state.goalNameErrorMessage,
                            },
                            {
                                label: 'Goal Type:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a goal type.',
                                inputKeyValue: 'goal-details__product-type',
                                inputOptions: this.state.goalTypeOptions,
                                inputValue: this.state.goalType,
                                inputOnChange: this.onGoalTypeChange,
                                errorMessage: this.state.goalTypeErrorMessage,
                            },
                            {
                                label: `Revenue Override (${this.props.currency.symbol}):`,
                                type: 'text',
                                requiredField: false,
                                toolTipCopy: 'Override revenue.',
                                inputKeyValue: 'goal-details__revenue_override',
                                inputPlaceholder: 'Revenue Override...',
                                inputValue: this.state.revenueOverride,
                                inputOnChange: this.onRevenueOverrideChange,
                                errorMessage: this.state.revenueOverrideErrorMessage,
                            },
                            {
                                label: 'Set Goal As Default:',
                                type: 'checkbox',
                                toolTipCopy:
                                    'Marking a goal as default auto-selects it to be viewed in reports. Non default goals will still be available but not selected by default.',
                                inputKeyValue: 'goal-details__defaultChecked',
                                checked: this.state.defaultGoalChecked,
                                inputOnChange: this.onCheckboxChange,
                                disabled:
                                    parseInt(this.state.goalType, 10) === 4 || parseInt(this.state.goalType, 10) === 5
                                        ? true
                                        : false,
                            },
                            {
                                label: 'Assign Goal to Market:',
                                type: 'checkbox',
                                toolTipCopy:
                                    'Marking a goal as default auto-selects it to be viewed in reports. Non default goals will still be available but not selected by default.',
                                inputKeyValue: 'goal-details__assignGoalToMarket',
                                checked: this.state.assignGoalToMarketChecked,
                                inputOnChange: this.handleAssignGoalToMarket,
                            },
                            this.state.assignGoalToMarketChecked && {
                                label: 'Market List:',
                                type: 'reactSelect',
                                requiredField: this.state.assignGoalToMarketChecked,
                                placeholder: 'Select Markets',
                                inputOptions: this.state.marketList,
                                toolTipCopy: 'Select Markets for Goals.',
                                isMulti: true,
                                inputKeyValue: 'goal-details__assignGoalToMarketList',
                                inputValue: this.state.selectedMarketList,
                                inputOnChange: this.handleMarketListOnchange,
                                errorMessage: this.state.marketListFieldErrorMessage,
                                isClearable: true,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'NEXT',
                                onClick: this.onGoalDetailsNextClick,
                            },
                        ],
                    },
                },
            },
            {
                header: 'Assign Sale Events',
                required: false,
                open: this.state.assignEventAccordionOpen,
                type: 'simpleTable',
                intro: '<p>Choose the event(s) which signify the completion of your goal. </p>',
                config: {
                    tableHeader: {
                        columns: [
                            {
                                title: 'Events',
                            },
                            {
                                title: 'Sale',
                            },
                        ],
                    },
                    tableRows: eventRows,
                    errorMessageOverride: 'No events have been created.',
                    enableSearch: true,
                    searchableColumns: [0],
                    enableFilters: true,
                    filters: filters,
                    isScrollable: true,
                    hasEndAction: true,
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__create-goal-wizard">
                    <this.renderModalNavigation />
                    <h2>Create Goal</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__create-goal-wizard">
                    <this.renderModalNavigation />
                    <h2>Create Goal</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email. " />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__create-goal-wizard">
                <this.renderModalNavigation />
                <h2>Create Goal</h2>
                <p>
                    Create a new goal using the wizard below. Goals you may wish to configure include product purchases,
                    newsletter signups, PDF downloads etc. For more help see{' '}
                    <a href="https://tag.docs.withcubed.com/onboarding/general/#goals">setting up goals</a>.
                </p>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta,
        account: state.account,
        request: state.request,
        currency: state.currency,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalCreateGoalWizard);
