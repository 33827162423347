import React from 'react';
import styled from 'styled-components';

// Context
import { FieldError, useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Validation
import buildValidators from '../validation/helpers/build-validators';
import { Validator } from '../types';

// Components
import InputErrorMessage from './components/input-error-message';
import IconFileArrowUp from '../../icons/file-arrow-up';

const StyledLabel = styled.label`
    background-color: ${props => props.theme.buttons.primary.backgroundColour};
    color: ${props => props.theme.buttons.primary.color};
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    min-width: 40px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 36px;
    font-weight: 500;
    border: none;
    border-radius: 2px;
`;

const StyledLabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const StyledIcon = styled(IconFileArrowUp)`
    width: 16px;
    height: 16px;
`;

const StyledInput = styled.input<{ disabled: boolean }>`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

export type InputFileUploadProps = {
    name: string;
    disabled?: boolean;
    accept: string;
    validators?: Validator[];
};

const InputFileUpload = ({ name, disabled, accept, validators = [] }: InputFileUploadProps) => {
    const { status } = useCubedFormContext();
    const {
        register,
        formState: { errors },
        getValues,
    } = useFormContext();
    const currentValue = getValues(name);

    return (
        <div>
            <StyledLabel htmlFor={name}>
                <StyledLabelContainer>
                    <StyledIcon />
                    {currentValue && currentValue.length > 0 && currentValue[0]?.name
                        ? currentValue[0].name
                        : 'Choose File'}
                </StyledLabelContainer>
            </StyledLabel>
            <StyledInput
                type="file"
                id={name}
                accept={accept}
                disabled={!!disabled || status === 'disabled' || status === 'loading'}
                {...register(name, { ...buildValidators(validators) })}
            />

            {errors[name] && <InputErrorMessage error={errors[name] as FieldError} />}
        </div>
    );
};

export default InputFileUpload;
