// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { generateUrl, generatePath, generateUrlDetail } from '../../helpers/request-builder';
import { isDomain } from '../../helpers/validator';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class LayoutModalManageDomains extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accordionVisible: false,
            isLoading: true,
            pageError: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            removeButtonState: true,
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: false,
            editDomainAccordionOpen: false,
            editButtonDisabled: true,
            updateButtonDisabled: true,
            removeButtonDisabled: true,
            disableDomainSelect: false,
            domains: [],
            selectedDomains: [],
            removedDomains: [],
            updatedDomains: [],
            editDomainName: '',
            editDomainNameErrorMessage: '',
            'selected-domains': [],
            'removed-domains': [],
            'updated-domains': [],
            'edit-domain__name': '',
            'edit-domain__name__error-message': '',
        };

        this.onCloseClick = this.onCloseClick.bind(this);
        this.onEditDomainChange = this.onEditDomainChange.bind(this);
        this.formValidator = this.formValidator.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.onEditClick = this.onEditClick.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onDomainSelect = this.onDomainSelect.bind(this);
        this.onUpdateClick = this.onUpdateClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.handleOnCreate = this.handleOnCreate.bind(this);

        this.renderAccordion = this.renderAccordion.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderTable = this.renderTable.bind(this);
    }

    componentDidMount() {
        const config = [
            {
                resourceGroup: 'config',
                resourceName: 'domains',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
        ];

        this.props.getRequest(config);
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // Sorting Out Domains
                const domains = this.props.request.data[0].objects.map(domain => {
                    return {
                        id: domain.id,
                        name: domain.domain,
                    };
                });

                this.setState({
                    isLoading: false,
                    domains: domains,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }

            this.props.delRequest();
        }

        // Enable the save changes and cancel when a user is added into either the revoked or updated arrays
        if (
            this.state.saveChangesButtonHidden &&
            (this.state.updatedDomains.length > 0 || this.state.removedDomains.length > 0)
        ) {
            this.setState({
                saveChangesButtonHidden: false,
                closeButtonState: 'cancel',
            });
        }
    }

    formValidator() {
        this.setState({
            editDomainNameErrorMessage: '',
        });

        let hasFormError = false;
        let editDomainNameErrorMessage = '';

        if (!isDomain(this.state.editDomainName) || this.state.editDomainName.length === 0) {
            hasFormError = true;
            editDomainNameErrorMessage = 'Please enter a valid Domain name.';
        }

        if (hasFormError) {
            this.setState({
                editDomainNameErrorMessage: editDomainNameErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    }

    onSaveChangesClick() {
        this.setState({
            saveChangesButtonHidden: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        const requests = [];

        if (this.state.updatedDomains.length > 0) {
            requests.push(
                Axios({
                    method: 'PATCH',
                    url: generateUrl('config', 'domains'),
                    data: {
                        objects: this.state.updatedDomains.map(domain => {
                            return { resource_uri: generatePath('config', 'domains', domain.id), domain: domain.name };
                        }),
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        }

        if (this.state.removedDomains.length > 0) {
            this.state.removedDomains.forEach(domain => {
                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrlDetail('config', 'domains', domain.id),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        Axios.all(requests)
            .then(
                Axios.spread((...requestRes) => {
                    this.setState({
                        saveChangesButtonHidden: false,
                        saveChangesButtonLoading: false,
                    });

                    this.props.removeModal();
                    this.props.addNotification({
                        copy: 'The changes to the Domains have been successfully made.',
                        type: NotificationMessageType.Success,
                    });
                })
            )
            .catch(error => {
                this.props.addNotification({
                    copy: 'There was an issue trying to update these Domains. Please try again later',
                    type: NotificationMessageType.Error,
                });
                this.setState({
                    saveChangesButtonHidden: false,
                    saveChangesButtonLoading: false,
                    closeButtonDisabled: false,
                });
            });
    }

    onEditDomainChange(event) {
        const value = event.target.value;

        this.setState({
            editDomainName: value,
        });
    }

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.props.removeModal();

            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    }

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.props.removeModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    onUpdateClick() {
        if (!this.formValidator()) {
            return;
        }

        const updatedDomains = [].concat(this.state.updatedDomains);

        this.state.selectedDomains.forEach(domain => {
            // Check if a change was actually made.
            if (domain.name !== this.state.editDomainName) {
                const domainIndex = updatedDomains.indexOf(domain);

                if (domainIndex >= 0) {
                    updatedDomains[domainIndex].name = this.state.editDomainName;
                } else {
                    domain.name = this.state.editDomainName;

                    updatedDomains.push(domain);
                }
            }
        });

        this.setState({
            accordionVisible: false,
            updatedDomains: updatedDomains,
            editDomainAccordionOpen: false,
            updateButtonDisabled: true,
            disableDomainSelect: false,
            selectedDomains: [],
            editDomainName: '',
            editDomainNameErrorMessage: '',
        });
    }

    handleOnCreate() {
        this.props.setModal('AddDomain', {});
    }

    onEditClick() {
        this.setState({
            accordionVisible: true,
            editDomainAccordionOpen: true,
            editButtonDisabled: true,
            removeButtonDisabled: true,
            disableDomainSelect: true,
            updateButtonDisabled: false,
            editDomainName: this.state.selectedDomains[0].name,
        });
    }

    onRemoveClick() {
        const removedDomains = [].concat(this.state.removedDomains);
        const updatedDomains = [].concat(this.state.updatedDomains);

        this.state.selectedDomains.forEach(domain => {
            if (this.state.removeButtonState) {
                removedDomains.push(domain);
            } else {
                removedDomains.splice(removedDomains.indexOf(domain), 1);
            }

            // If a user was edited and then revoked in the same instance then the revoke should take priority
            const updatedDomainIndex = updatedDomains.indexOf(domain);

            if (updatedDomainIndex >= 0) {
                updatedDomains.splice(updatedDomainIndex, 1);
            }
        });

        this.setState({
            removedDomains: removedDomains,
            updatedDomains: updatedDomains,
            selectedDomains: [],
            removeButtonState: true,
            editButtonDisabled: true,
            removeButtonDisabled: true,
        });
    }

    onCancelClick() {
        this.setState({
            accordionVisible: false,
            editButtonDisabled: true,
            removeButtonDisabled: true,
            updateButtonDisabled: true,
            editDomainAccordionOpen: false,
            disableDomainSelect: false,
            selectedDomains: [],
            editDomainName: '',
            editDomainNameErrorMessage: '',
        });
    }

    onDomainSelect(event) {
        if (this.state.disableDomainSelect === true) {
            return;
        }

        const selectedDomainId = parseInt(event.currentTarget.getAttribute('data-value'));
        let selectedDomains = [];

        if (this.state.selectedDomains.filter(domain => domain.id === selectedDomainId).length > 0) {
            selectedDomains = this.state.selectedDomains.filter(domain => domain.id !== selectedDomainId);
            this.setState({
                selectedDomains: selectedDomains,
                editButtonDisabled: selectedDomains === 0,
                removeButtonDisabled: selectedDomains === 0,
            });
        } else {
            selectedDomains = this.state.selectedDomains.concat(
                this.state.domains.filter(domain => domain.id === selectedDomainId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRemoved = false;
        let containsNonRemoved = false;
        let containsEdited = false;

        selectedDomains.forEach(domain => {
            if (this.state.removedDomains.indexOf(domain) >= 0) {
                containsRemoved = true;
            } else {
                containsNonRemoved = true;
            }

            if (this.state.updatedDomains.indexOf(domain) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;

        if (selectedDomains.length > 0) {
            editButtonDisabled = false;
            removeButtonDisabled = false;
        }

        if (selectedDomains.length > 1) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRemoved && !containsNonRemoved && !containsEdited) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRemoved) {
            editButtonDisabled = true;
        }

        if (containsRemoved && containsNonRemoved) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        this.setState({
            selectedDomains: selectedDomains,
            editButtonDisabled: editButtonDisabled,
            removeButtonDisabled: removeButtonDisabled,
            removeButtonState: removeButtonState,
            updateButtonDisabled: true,
            editDomainAccordionOpen: false,
        });
    }

    renderModalNavigation() {
        let numberOfChanges = this.state.updatedDomains.length + this.state.removedDomains.length;

        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: this.onSaveChangesClick,
                hidden: this.state.saveChangesButtonHidden,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    }

    renderAccordion() {
        if (this.state.accordionVisible === false) {
            return null;
        }

        let introText = '';

        if (!this.state.updateButtonDisabled) {
            introText = `<p>Currently Editing:</p><ul> ${this.state.selectedDomains
                .map(domain => '<li>' + domain.name + '</li>')
                .join('')} </ul>`;
        }

        const accordions = [
            {
                header: 'Edit Domain',
                required: false,
                open: this.state.editDomainAccordionOpen,
                type: 'form',
                intro: introText,
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Domain Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter the domain name that the Cubed Tag will be firing on.',
                                inputKeyValue: 'edit-domain__name',
                                inputValue: this.state.editDomainName,
                                inputOnChange: this.onEditDomainChange,
                                errorMessage: this.state.editDomainNameErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: 'APPLY',
                                onClick: this.onUpdateClick,
                                disabled: this.state.updateButtonDisabled,
                            },
                            {
                                value: 'CANCEL',
                                onClick: this.onCancelClick,
                                buttonTheme: ButtonThemes.Secondary,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    renderTable() {
        const errorMessageOverride = 'No domains have been created for this account.';

        const header = {
            columns: [
                {
                    title: 'Domain',
                },
            ],
        };

        const rows = this.state.domains.map(domain => {
            const rowProperty = {
                selected: this.state.selectedDomains.includes(domain),
                deleted:
                    this.state.removedDomains.filter(filteredDomain => {
                        return domain.id === filteredDomain.id;
                    }).length === 1,
                edited:
                    this.state.updatedDomains.filter(filteredDomain => {
                        return domain.id === filteredDomain.id;
                    }).length === 1,
                disabled: this.state.disableDomainSelect === true,
            };

            return {
                onClick: this.onDomainSelect,
                keyValue: `domains__${domain.id}`,
                dataValue: domain.id,
                rowProperty,
                columns: [
                    {
                        copy: domain.name,
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                enableSearch={true}
                searchableColumns={[0]}
                isScrollable={true}
            />
        );
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__manage-domains manage-modals">
                    <this.renderModalNavigation />
                    <h2>Manage Domains</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__manage-domains manage-modals">
                    <this.renderModalNavigation />
                    <h2>Manage Domains</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__manage-domains manage-modals">
                <this.renderModalNavigation />
                <h2>Manage Domains</h2>
                <p>Manage previously configured domains below.</p>
                <div className="modal__side-panel__manage-domains__domains">
                    <this.renderTable />
                    <InputButtonWrapper>
                        <InputButton value="Add a Domain" onClick={this.handleOnCreate} />
                        <InputButton value="EDIT" disabled={this.state.editButtonDisabled} onClick={this.onEditClick} />
                        <InputButton
                            buttonTheme={this.state.removeButtonState && ButtonThemes.Red}
                            value={this.state.removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                            disabled={this.state.removeButtonDisabled}
                            onClick={this.onRemoveClick}
                        />
                    </InputButtonWrapper>
                </div>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalManageDomains);
