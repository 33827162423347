/* eslint-disable react-hooks/exhaustive-deps */

// React dependencies
import React, { useContext, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import styled from 'styled-components';

// Theme
import { ThemeProvider } from 'styled-components';
import ResetSectionDashboardStyles from '../styled-components/reset-section-dashboard.styled';
import { SectionDashboardTheme } from '../filter-bar/enums/section-dashboard-theme';
import darkTheme from '../styled-components/dark-theme.styled';
import theme from '../styled-components/theme.styled';

// Redux dependencies
import { setTitle } from '../redux/actions/page-meta';
import { setShowNavBar } from '../redux/actions/nav-bar';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

// Routes
import RouteUserAccess from '../components/routes/user-access';
import { AdminViewer, AdminApi } from '../components/routes/enum/user-types';

// Context
import FilterBarProvider from '../filter-bar/context/filter-bar-context';
import { CubedThemeContext } from '../styled-components/theme-context/cubed-theme-context';

// Layouts dependencies
import LayoutAccountConfiguration from '../layouts/account-configuration';
import LayoutAccountManagement from '../layouts/account-management';
import LayoutHealthDashboard from '../layouts/health-dashboard/health-dashboard';
import ViewModal from './modal';
import ViewNotification from './notifications';
import ViewPopup from './popup';
import ViewMenu from './menu';
import LayoutTaggingGuide from '../layouts/tagging-guide';
import LayoutReport from '../layouts/report-layout';
import Nav from './nav';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
`;

export const StyledDashboardView = styled.div<{ showSidebar: boolean }>`
    flex: 1;
    margin-left: ${props => (props.showSidebar ? '300px' : '90px')};
`;

const StyledDashboardViewContent = styled.div`
    margin-top: 90px;
    padding: 16px;
`;

const ViewDashboard = (props: any) => {
    const reduxDispatch = useAppDispatch();
    const { user } = useAppSelector(state => state);
    const { showSidebar } = useAppSelector(state => state.nav);
    const { token } = useAppSelector(state => state.account);
    const { enableThemeToggle, sectionDashboardTheme } = useContext(CubedThemeContext);

    const location = useLocation();

    const toggleSidebar = () => {
        reduxDispatch(setShowNavBar(!showSidebar));
    };

    const getTitle = () => {
        const pathnameWithoutToken = location.pathname.split('/').splice(2).join('/');
        let pageTitle = undefined;

        switch (pathnameWithoutToken) {
            case 'account-management':
                pageTitle = 'Documentation';
                break;
            case 'account-configuration':
                pageTitle = 'Account Configuration';
                break;
            case 'tagging-guide':
                pageTitle = 'Tagging Guide';
                break;
            default:
                break;
        }

        return pageTitle;
    };

    useEffect(() => {
        if (getTitle()) reduxDispatch(setTitle(getTitle()));
    }, [location]);

    if (user.isLoggedIn !== true || !token) {
        return <Navigate to="/" />;
    }

    return (
        <ThemeProvider
            theme={enableThemeToggle && sectionDashboardTheme === SectionDashboardTheme.Dark ? darkTheme : theme}
        >
            <ResetSectionDashboardStyles />
            <StyledContainer>
                <Nav showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

                <StyledDashboardView showSidebar={showSidebar}>
                    <ViewMenu sidebarExpanded={showSidebar} history={props.history} upperMenuTitle={getTitle()} />
                    <StyledDashboardViewContent>
                        <Routes>
                            <Route path="*" element={<Navigate to="/" />} />
                            <Route index element={<Navigate to="reports/attribution/channel-insights-dashboard" />} />
                            <Route
                                path="account-configuration/*"
                                element={
                                    <RouteUserAccess userAccess={AdminApi}>
                                        <LayoutAccountConfiguration />
                                    </RouteUserAccess>
                                }
                            />
                            <Route
                                path="account-management/*"
                                element={
                                    <RouteUserAccess userAccess={AdminApi}>
                                        <LayoutAccountManagement />
                                    </RouteUserAccess>
                                }
                            />
                            <Route
                                path="health/*"
                                element={
                                    <RouteUserAccess userAccess={AdminViewer}>
                                        <LayoutHealthDashboard />
                                    </RouteUserAccess>
                                }
                            />
                            <Route
                                path="tagging-guide/*"
                                element={
                                    <RouteUserAccess userAccess={AdminViewer}>
                                        <LayoutTaggingGuide />
                                    </RouteUserAccess>
                                }
                            />
                            <Route
                                path="reports/*"
                                element={
                                    <RouteUserAccess userAccess={AdminViewer}>
                                        <FilterBarProvider>
                                            <LayoutReport />
                                        </FilterBarProvider>
                                    </RouteUserAccess>
                                }
                            />
                            <Route element={<Navigate to="/404" />} />
                        </Routes>
                    </StyledDashboardViewContent>
                    <ViewModal />
                    <ViewNotification />
                    <ViewPopup />
                </StyledDashboardView>
            </StyledContainer>
        </ThemeProvider>
    );
};

export default ViewDashboard;
