// Helpers
import { unknownErrorData } from '../../helpers/errors';
import { sparkLineDataSeries } from '../../helpers/create-data-series';

// Types
import { CubedField } from '../../../types';
import { ResourceComparisonData, SparkLineComparisonData } from '../../types';

export type UseResourceSparkLineComparisonArgs = {
    resourceData: ResourceComparisonData;
    metric: CubedField;
    dateDimension: CubedField;
};

const useResourceSparkLineComparison = ({
    resourceData,
    metric,
    dateDimension,
}: UseResourceSparkLineComparisonArgs): SparkLineComparisonData => {
    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        const sparkLineData: SparkLineComparisonData = {
            type: 'sparkLineComparison',
            status: 'success',
            metric: metric,
            series: [],
            comparisonSeries: [],
        };

        // insert data
        if (resource.status === 'success' && resource.objects.length > 0) {
            sparkLineData.series = sparkLineDataSeries(resource.objects, metric, dateDimension);
        }

        // Insert comparison data
        if (comparisonResource.status === 'success' && comparisonResource.objects.length > 0) {
            sparkLineData.comparisonSeries = sparkLineDataSeries(comparisonResource.objects, metric, dateDimension);
        }

        return sparkLineData;
    }

    return unknownErrorData();
};

export default useResourceSparkLineComparison;
