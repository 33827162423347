import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generatePath, generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isString } from '../../helpers/validator';
import { MarketResponse } from '../types';
import { DropdownOption } from '../../types';

type ErrorMessageObject = {
    editBlacklistedKeywordErrorMessage: string;
    editMarketErrorMessage: string;
    editKeywordTypeErrorMessage: string;
};

type KeywordTypeResponse = {
    id: number;
    type: string;
    resource_uri: string;
};

type BlackListedKeywordResponse = {
    keyword: string;
    market: MarketResponse;
    type: KeywordTypeResponse;
    active: number;
    created: string;
    updated: string;
    id: number;
    resource_uri: string;
};

type DbBlackListedKeywords = {
    id: number;
    keyword: string;
    marketId: number;
    market: string;
    keywordTypeId: number;
    keywordType: string;
    updated: string;
};

const LayoutManageBlacklistedKeywords = () => {
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(true);
    const [closeButtonState, setCloseButtonState] = useState<string>('close');
    const [disableSelectedKeyword, setDisableSelectedConnection] = useState<boolean>(false);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState<boolean>(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState<boolean>(true);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState<boolean>(true);
    const [addNewButtonDisabled, setAddNewButtonDisabled] = useState<boolean>(false);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState<boolean>(true);
    const [editConnectionAccordionOpen, setEditKeywordAccordionOpen] = useState<boolean>(false);
    const [editConnectionAccordionVisible, setEditKeywordAccordionVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageError, setPageError] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [removeButtonState, setRemoveButtonState] = useState(true);

    const [selectedKeyword, setSelectedKeyword] = useState<DbBlackListedKeywords[]>([]);
    const [updatedKeyword, setUpdatedKeyword] = useState<DbBlackListedKeywords[]>([]);
    const [deletedKeyword, setDeletedKeyword] = useState<DbBlackListedKeywords[]>([]);

    const [marketList, setMarketList] = useState<DropdownOption[]>([]);
    const [keywordTypes, setKeywordTypes] = useState<DropdownOption[]>([]);
    const [dbBlackListedKeywords, setDbBlackListedKeywords] = useState<DbBlackListedKeywords[]>([]);

    const [editSelectedMarket, setEditSelectedMarket] = useState<DropdownOption | undefined>(undefined);
    const [editSelectedKeywordType, setEditSelectedKeywordType] = useState<DropdownOption | undefined>(undefined);
    const [editBlacklistedKeyword, setEditBlacklistedKeyword] = useState<string>('');

    const [errorMessageObject, setErrorMessageObject] = useState<ErrorMessageObject>({
        editBlacklistedKeywordErrorMessage: '',
        editMarketErrorMessage: '',
        editKeywordTypeErrorMessage: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        setCloseButtonState('close');
        fetchAll();
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        if (editBlacklistedKeyword || editSelectedMarket || editSelectedKeywordType) {
            setSaveChangesButtonDisabled(false);
            setErrorMessageObject({
                editBlacklistedKeywordErrorMessage: '',
                editMarketErrorMessage: '',
                editKeywordTypeErrorMessage: '',
            });
        }
    }, [editBlacklistedKeyword, editSelectedMarket, editSelectedKeywordType]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            editBlacklistedKeywordErrorMessage: '',
            editMarketErrorMessage: '',
            editKeywordTypeErrorMessage: '',
        };

        if (!isString(editBlacklistedKeyword) || editBlacklistedKeyword.length === 0) {
            hasFormError = true;
            errorMessageObj.editBlacklistedKeywordErrorMessage =
                'Please enter valid black listed keywords in comma separated format.';
        }

        if (!editSelectedKeywordType) {
            hasFormError = true;
            errorMessageObj.editKeywordTypeErrorMessage = 'Please select a keyword type.';
        }

        if (!editSelectedMarket) {
            hasFormError = true;
            errorMessageObj.editMarketErrorMessage = 'Please select a market.';
        }

        if (hasFormError) {
            setSaveChangesButtonDisabled(true);
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const handleBlackListedKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditBlacklistedKeyword(event?.target?.value);
    };

    const handleMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
        const marketId = parseInt(event?.target?.value);
        const selectedMarket = marketList.find(market => market.value === marketId);
        setEditSelectedMarket(selectedMarket);
    };

    const handleKeywordType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keywordTypeId = parseInt(event?.target?.value);
        const selectedKeywordType = keywordTypes.find(type => type.value === keywordTypeId);
        setEditSelectedKeywordType(selectedKeywordType);
    };

    const fetchAll = () => {
        const resources = ['kwr-blacklisted-keyword', 'seogd-market', 'keyword-type'];
        const allRequests: AxiosPromise[] = [];

        resources.forEach(resource => {
            allRequests.push(
                axios({
                    method: 'GET',
                    url: generateUrl('config', resource, [
                        { key: 'active', value: 1 },
                        { key: 'limit', value: 0 },
                        ...(resource === 'seogd-market' ? [{ key: 'order_by', value: 'country' }] : []),
                    ]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        });
        axios
            .all(allRequests)
            .then(
                axios.spread((...response) => {
                    const blacklistedKeywords = response[0].data.objects.map((keyword: BlackListedKeywordResponse) => {
                        return {
                            id: keyword.id,
                            keyword: keyword.keyword,
                            marketId: keyword.market.id,
                            market: keyword.market.country,
                            keywordTypeId: keyword.type.id,
                            keywordType: keyword.type.type,
                            updated: keyword.updated,
                        };
                    });

                    const allMarkets = response[1].data.objects.map((data: MarketResponse) => {
                        return {
                            label: data.alpha2,
                            value: data.id,
                            name: data.country,
                        };
                    });

                    const keywordTypes = response[2].data.objects.map((type: KeywordTypeResponse) => {
                        return {
                            label: type.type,
                            value: type.id,
                            name: type.type,
                        };
                    });

                    setDbBlackListedKeywords(blacklistedKeywords);
                    setMarketList(allMarkets);
                    setKeywordTypes(keywordTypes);
                    setIsLoading(false);
                })
            )
            .catch(error => {
                setIsLoading(false);
                setPageError(true);
                errorHandling(error);
            });
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onDeleteConnection = () => {
        setSaveChangesButtonDisabled(false);
        setCloseButtonState('cancel');
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);

        if (disableSelectedKeyword) {
            return;
        }

        let tempRemovedConnection = Array.prototype.concat(deletedKeyword);
        let tempUpdatedConnection = Array.prototype.concat(updatedKeyword);

        selectedKeyword.forEach(keyword => {
            if (removeButtonState) {
                tempRemovedConnection.push(keyword);
            } else {
                tempRemovedConnection.splice(tempRemovedConnection.indexOf(keyword), 1);
            }

            // If a keyword was edited and then revoked in the same instance then the revoke should take priority
            const updatedConfigIndex = tempUpdatedConnection.indexOf(keyword);

            if (updatedConfigIndex >= 0) {
                tempUpdatedConnection.splice(updatedConfigIndex, 1);
            }
        });

        setDeletedKeyword(tempRemovedConnection);
        setUpdatedKeyword(tempUpdatedConnection);
        setSelectedKeyword([]);
        setRemoveButtonState(true);
    };

    const onKeywordSelect = (keyword: DbBlackListedKeywords) => {
        if (disableSelectedKeyword) {
            return;
        }

        const selectedConnectionId = keyword.id;
        let selectedConnections = [];

        if (selectedKeyword.filter(event => event.id === selectedConnectionId).length > 0) {
            selectedConnections = selectedKeyword.filter(event => event.id !== selectedConnectionId);
            setSelectedKeyword(selectedConnections);
            setEditButtonDisabled(selectedConnections.length === 0);
            setRemoveButtonDisabled(selectedConnections.length === 0);
            setAddNewButtonDisabled(selectedConnections.length === 0);
        } else {
            selectedConnections = selectedKeyword.concat(
                dbBlackListedKeywords.filter(conn => conn.id === selectedConnectionId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRevoked = false;
        let containsNonRevoked = false;
        let containsEdited = false;

        selectedConnections.forEach(event => {
            if (deletedKeyword.indexOf(event) >= 0) {
                containsRevoked = true;
            } else {
                containsNonRevoked = true;
            }

            if (updatedKeyword.indexOf(event) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;
        let addButtonDisabled = false;

        if (selectedConnections.length > 0) {
            editButtonDisabled = false;
            removeButtonDisabled = false;
            addButtonDisabled = true;
        }

        if (selectedConnections.length > 1) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRevoked && !containsNonRevoked && !containsEdited) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRevoked) {
            editButtonDisabled = true;
        }

        if (containsRevoked && containsNonRevoked) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        setAddNewButtonDisabled(addButtonDisabled);
        setSelectedKeyword(selectedConnections);
        setEditButtonDisabled(editButtonDisabled);
        setRemoveButtonDisabled(removeButtonDisabled);
        setRemoveButtonState(removeButtonState);
        setUpdateButtonDisabled(true);
        setEditKeywordAccordionOpen(false);
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(removeModal());
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const handleAddNew = () => {
        dispatch(setModal('AddBlacklistedKeywords', {}));
    };

    const onEditClick = () => {
        const market = marketList.find(market => market.value === selectedKeyword[0].marketId);
        const keywordType = keywordTypes.find(type => type.value === selectedKeyword[0].keywordTypeId);

        setCloseButtonState('cancel');
        setEditKeywordAccordionVisible(true);
        setEditKeywordAccordionOpen(true);
        setRemoveButtonDisabled(true);
        setUpdateButtonDisabled(false);
        setDisableSelectedConnection(true);
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setEditBlacklistedKeyword(selectedKeyword[0].keyword);
        setEditSelectedMarket(market);
        setEditSelectedKeywordType(keywordType);

        setErrorMessageObject({
            editBlacklistedKeywordErrorMessage: '',
            editMarketErrorMessage: '',
            editKeywordTypeErrorMessage: '',
        });
    };

    const updateKeyword = () => {
        return new Promise(async (resolve, reject) => {
            const payload = {
                keyword: editBlacklistedKeyword,
                market: generatePath('config', 'seogd-market', String(editSelectedMarket?.value)),
                type: generatePath('config', 'keyword-type', String(editSelectedKeywordType?.value)),
            };

            try {
                const requestConfig: AxiosRequestConfig = {
                    method: 'PATCH',
                    url: generateUrlDetail('config', 'kwr-blacklisted-keyword', String(selectedKeyword[0].id))!,
                    data: payload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const requestResponse = await axios(requestConfig);

                if (requestResponse.status !== 202) {
                    throw new Error('Status not 202');
                }
            } catch (error) {
                console.log(error);
                reject('Error making a connection to API.');
            }
            resolve('Blacklisted keyword updated successfully.');
        });
    };

    const handleNavigateManageConnectionModal = () => {
        dispatch(removeModal());
    };

    const onUpdateClick = () => {
        if (!formValidator()) {
            return;
        }
        setIsUpdating(true);
        updateKeyword()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'Blacklisted keyword was successfully updated.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
                setIsUpdating(false);
                handleNavigateManageConnectionModal();
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue updating a Blacklisted keyword.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const onSaveChangesClick = () => {
        setSaveChangesButtonLoading(true);
        const requests: AxiosPromise[] = [];

        if (deletedKeyword.length > 0) {
            const deleteBlacklistedKeyword = {
                active: 0,
            };

            deletedKeyword.forEach(keyword => {
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'kwr-blacklisted-keyword', String(keyword.id))!,
                        data: deleteBlacklistedKeyword,
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        axios
            .all(requests)
            .then(
                axios.spread((...res) => {
                    dispatch(
                        addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: NotificationMessageType.Success,
                        })
                    );
                    setSaveChangesButtonLoading(false);
                    dispatch(removeModal());
                })
            )
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to save your changes. Please try again later or contact Cubed Support.',
                        type: NotificationMessageType.Error,
                    })
                );
                setSaveChangesButtonLoading(false);
            });
    };

    const renderModalNavigation = () => {
        let numberOfChanges = updatedKeyword.length + deletedKeyword.length;
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    };

    const renderAccordion = () => {
        if (editConnectionAccordionVisible) {
            const accordions = [
                {
                    header: 'Edit Blacklisted keyword',
                    required: false,
                    open: editConnectionAccordionOpen,
                    type: 'form',
                    intro: '',
                    config: {
                        formConfig: {
                            fields: [
                                {
                                    label: 'Blacklisted Keyword:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'Enter blacklisted keyword',
                                    inputKeyValue: 'editBlacklistedKeyword',
                                    inputValue: editBlacklistedKeyword,
                                    inputOnChange: handleBlackListedKeyword,
                                    errorMessage: errorMessageObject.editBlacklistedKeywordErrorMessage,
                                },
                                {
                                    label: 'Market:',
                                    type: 'select',
                                    requiredField: true,
                                    toolTipCopy: 'Select a Market',
                                    inputKeyValue: 'market',
                                    inputValue: editSelectedMarket?.value,
                                    inputOptions: marketList,
                                    inputOnChange: handleMarket,
                                    errorMessage: errorMessageObject.editMarketErrorMessage,
                                },
                                {
                                    label: 'Keyword Type:',
                                    type: 'select',
                                    requiredField: true,
                                    toolTipCopy: 'Select a Keyword Type',
                                    inputValue: editSelectedKeywordType?.value,
                                    inputKeyValue: 'keywordTypes',
                                    inputOptions: keywordTypes,
                                    inputOnChange: handleKeywordType,
                                    errorMessage: errorMessageObject.editKeywordTypeErrorMessage,
                                },
                            ],
                            buttons: [
                                {
                                    value: 'UPDATE',
                                    onClick: onUpdateClick,
                                    disabled: updateButtonDisabled,
                                    isLoading: isUpdating,
                                },
                            ],
                        },
                    },
                },
            ];

            return <WidgetAccordion accordions={accordions} />;
        }
    };

    const renderTable = () => {
        const errorMessageOverride = 'No Blacklisted keyword is added for this account.';

        const header = {
            columns: [
                {
                    title: 'Blacklisted Keyword',
                },
                {
                    title: 'Market',
                },
                {
                    title: 'Keyword Type',
                },
            ],
        };

        const rows = dbBlackListedKeywords.map(keyword => {
            const rowProperty = {
                selected: selectedKeyword.includes(keyword),
                deleted: deletedKeyword.includes(keyword),
                edited: updatedKeyword.includes(keyword),
                disabled: disableSelectedKeyword,
            };

            return {
                onClick: () => onKeywordSelect(keyword),
                key: `blacklisted_keyword__${keyword.id}`,
                dataValue: keyword.id,
                rowProperty,
                columns: [
                    {
                        copy: keyword.keyword,
                    },
                    {
                        copy: keyword.market,
                    },
                    {
                        copy: keyword.keywordType,
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Blacklisted Keywords</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Blacklisted Keywords</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Manage Blacklisted Keywords</h2>
            <p>You can add and manage blacklisted keywords from here. Click to Add New to add blacklisted keywords.</p>
            <div className="">
                {renderTable()}
                <InputButtonWrapper>
                    <InputButton value="Add New" disabled={addNewButtonDisabled} onClick={handleAddNew} />
                    <InputButton value="EDIT" disabled={editButtonDisabled} onClick={onEditClick} />
                    <InputButton
                        buttonTheme={ButtonThemes.Red}
                        value={removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                        disabled={removeButtonDisabled}
                        onClick={onDeleteConnection}
                    />
                </InputButtonWrapper>
            </div>
            {renderAccordion()}
        </div>
    );
};

export default LayoutManageBlacklistedKeywords;
