// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import {
    isPassword,
    hasCapitalLetter,
    hasLowerCaseLetter,
    hasNumber,
    hasSpecialCharacter,
    hasPasswordValidCharacters,
} from '../../helpers/validator';
import { generateUrl } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';

// Widget Dependencies
import WidgetAccordion from '../../widgets/accordion';

class LayoutModalResetUserPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            closeButtonState: 'close',
            saveChangesButtonDisabled: true,
            passwordAccordionOpen: true,
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: '',
            currentPasswordErrorMessage: '',
            newPasswordErrorMessage: '',
            repeatNewPasswordErrorMessage: '',
            newPasswordHasLength: false,
            newPasswordHasCapitalLetter: false,
            newPasswordHasLowercaseLetter: false,
            newPasswordHasNumber: false,
            newPasswordHasSpecialCharacter: false,
            newPasswordHasValidCharacter: false,
        };

        this.formValidator = this.formValidator.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderAccordion = this.renderAccordion.bind(this);
    }

    formValidator() {
        let hasFormError = false;
        let errorMessageObject = {
            currentPasswordErrorMessage: '',
            newPasswordErrorMessage: '',
            repeatNewPasswordErrorMessage: '',
        };

        if (!isPassword(this.state.currentPassword) || this.state.currentPassword.length === 0) {
            hasFormError = true;
            errorMessageObject.currentPasswordErrorMessage = 'Please enter a valid Password.';
        }

        if (!isPassword(this.state.newPassword) || this.state.newPassword.length === 0) {
            hasFormError = true;
            errorMessageObject.newPasswordErrorMessage =
                'Please enter a valid Password that meets the below requirements.';
        }

        if (this.state.newPassword !== this.state.repeatNewPassword || this.state.repeatNewPassword.length === 0) {
            hasFormError = true;
            errorMessageObject.repeatNewPasswordErrorMessage =
                'Please ensure the password is the same as New Password.';
        }

        if (hasFormError) {
            this.setState({
                currentPasswordErrorMessage: errorMessageObject.currentPasswordErrorMessage,
                newPasswordErrorMessage: errorMessageObject.newPasswordErrorMessage,
                repeatNewPasswordErrorMessage: errorMessageObject.repeatNewPasswordErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    }

    updatePassword() {
        const updatePasswordConfig = {
            method: 'POST',
            url: generateUrl('user', 'password', [], false),
            data: {
                new_password1: this.state.newPassword,
                new_password2: this.state.repeatNewPassword,
                current_password: this.state.currentPassword,
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        Axios(updatePasswordConfig)
            .then(res => {
                this.setState({
                    saveChangesButtonDisabled: false,
                });

                this.props.addNotification({
                    copy: 'Password has been successfully updated..',
                    type: NotificationMessageType.Success,
                });
                this.props.removeModal();
            })
            .catch(error => {
                if (error.response.status) {
                    this.setState({
                        saveChangesButtonDisabled: false,
                        currentPasswordErrorMessage: 'Please enter your Current Password. ',
                    });
                } else {
                    this.setState({
                        saveChangesButtonDisabled: false,
                    });

                    this.props.addNotification({
                        copy: 'There was an issue trying to update your password. Please try again later',
                        type: NotificationMessageType.Error,
                    });
                }
            });
    }

    onSaveChangesClick() {
        this.setState({
            saveChangesButtonDisabled: true,
            currentPasswordErrorMessage: '',
            newPasswordErrorMessage: '',
            repeatNewPasswordErrorMessage: '',
        });

        if (this.formValidator()) {
            this.updatePassword();
        } else {
            this.setState({
                saveChangesButtonDisabled: false,
            });
        }
    }

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.props.removeModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    // Manage the input fields changes and updating the state with the new value entered by the user
    checkClosedButtonState = () => {
        if (this.state.closeButtonState === 'close') {
            this.setState({
                closeButtonState: 'cancel',
                saveChangesButtonDisabled: false,
            });
        }
    };

    onCurrentPasswordChange = event => {
        this.checkClosedButtonState();
        this.setState({
            currentPassword: event.target.value,
        });
    };
    onNewPasswordChange = event => {
        this.checkClosedButtonState();
        const value = event.target.value;
        this.setState({
            newPasswordHasLength: value.length >= 8,
            newPasswordHasCapitalLetter: hasCapitalLetter(value),
            newPasswordHasLowercaseLetter: hasLowerCaseLetter(value),
            newPasswordHasNumber: hasNumber(value),
            newPasswordHasSpecialCharacter: hasSpecialCharacter(value),
            newPasswordHasValidCharacter: hasPasswordValidCharacters(value),
            newPassword: value,
        });
    };
    onRepeatNewPasswordChange = event => {
        this.checkClosedButtonState();
        this.setState({
            repeatNewPassword: event.target.value,
        });
    };

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.props.removeModal();
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    }

    renderModalNavigation() {
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: false,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    }

    renderAccordion() {
        const accordions = [
            {
                header: 'Password',
                required: false,
                open: this.state.passwordAccordionOpen,
                type: 'form',
                intro: '',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Current Password:',
                                type: 'password',
                                requiredField: true,
                                inputType: 'password',
                                inputAutocomplete: 'current-password',
                                inputKeyValue: 'password__current-password',
                                inputPlaceholder: 'Current Password...',
                                inputValue: this.state.currentPassword,
                                inputOnChange: this.onCurrentPasswordChange,
                                errorMessage: this.state.currentPasswordErrorMessage,
                            },
                            {
                                label: 'New Password:',
                                type: 'password',
                                requiredField: true,
                                inputType: 'password',
                                inputAutocomplete: 'new-password',
                                inputKeyValue: 'password__new-password',
                                inputPlaceholder: 'New Password...',
                                inputValue: this.state.newPassword,
                                inputOnChange: this.onNewPasswordChange,
                                errorMessage: this.state.newPasswordErrorMessage,
                            },
                            {
                                type: 'passwordRequirements',
                                characterLength: this.state.newPasswordHasLength,
                                hasCapitalLetter: this.state.newPasswordHasCapitalLetter,
                                hasLowercaseLetter: this.state.newPasswordHasLowercaseLetter,
                                hasNumber: this.state.newPasswordHasNumber,
                                hasSpecialCharacter: this.state.newPasswordHasSpecialCharacter,
                                hasValidCharacter: this.state.newPasswordHasValidCharacter,
                            },
                            {
                                label: 'Repeat New Password:',
                                type: 'password',
                                requiredField: true,
                                inputType: 'password',
                                inputAutocomplete: 'new-password',
                                inputKeyValue: 'password__repeat-new-password',
                                inputPlaceholder: 'Repeat New Password...',
                                inputValue: this.state.repeatNewPassword,
                                inputOnChange: this.onRepeatNewPasswordChange,
                                errorMessage: this.state.repeatNewPasswordErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__create-event-wizard">
                    <this.renderModalNavigation />
                    <h2>Reset Password</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__create-event-wizard">
                <this.renderModalNavigation />
                <h2>Reset Password</h2>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta,
        account: state.account,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalResetUserPassword);
