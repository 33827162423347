// React Dependencies
import React, { ChangeEvent, useEffect, useState } from 'react';

// Core Dependencies
import Papa from 'papaparse';
import Axios from 'axios';
import styled from 'styled-components';

// Helpers
import { generateUrl } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal } from '../../redux/actions/modal';

// Redux Selectors
import { useSelector, useDispatch } from 'react-redux';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import InputButton from '../../components/inputs/input-button';

import WidgetAccordion from '../../widgets/accordion';
import ProgressBar from '../../components/progress-bar';
import moment from 'moment';
import { validateFileType } from '../../helpers/validate-csv-file';
import { RootState } from '../../redux/store';
import useCSVUploadResource from '../../react-query/hooks/use-csv-upload-resource';

import { FORECAST_FILE_UPLOAD_RESOURCE } from '../../configurations/resources-config';

const StyledButtonWithMargin = styled.div`
    margin-top: 20px;
`;

type ProductRefererResponse = {
    id: number;
    name: string;
};

type CSVHeaders = ['Budget', 'Channel', 'Date', 'Forecasted_Revenue', 'Forecasted_Sales', 'Forecasted_Traffic'];
type Forecast = {
    Budget: string;
    Channel: string;
    Date: string;
    Forecasted_Revenue: string;
    Forecasted_Sales: string;
    Forecasted_Traffic: string;
};

const LayoutModalAddForecasts = () => {
    const [products, setProducts] = useState<ProductRefererResponse[]>([]);
    const [referer, setReferer] = useState<ProductRefererResponse[]>([]);
    const [selectedProductId, setSelectedProductId] = useState<number>();
    const [selectedFile, setSelectedFile] = useState<Blob | null>(null);
    const [selectedFileIsValid, setSelectedFileIsValid] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState(true);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [fileInputErrorMessage, setFileInputErrorMessage] = useState('');
    const [isLoadingProductAndReferrer, setIsLoadingProductAndReferrer] = useState(true);
    const [pageProductAndReferrerError, setPageProductAndReferrerError] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<string>('');
    const [formData, setFormData] = useState<FormData | null>(null);
    const [fileName, setFileName] = useState('');

    const fileHeaders = ['Budget', 'Channel', 'Date', 'Forecasted_Revenue', 'Forecasted_Sales', 'Forecasted_Traffic'];
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);

    useEffect(() => {
        Promise.all([
            Axios({
                method: 'GET',
                url: generateUrl('config', 'product', [{ key: 'active', value: 1 }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
            Axios({
                method: 'GET',
                url: generateUrl('config', 'referer', [{ key: 'active', value: 1 }]),
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        ])
            .then(([productResponse, refererResponse]) => {
                if (productResponse.status === 200) {
                    const products = productResponse.data.objects.map((product: ProductRefererResponse) => {
                        return {
                            id: product.id,
                            keyValue: product.id,
                            name: product.name,
                        };
                    });

                    setProducts(products);
                }

                if (refererResponse.status === 200) {
                    const referer = refererResponse.data.objects.map((referer: ProductRefererResponse) => {
                        return {
                            id: referer.id,
                            keyValue: referer.id,
                            name: referer.name,
                        };
                    });

                    setReferer(referer);
                }
                setIsLoadingProductAndReferrer(false);
            })
            .catch(() => {
                setPageProductAndReferrerError(true);
            });
    }, []);

    const {
        isLoading,
        isUploading,
        uploadComplete,
        uploadProgress,
        returningMessage,
        uploadError,
        pageError,
        resetStateFunction,
    } = useCSVUploadResource({
        fileUploadProgressResource: FORECAST_FILE_UPLOAD_RESOURCE,
        formData,
    });

    const onCloseClick = () => {
        dispatch(removeModal());
    };

    const onProductInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const selectedProductId = products.filter(
            (product: ProductRefererResponse) => product.name === event.target.value
        )[0].id;
        setSelectedProduct(event.target.value);
        setSelectedProductId(selectedProductId);
    };

    const finishUpload = () => {
        setProducts([]);
        setReferer([]);
        setSelectedProductId(undefined);
        setSelectedFile(null);
        setSelectedFileIsValid(false);
        setAccordionOpen(true);
        setCloseButtonDisabled(false);
        setFileInputErrorMessage('');
        resetStateFunction();
    };

    const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0] as File;

        // Check the file extension
        const { isValid, selectedFile, message } = validateFileType(file, 'csv');
        if (!isValid) {
            setSelectedFileIsValid(isValid);
            setSelectedFile(selectedFile);
            setFileInputErrorMessage(message);

            return;
        }

        const reader = new FileReader();
        let dataCsv: Forecast[];
        let firstRowData: CSVHeaders | undefined;
        try {
            reader.readAsText(file);
            reader.onload = fileEvent => {
                const csvData: string = fileEvent.target?.result as string;
                Papa.parse(csvData, {
                    skipEmptyLines: true,
                    header: true,
                    complete: result => {
                        firstRowData = result.data[0] as CSVHeaders;
                        dataCsv = result.data as Forecast[];
                    },
                });

                if (firstRowData === undefined) {
                    setSelectedFileIsValid(false);
                    setSelectedFile(null);
                    setFileInputErrorMessage(
                        "Sorry, this file doesn't appear to have data. Please check it and try again."
                    );
                } else {
                    if (!validateHeadersCSVFile(firstRowData)) {
                        setSelectedFileIsValid(false);
                        setSelectedFile(null);
                        setFileInputErrorMessage(
                            "Sorry, this file doesn't appear to be in the correct format. Please check it and try again."
                        );
                        return;
                    }
                    if (validateDataCSVFile(firstRowData, dataCsv)) {
                        setSelectedFileIsValid(true);
                        setFileInputErrorMessage('');

                        dataCsv.forEach((row, index) => {
                            const channelInfo = referer.find(
                                item => item.name === row.Channel
                            ) as ProductRefererResponse;
                            dataCsv[index].Channel = String(channelInfo.id);
                        });

                        const keys = Object.keys(dataCsv[0]);
                        const commaSeparatedString = [
                            keys.join(','),
                            dataCsv.map(row => keys.map(key => row[key as keyof Forecast]).join(',')).join('\n'),
                        ].join('\n');
                        const csvBlob = new Blob([commaSeparatedString], { type: 'text/csv' });
                        setFileName(file.name);
                        setSelectedFile(csvBlob);
                    }
                }
            };
            reader.onerror = () => {
                setSelectedFileIsValid(false);
                setSelectedFile(null);
                setFileInputErrorMessage(
                    'Sorry, there was a problem reading this file. Please check it and try again.'
                );
            };
        } catch (error) {
            setSelectedFileIsValid(false);
            setSelectedFile(null);
            setFileInputErrorMessage('Sorry, there was a problem reading this file. Please check it and try again.');
        }
    };

    const uploadCSVFile = () => {
        if (!selectedFile || !selectedProductId) {
            return;
        }
        const data = new FormData();
        data.append('upload_type', 'forecast');
        data.append('file', selectedFile);
        data.append('product', selectedProductId.toString());
        data.append('account_id', account.id);
        setFormData(data);
    };

    const validateHeadersCSVFile = (data: CSVHeaders) => {
        const fileHeadersToCheck = data;
        const fileHeadersSorted = Object.keys(fileHeadersToCheck)
            .sort()
            .map(header => {
                return header.toLowerCase();
            });
        const lowerCaseHeaders = fileHeaders.map(header => {
            return header.toLowerCase();
        });
        return (
            lowerCaseHeaders.length === fileHeadersSorted.length &&
            lowerCaseHeaders.every((header, index) => {
                return header === fileHeadersSorted[index];
            })
        );
    };

    const setInvalidFileState = (message: string) => {
        setFileInputErrorMessage(message);
        setSelectedFileIsValid(false);
        setSelectedFile(null);
        return false;
    };

    const validateDataCSVFile = (headerData: string[], data: Forecast[]) => {
        const csvData = data;
        const fileHeadersToCheck = headerData;
        const fileHeadersSorted = Object.keys(fileHeadersToCheck)
            .sort()
            .map(header => {
                return header;
            });

        for (let i = 0; i < fileHeadersSorted.length; i++) {
            const header = fileHeadersSorted[i];
            for (let j = 0; j < csvData.length; j++) {
                const data = csvData[j];
                if (header === 'Date') {
                    if (data[header] !== '') {
                        let isDate = moment(data[header], 'YYYY-MM-DD', true).isValid();
                        if (!isDate) {
                            return setInvalidFileState(
                                "Sorry, the column date doesn't appear to be in the correct format."
                            );
                        }
                    } else {
                        return setInvalidFileState('Sorry, the column date cannot be empty.');
                    }
                }

                if (
                    header === 'Budget' ||
                    header === 'Forecasted_Revenue' ||
                    header === 'Forecasted_Sales' ||
                    header === 'Forecasted_Traffic'
                ) {
                    if (data[header] !== '') {
                        let isNumber = Number.isNaN(parseInt(data[header]));
                        if (isNumber) {
                            return setInvalidFileState(`Sorry, the column ${header} must be a number.`);
                        }
                    }
                }
                if (header === 'Channel') {
                    if (
                        !(
                            referer.some(item => item.name === data[header]) &&
                            data[header] !== '' &&
                            Number.isNaN(parseInt(data[header]))
                        )
                    ) {
                        return setInvalidFileState(
                            `Sorry, the column ${header} doesn't have a correct channel name. Please check the available channels and re-upload the file.`
                        );
                    }
                }
            }
        }

        return true;
    };

    const RenderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const RenderUploadAccordion = () => {
        const accordion = [
            {
                header: 'Add Forecast',
                required: false,
                open: accordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Goal:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'The goal associated with the forecasts and budgets inserted.',
                                inputKeyValue: 'add-forecast__product',
                                inputValue: selectedProduct,
                                inputOptions: products,
                                inputOnChange: onProductInputChange,
                            },
                            {
                                label: 'Forecast CSV File:',
                                type: 'file-upload',
                                requiredField: true,
                                toolTipCopy: 'Upload A Forecast CSV File.',
                                inputKeyValue: 'add-forecast__file',
                                inputOnChange: onFileInputChange,
                                isValid: selectedFileIsValid,
                                uploadedFile: fileName,
                                errorMessage: fileInputErrorMessage,
                            },
                        ],
                        columns: 1,
                        buttons: [
                            {
                                value: 'UPLOAD',
                                onClick: uploadCSVFile,
                                disabled: selectedFile && selectedProductId ? false : true,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordion} />;
    };

    if (isLoading || isLoadingProductAndReferrer) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add Forecasts</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError || pageProductAndReferrerError) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add Forecasts</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact" />
            </div>
        );
    }

    if (uploadError) {
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add Forecasts</h2>
                <WarningMessage copy="There was a problem with your file upload. Please check your file, try again later or contact" />
            </div>
        );
    }

    if (isUploading || uploadComplete) {
        const progressCopy = `File upload ${uploadProgress === '100.00' ? 'complete!' : 'in progress...'}`;
        return (
            <div className="modal__side-panel__add-csv manage-modals">
                <RenderModalNavigation />
                <h2>Add Forecasts</h2>
                <div className="file-upload-progress">
                    {returningMessage && <p>{returningMessage}</p>}
                    <p>{progressCopy}</p>
                    <ProgressBar value={parseFloat(uploadProgress) / 100} difference={100} label="Upload Progress" />
                    <StyledButtonWithMargin>
                        <InputButton
                            buttonTheme={ButtonThemes.Primary}
                            value={'Done'}
                            hidden={uploadProgress !== '100.00'}
                            onClick={finishUpload}
                        />
                    </StyledButtonWithMargin>
                </div>
            </div>
        );
    }

    return (
        <div className="modal__side-panel__add-csv manage-modals">
            <RenderModalNavigation />
            <h2>Add Forecasts</h2>
            <p>
                Import forecast data into Cubed to enable advanced forecast reporting. It is important to use the
                defined CSV structure to upload your forecasts - a blank CSV template can be downloaded below. A goal
                and channel must be specified. For more information on uploading forecasts and budgets using the CSV
                template, please see our <a href="https://tag.docs.withcubed.com/onboarding/forecasts">documentation</a>
                .
            </p>
            <p>
                If you are uploading forecasts and budgets for dates in the past, please contact a member of the team to
                re-run your reports.
            </p>
            <p>
                <a href="/files/forecast_template.csv">CSV Template</a>
            </p>
            <RenderUploadAccordion />
        </div>
    );
};

export default LayoutModalAddForecasts;
