// React Dependencies
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonThemes } from '../../enums/button-themes';
import { RootState } from '../../redux/reducers/core';

// Libraries
import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';

// Helpers
import { generatePath, generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import {
    removeParentConnection,
    removeAdwordAccountConnectionDetail,
} from '../../helpers/google-oauth/remove-connection';

// Components
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WarningMessage from '../../components/warning-message';
import WidgetAccordion from '../../widgets/accordion';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import { SimpleTableRow } from '../../components/tables/types';
import Message from '../../components/message';

const StyledButtonGroup = styled.div`
    display: flex;
    gap: 4px;
`;

const StyledErrorMessageContainer = styled.div`
    margin: 0px 0px 20px 0px;
    padding: 8px;
    background-color: ${props => props.theme.colours.lightGrey};
    border: 1.5px solid ${props => props.theme.colours.warning};
`;

export type Connection = {
    id: string;
    name: string;
    user: string;
    userId: string;
    SEARCH_CONSOLE: boolean;
    ADWORDS: boolean;
    YOUTUBE: boolean;
    relatedAdwordsConnection: FormattedAdwordsConnections[];
    parentOauth: boolean;
    adwordDeactivated: boolean;
    searchConsoleDeactivated: boolean;
    youtubeDeactivated: boolean;
    connectionStatusId: string;
};

type ConnectionServiceResponse = {
    service_id: number;
    service_selected: string;
};

type ConnectionStatusResponse = {
    adwords_status: boolean;
    search_console_status: boolean;
    youtube_status: boolean;
    status_id: string;
};

export type ConnectionResponse = {
    active: boolean;
    cubed_user_id: number;
    deleted: boolean;
    id: number;
    name: string;
    resource_uri: string;
    service: ConnectionServiceResponse[];
    connection_status: ConnectionStatusResponse | null;
};

// ADWORDS RESPONSE TYPES
type AdAccountDataResponse = {
    connection: ConnectionResponse;
    name: string;
    id: number;
    resource_uri: string;
    active: boolean;
    deleted: boolean;
};

export type FormattedAdwordsConnections = {
    connection: ConnectionResponse;
    name: string;
    id: number;
    resource_uri: string;
    active: boolean;
    deleted: boolean;
    userId: string;
    SEARCH_CONSOLE: boolean;
    ADWORDS: boolean;
    YOUTUBE: boolean;
    parentOauth: boolean;
    adwordDeactivated: boolean;
    searchConsoleDeactivated: boolean;
    youtubeDeactivated: boolean;
    connectionStatusId: string;
};

type AdwordsAccountsResponse = {
    id: number;
    active: boolean;
    deleted: boolean;
    account_name: string;
};

type FormattedAdwordsAccounts = {
    accountType: string;
    accountId: number;
    active: boolean;
    name: string;
    children: FormattedAdwordsAccountsChildren[];
};
type FormattedAdwordsAccountsChildren = {
    accountType: string;
    accountId: number;
    active: boolean;
    name: string;
};

type MCCAdwordsAccountResponse = {
    id: number;
    active: boolean;
    deleted: boolean;
    account_name: string;
    children: AdwordsAccountsResponse[];
};

type ParentAccountsToDeactivateType = [number, string];

type MCCAccountsToDeactivateType = [number, number];
type AccountLookupToDeactivateType = [number, number];

export type AccountLookupResponse = {
    account: string;
    active: boolean;
    id: number;
    mcc_account: string;
    resource_uri: string;
};
type NestedTableProperty = {
    child: boolean;
};
type EditTableRow = {
    rowProperty?: NestedTableProperty;
    columns: EditAdwordsRowColumn;
};

type EditAdwordsRowColumn = [EditAdwordsColumnDescriptionType, ToggleSelectType];
type EditAdwordsColumnDescriptionType = {
    copy: string;
};
type ToggleSelectType = {
    type: string;
    toggleChecked: boolean;
    toggleOnClick: () => void;
    toggleDisabled?: boolean;
};
// GSC RESPONSE TYPES:
type GSCSitesResponseType = {
    id: number;
    active: boolean;
    deleted: boolean;
    oauth_connection: ConnectionResponse;
    resource_uri: string;
    site_url: string;
};

// type YoutubeConnectionStatusResponse = {
//     active: boolean;
//     id: string | undefined;
// };

const LayoutModalGoogleAPI = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState(true);
    const [tableSelectDisabled, setTableSelectDisabled] = useState(false);
    const [createButtonDisabled, setCreateButtonDisabled] = useState(false);
    const [isApiOwner, setIsApiOwner] = useState(false);
    const [connections, setConnections] = useState<Connection[]>([]);
    const [selectedConnections, setSelectedConnections] = useState<(Connection | FormattedAdwordsConnections)[]>([]);

    // Edit Site Accordion States
    const [editSiteUrlsButtonDisabled, setEditSiteUrlsButtonDisabled] = useState(true);
    const [isLoadingConnectionSites, setIsLoadingConnectionSites] = useState(true);
    const [editSiteUrlsAccordionHidden, setSiteUrlsAccordionHidden] = useState(true);
    const [editSiteUrlsAccordionOpen, setEditSiteUrlsAccordionOpen] = useState(true);
    const [gscSites, setGSCSites] = useState<GSCSitesResponseType[]>([]);
    const [gscSitesToUpdate, setGSCSitesToUpdate] = useState<GSCSitesResponseType[]>([]);

    // Edit Ad Accounts Accordion States
    const [editAdAccountsButtonDisabled, setEditAdAccountsButtonDisabled] = useState(true);
    const [editAdAccountsAccordionHidden, setAdAccountsAccordionHidden] = useState(true);
    const [editAdAccountsAccordionOpen, setAdAccountsAccordionOpen] = useState(true);
    const [isLoadingConnectionAccounts, setIsLoadingConnectionAccounts] = useState(true);
    const [applyEditAdAccountButtonDisabled, setApplyEditAdAccountButtonDisabled] = useState(true);
    const [adwordsAccounts, setAdwordsAccounts] = useState<FormattedAdwordsAccounts[]>([]);
    const [adwordsAccountsToUpdate, setAdwordsAccountsToUpdate] = useState<FormattedAdwordsAccounts[]>([]);
    const [parentAccountsToDeactivate, setParentAccountsToDeactivate] = useState<ParentAccountsToDeactivateType[]>([]);
    const [mccChildAccountsToDeactivate, setMccChildAccountsToDeactivate] = useState<MCCAccountsToDeactivateType[]>([]);

    // Edit Youtube Accordion States
    // const [editYoutubeConnectionButtonDisabled, setEditYoutubeConnectionButtonDisabled] = useState(true);
    // const [editYoutubeConnectionAccordionHidden, setEditYoutubeConnectionAccordionHidden] = useState(true);
    // const [editYoutubeConnectionAccordionOpen, setEditYoutubeConnectionAccordionOpen] = useState(true);
    // const [applyYoutubeConnectionButtonDisabled, setApplyYoutubeConnectionButtonDisabled] = useState(true);
    // const [isLoadingYoutubeStatus, setIsLoadingYoutubeStatus] = useState(true);
    // const [youtubeConnectionStatus, setYoutubeConnectionStatus] = useState<YoutubeConnectionStatusResponse>();
    // const [youtubeConnectionStatusToUpdate, setYoutubeConnectionStatusToUpdate] =
    // useState<YoutubeConnectionStatusResponse>();

    // warning message state
    const [serviceDeactivated, setServiceDeactivated] = useState(false);

    const userId: string = useSelector((state: RootState) => state.user.id);

    const dispatch = useDispatch();

    useEffect(() => {
        //fetch all connection on initial load
        fetchConnections();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const fetchConnections = async () => {
        /**
         * This fetches the OAuth connection that is
         * common to ADWORDS, SEARCH CONSOLE AND YOUTUBE.
         */
        try {
            const connectionUrl = generateUrl('integrations', 'api-google-connection', [
                { key: 'active', value: 1 },
                { key: 'deleted', value: 0 },
                { key: 'limit', value: 0 },
            ]);
            const connectionDataResponse = await Axios.get(connectionUrl, { withCredentials: true });
            const connectionData = connectionDataResponse.data.objects;

            const adwordsConnectionUrl = generateUrl('integrations', 'lite-api-adwords-connection-detail', [
                { key: 'active', value: 1 },
                { key: 'deleted', value: 0 },
                { key: 'limit', value: 0 },
            ]);
            const adwordsConnectionDataResponse = await Axios.get(adwordsConnectionUrl, { withCredentials: true });
            const adwordsConnectionData = adwordsConnectionDataResponse.data.objects;

            let emailUrl: string | null;

            const emailDataPromises = connectionData.map((connection: ConnectionResponse) => {
                emailUrl = generateUrlDetail('config', 'lite-user-email', connection.cubed_user_id.toString());
                return Axios.get(String(emailUrl), { withCredentials: true })
                    .then(response => response.data)
                    .catch(e => {
                        return {
                            email: 'Unknown',
                            id: connection.cubed_user_id,
                            resource_uri: '',
                        };
                    });
            });

            const rawEmail = await Promise.all(emailDataPromises);

            let tempObject: Connection[] = [];
            tempObject = connectionData.map((rawConnection: ConnectionResponse) => {
                let user = rawEmail.find(user => user.id.toString() === rawConnection.cubed_user_id.toString());
                let connectionServices = {
                    SEARCH_CONSOLE: false,
                    ADWORDS: false,
                    YOUTUBE: false,
                };
                rawConnection.service.forEach((item: ConnectionServiceResponse) => {
                    if (item.service_selected === 'SEARCH_CONSOLE') {
                        connectionServices.SEARCH_CONSOLE = true;
                    } else if (item.service_selected === 'ADWORDS') {
                        connectionServices.ADWORDS = true;
                    } else if (item.service_selected === 'YOUTUBE') {
                        connectionServices.YOUTUBE = true;
                    }
                });

                let connectionDeactivatedStatus = rawConnection.connection_status || {
                    adwords_status: false,
                    search_console_status: false,
                    youtube_status: false,
                    status_id: '',
                };

                if (
                    connectionDeactivatedStatus.adwords_status ||
                    connectionDeactivatedStatus.search_console_status ||
                    connectionDeactivatedStatus.youtube_status
                ) {
                    setServiceDeactivated(true);
                }

                const relatedAdwordsConnections = adwordsConnectionData.filter(
                    (item: AdAccountDataResponse) => item.connection.id === rawConnection.id
                );
                const formattedRelatedAdwordsConnections = relatedAdwordsConnections.map(
                    (item: AdAccountDataResponse) => {
                        const connectedServices = { SEARCH_CONSOLE: false, ADWORDS: true, YOUTUBE: false };
                        return {
                            ...item,
                            ...connectedServices,
                            parentOauth: false,
                            userId: rawConnection.cubed_user_id.toString(),
                            adwordDeactivated: false,
                            searchConsoleDeactivated: false,
                            youtubeDeactivated: false,
                        };
                    }
                );

                return {
                    id: rawConnection.id.toString(),
                    name: rawConnection.name,
                    user: user?.email,
                    userId: rawConnection.cubed_user_id.toString(),
                    ...connectionServices,
                    relatedAdwordsConnection: formattedRelatedAdwordsConnections,
                    parentOauth: true,
                    adwordDeactivated: connectionDeactivatedStatus.adwords_status,
                    searchConsoleDeactivated: connectionDeactivatedStatus.search_console_status,
                    youtubeDeactivated: connectionDeactivatedStatus.youtube_status,
                    connectionStatusId: connectionDeactivatedStatus.status_id.toString(),
                };
            });

            setConnections(tempObject);
            setIsLoading(false);
        } catch (e) {
            setPageError(true);
        }
    };

    const deleteConnections = async () => {
        dispatch(removePopup());
        setConnections([]);
        setIsLoading(true);

        setRemoveButtonDisabled(true);
        setSelectedConnections([]);
        setEditAdAccountsButtonDisabled(true);
        setEditSiteUrlsButtonDisabled(true);

        try {
            for (const connection of selectedConnections) {
                if (connection?.parentOauth) {
                    await removeParentConnection(connection);
                } else {
                    await removeAdwordAccountConnectionDetail(connection.id.toString()!);
                }

                fetchConnections().then(() => {
                    dispatch(
                        addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: 'success',
                        })
                    );
                });
            }
        } catch (e) {
            setPageError(true);
        }
    };

    const onDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Confirm delete?',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you want to delete these google connection(s)? Please note that if you are deleting a manager connection, all related adwords account will also be deleted.',
                },
                buttons: [
                    {
                        onClick: deleteConnections,
                        value: 'CONFIRM DELETE',
                    },
                    {
                        onClick: () => dispatch(removePopup()),
                        value: 'CANCEL',
                        style: 'secondary',
                    },
                ],
            })
        );
    };

    const onCloseClick = () => {
        dispatch(removeModal());
    };

    const onCreateClick = () => {
        dispatch(
            setPopup({
                title: 'Create connection with Google.',
                iconType: 'informative',
                contentType: 'createOauthConnection',
                config: {
                    description: 'Please select the services below that you want to allow the access to Cubed:',
                    detailedDescription:
                        'You will be taken to Google to authorize access to your data. Once you have authorized Cubed, our access to your data can be managed via the Cubed account configuration pages. You can remove connection at anytime. Cubed will also access your email address and username to identify you.',
                    informationAboutPreviousConnection:
                        "If you have previously connected to the Cubed, please don't forget to select previous services below.",
                },
                buttons: [],
            })
        );
    };

    /* Used to disable/enable common states */
    const disableCommonStateWhileEditing = (disable: boolean) => {
        // Table select
        setTableSelectDisabled(disable);

        // Create, Edit, Remove buttons
        setCreateButtonDisabled(disable);
        setRemoveButtonDisabled(disable);
    };

    // EDIT AD ACCOUNTS HANDLERS START:
    const onEditAdAccountsClick = () => {
        disableCommonStateWhileEditing(true);
        setEditAdAccountsButtonDisabled(true);
        setEditSiteUrlsButtonDisabled(true);
        setIsApiOwner(selectedConnections[0]?.userId === userId.toString());
        setAdAccountsAccordionOpen(true);
        getAdAccounts();
        setAdAccountsAccordionHidden(false);
        setApplyEditAdAccountButtonDisabled(true);
    };
    const getAdAccounts = async () => {
        setIsLoadingConnectionAccounts(true);
        let accounts = [];

        const mccAdAccountRequestUrl = generateUrl('integrations', 'lite-adwords-connection-mcc-account', [
            { key: 'connection_detail__id', value: String(selectedConnections[0]?.id) },
            { key: 'deleted', value: 0 },
        ]);
        const adAccountRequestUrl = generateUrl('integrations', 'lite-api-adwords-connection-account', [
            { key: 'connection_detail__id', value: String(selectedConnections[0]?.id) },
            { key: 'deleted', value: 0 },
            { key: 'non-mcc', value: 1 },
        ]);
        const mccAdAccountResponse = await Axios.get(mccAdAccountRequestUrl, { withCredentials: true });
        const adAccountsResponse = await Axios.get(adAccountRequestUrl, { withCredentials: true });
        let adAccountsData: FormattedAdwordsAccounts[] = adAccountsResponse.data.objects.map(
            (item: AdwordsAccountsResponse) => {
                // check if the the items are null
                if (item) {
                    return {
                        accountType: 'nonMcc',
                        accountId: item.id,
                        active: item.active,
                        name: item.account_name,
                        children: [],
                    };
                } else {
                    return undefined;
                }
            }
        );
        adAccountsData = adAccountsData.filter((item: FormattedAdwordsAccounts) => item !== undefined);

        const mccAdAccountData: FormattedAdwordsAccounts[] = mccAdAccountResponse.data.objects.map(
            (item: MCCAdwordsAccountResponse) => {
                if (item) {
                    let children: FormattedAdwordsAccountsChildren[] = [];
                    if (item.children) {
                        item.children.forEach((subItem: AdwordsAccountsResponse) => {
                            if (subItem.active) {
                                children.push({
                                    accountId: subItem.id,
                                    accountType: 'children',
                                    active: subItem.active,
                                    name: subItem.account_name,
                                });
                            }
                        });
                    }

                    return {
                        accountType: 'mcc',
                        accountId: item.id,
                        active: item.active,
                        name: item.account_name,
                        children: children,
                    };
                } else {
                    return undefined;
                }
            }
        );
        const mccAdAccountDataClean = mccAdAccountData.filter((item: FormattedAdwordsAccounts) => item !== undefined);

        accounts = [...adAccountsData, ...mccAdAccountDataClean];
        setAdwordsAccounts(accounts);
        setAdwordsAccountsToUpdate(JSON.parse(JSON.stringify(accounts)));
        setIsLoadingConnectionAccounts(false);
    };

    // for render edit adwords:
    const toggleAdAccountsActive = (accountId: number, accountType: string) => {
        /**
         * Account id and account type are stored if the toggle is deactivated.
         * If the Apply button is pressed then looking at the account type
         * the account id would be deactivated in either MCC or non MCC account.
         * If account type is MCC then child accounts are also deactivated.
         */
        let accountsModified = false;

        // This loop changes the active for the toggle display
        adwordsAccountsToUpdate.forEach((item: FormattedAdwordsAccounts) => {
            if (item.accountId === accountId && item.accountType === accountType) {
                item.active = !item.active;
            }
        });

        // Toggle the presence in the array that keeps track of item to deactivate
        const typeIdPair: ParentAccountsToDeactivateType = [accountId, accountType];
        let pairExists = false;

        for (const item of parentAccountsToDeactivate) {
            if (accountId === item[0] && accountType === item[1]) {
                pairExists = true;
                break;
            }
        }

        if (!pairExists) {
            setParentAccountsToDeactivate([...parentAccountsToDeactivate, typeIdPair]);
            accountsModified = true;
        } else {
            // Number is in the array, so remove it (as we are toggling)
            const poppedNonMccAccounts = parentAccountsToDeactivate.filter((item: ParentAccountsToDeactivateType) => {
                return item[0] !== typeIdPair[0] || item[1] !== typeIdPair[1];
            });
            if (poppedNonMccAccounts || mccChildAccountsToDeactivate) {
                accountsModified = true;
            }

            setParentAccountsToDeactivate(poppedNonMccAccounts);
        }
        setApplyEditAdAccountButtonDisabled(!accountsModified);
    };

    const toggleAdChildAccountsActive = (accountId: number, childAccountId: number) => {
        /**
         * Account id and childAccountId are stored if the toggle is deactivated.
         * If the Apply button is pressed then looking at the childAccountId
         * the account would be deactivated in non MCC account. The account lookup table
         * would also need to be deactivated. We need both the ids for this and to toggle off
         * child account when parent accounts are deactivated.
         */
        let accountsModified = false;

        // This loop changes the active for the toggle display
        adwordsAccountsToUpdate.forEach((item: FormattedAdwordsAccounts) => {
            if (item.accountId === accountId) {
                item.children.forEach((subItem: FormattedAdwordsAccountsChildren) => {
                    if (subItem.accountId === childAccountId) {
                        subItem.active = !subItem.active;
                    }
                });
            }
        });
        const parentChildIdPair: MCCAccountsToDeactivateType = [accountId, childAccountId];

        let pairExists = false;
        for (const item of mccChildAccountsToDeactivate) {
            if (item[0] === accountId && item[1] === childAccountId) {
                pairExists = true;
                break;
            }
        }

        // Toggle the presence in the array that keeps track of item to deactivate
        if (!pairExists) {
            setMccChildAccountsToDeactivate([...mccChildAccountsToDeactivate, parentChildIdPair]);
            accountsModified = true;
        } else {
            // Number is in the array, so remove it
            const poppedChildAccounts = mccChildAccountsToDeactivate.filter((item: MCCAccountsToDeactivateType) => {
                return item[0] !== parentChildIdPair[0] || item[1] !== parentChildIdPair[1];
            });

            if (poppedChildAccounts || parentAccountsToDeactivate) {
                accountsModified = true;
            }
            setMccChildAccountsToDeactivate(poppedChildAccounts);
        }
        setApplyEditAdAccountButtonDisabled(!accountsModified);
    };

    const onReImportAdAccountsFinishOverride = () => {
        getAdAccounts();
        setParentAccountsToDeactivate([]);
        setMccChildAccountsToDeactivate([]);
    };
    const onReimportAdAccountsButtonClick = () => {
        dispatch(
            setPopup({
                title: 'Import Accounts',
                iconType: 'informative',
                contentType: 'importIntegrationAccounts',
                config: {
                    technology: 'adwords-post-migration',
                    connectionId: selectedConnections[0]?.id,
                    onImportFinishOverride: onReImportAdAccountsFinishOverride,
                },
                buttons: [],
            })
        );
    };

    const onApplyEditAdAccountsButtonClick = async () => {
        setAdAccountsAccordionOpen(false);
        setAdAccountsAccordionHidden(true);
        setSelectedConnections([]);
        setIsLoading(true);
        fetchConnections();

        const accountLookupToDelete = new Set<AccountLookupToDeactivateType>();

        let requestsForMCCandCustomerAccount: AxiosRequestConfig[] = [];

        parentAccountsToDeactivate.forEach((item: ParentAccountsToDeactivateType) => {
            const accountId = item[0];
            const accountType = item[1];
            if (accountType === 'mcc') {
                requestsForMCCandCustomerAccount.push({
                    method: 'DELETE',
                    url: generateUrlDetail('integrations', 'api-adwords-connection-mcc-account', accountId.toString())!,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const mccAccountChildren = adwordsAccounts.filter(
                    (account: FormattedAdwordsAccounts) => account.accountId === accountId
                );

                mccAccountChildren.forEach((item: FormattedAdwordsAccounts) => {
                    item.children.forEach((subItem: FormattedAdwordsAccountsChildren) => {
                        requestsForMCCandCustomerAccount.push({
                            method: 'DELETE',
                            url: generateUrlDetail(
                                'integrations',
                                'api-adwords-connection-account',
                                subItem.accountId.toString()
                            )!,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        accountLookupToDelete.add([subItem.accountId, item.accountId]);
                    });
                });
            } else {
                requestsForMCCandCustomerAccount.push({
                    method: 'DELETE',
                    url: generateUrlDetail('integrations', 'api-adwords-connection-account', accountId.toString())!,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            }
        });
        mccChildAccountsToDeactivate.forEach((item: MCCAccountsToDeactivateType) => {
            const mccAccountId = item[0];
            const childAccountId = item[1];

            requestsForMCCandCustomerAccount.push({
                method: 'DELETE',
                url: generateUrlDetail('integrations', 'api-adwords-connection-account', childAccountId.toString())!,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            accountLookupToDelete.add([childAccountId, mccAccountId]);
        });

        let requestForAccountLookup: AxiosRequestConfig[] = [];

        if (accountLookupToDelete.size > 0) {
            const fetchAccountLookupUrl = generateUrl('integrations', 'lite-uri-adwords-connection-account-lookup');
            const adAccountsLookupResponse = await Axios.get(fetchAccountLookupUrl, { withCredentials: true });

            accountLookupToDelete.forEach((accountLookup: AccountLookupToDeactivateType) => {
                let accountURI = generatePath(
                    'integrations',
                    'lite-api-adwords-connection-account',
                    accountLookup[0].toString()
                );
                let mccAccountURI = generatePath(
                    'integrations',
                    'lite-adwords-connection-mcc-account',
                    accountLookup[1].toString()
                );
                adAccountsLookupResponse.data.objects.forEach((item: AccountLookupResponse) => {
                    if (item.account === accountURI && item.mcc_account === mccAccountURI) {
                        requestForAccountLookup.push({
                            method: 'DELETE',
                            url: generateUrlDetail(
                                'integrations',
                                'api-adwords-connection-account-lookup',
                                item.id.toString()
                            )!,
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                    }
                });
            });
        }
        try {
            await resolveBulkDeleteRequest(requestForAccountLookup);
            await resolveBulkDeleteRequest(requestsForMCCandCustomerAccount);
        } catch (error) {
            console.log(error);
            setPageError(true);
        } finally {
            onFinishEditAdAccountsButtonClick();
            disableCommonStateWhileEditing(true);

            // Table select
            setTableSelectDisabled(false);

            // Create, Edit, Remove buttons
            setCreateButtonDisabled(false);
            setRemoveButtonDisabled(true);

            setIsLoading(false);
            dispatch(
                addNotification({
                    copy: 'These changes have been successfully saved.',
                    type: 'success',
                })
            );
        }
    };

    const resolveBulkDeleteRequest = async (requests: AxiosRequestConfig[]) => {
        // removing possible duplicate because the user might select both parent and its
        // child account to deactivate which might add duplicate requests
        let nonRepeatingRequests: AxiosRequestConfig[] = [];
        for (const request of requests) {
            if (!nonRepeatingRequests.find(({ url }) => url === request.url)) {
                nonRepeatingRequests.push(request);
            }
        }
        await Promise.all(nonRepeatingRequests.map(request => Axios(request)));
    };

    const onFinishEditAdAccountsButtonClick = () => {
        setAdwordsAccounts([]);
        setAdwordsAccountsToUpdate([]);
        setAdAccountsAccordionOpen(false);
        setAdAccountsAccordionHidden(true);
        disableCommonStateWhileEditing(false);
        setEditSiteUrlsButtonDisabled(true);
        setApplyEditAdAccountButtonDisabled(true);
        setMccChildAccountsToDeactivate([]);
        setParentAccountsToDeactivate([]);
    };

    const onCancelEditAdAccountsButtonClick = () => {
        disableCommonStateWhileEditing(false);
        setApplyEditAdAccountButtonDisabled(true);
        setEditAdAccountsButtonDisabled(false);
        setAdwordsAccounts([]);
        setAdwordsAccountsToUpdate([]);
        setAdAccountsAccordionOpen(false);
        setAdAccountsAccordionHidden(true);
        setEditSiteUrlsButtonDisabled(true);
        setParentAccountsToDeactivate([]);
        setMccChildAccountsToDeactivate([]);
    };

    const RenderEditAdwordsAccordion = () => {
        if (editAdAccountsAccordionHidden) {
            return null;
        }
        let introText;

        if (isApiOwner) {
            introText =
                '<p>You have full control over the accounts which will be used by the Cubed Platform. If the required account does not appear in the list below, you can reimport it by clicking "REIMPORT".</p> <p>Access to individual accounts can also be removed below.</p>';
        } else {
            introText =
                '<p>You have full control over the accounts which will be used by the Cubed Platform. If the required account does not appear in the list below, you can login to Cubed with same account using which you created the connection to the adwords and you will be able to re-import the accounts.</p>';
        }

        introText += `<p><strong>Currently Editing:</strong> ${selectedConnections[0]?.name}</p>`;
        const accounts: EditTableRow[] = [];

        adwordsAccountsToUpdate.forEach((account: FormattedAdwordsAccounts) => {
            if (account) {
                accounts.push({
                    columns: [
                        {
                            copy: account.name,
                        },
                        {
                            type: 'toggle',
                            toggleChecked: account.active,
                            toggleOnClick: () => toggleAdAccountsActive(account.accountId, account.accountType),
                        },
                    ],
                });
                account.children.forEach((childAccount: FormattedAdwordsAccountsChildren) => {
                    if (childAccount) {
                        accounts.push({
                            rowProperty: {
                                child: true,
                            },
                            columns: [
                                {
                                    copy: childAccount.name,
                                },
                                {
                                    type: 'toggle',
                                    toggleChecked: childAccount.active,
                                    toggleOnClick: () =>
                                        toggleAdChildAccountsActive(account.accountId, childAccount.accountId),
                                },
                            ],
                        });
                    }
                });
            }
        });

        const accordionButtons = [];
        // Only allow re-import button to the owner of connection.
        if (isApiOwner) {
            accordionButtons.push({
                value: 'RE-IMPORT',
                onClick: onReimportAdAccountsButtonClick,
            });
        }
        accordionButtons.push({
            value: 'APPLY',
            onClick: onApplyEditAdAccountsButtonClick,
            disabled: applyEditAdAccountButtonDisabled,
        });
        accordionButtons.push({
            value: 'CANCEL',
            onClick: onCancelEditAdAccountsButtonClick,
            buttonTheme: ButtonThemes.Secondary,
        });

        const accordions = [
            {
                header: 'Manage Adwords Accounts',
                required: false,
                open: editAdAccountsAccordionOpen,
                type: 'simpleTable',
                intro: introText,
                config: {
                    isLoading: isLoadingConnectionAccounts,
                    tableHeader: {
                        columns: [
                            {
                                title: 'Account Name',
                            },
                            {
                                title: 'Enabled',
                            },
                        ],
                    },
                    tableRows: accounts,
                    optionalButtonConfig: {
                        buttons: accordionButtons,
                    },
                    errorMessageOverride: 'No accounts have been imported for this connection.',
                    isScrollable: true,
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    };
    // EDIT AD ACCOUNTS HANDLERS END:

    // EDIT GSC SITES HANDLERS START:
    const getGSCSites = async () => {
        setIsLoadingConnectionSites(true);
        const gscSitesRequestUrl = generateUrl('integrations', 'api-searchconsole-siteurl', [
            { key: 'oauth_connection__id', value: String(selectedConnections[0]?.id) },
            { key: 'deleted', value: 0 },
            { key: 'active', value: 1 },
        ]);
        const gscSitesResponse = await Axios.get(gscSitesRequestUrl, { withCredentials: true });
        const gscSitesResponseData = gscSitesResponse.data.objects;
        setGSCSites([...gscSitesResponseData]);
        setGSCSitesToUpdate(JSON.parse(JSON.stringify(gscSitesResponseData)));
        setIsLoadingConnectionSites(false);
    };

    const onEditSiteUrlsClick = () => {
        setIsApiOwner(selectedConnections[0]?.userId === userId.toString());
        setSiteUrlsAccordionHidden(false);
        setEditSiteUrlsAccordionOpen(true);

        disableCommonStateWhileEditing(true);
        setEditAdAccountsButtonDisabled(true);
        setEditSiteUrlsButtonDisabled(true);

        getGSCSites();
    };

    const onFinishEditSitesUrlClick = () => {
        const updatedGscSites = gscSitesToUpdate.filter(
            (item: GSCSitesResponseType) =>
                item.active !== gscSites.filter((item_: GSCSitesResponseType) => item_.id === item.id)[0].active
        );

        if (updatedGscSites.length > 0) {
            let updateRequest: AxiosPromise[] = [];
            updatedGscSites.forEach((site: GSCSitesResponseType) => {
                updateRequest.push(
                    Axios({
                        method: 'PATCH',
                        url: generateUrlDetail('integrations', 'api-searchconsole-siteurl', site.id.toString())!,
                        data: { deleted: site.deleted, active: site.active },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
            Axios.all(updateRequest)
                .then(() => {
                    setSiteUrlsAccordionHidden(true);
                    setEditSiteUrlsAccordionOpen(false);

                    // Enable buttons back
                    disableCommonStateWhileEditing(false);
                    setEditAdAccountsButtonDisabled(true);
                    setEditSiteUrlsButtonDisabled(false);

                    setGSCSites([]);
                    setGSCSitesToUpdate([]);
                    dispatch(
                        addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: 'success',
                        })
                    );
                })
                .catch(e => {
                    console.log('error:', e);
                    setPageError(true);
                });
        } else {
            setSiteUrlsAccordionHidden(true);
            setEditSiteUrlsAccordionOpen(false);

            // Enable buttons back
            disableCommonStateWhileEditing(false);
            setEditAdAccountsButtonDisabled(true);
            setEditSiteUrlsButtonDisabled(false);

            setGSCSites([]);
            setGSCSitesToUpdate([]);
        }
    };
    const onReImportFinishOverride = () => {
        getGSCSites();
    };
    const onReimportSitesClick = () => {
        dispatch(
            setPopup({
                title: 'Import Site URLs',
                iconType: 'informative',
                contentType: 'importIntegrationAccounts',
                config: {
                    technology: 'search-console',
                    connectionId: selectedConnections[0]?.id,
                    onImportFinishOverride: onReImportFinishOverride,
                },
                buttons: [],
            })
        );
    };

    const RenderEditGSCSitesAccordion = () => {
        if (editSiteUrlsAccordionHidden) {
            return null;
        }
        let introText;
        if (isApiOwner) {
            introText =
                '<p>You have full control over the Site URLs which will be used by the Cubed Platform. If the required Site URL does not appear in the list below, you can reimport it by clicking "REIMPORT".</p> <p>Access to individual Site URLs can also be removed below.</p>';
        } else {
            introText =
                '<p>You have full control over the Site URLs which will be used by the Cubed Platform. If the required Site URL does not appear in the list below, you can login to Cubed with same account using which you created the connection to the google search console and re-import the sites.</p>';
        }

        introText += `<p><strong>Currently Editing:</strong> ${selectedConnections[0]?.name}</p>`;

        const siteRows: EditTableRow[] = [];

        const toggleSitesActive = (siteId: number) => {
            gscSitesToUpdate.forEach((item: GSCSitesResponseType) => {
                if (item.id === siteId) {
                    item.active = !item.active;
                    item.deleted = !item.deleted;
                }
            });
        };

        if (!isLoadingConnectionSites && selectedConnections) {
            gscSitesToUpdate.forEach((site: GSCSitesResponseType) => {
                siteRows.push({
                    columns: [
                        {
                            copy: site.site_url,
                        },
                        {
                            type: 'toggle',
                            toggleChecked: site.active,
                            toggleOnClick: () => {
                                toggleSitesActive(site.id);
                            },
                        },
                    ],
                });
            });
        }

        const accordionButtons = [];
        if (isApiOwner) {
            accordionButtons.push({
                value: 'RE-IMPORT',
                onClick: onReimportSitesClick,
            });
        }
        accordionButtons.push({
            value: 'OK',
            onClick: onFinishEditSitesUrlClick,
        });

        const accordions = [
            {
                header: 'Manage Site URLs',
                required: false,
                open: editSiteUrlsAccordionOpen,
                type: 'simpleTable',
                intro: introText,
                config: {
                    enableSearch: true,
                    enableFilters: true,
                    searchableColumns: [0],
                    isLoading: isLoadingConnectionSites,
                    tableHeader: {
                        columns: [
                            {
                                title: 'Site URL',
                            },
                            {
                                title: 'Enabled',
                            },
                        ],
                    },
                    tableRows: siteRows,
                    optionalButtonConfig: {
                        buttons: accordionButtons,
                    },
                    errorMessageOverride:
                        'No accounts have been imported for this connection. This can be done by clicking on "RE-IMPORT" below.',
                    isScrollable: true,
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };
    // EDIT GSC SITES HANDLERS END

    // EDIT YOUTUBE CONNECTION HANDLERS START:
    // const toggleYoutubeActive = () => {
    //     const stateChanged = !youtubeConnectionStatusToUpdate?.active !== youtubeConnectionStatus?.active;
    //     setYoutubeConnectionStatusToUpdate({
    //         id: youtubeConnectionStatusToUpdate?.id,
    //         active: !youtubeConnectionStatusToUpdate?.active,
    //     });
    //     setApplyYoutubeConnectionButtonDisabled(!stateChanged);
    // };
    // const onApplyYoutubeConnectionStatusChange = async () => {
    //     setEditYoutubeConnectionAccordionHidden(true);
    //     setEditYoutubeConnectionAccordionOpen(false);
    //     setSelectedConnections(null);
    //     setIsLoading(true);

    //     let requestConfig;
    //     if (youtubeConnectionStatusToUpdate?.id) {
    //         requestConfig = {
    //             method: 'PATCH',
    //             url: generateUrlDetail(
    //                 'integrations',
    //                 'api-youtube-connection-detail',
    //                 youtubeConnectionStatusToUpdate.id.toString()
    //             )!,
    //             data: { youtube_active: youtubeConnectionStatusToUpdate?.active },
    //             withCredentials: true,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         };
    //     } else {
    //         requestConfig = {
    //             method: 'POST',
    //             url: generateUrl('integrations', 'api-youtube-connection-detail'),
    //             data: {
    //                 youtube_active: youtubeConnectionStatusToUpdate?.active,
    //                 connection: `/api/integrations/api-google-connection/${selectedConnections?.id}/`,
    //             },
    //             withCredentials: true,
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         };
    //     }

    //     Axios(requestConfig)
    //         .then(() => {
    //             setEditYoutubeConnectionAccordionHidden(true);
    //             setEditYoutubeConnectionAccordionOpen(false);
    //             setSelectedConnections(null);
    //             fetchConnections();
    //             setRemoveButtonDisabled(true);
    //             setTableSelectDisabled(false);
    //             setCreateButtonDisabled(false);
    //             setEditYoutubeConnectionButtonDisabled(true);
    //             setEditAdAccountsButtonDisabled(true);
    //             setEditSiteUrlsButtonDisabled(true);
    //             setApplyYoutubeConnectionButtonDisabled(true);
    //             dispatch(
    //                 addNotification({
    //                     copy: 'These changes have been successfully saved.',
    //                     type: 'success',
    //                 })
    //             );
    //         })
    //         .catch(e => {
    //             setPageError(e);
    //         });
    // };
    // const onEditYoutubeConnectionClick = () => {
    //     disableCommonStateWhileEditing(true);
    //     setEditYoutubeConnectionButtonDisabled(true);
    //     setIsLoadingYoutubeStatus(true);
    //     setEditAdAccountsButtonDisabled(true);
    //     setEditSiteUrlsButtonDisabled(true);
    //     setEditYoutubeConnectionAccordionOpen(true);
    //     setEditYoutubeConnectionAccordionHidden(false);
    //     setIsApiOwner(selectedConnections?.userId === userId.toString());
    //     fetchYoutubeConnectionStatus().then(() => {
    //         setIsLoadingYoutubeStatus(false);
    //     });
    // };

    // const onCancelYoutubeEditing = () => {
    //     setEditYoutubeConnectionAccordionHidden(true);
    //     setEditYoutubeConnectionAccordionOpen(false);
    //     disableCommonStateWhileEditing(false);
    //     setEditYoutubeConnectionButtonDisabled(false);
    //     setEditAdAccountsButtonDisabled(selectedConnections?.adwordDeactivated || selectedConnections?.parentOauth!);
    //     setEditSiteUrlsButtonDisabled(
    //         selectedConnections?.searchConsoleDeactivated! || !selectedConnections?.SEARCH_CONSOLE
    //     );
    //     setApplyYoutubeConnectionButtonDisabled(true);
    // };

    // const fetchYoutubeConnectionStatus = async () => {
    //     setIsLoadingYoutubeStatus(true);
    //     const url = generateUrl('integrations', 'api-youtube-connection-detail', [
    //         { key: 'connection__id', value: String(selectedConnections?.id) },
    //     ]);
    //     const response = await Axios.get(url, { withCredentials: true });

    //     const connectionStatus =
    //         response.data.objects.length > 0
    //             ? {
    //                   active: response.data.objects[0].youtube_active,
    //                   id: response.data.objects[0].id,
    //               }
    //             : {
    //                   active: true,
    //                   id: undefined,
    //               };

    //     setYoutubeConnectionStatus({ ...connectionStatus });
    //     setYoutubeConnectionStatusToUpdate({ ...connectionStatus });

    //     setIsLoadingYoutubeStatus(false);
    // };

    // const RenderEditYoutubeConnection = () => {
    //     if (editYoutubeConnectionAccordionHidden) {
    //         return null;
    //     }
    //     let introText;
    //     if (isApiOwner) {
    //         introText = `<p>If you enable YouTube connection below, Cubed will be able to import  YouTube data for this connection</p><p><strong>Currently Editing:</strong> ${selectedConnections?.name}</p>`;
    //     } else {
    //         introText = `<p>If you login with the same account with which you created the connection, you can enable/disable YouTube connection below."</p><p><strong>Account Selected:</strong> ${selectedConnections?.name}</p>`;
    //     }

    //     const youtubeRow: EditTableRow[] = [];

    //     youtubeRow.push({
    //         columns: [
    //             {
    //                 copy: 'Youtube',
    //             },
    //             {
    //                 type: 'toggle',
    //                 toggleChecked: youtubeConnectionStatusToUpdate?.active!,
    //                 toggleDisabled: !isApiOwner,
    //                 toggleOnClick: () => {
    //                     toggleYoutubeActive();
    //                 },
    //             },
    //         ],
    //     });

    //     const accordionButtons = [];
    //     if (isApiOwner) {
    //         accordionButtons.push({
    //             value: 'APPLY',
    //             onClick: onApplyYoutubeConnectionStatusChange,
    //             disabled: applyYoutubeConnectionButtonDisabled,
    //         });
    //     }
    //     accordionButtons.push({
    //         value: 'CANCEL',
    //         onClick: onCancelYoutubeEditing,
    //     });

    //     const accordions = [
    //         {
    //             header: 'Manage Youtube Connection',
    //             required: false,
    //             open: editYoutubeConnectionAccordionOpen,
    //             type: 'simpleTable',
    //             intro: introText,
    //             config: {
    //                 isLoading: isLoadingYoutubeStatus,
    //                 enableSearch: true,
    //                 enableFilters: true,
    //                 searchableColumns: [0],
    //                 tableHeader: {
    //                     columns: [
    //                         {
    //                             title: 'Google Service',
    //                         },
    //                         {
    //                             title: 'Enabled',
    //                         },
    //                     ],
    //                 },
    //                 tableRows: youtubeRow,
    //                 optionalButtonConfig: {
    //                     buttons: accordionButtons,
    //                 },
    //                 isScrollable: true,
    //             },
    //         },
    //     ];

    //     return <WidgetAccordion accordions={accordions} />;
    // };

    // MAIN MODAL RELATED METHODS:
    const isConnectionEqual = (
        connection1: Connection | FormattedAdwordsConnections,
        connection2: Connection | FormattedAdwordsConnections
    ) => {
        if (typeof connection1 !== typeof connection2) {
            return false;
        }
        return (
            connection1.id === connection2.id &&
            connection1.ADWORDS === connection2.ADWORDS &&
            connection1.SEARCH_CONSOLE === connection2.SEARCH_CONSOLE &&
            connection1.YOUTUBE === connection2.YOUTUBE
        );
    };

    const onConnectionSelect = (connection: Connection | FormattedAdwordsConnections) => {
        const selectedConnection = selectedConnections?.[0];

        if (selectedConnections?.length > 1) {
            const existingConnection = selectedConnections?.find(
                (selectedConnection: Connection | FormattedAdwordsConnections) => selectedConnection === connection
            );

            if (existingConnection) {
                if (selectedConnections?.length === 1) {
                    setRemoveButtonDisabled(false);
                }
                setSelectedConnections(
                    selectedConnections?.filter(
                        (selectedConnection: Connection | FormattedAdwordsConnections) =>
                            !isConnectionEqual(selectedConnection, connection)
                    )
                );
            } else {
                setSelectedConnections([...selectedConnections, connection]);
                setEditSiteUrlsButtonDisabled(true);
                setEditAdAccountsButtonDisabled(true);
                // setEditYoutubeConnectionButtonDisabled(true);
            }
        } else if (selectedConnections?.length === 1) {
            if (isConnectionEqual(selectedConnection, connection)) {
                setEditSiteUrlsButtonDisabled(true);
                setEditAdAccountsButtonDisabled(true);
                // setEditYoutubeConnectionButtonDisabled(true);
                setSelectedConnections([]);
                return;
            }
            setSelectedConnections([...selectedConnections, connection]);
            setEditSiteUrlsButtonDisabled(true);
            setEditAdAccountsButtonDisabled(true);
        } else if (selectedConnections?.length === 0) {
            setEditAdAccountsButtonDisabled(!connection.ADWORDS || connection.adwordDeactivated);
            // disable edit ad account if connection is not adwords
            // and if connection is parent Oauth connection
            if (connection.ADWORDS && connection.parentOauth) {
                setEditAdAccountsButtonDisabled(true);
            }
            setEditSiteUrlsButtonDisabled(!connection.SEARCH_CONSOLE || connection.searchConsoleDeactivated);

            // setEditYoutubeConnectionButtonDisabled(!connection.YOUTUBE || connection.youtubeDeactivated);

            setSelectedConnections([connection]);
            setRemoveButtonDisabled(false);
        }
    };

    const RenderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'close',
                onClick: onCloseClick,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };
    const renderWarningMessage = () => {
        if (serviceDeactivated)
            return (
                <StyledErrorMessageContainer>
                    <Message
                        title="Warning"
                        copy='The connection that has "disabled" service in the table below encountered with an error. So it is advised to delete that connection and re-create it. Please contact us at support@cubed.email if you need any assistance.'
                        type="warning"
                        display="horizontal"
                        shortTitle="test"
                        size="small"
                    />
                </StyledErrorMessageContainer>
            );
    };
    const renderTable = () => {
        const errorMessageOverride = 'There are no connections assigned to this account.';
        const header = {
            columns: [
                {
                    title: 'Connection',
                },
                {
                    title: 'User',
                },
                {
                    title: 'Search Console',
                },
                {
                    title: 'Adwords',
                },
                // {
                //     title: 'Youtube',
                // },
            ],
        };

        const rows: SimpleTableRow[] = [];

        connections.forEach(connection => {
            const rowProperty = {
                selected:
                    selectedConnections.filter(selectedConnection => isConnectionEqual(connection, selectedConnection))
                        .length > 0,
            };
            let adwordsConnectionDescription = 'not connected';
            if (connection.ADWORDS) {
                if (connection.relatedAdwordsConnection.length === 0) {
                    adwordsConnectionDescription = 'no accounts';
                } else {
                    adwordsConnectionDescription = 'connected';
                }
            }
            if (connection.adwordDeactivated) {
                adwordsConnectionDescription = 'disabled';
            }
            rows.push({
                onClick: () => onConnectionSelect(connection),
                key: `api-google-connection__${connection.id}`,
                dataValue: connection.id,
                rowProperty,
                columns: [
                    {
                        copy: connection.name,
                    },
                    {
                        copy: connection.user,
                    },
                    {
                        copy: connection.searchConsoleDeactivated
                            ? 'disabled'
                            : connection.SEARCH_CONSOLE
                            ? 'connected'
                            : 'not connected',
                    },
                    {
                        copy: adwordsConnectionDescription,
                    },
                    // {
                    //     copy: connection.YOUTUBE ? 'connected' : 'not connected',
                    // },
                ],
            });

            connection.relatedAdwordsConnection.forEach((adwordConnection: FormattedAdwordsConnections) => {
                const rowProperty = {
                    selected:
                        selectedConnections.filter(selectedConnection =>
                            isConnectionEqual(selectedConnection, adwordConnection)
                        ).length > 0,
                    //  selectedConnections === adwordConnection,
                    child: true,
                };

                rows.push({
                    onClick: () => onConnectionSelect(adwordConnection),
                    key: `api-google-adword-connection__${adwordConnection.id}`,
                    dataValue: adwordConnection.id,
                    rowProperty,
                    columns: [
                        {
                            copy: adwordConnection.name,
                        },
                        {
                            copy: connection.user,
                        },
                        {
                            copy: 'not applicable',
                        },
                        {
                            copy: 'connected',
                        },
                        // {
                        //     copy: 'not applicable',
                        // },
                    ],
                });
            });
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
                selectDisabled={tableSelectDisabled}
            />
        );
    };

    if (isLoading) {
        return (
            <div className="modal__side-panel__manage-domains">
                <RenderModalNavigation />
                <h2>Google</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div className="modal__side-panels">
                <RenderModalNavigation />
                <h2>Google</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            <RenderModalNavigation />
            <h2>Google</h2>
            <p>
                You can manage your Google API Integration with the Cubed Platform, which allows you to enhance Cubed
                data and reporting by integrating data from Ads API and Google Search Console. To set up the connection,
                you can click the create button and choose which of the three Google services you want to grant access
                to Cubed.
            </p>
            {/* <p>
                You can manage your Google API Integration with the Cubed Platform, which allows you to enhance Cubed
                data and reporting by integrating data from Ads API, Google Search Console, and YouTube API. To set up
                the connection, you can click the create button and choose which of the three Google services you want
                to grant access to Cubed.
            </p> */}
            <p>
                All the connections are disabled by default. We will pull MCC accounts but their child accounts and the
                data will not be pulled unless you import them after creating the connection by going to the Edit Ad
                accounts section. Same goes for site urls where you need to import site urls by going to Edit Site Urls
                Section.
            </p>
            {/* <p>
                All the connections are disabled by default. We will pull MCC accounts but their child accounts and the
                data will not be pulled unless you import them after creating the connection by going to the Edit Ad
                accounts section. Same goes for site urls where you need to import site urls by going to Edit Site Urls
                Section. For youtube you have to enable the connection after creating it by going to Edit Youtube
                Connection section.
            </p> */}
            <p>
                You can re-import site urls and ad account as well as remove unwanted Ad accounts or site URLs by
                selecting the connection, clicking on edit and disabling them.
            </p>
            <p>
                If new Ad accounts are added to the Google Account then it is recommended to create the connection again
                to see new accounts in the table below.
            </p>
            <div>
                {renderWarningMessage()}
                {renderTable()}
                <InputButtonWrapper>
                    <StyledButtonGroup>
                        <InputButton value="CREATE" disabled={createButtonDisabled} onClick={onCreateClick} />
                        <InputButton
                            value="EDIT SITE URLS"
                            disabled={editSiteUrlsButtonDisabled}
                            onClick={onEditSiteUrlsClick}
                        />
                        <InputButton
                            value="EDIT AD ACCOUNTS"
                            disabled={editAdAccountsButtonDisabled}
                            onClick={onEditAdAccountsClick}
                        />
                        {/* <InputButton
                            value="EDIT YOUTUBE CONNECTION"
                            disabled={editYoutubeConnectionButtonDisabled}
                            onClick={onEditYoutubeConnectionClick}
                        /> */}
                        <InputButton
                            buttonTheme={ButtonThemes.Red}
                            value="REMOVE"
                            disabled={removeButtonDisabled}
                            onClick={onDeleteClick}
                        />
                    </StyledButtonGroup>
                </InputButtonWrapper>
                <RenderEditGSCSitesAccordion />
                <RenderEditAdwordsAccordion />
                {/* <RenderEditYoutubeConnection /> */}
            </div>
        </div>
    );
};

export default LayoutModalGoogleAPI;
