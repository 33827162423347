import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { useAppSelector } from '../../../../redux/hooks';

// Helpers
import { createDateRange } from '../../../helpers/create-date-range';
import { getRawValueAsString, getValue } from '../../../helpers/resource-data';

// Types
import { CubedField } from '../../../../types';
import { ResourceData } from '../../../types';
import { ResourceDataObject } from '../../../../react-query/types';
import { unknownErrorData } from '../../../helpers/errors';
import { UseResourceDateRange } from '../../../types';

export type UseResourceLineGraphInterpolatedArgs = {
    resourceData: ResourceData;
    dateDimension: CubedField;
    seriesField: CubedField;
    yAxis: CubedField;
    dateRange?: UseResourceDateRange;
};

const useResourceLineGraphInterpolated = ({
    resourceData,
    dateDimension,
    seriesField,
    yAxis,
    dateRange,
}: UseResourceLineGraphInterpolatedArgs): ResourceData => {
    const dates = useAppSelector(state => state.date);
    const { startDate, endDate } = dateRange ? dateRange : dates;

    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        // get the date formats and build an array of dates
        const dateRawValueFormat = moment(
            getRawValueAsString(resourceData.objects[0], dateDimension.rawName)
        ).creationData().format as string;

        const dateValueFormat = moment(getValue(resourceData.objects[0], dateDimension.rawName)).creationData()
            .format as string;

        const dateRange = createDateRange(startDate, endDate);

        if (!dateRawValueFormat || !dateValueFormat) {
            throw new Error('Unexpected undefined when retrieving Moment date format');
        }

        // Find the unique dimensions
        let uniqueSeriesNames: string[] = [];

        if (seriesField.isDimension) {
            uniqueSeriesNames = [
                ...new Set(
                    resourceData.objects.map(data => {
                        return getValue(data, seriesField.rawName);
                    })
                ),
            ];
        } else {
            uniqueSeriesNames.push(seriesField.rawName);
        }

        // Interpolate the objects for each unique dimension
        const interpolatedObjects: ResourceDataObject[] = [];

        uniqueSeriesNames.forEach(value => {
            dateRange.forEach(date => {
                let matchFound = false;

                resourceData.objects.forEach(object => {
                    if (seriesField.isDimension) {
                        if (
                            date === getRawValueAsString(object, dateDimension.rawName) &&
                            value === getValue(object, seriesField.rawName)
                        ) {
                            matchFound = true;
                            interpolatedObjects.push(object);
                        }
                    } else {
                        if (date === moment(getRawValueAsString(object, dateDimension.rawName)).format('YYYY-MM-DD')) {
                            matchFound = true;
                            interpolatedObjects.push(object);
                        }
                    }
                });
                if (!matchFound) {
                    interpolatedObjects.push({
                        __id: uuidv4(),
                        values: {
                            [dateDimension.rawName]: {
                                rawValue: moment(date).format(dateRawValueFormat as string),
                                value: moment(date).format(dateValueFormat as string),
                            },
                            [seriesField.rawName]: {
                                rawValue: value,
                                value: value,
                            },
                            [yAxis.rawName]: {
                                rawValue: 0,
                                value: '0',
                            },
                        },
                    });
                }
            });
        });

        return {
            ...resourceData,
            objects: interpolatedObjects,
        };
    }

    return unknownErrorData();
};

export default useResourceLineGraphInterpolated;
