import React from 'react';
import styled, { useTheme } from 'styled-components';
import IconChevronRightThin from '../../../components/icons/chevron-right-thin';
import { WidgetDialogLocation } from '../../types';
import WidgetBaseLoader from './widget-base-loader';

const StyledContainer = styled.div<{ location?: WidgetDialogLocation; hasText: boolean }>`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: ${props => (props.location === WidgetDialogLocation.Dropdown ? '10px 20px' : '12px')};
    gap: 12px;
    color: ${props => props.theme.sectionDashboard.widget.base.link.textColor};
    font-weight: ${props =>
        props.location === WidgetDialogLocation.Dropdown
            ? props.theme.sectionDashboard.widget.base.link.fontWeightDropdown
            : props.theme.sectionDashboard.widget.base.link.fontWeight};
    ${props => props.hasText && 'min-width: 0;'}
`;

const StyledLinkContainer = styled(StyledContainer)<{ location?: WidgetDialogLocation }>`
    outline: none;
    cursor: pointer;
    :hover,
    :focus {
        color: ${props => props.theme.sectionDashboard.widget.base.link.focus.color};
        background: ${props => props.theme.sectionDashboard.widget.base.link.focus.backgroundColor};
    }
`;

const StyledText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const StyledTextPlaceholder = styled.div<{ location?: WidgetDialogLocation }>`
    width: ${props => (props.location === WidgetDialogLocation.Footer ? '65px' : '100px')};
    max-width: 100px;
    height: 19px;
    border-radius: 6px;
    background: ${props => props.theme.sectionDashboard.widget.base.link.textPlaceholder};
`;

const StyledIconPlaceholder = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: ${props => props.theme.sectionDashboard.widget.base.link.textPlaceholder};
`;

const StyledIconContainer = styled.div<{ hasText: boolean }>`
    & svg {
        width: 100%;
        height: 100%;
    }

    height: ${props => (props.hasText ? '15px' : '20px')};
    width: auto;
`;

const StyledSubMenuIconContainer = styled.div`
    width: 6px;
    position: relative;
    top: 1px;
`;

export type WidgetBaseLinkProps = {
    text?: string;
    icon?: React.ReactElement;
    location?: WidgetDialogLocation;
    loading?: boolean;
    iconLoading?: boolean;
    onClick?: () => void;
};

const WidgetBaseLink = ({ text, icon, location, loading, iconLoading, onClick }: WidgetBaseLinkProps) => {
    const theme = useTheme();

    const hasText = text !== undefined && text !== '';
    const hasLink = onClick !== undefined;

    if (loading) {
        return (
            <StyledContainer hasText={hasText}>
                {hasText ? <StyledTextPlaceholder location={location} /> : <StyledIconPlaceholder />}
            </StyledContainer>
        );
    }

    const styledText = text && <StyledText title={text}>{text}</StyledText>;
    const styledIcon = icon && <StyledIconContainer hasText={hasText}>{icon}</StyledIconContainer>;

    if (location === WidgetDialogLocation.Footer || location === WidgetDialogLocation.Dropdown) {
        return (
            <StyledLinkContainer location={location} tabIndex={0} hasText={hasText} onClick={onClick}>
                {iconLoading ? (
                    <WidgetBaseLoader
                        width={20}
                        height={20}
                        margin={-3}
                        color={theme.name === 'dark' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 1)'}
                    />
                ) : (
                    styledIcon
                )}
                {styledText}
            </StyledLinkContainer>
        );
    }

    if (!hasLink) {
        return (
            <StyledContainer hasText={hasText}>
                {styledIcon}
                {styledText}
            </StyledContainer>
        );
    }

    return (
        <StyledLinkContainer tabIndex={0} hasText={hasText} onClick={onClick}>
            {styledIcon}
            {styledText}
            {hasText && (
                <StyledSubMenuIconContainer>
                    <IconChevronRightThin />
                </StyledSubMenuIconContainer>
            )}
        </StyledLinkContainer>
    );
};

export default WidgetBaseLink;
