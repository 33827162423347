// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Request from './../../helpers/request';
import { generateUrl, generatePath } from '../../helpers/request-builder';

// Core Dependencies
import Axios from 'axios';

// Redux Actions
import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { updateUser } from '../../redux/actions/user';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';

//
import WidgetAccordion from '../../widgets/accordion';
import { isDate, isString } from '../../helpers/validator';
import { ButtonThemes } from '../../enums/button-themes';

class LayoutModalSetupAboveTheLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            closeButtonState: 'close',
            saveChangesButtonDisabled: true,
            atlDetailsAccordionOpen: true,
            nationWide: false,
            country: [],
            city: [],
            storeRegionAdded: [].sort((a, b) => a.name.localeCompare(b.name)),
            regionSelectedOnList: [],
            deleteRegionDisabled: true,
            atlStartDate: '',
            atlEndDate: '',
            atlName: '',
            atlCountry: '',
            atlCity: '',
            atlReferer: '',
            atlRefererOptions: [],
            deleteRegions: [],
            atlStartDateErrorMessage: '',
            atlEndDateErrorMessage: '',
            atlNameErrorMessage: '',
            atlCountryErrorMessage: '',
            atlCityErrorMessage: '',
            atlRefererErrorMessage: '',
        };

        this.formValidator = this.formValidator.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onATLNameChange = this.onATLNameChange.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderAccordion = this.renderAccordion.bind(this);
        this.renderRegionForm = this.renderRegionForm.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onRegionDetailsAddClick = this.onRegionDetailsAddClick.bind(this);
        this.requestCityOptions = this.requestCityOptions.bind(this);
        this.getCountiesList = this.getCountiesList.bind(this);
        this.onRegionListSelected = this.onRegionListSelected.bind(this);
        this.onDeleteRegionClick = this.onDeleteRegionClick.bind(this);
        this.handleNavigateManageEventModal = this.handleNavigateManageEventModal.bind(this);
    }

    componentDidMount() {
        const requestConfig = [
            {
                resourceGroup: 'config',
                resourceName: 'referer',
                params: [
                    {
                        key: 'active',
                        value: 1,
                    },
                ],
            },
        ];
        this.props.getRequest(requestConfig);
    }

    componentDidUpdate() {
        if (this.state.isLoading && !this.props.request.isLoading) {
            if (!this.props.request.hasError) {
                // Manipulate the referer to work with a select field
                const channelNameOptions = this.props.request.data[0].objects.map(referer => {
                    return {
                        id: referer.id,
                        keyValue: referer.id,
                        name: referer.name,
                    };
                });
                this.setState({
                    isLoading: false,
                    atlRefererOptions: channelNameOptions,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }
            this.props.delRequest();
        } else if (this.state.isLoading) {
            setTimeout(() => {
                this.forceUpdate();
            }, 600);
        }
    }

    formValidator() {
        let hasFormError = false;
        let errorMessageObject = {
            atlNameErrorMessage: '',
            atlStartDateErrorMessage: '',
            atlEndDateErrorMessage: '',
        };

        if (!isString(this.state.atlName) || this.state.atlName.length === 0) {
            hasFormError = true;
            errorMessageObject.atlNameErrorMessage = 'Please enter a valid name.';
        }

        if (!isDate(this.state.atlStartDate) || this.state.atlStartDate.length === 0) {
            hasFormError = true;
            errorMessageObject.atlStartDateErrorMessage = 'Please enter a valid date.';
        }

        if (!isDate(this.state.atlEndDate) || this.state.atlEndDate.length === 0) {
            hasFormError = true;
            errorMessageObject.atlEndDateErrorMessage = 'Please enter a valid date.';
        }

        if (this.state.atlStartDate > this.state.atlEndDate) {
            hasFormError = true;
            errorMessageObject.atlEndDateErrorMessage = 'Please enter a end date after the start date.';
        }

        if (hasFormError) {
            this.setState({
                atlNameErrorMessage: errorMessageObject.atlNameErrorMessage,
                atlStartDateErrorMessage: errorMessageObject.atlStartDateErrorMessage,
                atlEndDateErrorMessage: errorMessageObject.atlEndDateErrorMessage,
            });
        }
        return !hasFormError;
    }

    dropDownValidator() {
        let hasFormError = false;
        let errorMessageObject = {
            atlCountryErrorMessage: '',
            atlCityErrorMessage: '',
            atlRefererErrorMessage: '',
        };

        if (!this.state.atlCountry) {
            hasFormError = true;
            errorMessageObject.atlCountryErrorMessage = 'Please select a country.';
        }

        if (!this.state.atlCity) {
            hasFormError = true;
            errorMessageObject.atlCityErrorMessage = 'Please select a city.';
        }
        if (!this.state.atlReferer) {
            hasFormError = true;
            errorMessageObject.atlRefererErrorMessage = 'Please select a channel.';
        }

        if (hasFormError) {
            this.setState({
                atlCountryErrorMessage: errorMessageObject.atlCountryErrorMessage,
                atlCityErrorMessage: errorMessageObject.atlCityErrorMessage,
                atlRefererErrorMessage: errorMessageObject.atlRefererErrorMessage,
            });
        }
        return !hasFormError;
    }

    sendRequest() {
        return new Promise(async (resolve, reject) => {
            const atl = {
                method: 'POST',
                url: generateUrl('config', 'atl'),
                data: {
                    name: this.state.atlName,
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            try {
                const atlTable = await Axios(atl);
                Axios({
                    method: 'POST',
                    url: generateUrl('config', 'atl-config'),
                    data: {
                        base_activity_start_date: this.state.atlStartDate,
                        base_activity_end_date: this.state.atlEndDate,
                        atl: generatePath('config', `atl`, atlTable.data.id),
                        active: 1,
                        referer: generatePath('config', `referer`, this.state.atlReferer),
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!this.state.nationWide) {
                    Axios({
                        method: 'POST',
                        url: generateUrl('config', 'atl-region'),
                        data: {
                            atl_config: generatePath('config', `atl`, atlTable.data.id),
                            country_name: '',
                            city_name: '',
                            nationwide: !this.state.nationWide,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                } else {
                    const requests = [];

                    this.state.storeRegionAdded.forEach(region => {
                        requests.push(
                            Axios({
                                method: 'POST',
                                url: generateUrl('config', 'atl-region'),
                                data: {
                                    atl_config: generatePath('config', `atl`, atlTable.data.id),
                                    country_name: region.country,
                                    city_name: region.city,
                                    nationwide: !this.state.nationWide,
                                },
                                withCredentials: true,
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                        );
                    });
                    Axios.all(requests);
                }
                resolve('ATL Created');
                this.props.addNotification({
                    copy: 'The ATL it was successfully Created.',
                    type: NotificationMessageType.Success,
                });
                this.onResetClick();
                this.handleNavigateManageEventModal();
            } catch (error) {
                console.log(error);
                reject('Error making a connection to API.');
                this.props.addNotification({
                    copy: 'There was an issue sending the details, please try again later.',
                    type: NotificationMessageType.Error,
                });
                this.handleNavigateManageEventModal();
            }
        });
    }

    onSaveChangesClick() {
        if (this.formValidator()) {
            if (this.state.nationWide && this.state.storeRegionAdded.length === 0) {
                this.props.addNotification({
                    copy: 'The region list is empty, please add a region to submit the form.',
                    type: NotificationMessageType.Error,
                });
            } else {
                this.sendRequest();
            }
        } else {
            this.setState({
                saveChangesButtonDisabled: false,
            });
        }
    }

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.handleNavigateManageEventModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    onCheckboxChange() {
        if (!this.state.nationWide) {
            this.setState({
                city: [],
                storeRegionAdded: [],
                atlCountry: '',
                atlCity: '',
            });
        }
        if (this.state.country.length === 0) {
            this.getCountiesList();
        }

        this.setState({
            nationWide: !this.state.nationWide,
        });
    }

    getCountiesList() {
        const fetchPromise = new Request();

        fetchPromise.get('config', 'region-list', [{ key: 'active', value: 1 }]).then(response => {
            const formattedCountry = response.data.objects
                .filter(
                    (item, index, self) =>
                        item.country_name !== '(Not Set)' &&
                        self.findIndex(i => i.country_name === item.country_name) === index
                )
                .map(object => {
                    return {
                        keyValue: 'country-option_' + object.country_name,
                        value: object.country_name,
                        name: object.country_name,
                    };
                });
            this.setState({
                country: formattedCountry.sort((a, b) => a.name.localeCompare(b.name)),
            });
        });
    }

    requestCityOptions(country) {
        const fetchPromise = new Request();

        fetchPromise
            .get('config', 'region-list', [
                { key: 'active', value: 1 },
                { key: 'country_name', value: country },
            ])
            .then(response => {
                const formattedCity = response.data.objects
                    .filter(
                        (item, index, self) =>
                            item.city_name !== '(Not Set)' &&
                            self.findIndex(i => i.city_name === item.city_name) === index
                    )
                    .map(object => {
                        return {
                            keyValue: 'city-option_' + object.city_name,
                            value: object.city_name,
                            name: object.city_name,
                        };
                    });
                this.setState({
                    city: formattedCity.sort((a, b) => a.name.localeCompare(b.name)),
                });
            });
    }

    onRegionListSelected(event) {
        const selectedCity = event.currentTarget.getAttribute('data-value');
        let selectedEvents = [];

        if (this.state.deleteRegions.filter(region => region.city === selectedCity).length > 0) {
            selectedEvents = this.state.deleteRegions.filter(region => region.city !== selectedCity);
            this.setState({
                deleteRegions: selectedEvents,
            });
        } else {
            selectedEvents = this.state.deleteRegions.concat(
                this.state.storeRegionAdded.filter(region => region.city === selectedCity)
            );
        }

        let deleteRegionDisabled = true;

        if (selectedEvents.length > 0) {
            deleteRegionDisabled = false;
        }

        this.setState({
            deleteRegions: selectedEvents,
            deleteRegionDisabled: deleteRegionDisabled,
        });
    }

    onDeleteRegionClick() {
        let deleteRegionDisabled = true;

        if (this.state.deleteRegions.length > 0) {
            deleteRegionDisabled = false;
        }

        this.setState({
            storeRegionAdded: this.state.storeRegionAdded.filter(region => !this.state.deleteRegions.includes(region)),
            deleteRegionDisabled: deleteRegionDisabled,
        });
    }

    checkCloseState = () => {
        if (this.state.closeButtonState === 'close') {
            this.setState({
                closeButtonState: 'cancel',
                saveChangesButtonDisabled: false,
            });
        }
    };

    onATLNameChange(event) {
        this.checkCloseState();
        this.setState({
            atlName: event.target.value,
        });
    }
    onCountryChange(event) {
        this.checkCloseState();
        this.requestCityOptions(event.target.value);
        this.setState({
            atlCountry: event.target.value,
        });
    }
    onCityChange(event) {
        this.checkCloseState();
        this.setState({
            atlCountry: event.target.value,
        });
    }

    handleChangeActivityStartDate = event => {
        this.setState({ atlStartDate: event.target.value });
    };

    handleChangeActivityEndDate = event => {
        this.setState({ atlEndDate: event.target.value });
    };

    onRefererInputChange = event => {
        const selectedRefererId = this.state.atlRefererOptions.filter(referer => referer.name === event.target.value)[0]
            .id;
        this.setState({
            atlReferer: selectedRefererId.toString(),
        });
    };

    onRegionDetailsAddClick() {
        if (this.dropDownValidator()) {
            this.checkCloseState();
            this.setState(prevState => ({
                storeRegionAdded: [
                    ...prevState.storeRegionAdded,
                    {
                        country: this.state.atlCountry,
                        city: this.state.atlCity,
                    },
                ],
                atlCountry: '',
                atlCity: '',
                city: [],
                atlCountryErrorMessage: '',
                atlCityErrorMessage: '',
            }));
        }
    }

    onResetClick() {
        this.setState({
            isLoading: false,
            closeButtonState: 'close',
            saveChangesButtonDisabled: true,
            atlDetailsAccordionOpen: true,
            nationWide: false,
            country: [],
            city: [],
            storeRegionAdded: [].sort((a, b) => a.name.localeCompare(b.name)),
            regionSelectedOnList: [],
            deleteRegionDisabled: true,
            atlStartDate: '',
            atlEndDate: '',
            atlName: '',
            atlCountry: '',
            atlCity: '',
            atlNameErrorMessage: '',
            atlStartDateErrorMessage: '',
            atlEndDateErrorMessage: '',
            atlCountryErrorMessage: '',
            atlCityErrorMessage: '',
            deleteRegions: [],
        });
    }

    handleNavigateManageEventModal() {
        this.props.setModal('ManageAboveTheLine', {});
    }

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.handleNavigateManageEventModal();
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
        } else {
            this.props.setPopup({
                title: 'Send',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        style: 'secondary',
                    },
                ],
            });
        }
    }

    renderModalNavigation() {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
            },
            {
                value: 'RESET',
                onClick: this.onResetClick,
                disabled: false,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: false,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    }

    renderAccordion() {
        const accordions = [
            {
                header: 'Setup above the line',
                required: true,
                open: this.state.atlDetailsAccordionOpen,
                type: 'form',
                intro: '<p>Our ATL reporting is built upon modelling a baseline during a period where no ATL activity is running. We model a baseline for each ATL activity, such as TV or Display. Please configure below.</p>',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'ATL Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter a name for ATL, for example, TV.',
                                inputKeyValue: 'setup-atl__atl-name',
                                inputPlaceholder: 'ATL name...',
                                inputValue: this.state.atlName,
                                inputOnChange: this.onATLNameChange,
                                errorMessage: this.state.atlNameErrorMessage,
                            },
                            {
                                label: 'Base Activity Start Date:',
                                type: 'datePicker',
                                requiredField: true,
                                toolTipCopy:
                                    'The start date for a period in time where no ATL was not running. We use this period for modelling a baseline.',
                                inputValue: this.state.atlStartDate,
                                inputOnChange: this.handleChangeActivityStartDate,
                                errorMessage: this.state.atlStartDateErrorMessage,
                            },
                            {
                                label: 'Base Activity End Date:',
                                type: 'datePicker',
                                requiredField: true,
                                toolTipCopy:
                                    'The end date for a period in time where no ATL was not running. We use this period for modelling a baseline.',
                                inputValue: this.state.atlEndDate,
                                inputOnChange: this.handleChangeActivityEndDate,
                                errorMessage: this.state.atlEndDateErrorMessage,
                            },
                            {
                                label: 'Channel:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'The channel associated with the ATL reporting.',
                                inputOptions: this.state.atlRefererOptions,
                                inputOnChange: this.onRefererInputChange,
                                errorMessage: this.state.atlRefererErrorMessage,
                            },
                            {
                                label: 'Regional tickbox:',
                                type: 'checkbox',
                                toolTipCopy:
                                    'If your ATL activity is regional, tick this box. If left un-ticked your ATL activity will be assumed nationwide.',
                                checked: this.state.nationWide,
                                inputOnChange: this.onCheckboxChange,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    }

    renderRegionForm() {
        const accordions = [
            {
                header: 'Multiple regions',
                required: false,
                open: this.state.nationWide,
                type: 'formSimpleTable',
                intro: '<p>Please add the country to submit the form. </p>',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Country:',
                                type: 'select',
                                requiredField: false,
                                inputKeyValue: 'setup-atl__country',
                                inputOptions: this.state.country,
                                inputValue: this.state.atlCountry,
                                inputOnChange: this.onCountryChange,
                                errorMessage: this.state.atlCountryErrorMessage,
                            },
                            {
                                label: 'City:',
                                type: 'select',
                                requiredField: false,
                                inputKeyValue: 'setup-atl__city',
                                inputOptions: this.state.city,
                                inputValue: this.state.atlCity,
                                inputOnChange: this.onCityChange,
                                errorMessage: this.state.atlCityErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: 'ADD',
                                onClick: this.onRegionDetailsAddClick,
                            },
                        ],
                        columns: 2,
                    },
                    tableConfig: {
                        header: {
                            columns: [
                                {
                                    title: 'Country',
                                },
                                {
                                    title: 'City',
                                },
                            ],
                        },
                        rows: this.state.storeRegionAdded.map(region => {
                            const rowProperty = {
                                selected: this.state.deleteRegions.includes(region),
                            };

                            return {
                                columns: [
                                    {
                                        copy: region.country,
                                    },
                                    {
                                        copy: region.city,
                                    },
                                ],
                                keyValue: `region__${region.city}`,
                                dataValue: region.city,
                                rowProperty,
                                onClick: this.onRegionListSelected,
                            };
                        }),
                        isScrollable: true,
                    },
                    optionalButtonConfig: {
                        buttons: [
                            {
                                value: 'DELETE',
                                style: 'red',
                                onClick: this.onDeleteRegionClick,
                                disabled: this.state.deleteRegionDisabled,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__create-atl">
                    <this.renderModalNavigation />
                    <h2>Setup Above the Line</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__create-atl">
                <this.renderModalNavigation />
                <h2>Setup Above the Line</h2>
                <this.renderAccordion />
                {this.state.nationWide && <this.renderRegionForm />}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta,
        account: state.account,
        request: state.request,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        updateUser: user => {
            dispatch(updateUser(user));
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        delRequest: () => {
            dispatch(delRequest());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalSetupAboveTheLine);
