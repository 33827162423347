// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import { generateUrlDetail } from '../../helpers/request-builder';
import Axios from 'axios';

// Redux Actions
import { removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setUser } from '../../redux/actions/user';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';

//
import WidgetAccordion from '../../widgets/accordion';
import { isEmail, isString } from '../../helpers/validator';
import { ButtonThemes } from '../../enums/button-themes';

class LayoutModalUpdateUserDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            closeButtonState: 'close',
            saveChangesButtonDisabled: true,
            userDetailsAccordionOpen: true,
            saveButtonLoading: false,
            userDetailsEmail: this.props.user.email,
            userDetailsFirstName: this.props.user.firstName,
            userDetailsLastName: this.props.user.lastName,
            userDetailsEmailErrorMessage: '',
            userDetailsFirstNameErrorMessage: '',
            userDetailsLastNameErrorMessage: '',
        };

        this.formValidator = this.formValidator.bind(this);
        this.updateDetails = this.updateDetails.bind(this);
        this.onSaveChangesClick = this.onSaveChangesClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderAccordion = this.renderAccordion.bind(this);
    }

    formValidator() {
        let hasFormError = false;
        let errorMessageObject = {
            userDetailsEmailErrorMessage: '',
            userDetailsFirstNameErrorMessage: '',
            userDetailsLastNameErrorMessage: '',
        };

        if (!isEmail(this.state.userDetailsEmail) || this.state.userDetailsEmail.length === 0) {
            hasFormError = true;
            errorMessageObject.userDetailsEmailErrorMessage = 'Please enter a valid email address.';
        }

        if (!isString(this.state.userDetailsFirstName) || this.state.userDetailsFirstName.length === 0) {
            hasFormError = true;
            errorMessageObject.userDetailsFirstNameErrorMessage = 'Please enter a valid first name.';
        }

        if (!isString(this.state.userDetailsLastName) || this.state.userDetailsLastName.length === 0) {
            hasFormError = true;
            errorMessageObject.userDetailsLastNameErrorMessage = 'Please enter a valid first name.';
        }

        if (hasFormError) {
            this.setState({
                userDetailsEmailErrorMessage: errorMessageObject.userDetailsEmailErrorMessage,
                userDetailsFirstNameErrorMessage: errorMessageObject.userDetailsFirstNameErrorMessage,
                userDetailsLastNameErrorMessage: errorMessageObject.userDetailsLastNameErrorMessage,
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    }

    onSaveChangesClick() {
        this.setState({
            saveChangesButtonDisabled: true,
            saveButtonLoading: true,
        });

        if (this.formValidator() === true) {
            this.updateDetails();
        } else {
            this.setState({
                saveChangesButtonDisabled: false,
                saveButtonLoading: false,
            });
        }
    }

    updateDetails() {
        const updateDetailsUrl = generateUrlDetail('config', `users`, this.props.user.id, []);

        const updateDetailsConfig = {
            method: 'PUT',
            url: updateDetailsUrl,
            data: {
                first_name: this.state.userDetailsFirstName,
                last_name: this.state.userDetailsLastName,
                is_active: true,
                email: this.state.userDetailsEmail,
                username: this.state.userDetailsEmail,
                old_username: this.props.user.username,
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        Axios(updateDetailsConfig)
            .then(res => {
                this.setState({
                    saveChangesButtonDisabled: true,
                });

                const user = {
                    ...this.props.user,
                    firstName: this.state.userDetailsFirstName,
                    lastName: this.state.userDetailsLastName,
                    username: this.state.userDetailsEmail,
                    email: this.state.userDetailsEmail,
                };

                this.props.setUser(user);
                this.props.addNotification({
                    copy: 'Your details has been successfully updated.',
                    type: NotificationMessageType.Success,
                });
                this.props.removeModal();
            })
            .catch(error => {
                this.props.addNotification({
                    copy: 'There was an issue updating your details, please try again later.',
                    type: NotificationMessageType.Error,
                });
            })
            .finally(() => {
                this.setState({ saveChangesButtonDisabled: false, saveButtonLoading: false });
            });
    }

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.props.removeModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    // Manage the input fields changes and updating the state with the new value entered by the user
    onFirstNameChange(event) {
        if (this.state.closeButtonState === 'close') {
            this.setState({
                closeButtonState: 'cancel',
                saveChangesButtonDisabled: false,
            });
        }
        this.setState({
            userDetailsFirstName: event.target.value,
        });
    }
    onLastNameChange(event) {
        if (this.state.closeButtonState === 'close') {
            this.setState({
                closeButtonState: 'cancel',
                saveChangesButtonDisabled: false,
            });
        }
        this.setState({
            userDetailsLastName: event.target.value,
        });
    }
    onEmailChange(event) {
        if (this.state.closeButtonState === 'close') {
            this.setState({
                closeButtonState: 'cancel',
                saveChangesButtonDisabled: false,
            });
        }
        this.setState({
            userDetailsEmail: event.target.value,
        });
    }

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.props.removeModal();
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    }

    renderModalNavigation() {
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: false,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    }

    renderAccordion() {
        const accordions = [
            {
                header: 'User Details',
                required: true,
                open: this.state.userDetailsAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'First Name:',
                                type: 'text',
                                requiredField: true,
                                inputKeyValue: 'user-details__first-name',
                                inputPlaceholder: 'First Name...',
                                inputValue: this.state.userDetailsFirstName,
                                inputOnChange: this.onFirstNameChange,
                                errorMessage: this.state.userDetailsFirstNameErrorMessage,
                            },
                            {
                                label: 'Last Name:',
                                type: 'text',
                                requiredField: true,
                                inputKeyValue: 'user-details__last-name',
                                inputPlaceholder: 'Last Name...',
                                inputValue: this.state.userDetailsLastName,
                                inputOnChange: this.onLastNameChange,
                                errorMessage: this.state.userDetailsLastNameErrorMessage,
                            },
                            {
                                label: 'Email:',
                                type: 'text',
                                requiredField: true,
                                inputKeyValue: 'user-details__email',
                                inputPlaceholder: 'Email...',
                                inputValue: this.state.userDetailsEmail,
                                inputOnChange: this.onEmailChange,
                                errorMessage: this.state.userDetailsEmailErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__create-event-wizard">
                    <this.renderModalNavigation />
                    <h2>Update Details</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__create-event-wizard">
                <this.renderModalNavigation />
                <h2>Update Details</h2>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta,
        account: state.account,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        setUser: user => {
            dispatch(setUser(user));
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalUpdateUserDetails);
