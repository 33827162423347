import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Helpers
import { formatNumber, formatValue } from '../../helpers/format-number';

//Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Hooks
import useWidgetTheme from '../../hooks/base/use-widget-theme';

// Components
import WidgetBase from '../base/widget-base';

// Types
import { BigNumberData, WidgetColour } from '../../types';

const StyledWidgetValue = css`
    color: ${props => props.theme.sectionDashboard.widget.bigNumber.textColor};
    text-align: center;
    z-index: 1;
    margin: 0px;
`;

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;

const StyledTextContainer = styled.div<{ direction: 'horizontal' | 'vertical' }>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${props => (props.direction === 'horizontal' ? 'row' : 'column')};
    justify-content: center;
    align-items: center;
`;

const StyledWidgetNumber = styled.h1<{ width: number; height: number }>`
    ${StyledWidgetValue}
    font-size: ${props =>
        props.width === 1 || props.height === 1 ? '2.5rem' : props.height > 2 && props.width > 2 ? '6rem' : '4rem'};
`;

const StyledWidgetRatioNumber = styled.h1<{ width: number; height: number }>`
    ${StyledWidgetValue}
    font-size: ${props =>
        props.width === 1 || props.height === 1 ? '2.5rem' : props.height > 2 && props.width > 2 ? '5.5rem' : '3.5rem'};
`;

const StyledWidgetChart = styled.div`
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
`;

export type WidgetBigNumberProps = {
    title: string;
    data: BigNumberData;
    colour?: WidgetColour;
    href?: string;
    menu?: React.ReactElement;
};

const WidgetBigNumber = ({ title, data, href, colour = WidgetColour.Default, menu }: WidgetBigNumberProps) => {
    const { width, height } = useSectionDashboard();

    const widgetTheme = useWidgetTheme(colour);

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} colour={colour} menu={menu} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} colour={colour} href={href} menu={menu} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} colour={colour} href={href} />;
    }

    const { formatting, rounding, series } = data;
    const direction = width > 2 ? 'horizontal' : 'vertical';

    const chartOptions = {
        chart: {
            type: 'areaspline',
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            borderRadius: 0,
            animation: false,
        },
        plotOptions: {
            series: {
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, widgetTheme.splineGradientStart],
                        [1, widgetTheme.splineGradientEnd],
                    ],
                },
                lineColor: widgetTheme.splineLine,
                animation: false,
                marker: {
                    enabled: false,
                    fillColor: '#FFFFFF',
                    lineWidth: 1,
                    lineColor: '#FFFFFF',
                },
                enableMouseTracking: false,
            },
        },
        title: {
            text: '',
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                data: series,
            },
        ],
        xAxis: {
            visible: false,
            minPadding: 0,
            maxPadding: 0,
        },
        yAxis: {
            visible: false,
            minPadding: 0,
            maxPadding: 0,
        },
    };

    const formattedNumber = formatNumber(data.value, width, rounding);
    const formattedRatioNumber = data.ratioValue ? formatNumber(data.ratioValue, width, rounding) : undefined;

    const bigNumberValue = formatting ? formatValue(formattedNumber, formatting) : formattedNumber;
    const bigNumberRatioValue = formattedRatioNumber
        ? formatting
            ? formatValue(formattedRatioNumber, formatting)
            : formattedRatioNumber
        : undefined;

    return (
        <WidgetBase title={title} href={href} colour={colour} menu={menu}>
            <StyledContainer>
                <StyledTextContainer direction={direction}>
                    {bigNumberRatioValue ? (
                        <StyledWidgetRatioNumber width={width} height={height}>
                            {bigNumberValue} : {bigNumberRatioValue}
                        </StyledWidgetRatioNumber>
                    ) : (
                        <StyledWidgetNumber width={width} height={height}>
                            {bigNumberValue}
                        </StyledWidgetNumber>
                    )}
                </StyledTextContainer>
                {series && (
                    <StyledWidgetChart>
                        <CommonHighcharts
                            highcharts={Highcharts}
                            options={chartOptions}
                            containerProps={{ style: { height: '100%' } }}
                        />
                    </StyledWidgetChart>
                )}
            </StyledContainer>
        </WidgetBase>
    );
};

export default memo(WidgetBigNumber, isEqual);
