import * as fields from './fields';
import * as resources from './resources';
import { getLastValidDay } from '../helpers/valid-date-helper';
import moment from 'moment';

// Helper
import { ChartType, allChartTypesWithAreaSpline } from './common/chart-types';
import dateStringToUnix from '../helpers/date-string-to-unix';

export const config = {
    pageTitle: 'Google Search Trends',
    reportDescription:
        'Explore search trends from Google Trends by keyword Market and Category. This report updates on the 1st and 15th each month, and runs two weeks in arrears.',
    dateDimension: fields.SEO_TREND_DATE_DATE,
    filters: {
        status: {
            isEnableDatePicker: true,
            isEnableProductSelect: false,
            isEnableMetrics: true,
        },
        datePicker: {
            // data is batched weekly Saturday - Saturday
            customStartDate: () => {
                const lastValidDay = getLastValidDay(moment(), 'Saturday');
                const startDate = lastValidDay.subtract(1, 'weeks');
                return startDate;
            },
            customEndDate: () => {
                const endDate = getLastValidDay(moment(), 'Saturday');
                return endDate;
            },
            // disable day if date is not a sunday or is a sunday after the 14th of the current month and year
            disabledDays: function (date) {
                if (
                    moment().year() === moment(date).year() &&
                    moment().month() === moment(date).month() &&
                    moment(date).date() >= 14
                ) {
                    return true;
                }
                return moment(date).format('dddd') !== 'Saturday';
            },
        },
    },
    resources: [resources.REPORT_SEOPT_GOOGLE_TRENDS],
    ignoreProducts: true,
    chart: {
        chartType: ChartType.AreaSpline,
        allowedChartTypes: allChartTypesWithAreaSpline,
        chartId: 'report-seogd-google-trends-traffic',
        allYMetrics: [
            fields.GOOGLE_TRENDS_HITS,
            fields.GOOGLE_TRENDS_CHANGE_OVER_TIME,
            fields.GOOGLE_TRENDS_CHANGE_OVER_TIME_PCT,
        ],
        initialYMetrics: [fields.GOOGLE_TRENDS_HITS],
        dataFormatter: function (_, datas, dimension, metric) {
            return datas.map(data => {
                return [dateStringToUnix(data[dimension.rawName].raw_value), data[metric].raw_value];
            });
        },
    },
    table: {
        hideTotals: true,
        tabListItems: [
            {
                tabName: 'Search Trends',
                displayName: 'Search Trends',
                isSelected: true,
                metrics: [
                    fields.GOOGLE_TRENDS_HITS,
                    fields.GOOGLE_TRENDS_CHANGE_OVER_TIME,
                    fields.GOOGLE_TRENDS_CHANGE_OVER_TIME_PCT,
                ],
            },
            {
                tabName: 'Search Trends YoY',
                displayName: 'Search Trends YoY',
                isSelected: true,
                metrics: [
                    fields.GOOGLE_TRENDS_HITS_SD_YOY,
                    fields.GOOGLE_TRENDS_HITS_ED_YOY,
                    fields.GOOGLE_TRENDS_CHANGE_OVER_TIME_LAST_YEAR,
                ],
            },
        ],
        fieldDataOverrides: {},
        fieldParentOverrides: {},
        fieldChildren: {
            [fields.MARKET_KEYWORD_COUNTRY.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_PRODUCT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
            [fields.CORE_PARENT_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.CORE_CHILD_CAT,
                    },
                ],
            },
            [fields.CORE_CHILD_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.CORE_SUB_CAT,
                    },
                ],
            },
            [fields.CORE_SUB_CAT.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_GOOGLE_TRENDS,
                        field: fields.MARKET_KEYWORD_KEYWORD,
                    },
                ],
            },
            [fields.KEYWORD_MODIFIER.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.LOCALE,
                    },
                ],
            },
            [fields.LOCALE.id]: {
                default: [
                    {
                        resource: resources.REPORT_SEOPT_KEYWORDS_LANDING,
                        field: fields.CORE_PARENT_CAT,
                    },
                ],
            },
        },
    },
    pagination: {
        currentPage: 1,
        currentRange: [1, 25],
        minPage: 1,
        maxPage: 100,
        totalResults: 12345,
        maxButtons: 10,
        rowDefault: 25,
        rowOptions: [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }, { value: 200 }, { value: 500 }],
    },
};
