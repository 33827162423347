// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import {
    isPassword,
    hasCapitalLetter,
    hasLowerCaseLetter,
    hasNumber,
    hasSpecialCharacter,
    hasPasswordValidCharacters,
    isEmail,
    isString,
} from '../../helpers/validator';
import { generateUrl, generatePath } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

import WidgetAccordion from '../../widgets/accordion';
import UserGroups from '../../enums/user-groups';

const { ADMIN, VIEWER, API } = UserGroups;

class LayoutModalCreateUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageError: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            saveChangesButtonDisabled: false,
            saveChangesButtonLoading: false,
            userDetailsAccordionOpen: true,
            roleOptions: [],
            email: '',
            password: '',
            repeatPassword: '',
            firstName: '',
            lastName: '',
            role: '',
            'user-details__password__hasLength': false,
            'user-details__password__hasCapitalLetter': false,
            'user-details__password__hasLowercaseLetter': false,
            'user-details__password__hasNumber': false,
            'user-details__password__hasSpecialCharacter': false,
            'user-details__password__hasValidCharacter': false,
            'user-details__email__error-message': '',
            'user-details__password__error-message': '',
            'user-details__repeat-password__error-message': '',
            'user-details__first-name__error-message': '',
            'user-details__last-name__error-message': '',
            'user-details__role__error-message': '',
        };
    }

    componentDidMount() {
        const config = {
            resourceGroup: 'config',
            resourceName: 'groups',
            resourceParams: [
                {
                    key: 'active',
                    value: 1,
                },
                {
                    key: 'id__in',
                    value: [ADMIN, VIEWER, API],
                },
            ],
        };

        this.props.getRequest(config);
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                const roleOptions = this.props.request.data.objects.map(role => {
                    return {
                        value: role.id,
                        name: role.name,
                    };
                });

                this.setState({
                    isLoading: false,
                    roleOptions: roleOptions,
                });
            } else {
                this.setState({
                    isLoading: false,
                    pageError: true,
                });
            }

            this.props.delRequest();
        }
    }

    formValidator = () => {
        // Clear existing error messages
        this.setState({
            'user-details__email__error-message': '',
            'user-details__password__error-message': '',
            'user-details__repeat-password__error-message': '',
            'user-details__first-name__error-message': '',
            'user-details__last-name__error-message': '',
            'user-details__role__error-message': '',
        });

        let hasFormError = false;
        const errorMessageObject = {
            'user-details__email__error-message': '',
            'user-details__password__error-message': '',
            'user-details__repeat-password__error-message': '',
            'user-details__first-name__error-message': '',
            'user-details__last-name__error-message': '',
            'user-details__role__error-message': '',
        };

        // Validate and ensure email and check that it is populated
        if (!isEmail(this.state.email) || this.state.email.length === 0) {
            hasFormError = true;
            errorMessageObject['user-details__email__error-message'] = 'Please enter a valid email address.';
        }

        // Validate and ensure password and check that it is populated
        if (!isPassword(this.state.password) || this.state.password.length === 0) {
            hasFormError = true;
            errorMessageObject['user-details__password__error-message'] =
                'Please enter a valid Password that meets the below requirements.';
        }

        // Validate and ensure repeatPassword and check that it is populated
        if (this.state.password !== this.state.repeatPassword) {
            hasFormError = true;
            errorMessageObject['user-details__repeat-password__error-message'] =
                'Please ensure the password is the same as Password.';
        }

        // Validate and ensure user-details__first-name and check that it is populated
        if (!isString(this.state.firstName) || this.state.firstName.length === 0) {
            hasFormError = true;
            errorMessageObject['user-details__first-name__error-message'] = 'Please enter a valid First Name.';
        }

        // Validate and ensure user-details__last-name and check that it is populated
        if (!isString(this.state.lastName) || this.state.lastName.length === 0) {
            hasFormError = true;
            errorMessageObject['user-details__last-name__error-message'] = 'Please enter a valid Last Name.';
        }

        // Validate and ensure user-details__role and check that it is populated
        if (!isString(this.state.role) || this.state.role.length === 0) {
            hasFormError = true;
            errorMessageObject['user-details__role__error-message'] = 'Please select a role for this user.';
        }

        if (hasFormError) {
            this.setState({
                'user-details__email__error-message': errorMessageObject['user-details__email__error-message'],
                'user-details__password__error-message': errorMessageObject['user-details__password__error-message'],
                'user-details__repeat-password__error-message':
                    errorMessageObject['user-details__repeat-password__error-message'],
                'user-details__first-name__error-message':
                    errorMessageObject['user-details__first-name__error-message'],
                'user-details__last-name__error-message': errorMessageObject['user-details__last-name__error-message'],
                'user-details__role__error-message': errorMessageObject['user-details__role__error-message'],
            });
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    createUser = () => {
        const createUserConfig = {
            method: 'POST',
            url: generateUrl('config', 'users', []),
            data: {
                username: this.state.email,
                email: this.state.email,
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                password: this.state.password,
            },
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        Axios(createUserConfig)
            .then(res => {
                const userId = res.data.id;

                const assignUserToGroupConfig = {
                    method: 'POST',
                    url: generateUrl('config', 'account-user', []),
                    data: {
                        account: generatePath('config', 'account', this.props.account.id),
                        user: generatePath('config', 'lite-users', userId),
                        group: generatePath('config', 'groups', this.state.role),
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                Axios(assignUserToGroupConfig)
                    .then(res => {
                        this.handleNavigateManageUserModal();
                        this.props.addNotification({
                            copy: 'The user has been successfully created.',
                            type: NotificationMessageType.Success,
                        });
                    })
                    .catch(err => {
                        this.props.addNotification({
                            copy: 'There was an issue trying to create this user. Please try again later',
                            type: NotificationMessageType.Error,
                        });
                        this.setState({
                            saveChangesButtonDisabled: false,
                            saveChangesButtonLoading: false,
                            closeButtonDisabled: false,
                        });
                    });
            })
            .catch(err => {
                if (err.response.status === 409) {
                    this.setState({
                        'user-details__email__error-message':
                            'This user already exists, try inviting them instead of creating them as a new user.',
                    });
                } else {
                    this.props.addNotification({
                        copy: 'There was an issue trying to create this user. Please try again later',
                        type: NotificationMessageType.Error,
                    });
                }

                this.setState({
                    saveChangesButtonDisabled: false,
                    saveChangesButtonLoading: false,
                    closeButtonDisabled: false,
                });
            });
    };

    onSaveChangesClick = () => {
        this.setState({
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        if (this.formValidator() === true) {
            this.createUser();
        } else {
            this.setState({
                saveChangesButtonDisabled: false,
                saveChangesButtonLoading: false,
                closeButtonDisabled: false,
            });
        }
    };

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    };

    handleFirstNameChange = event => {
        this.setState({ firstName: event.target.value });
    };

    handleLastNameChange = event => {
        this.setState({ lastName: event.target.value });
    };

    handleRoleChange = event => {
        this.setState({ role: event.target.value });
    };

    handlePasswordChange = event => {
        const pass = event.target.value;
        this.setState({
            password: pass,
            'user-details__password__hasLength': pass.length >= 8,
            'user-details__password__hasCapitalLetter': hasCapitalLetter(pass),
            'user-details__password__hasLowercaseLetter': hasLowerCaseLetter(pass),
            'user-details__password__hasNumber': hasNumber(pass),
            'user-details__password__hasSpecialCharacter': hasSpecialCharacter(pass),
            'user-details__password__hasValidCharacter': hasPasswordValidCharacters(pass),
        });
    };

    handleRepeatPasswordChange = event => {
        this.setState({ repeatPassword: event.target.value });
    };

    handleNavigateManageUserModal = () => {
        this.props.setModal('ManageUsers', {});
    };

    onCloseClick = () => {
        if (this.state.closeButtonState === 'close') {
            if (this.props.request.isLoading !== null) {
                this.props.cancelRequest();
            }
            this.handleNavigateManageUserModal();
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to exit the "Create User"? Doing so means you will lose any details entered.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD USER',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        style: 'secondary',
                    },
                ],
            });
        }
    };

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick = () => {
        this.props.removePopup();
        this.handleNavigateManageUserModal();
    };

    // SecondaryAction of the close popup
    onPopupStayHereClick = () => {
        this.props.removePopup();
    };

    renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    renderAccordion = () => {
        const accordions = [
            {
                header: 'User Details',
                required: true,
                open: this.state.userDetailsAccordionOpen,
                type: 'form',
                intro: `<p>Create a new user which will have access to your account on the Cubed Platform. Please ensure to give the user adequate access by selecting the correct role. More details about each role can be found on our <a href="http://tag.docs.withcubed.com/onboarding/general/" target='_blank' rel='noopener noreferrer'>support page</a>.</p>`,
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Email:',
                                type: 'email',
                                requiredField: true,
                                toolTipCopy:
                                    'Enter the email address of the user you wish to create. This will become their username.',
                                inputValue: this.state.email,
                                inputOnChange: this.handleEmailChange,
                                errorMessage: this.state['user-details__email__error-message'],
                            },
                            {
                                label: 'First Name:',
                                type: 'text',
                                requiredField: true,
                                inputPlaceholder: 'First Name...',
                                inputValue: this.state.firstName,
                                inputOnChange: this.handleFirstNameChange,
                                errorMessage: this.state['user-details__first-name__error-message'],
                            },
                            {
                                label: 'Last Name:',
                                type: 'text',
                                requiredField: true,
                                inputPlaceholder: 'Last Name...',
                                inputValue: this.state.lastName,
                                inputOnChange: this.handleLastNameChange,
                                errorMessage: this.state['user-details__last-name__error-message'],
                            },
                            {
                                label: 'Role:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy:
                                    'This will determine how much access a given user has to the Cubed Dashboard.',
                                inputOptions: this.state.roleOptions,
                                inputValue: this.state['user-details__role'],
                                inputOnChange: this.handleRoleChange,
                                errorMessage: this.state['user-details__role__error-message'],
                            },
                            {
                                label: 'Password:',
                                type: 'password',
                                requiredField: true,
                                autocomplete: 'new-password',
                                inputPlaceholder: 'Password...',
                                inputValue: this.state.password,
                                inputOnChange: this.handlePasswordChange,
                                errorMessage: this.state['user-details__password__error-message'],
                            },
                            {
                                label: 'Repeat Password:',
                                type: 'password',
                                requiredField: true,
                                autocomplete: 'new-password',
                                inputPlaceholder: 'Repeat Password...',
                                inputValue: this.state.repeatPassword,
                                inputOnChange: this.handleRepeatPasswordChange,
                                errorMessage: this.state['user-details__repeat-password__error-message'],
                            },
                            {
                                type: 'passwordRequirements',
                                characterLength: this.state['user-details__password__hasLength'],
                                hasCapitalLetter: this.state['user-details__password__hasCapitalLetter'],
                                hasLowercaseLetter: this.state['user-details__password__hasLowercaseLetter'],
                                hasNumber: this.state['user-details__password__hasNumber'],
                                hasSpecialCharacter: this.state['user-details__password__hasSpecialCharacter'],
                                hasValidCharacter: this.state['user-details__password__hasValidCharacter'],
                            },
                        ],
                        columns: 2,
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__create-user">
                    <this.renderModalNavigation />
                    <h2>Create User</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__create-user">
                    <this.renderModalNavigation />
                    <h2>Create User</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__create-user">
                <this.renderModalNavigation />
                <h2>Create User</h2>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalCreateUser);
