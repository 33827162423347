import React, { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

// Redux
import { useDispatch } from 'react-redux';
import { removeModal, setModal } from '../../redux/actions/modal';
import { addNotification } from '../../redux/actions/notification';
import { removePopup, setPopup } from '../../redux/actions/popup';

// Enums
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// Helpers
import { generatePath, generateUrl, generateUrlDetail } from '../../helpers/request-builder';

// Components
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import SimpleTable, { SimpleTableProps } from '../../components/tables/components/simple-table';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import InputButton, { InputButtonProps } from '../../components/inputs/input-button';
// import InputNumber from '../../components/inputs/input-number';
// import FormLabel from '../../components/form-fields/form-label';
import Message from '../../components/message';
import FormSelect from '../../components/form-fields/form-select';
import FormCheckBoxInput from '../../components/form-fields/form-checkbox-input';

// Widget
import WidgetAccordion from '../../widgets/accordion';

// React Query
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import { ConfigDataSuccess } from '../../react-query/types';
import buildDropdownOptions from '../../react-query/select-functions/build-dropdown-options';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';
import usePatchResource from '../../react-query/hooks/use-patch-resource';

// Configurations
import { CONFIG_DASH_REFERER, REALLOCATION_CONFIG } from '../../configurations/resources-config';

// Types
import { DropdownOption } from '../../types';

const StyledH2 = styled.h2`
    font-size: 1.1rem;
`;

const StyledTableActionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 6px;
`;

const StyledEditContainer = styled.div`
    margin-top: 20px;
    background-color: white;
    padding: 1px 20px 20px 20px;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
`;

type ExcludeDateBase = {
    id: number;
    description: string;
};

type ExcludeDate = ExcludeDateBase & {
    startDate: string;
    endDate: string;
};

type ExcludeDateResponse = ExcludeDateBase & {
    start_date: string;
    end_date: string;
};

type ReallocationData = {
    id: string;
    referer: string;
    active: boolean;
};

const LayoutModalManageAttributionModels = () => {
    const dispatch = useDispatch();

    // Page states
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [formError, setFormError] = useState(false);
    const [applyButtonLoading, setApplyButtonLoading] = useState(false);
    const [deleteButttonLoading, setDeleteButtonLoading] = useState(false);

    const [formErrorMessages, setFormErrorMessages] = useState({
        description: '',
        startDate: '',
        endDate: '',
    });

    // Exclude date states
    const [excludeDates, setExcludeDates] = useState<ExcludeDate[]>([]);
    const [selectedExcludeDate, setSelectedExcludeDate] = useState<ExcludeDate | undefined>();
    const [tableConfig, setTableConfig] = useState<SimpleTableProps>({
        columns: [
            {
                title: 'Description',
            },
            {
                title: 'Start Date',
            },
            {
                title: 'End Date',
            },
        ],
        rows: [],
        isScrollable: false,
        errorMessageOverride: 'No Exclude Dates have been created for this account.',
    });

    useEffect(() => {
        fetchExcludeData();
    }, []);

    useEffect(() => {
        const tableRows = excludeDates.map(excludeDate => {
            const rowProperty = {
                selected: selectedExcludeDate && selectedExcludeDate.id === excludeDate.id,
                disabled: showEdit,
            };

            return {
                onClick: () => handleTableRowClick(excludeDate.id),
                key: String(excludeDate.id),
                dataValue: excludeDate.id,
                rowProperty,
                columns: [
                    {
                        copy: excludeDate.description,
                    },
                    {
                        copy: String(excludeDate.startDate),
                    },
                    {
                        copy: String(excludeDate.endDate),
                    },
                ],
            };
        });
        setTableConfig({ ...tableConfig, rows: tableRows });
    }, [excludeDates, selectedExcludeDate, showEdit]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        formValidator();
    }, [selectedExcludeDate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const errorMessages = Object.values(formErrorMessages);
        setFormError(errorMessages.some(message => message.length > 0));
    }, [formErrorMessages]);

    const fetchExcludeData = () => {
        setLoading(true);
        axios({
            method: 'GET',
            url: generateUrl('config', 'exclude-dates'),
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                setExcludeDates(
                    response.data.objects.map((object: ExcludeDateResponse) => {
                        return {
                            id: object.id,
                            description: object.description,
                            startDate: moment(object.start_date).format('YYYY/MM/DD'),
                            endDate: moment(object.end_date).format('YYYY/MM/DD'),
                        };
                    })
                );
            })
            .catch(e => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTableRowClick = (rowId: number) => {
        const selectedExclude = excludeDates.find(excludeDate => excludeDate.id === rowId);
        setSelectedExcludeDate(selectedExclude);
    };

    const handleDeleteClick = () => {
        dispatch(
            setPopup({
                title: 'Remove Date Exclusion',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to remove the date exclusion with the description '${selectedExcludeDate?.description}'?`,
                },
                buttons: [
                    {
                        value: 'YES, REMOVE',
                        onClick: handleDelete,
                    },
                    {
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                        onClick: () => dispatch(removePopup()),
                    },
                ],
            })
        );
    };

    const handleDelete = () => {
        dispatch(removePopup());
        if (selectedExcludeDate) {
            const url = generateUrlDetail('config', 'exclude-dates', String(selectedExcludeDate.id));
            if (url) {
                setDeleteButtonLoading(true);
                axios({
                    method: 'DELETE',
                    url: url,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(() => {
                        dispatch(
                            addNotification({
                                copy: 'Your date exclusion was successfully removed.',
                                type: NotificationMessageType.Success,
                            })
                        );
                        fetchExcludeData();
                    })
                    .catch(() => {
                        dispatch(
                            addNotification({
                                copy: 'There was an issue while removing your date exclusion. Please try again later.',
                                type: NotificationMessageType.Error,
                            })
                        );
                    })
                    .finally(() => {
                        setDeleteButtonLoading(false);
                        setSelectedExcludeDate(undefined);
                    });
            }
        }
    };

    const handleEditDescription = (event: React.FormEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        if (selectedExcludeDate) {
            setSelectedExcludeDate({ ...selectedExcludeDate, description: value });
        }
    };

    const handleEditStartDate = (event: React.FormEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        if (selectedExcludeDate) {
            setSelectedExcludeDate({
                ...selectedExcludeDate,
                startDate: value,
            });
        }
    };

    const handleEditEndDate = (event: React.FormEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        if (selectedExcludeDate) {
            setSelectedExcludeDate({
                ...selectedExcludeDate,
                endDate: value,
            });
        }
    };

    const handleApplyClick = () => {
        setApplyButtonLoading(true);
        if (selectedExcludeDate) {
            axios({
                method: 'PATCH',
                url: generateUrl('config', 'exclude-dates'),
                data: {
                    objects: [
                        {
                            resource_uri: generatePath('config', 'exclude-dates', String(selectedExcludeDate.id)),
                            id: selectedExcludeDate.id,
                            description: selectedExcludeDate.description,
                            start_date: selectedExcludeDate.startDate,
                            end_date: selectedExcludeDate.endDate,
                        },
                    ],
                },
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then(() => {
                    setShowEdit(false);
                    dispatch(
                        addNotification({
                            copy: 'Your date exclusion was successfully updated.',
                            type: NotificationMessageType.Success,
                        })
                    );
                    fetchExcludeData();
                })
                .catch(() => {
                    dispatch(
                        addNotification({
                            copy: 'There was an issue while saving your date exclusion. Please try again later.',
                            type: NotificationMessageType.Error,
                        })
                    );
                })
                .finally(() => {
                    setApplyButtonLoading(false);
                });
        }
    };

    const formValidator = () => {
        if (selectedExcludeDate) {
            if (selectedExcludeDate?.description.length === 0) {
                setFormErrorMessages(prevState => ({ ...prevState, description: 'Please enter a description' }));
            } else {
                setFormErrorMessages(prevState => ({ ...prevState, description: '' }));
            }

            if (!selectedExcludeDate?.startDate) {
                setFormErrorMessages(prevState => ({ ...prevState, startDate: 'Please select a start date' }));
            } else if (moment(selectedExcludeDate?.startDate) > moment(selectedExcludeDate?.endDate)) {
                setFormErrorMessages(prevState => ({
                    ...prevState,
                    startDate: 'Start date must be earlier than end date.',
                }));
            } else {
                setFormErrorMessages(prevState => ({ ...prevState, startDate: '' }));
            }

            if (!selectedExcludeDate?.endDate) {
                setFormErrorMessages(prevState => ({ ...prevState, endDate: 'Please select a start date' }));
            } else if (moment(selectedExcludeDate?.endDate) < moment(selectedExcludeDate?.startDate)) {
                setFormErrorMessages(prevState => ({
                    ...prevState,
                    endDate: 'End date must be later than start date.',
                }));
            } else {
                setFormErrorMessages(prevState => ({ ...prevState, endDate: '' }));
            }
        }
    };

    const navigationButtons: InputButtonProps[] = [
        {
            value: 'close',
            onClick: () => dispatch(removeModal()),
            buttonTheme: ButtonThemes.Secondary,
        },
    ];

    const editAccordion = [
        {
            header: 'Edit Exclude Dates',
            required: false,
            open: true,
            type: 'form',
            intro: '',
            config: {
                formConfig: {
                    fields: [
                        {
                            label: 'Exclude Date Description:',
                            type: 'textarea',
                            requiredField: true,
                            toolTipCopy: 'Enter the Exclude Date description.',
                            inputValue: selectedExcludeDate?.description,
                            inputOnChange: handleEditDescription,
                            errorMessage: formErrorMessages.description,
                        },
                        {
                            label: 'Exclude Date Start Date:',
                            type: 'datePicker',
                            requiredField: true,
                            toolTipCopy: 'Select the Exclude Date start date.',
                            inputValue: moment(selectedExcludeDate?.startDate).format('YYYY-MM-DD'),
                            inputOnChange: handleEditStartDate,
                            errorMessage: formErrorMessages.startDate,
                        },
                        {
                            label: 'Exclude Date End Date:',
                            type: 'datePicker',
                            requiredField: true,
                            toolTipCopy: 'Select the Exclude Date end date.',
                            inputValue: moment(selectedExcludeDate?.endDate).format('YYYY-MM-DD'),
                            inputOnChange: handleEditEndDate,
                            errorMessage: formErrorMessages.endDate,
                        },
                    ],
                    buttons: [
                        {
                            value: 'APPLY',
                            onClick: handleApplyClick,
                            isLoading: applyButtonLoading,
                            disabled: formError,
                        },
                        {
                            value: 'CANCEL',
                            onClick: () => {
                                setShowEdit(false);
                                setSelectedExcludeDate(undefined);
                            },
                            buttonTheme: ButtonThemes.Secondary,
                        },
                    ],
                },
            },
        },
    ];

    // State - Table
    const [selectedReallocationTableRows, setSelectedReallocationTableRows] = useState<ReallocationData[]>([]);

    // State - Edit Form Fields
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedReallocationId, setSelectedReallocationId] = useState('');
    const [selectedActive, setSelectedActive] = useState(false);

    // Queries
    const channelData = useFetchResource<ConfigDataSuccess, DropdownOption[]>({
        resource: CONFIG_DASH_REFERER,
        params: [
            { key: 'active', value: true },
            { key: 'order_by', value: 'name' },
        ],
        staleTime: 1000 * 60 * 5, // 5 minutes,
        select: (data: ConfigDataSuccess) => buildDropdownOptions({ data: data, labelField: 'name', valueField: 'id' }),
    });

    const reallocationChannelData = useFetchResource<ConfigDataSuccess>({
        resource: REALLOCATION_CONFIG,
        params: [{ key: 'active', value: true }],
        isPaginated: true,
    });

    const reallocationChannelPatchMutation = usePatchResource({
        resource: REALLOCATION_CONFIG,
        resourceId: selectedReallocationTableRows.length === 1 ? selectedReallocationTableRows[0].id : '',
        data: {
            id: selectedReallocationId,
            referer_id: selectedChannel,
            referer: `/api/config/referer/${selectedChannel}/`,
            active: selectedActive,
        },
        handleOnSuccess: () => {
            setSelectedReallocationTableRows([]);
            dispatch(
                addNotification({
                    copy: 'Channels updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your Channels. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const reallocationChannelDeleteMutation = useDeleteResource({
        resource: REALLOCATION_CONFIG,
        resourceIds: selectedReallocationTableRows.map(referer => referer.id),
        handleOnSuccess: () => {
            setSelectedReallocationTableRows([]);
            dispatch(
                addNotification({
                    copy: 'Channels deleted successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your Channels. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const handleChangeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSelectedChannel(event.target.value);
    };

    const handleChangeActive = () => {
        setSelectedActive(!selectedActive);
    };

    // Reallocation Channel Table Config
    const reallocationChannelSection = () => {
        // Loading
        if (reallocationChannelData.status === 'pending') {
            return (
                <div className="modal__side-panel__manage-excludeDates manage-modals">
                    <LoadingSpinner />
                </div>
            );
        }

        // Error
        if (reallocationChannelData.status === 'error') {
            return (
                <div className="modal__side-panel__manage-excludeDates manage-modals">
                    <Message
                        copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email."
                        type="error"
                    />
                </div>
            );
        }

        // Success
        if (reallocationChannelData.status === 'success') {
            const handleOnAddNewClick = () => {
                dispatch(setModal('AddReallocationChannels', {}));
            };

            const handleOnDeleteClick = () => {
                const configMessage =
                    selectedReallocationTableRows.length === 1
                        ? `Are you sure you would like to remove ${
                              (selectedReallocationTableRows[0].referer as unknown as { name: string }).name
                          }?`
                        : `Are you sure you would like to remove these Channels?`;
                dispatch(
                    setPopup({
                        title: 'Remove Channel',
                        iconType: 'warning',
                        contentType: 'simple',
                        config: {
                            copy: configMessage,
                        },
                        buttons: [
                            {
                                value: 'DELETE',
                                buttonTheme: ButtonThemes.Red,
                                onClick: () => {
                                    reallocationChannelDeleteMutation.mutate();
                                    dispatch(removePopup());
                                },
                            },
                            {
                                value: 'CANCEL',
                                onClick: () => dispatch(removePopup()),
                            },
                        ],
                    })
                );
            };

            const handleOnSaveClick = () => {
                reallocationChannelPatchMutation.mutate();
            };

            const handleOnCancelClick = () => {
                setSelectedReallocationTableRows([]);
            };

            const handleSelectReallocationTableRow = (pageCategory: ReallocationData) => {
                let selectedAllTableRows = [...selectedReallocationTableRows];

                const indexToRemove = selectedAllTableRows.findIndex(
                    item => item.referer === pageCategory.referer && item.id === pageCategory.id
                );

                if (indexToRemove !== -1) {
                    selectedAllTableRows.splice(indexToRemove, 1);
                } else {
                    selectedAllTableRows = [...selectedReallocationTableRows, pageCategory];
                }

                if (selectedAllTableRows.length === 1) {
                    // Table fields
                    setSelectedChannel((selectedAllTableRows[0].referer as unknown as { id: string }).id);
                    setSelectedActive(selectedAllTableRows[0].active);
                    setSelectedReallocationId(selectedAllTableRows[0].id);
                }

                setSelectedReallocationTableRows(selectedAllTableRows);
            };

            const reallocationChannelTableRows = reallocationChannelData.data.objects.map(reallocationData => {
                const rowProperty = {
                    selected: selectedReallocationTableRows.includes(reallocationData as ReallocationData),
                    disabled: false,
                };

                return {
                    columns: [
                        {
                            copy: (reallocationData.referer as { name: string }).name,
                        },
                    ],
                    keyValue: `page_category__${reallocationData.category}`,
                    key: `page_category__${reallocationData.category}`,
                    dataValue: reallocationData.id as string,
                    rowProperty,
                    onClick: () => handleSelectReallocationTableRow(reallocationData as ReallocationData),
                };
            });

            const reallocationChannelTableHeader = {
                columns: [
                    {
                        title: 'Channels',
                    },
                ],
            };

            return (
                <>
                    <SimpleTable
                        isLoading={
                            reallocationChannelData.isFetching ||
                            reallocationChannelPatchMutation.isPending ||
                            reallocationChannelDeleteMutation.isPending ||
                            channelData.isPending
                        }
                        header={reallocationChannelTableHeader}
                        rows={reallocationChannelTableRows}
                        hasIcons={false}
                        selectDisabled={false}
                        isScrollable={false}
                    />
                    <StyledTableActionsContainer>
                        <StyledButtonContainer>
                            <InputButton value="ADD CHANNEL" onClick={handleOnAddNewClick} />
                            <InputButton
                                buttonTheme={ButtonThemes.Red}
                                value="REMOVE"
                                disabled={selectedReallocationTableRows.length === 0}
                                onClick={handleOnDeleteClick}
                            />
                        </StyledButtonContainer>
                    </StyledTableActionsContainer>

                    {selectedReallocationTableRows && selectedReallocationTableRows.length === 1 && (
                        <StyledEditContainer>
                            <h2>Edit Reallocation Channel</h2>

                            <FormSelect
                                label="Channel:"
                                inputKeyValue="channel"
                                inputOptions={channelData.data || []}
                                inputOnChange={handleChangeChange}
                                requiredField={true}
                                inputValue={selectedChannel}
                                errorMessage={selectedChannel === '' ? 'Channel is required.' : ''}
                            />

                            <FormCheckBoxInput
                                inputKeyValue="active"
                                inputName="active"
                                label="Active"
                                checked={selectedActive}
                                inputOnChange={handleChangeActive}
                                disabled={
                                    reallocationChannelPatchMutation.isPending ||
                                    reallocationChannelDeleteMutation.isPending
                                }
                            />

                            <StyledButtonContainer>
                                <InputButton
                                    value="SAVE"
                                    onClick={handleOnSaveClick}
                                    isLoading={
                                        reallocationChannelPatchMutation.isPending ||
                                        reallocationChannelDeleteMutation.isPending
                                    }
                                />
                                <InputButton
                                    value="CANCEL"
                                    onClick={handleOnCancelClick}
                                    buttonTheme={ButtonThemes.Secondary}
                                />
                            </StyledButtonContainer>
                        </StyledEditContainer>
                    )}
                </>
            );
        }
    };

    if (loading) {
        return (
            <div className="modal__side-panel__manage-excludeDates manage-modals">
                <ModalNavigation buttons={navigationButtons} />
                <h2>Manage Attribution Models</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (error) {
        return (
            <div className="modal__side-panel__manage-excludeDates manage-modals">
                <ModalNavigation buttons={navigationButtons} />
                <h2>Manage Attribution Models</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div className="modal__side-panel__manage-excludeDates manage-modals">
            <ModalNavigation buttons={navigationButtons} />

            <h2>Manage Attribution Models</h2>
            <p>
                Manage your attribution models, including setting date exclusions, a lookback window and which channels
                you wish to reallocate
            </p>

            <StyledH2>Date Exclusions</StyledH2>
            <SimpleTable {...tableConfig} />
            <InputButtonWrapper>
                <InputButton
                    value="Add a Date Exclusion"
                    disabled={showEdit || deleteButttonLoading}
                    onClick={() => dispatch(setModal('AddExcludeDates', {}))}
                />
                <InputButton
                    value="EDIT"
                    disabled={
                        showEdit || applyButtonLoading || deleteButttonLoading
                            ? true
                            : selectedExcludeDate
                            ? false
                            : true
                    }
                    onClick={() => setShowEdit(true)}
                />
                <InputButton
                    buttonTheme={ButtonThemes.Red}
                    value={'REMOVE'}
                    disabled={showEdit ? true : selectedExcludeDate ? false : true}
                    isLoading={deleteButttonLoading}
                    onClick={handleDeleteClick}
                />
            </InputButtonWrapper>
            {showEdit && <WidgetAccordion accordions={editAccordion} />}

            <>
                <StyledH2>Manage Reallocation Channels</StyledH2>
                <p>
                    Sales and revenue are reallocated away from the reallocated channel where possible. If a user
                    journey consists of only reallocation channels, reallocation will not be possible and we will revert
                    to our standard methods.
                </p>

                {reallocationChannelSection()}
            </>
        </div>
    );
};

export default LayoutModalManageAttributionModels;
