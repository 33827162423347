import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generatePath, generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isString } from '../../helpers/validator';
import { MarketResponse } from '../types';
import { DropdownOption } from '../../types';

type ErrorMessageObject = {
    editBrandNameErrorMessage: string;
    editDomainNameErrorMessage: string;
    editMarketErrorMessage: string;
};

type CompetitorsResponse = {
    active: Boolean;
    brand_name: string;
    client_domain: Boolean;
    created: string;
    domain: string;
    id: number;
    market: MarketResponse;
    resource_uri: string;
    updated: string;
};

type Competitors = {
    id: number;
    brandName: string;
    domain: string;
    marketId: number;
    market: MarketResponse;
};

const LayoutManageCompetitors = () => {
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(true);
    const [closeButtonState, setCloseButtonState] = useState('close');
    const [disableSelectedCompetitor, setDisableSelectedConnection] = useState<boolean>(false);
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState<boolean>(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState<boolean>(true);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [editButtonDisabled, setEditButtonDisabled] = useState<boolean>(true);
    const [addNewButtonDisabled, setAddNewButtonDisabled] = useState<boolean>(false);
    const [removeButtonDisabled, setRemoveButtonDisabled] = useState<boolean>(true);
    const [editConnectionAccordionOpen, setEditKeywordAccordionOpen] = useState<boolean>(false);
    const [editConnectionAccordionVisible, setEditKeywordAccordionVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageError, setPageError] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [removeButtonState, setRemoveButtonState] = useState<boolean>(false);

    const [selectedCompetitor, setSelectedCompetitor] = useState<Competitors[]>([]);
    const [updatedCompetitor, setUpdatedCompetitor] = useState<Competitors[]>([]);
    const [deletedCompetitor, setDeletedCompetitor] = useState<Competitors[]>([]);

    const [competitors, setCompetitors] = useState<Competitors[]>([]);
    const [editBrandName, setEditBrandName] = useState<string>('');
    const [editDomainName, setEditDomainName] = useState<string>('');
    const [marketList, setMarketList] = useState<DropdownOption[]>([]);
    const [editSelectedMarket, setEditSelectedMarket] = useState<DropdownOption | undefined>(undefined);

    const [errorMessageObject, setErrorMessageObject] = useState<ErrorMessageObject>({
        editBrandNameErrorMessage: '',
        editDomainNameErrorMessage: '',
        editMarketErrorMessage: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        setCloseButtonState('close');
        fetchAll();
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        if (editBrandName || editDomainName || editSelectedMarket) {
            setSaveChangesButtonDisabled(false);
            setErrorMessageObject({
                editBrandNameErrorMessage: '',
                editDomainNameErrorMessage: '',
                editMarketErrorMessage: '',
            });
        }
    }, [editBrandName, editDomainName, editSelectedMarket]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            editBrandNameErrorMessage: '',
            editDomainNameErrorMessage: '',
            editMarketErrorMessage: '',
        };

        if (!isString(editBrandName) || editBrandName.length === 0) {
            hasFormError = true;
            errorMessageObj.editBrandNameErrorMessage = 'Please enter valid brand name.';
        }

        if (!isString(editDomainName) || editDomainName.length === 0) {
            hasFormError = true;
            errorMessageObj.editDomainNameErrorMessage = 'Please enter valid domain name.';
        }

        if (!editSelectedMarket) {
            hasFormError = true;
            errorMessageObj.editMarketErrorMessage = 'Please select a market.';
        }

        if (hasFormError) {
            setSaveChangesButtonDisabled(true);
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const handleEditBrandName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditBrandName(event?.target?.value);
    };

    const handleEditDomainName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditDomainName(event?.target?.value);
    };

    const handleMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
        const marketId = parseInt(event?.target?.value);
        const selectedMarket = marketList.find(market => market.value === marketId);
        setEditSelectedMarket(selectedMarket);
    };

    const fetchAll = () => {
        const resources = ['seogd-competitor-config', 'seogd-market'];
        const allRequests: AxiosPromise[] = [];

        resources.forEach(resource => {
            allRequests.push(
                axios({
                    method: 'GET',
                    url: generateUrl('config', resource, [
                        { key: 'active', value: 1 },
                        { key: 'limit', value: 0 },
                        ...(resource === 'seogd-market' ? [{ key: 'order_by', value: 'country' }] : []),
                    ]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        });
        axios
            .all(allRequests)
            .then(
                axios.spread((...response) => {
                    const competitors = response[0].data.objects.map((data: CompetitorsResponse) => {
                        return {
                            id: data.id,
                            brandName: data.brand_name,
                            domain: data.domain,
                            marketId: data.market.id,
                            market: data.market,
                        };
                    });

                    const allMarkets = response[1].data.objects.map((data: MarketResponse) => {
                        return {
                            label: data.alpha2,
                            value: data.id,
                            name: data.country,
                        };
                    });

                    setCompetitors(competitors);
                    setMarketList(allMarkets);
                    setIsLoading(false);
                })
            )
            .catch(error => {
                setIsLoading(false);
                setPageError(true);
                errorHandling(error);
            });
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const onDeleteConnection = () => {
        setSaveChangesButtonDisabled(false);
        setCloseButtonState('cancel');
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setRemoveButtonDisabled(true);

        if (disableSelectedCompetitor) {
            return;
        }

        let tempRemovedConnection = Array.prototype.concat(deletedCompetitor);
        let tempUpdatedConnection = Array.prototype.concat(updatedCompetitor);

        selectedCompetitor.forEach(keyword => {
            if (removeButtonState) {
                tempRemovedConnection.push(keyword);
            } else {
                tempRemovedConnection.splice(tempRemovedConnection.indexOf(keyword), 1);
            }

            // If a keyword was edited and then revoked in the same instance then the revoke should take priority
            const updatedConfigIndex = tempUpdatedConnection.indexOf(keyword);

            if (updatedConfigIndex >= 0) {
                tempUpdatedConnection.splice(updatedConfigIndex, 1);
            }
        });

        setDeletedCompetitor(tempRemovedConnection);
        setUpdatedCompetitor(tempUpdatedConnection);
        setSelectedCompetitor([]);
        setRemoveButtonState(true);
    };

    const onCompetitorSelect = (keyword: Competitors) => {
        if (disableSelectedCompetitor) {
            return;
        }

        const selectedCompetitorId = keyword.id;
        let selectedCompetitors = [];

        if (selectedCompetitor.filter(event => event.id === selectedCompetitorId).length > 0) {
            selectedCompetitors = selectedCompetitor.filter(event => event.id !== selectedCompetitorId);
            setSelectedCompetitor(selectedCompetitors);
            setEditButtonDisabled(selectedCompetitors.length === 0);
            setRemoveButtonDisabled(selectedCompetitors.length === 0);
            setAddNewButtonDisabled(selectedCompetitors.length === 0);
        } else {
            selectedCompetitors = selectedCompetitor.concat(
                competitors.filter(item => item.id === selectedCompetitorId)
            );
        }

        // Check the status of the items that have been selected.
        let containsRevoked = false;
        let containsNonRevoked = false;
        let containsEdited = false;

        selectedCompetitors.forEach(event => {
            if (deletedCompetitor.indexOf(event) >= 0) {
                containsRevoked = true;
            } else {
                containsNonRevoked = true;
            }

            if (updatedCompetitor.indexOf(event) >= 0) {
                containsEdited = true;
            }
        });

        // Change the status of the actions buttons depending on what values have been selected
        let editButtonDisabled = true;
        let removeButtonDisabled = true;
        let removeButtonState = true;
        let addButtonDisabled = false;

        if (selectedCompetitors.length > 0) {
            editButtonDisabled = false;
            removeButtonDisabled = false;
            addButtonDisabled = true;
        }

        if (selectedCompetitors.length > 1) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
        }

        if (containsRevoked && !containsNonRevoked && !containsEdited) {
            editButtonDisabled = true;
            removeButtonDisabled = false;
            removeButtonState = false;
        }

        if (containsRevoked) {
            editButtonDisabled = true;
        }

        if (containsRevoked && containsNonRevoked) {
            removeButtonDisabled = true;
            removeButtonState = true;
        }

        setAddNewButtonDisabled(addButtonDisabled);
        setSelectedCompetitor(selectedCompetitors);
        setEditButtonDisabled(editButtonDisabled);
        setRemoveButtonDisabled(removeButtonDisabled);
        setRemoveButtonState(removeButtonState);
        setUpdateButtonDisabled(true);
        setEditKeywordAccordionOpen(false);
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(removeModal());
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const handleAddNew = () => {
        dispatch(setModal('AddCompetitors', {}));
    };

    const onEditClick = () => {
        const market = marketList.find(market => market.value === selectedCompetitor[0].marketId);

        setCloseButtonState('cancel');
        setEditKeywordAccordionVisible(true);
        setEditKeywordAccordionOpen(true);
        setRemoveButtonDisabled(true);
        setUpdateButtonDisabled(false);
        setDisableSelectedConnection(true);
        setAddNewButtonDisabled(true);
        setEditButtonDisabled(true);
        setEditSelectedMarket(market);
        setEditBrandName(selectedCompetitor[0].brandName);
        setEditDomainName(selectedCompetitor[0].domain);
        setErrorMessageObject({
            editBrandNameErrorMessage: '',
            editDomainNameErrorMessage: '',
            editMarketErrorMessage: '',
        });
    };

    const updateKeyword = () => {
        return new Promise(async (resolve, reject) => {
            const TYPE_COMPETITOR = 2;

            const payload = {
                brand_name: editBrandName,
                domain: editDomainName,
                client_domain: 0,
                market: generatePath('config', 'seogd-market', String(editSelectedMarket?.value)),
                type: generatePath('config', 'keyword-type', String(TYPE_COMPETITOR)),
            };

            try {
                const requestConfig: AxiosRequestConfig = {
                    method: 'PATCH',
                    url: generateUrlDetail('config', 'seogd-competitor-config', String(selectedCompetitor[0].id))!,
                    data: payload,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };

                const requestResponse = await axios(requestConfig);

                if (requestResponse.status !== 202) {
                    throw new Error('Status not 202');
                }
            } catch (error) {
                reject('Error making a connection to API.');
            }
            resolve('Competitors updated successfully.');
        });
    };

    const handleNavigateManageConnectionModal = () => {
        dispatch(removeModal());
    };

    const onUpdateClick = () => {
        if (!formValidator()) {
            return;
        }
        setIsUpdating(true);
        updateKeyword()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'Competitors was successfully updated.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
                setIsUpdating(false);
                handleNavigateManageConnectionModal();
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue updating a Competitors.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const onSaveChangesClick = () => {
        setSaveChangesButtonLoading(true);
        const requests: AxiosPromise[] = [];

        if (deletedCompetitor.length > 0) {
            deletedCompetitor.forEach(competitor => {
                requests.push(
                    axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'seogd-competitor-config', String(competitor.id))!,
                        data: {
                            active: 0,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
        }

        axios
            .all(requests)
            .then(
                axios.spread((...res) => {
                    dispatch(
                        addNotification({
                            copy: 'These changes have been successfully saved.',
                            type: NotificationMessageType.Success,
                        })
                    );
                    setSaveChangesButtonLoading(false);
                    dispatch(removeModal());
                })
            )
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue trying to save your changes. Please try again later or contact Cubed Support.',
                        type: NotificationMessageType.Error,
                    })
                );
                setSaveChangesButtonLoading(false);
            });
    };

    const renderModalNavigation = () => {
        let numberOfChanges = updatedCompetitor.length + deletedCompetitor.length;
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} count={numberOfChanges} />;
    };

    const renderAccordion = () => {
        if (editConnectionAccordionVisible) {
            const accordions = [
                {
                    header: 'Edit Competitors',
                    required: false,
                    open: editConnectionAccordionOpen,
                    type: 'form',
                    intro: '',
                    config: {
                        formConfig: {
                            fields: [
                                {
                                    label: 'Brand Name:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'The brand name of your competitor',
                                    inputKeyValue: 'editBrandName',
                                    inputValue: editBrandName,
                                    inputOnChange: handleEditBrandName,
                                    errorMessage: errorMessageObject.editBrandNameErrorMessage,
                                },
                                {
                                    label: 'Domain Name:',
                                    type: 'text',
                                    requiredField: true,
                                    toolTipCopy: 'The domain of your competitor in format competitor.com.',
                                    inputKeyValue: 'editDomainName',
                                    inputValue: editDomainName,
                                    inputOnChange: handleEditDomainName,
                                    errorMessage: errorMessageObject.editDomainNameErrorMessage,
                                },
                                {
                                    label: 'Market:',
                                    type: 'select',
                                    requiredField: true,
                                    toolTipCopy: 'Select a Market',
                                    inputKeyValue: 'market',
                                    inputValue: editSelectedMarket?.value,
                                    inputOptions: marketList,
                                    inputOnChange: handleMarket,
                                    errorMessage: errorMessageObject.editMarketErrorMessage,
                                },
                            ],
                            buttons: [
                                {
                                    value: 'UPDATE',
                                    onClick: onUpdateClick,
                                    disabled: updateButtonDisabled,
                                    isLoading: isUpdating,
                                },
                            ],
                        },
                    },
                },
            ];

            return <WidgetAccordion accordions={accordions} />;
        }
    };

    const renderTable = () => {
        const errorMessageOverride = 'No Competitors is added for this account.';

        const header = {
            columns: [
                {
                    title: 'Brand Name',
                },
                {
                    title: 'Domain',
                },
                {
                    title: 'Market',
                },
            ],
        };

        const rows = competitors.map(competitor => {
            const rowProperty = {
                selected: selectedCompetitor.includes(competitor),
                deleted: deletedCompetitor.includes(competitor),
                edited: updatedCompetitor.includes(competitor),
                disabled: disableSelectedCompetitor,
            };

            return {
                onClick: () => onCompetitorSelect(competitor),
                key: `competitor__${competitor.id}`,
                dataValue: competitor.id,
                rowProperty,
                columns: [
                    {
                        copy: competitor.brandName,
                    },
                    {
                        copy: competitor.domain,
                    },
                    {
                        copy: competitor.market.country,
                    },
                ],
            };
        });

        return (
            <SimpleTable
                header={header}
                rows={rows}
                errorMessageOverride={errorMessageOverride}
                hasIcons={true}
                isScrollable={true}
            />
        );
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Competitors</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Manage Competitors</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Manage Competitors</h2>
            <p>You can add and manage Competitors from here. Click to Add New to add Brand Name, Domain and Market.</p>
            <div className="">
                {renderTable()}
                <InputButtonWrapper>
                    <InputButton value="Add New" disabled={addNewButtonDisabled} onClick={handleAddNew} />
                    <InputButton value="EDIT" disabled={editButtonDisabled} onClick={onEditClick} />
                    <InputButton
                        buttonTheme={ButtonThemes.Red}
                        value={removeButtonState ? 'REMOVE' : 'UNDO REMOVE'}
                        disabled={removeButtonDisabled}
                        onClick={onDeleteConnection}
                    />
                </InputButtonWrapper>
            </div>
            {renderAccordion()}
        </div>
    );
};

export default LayoutManageCompetitors;
