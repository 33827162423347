export type CubedResource = {
    category: string;
    id: string;
    displayName: string;
    dateDimension: CubedField;
    defaultDimensions?: CubedField[];
    dimensions?: CubedField[];
    defaultOrderBy?: CubedField;
    forceDefaultOrderDir?: boolean;
    forceDefaultOrderBy?: boolean;
    graphGroupByOverride?: CubedField[];
};

export type CubedConfigResource = {
    category: string;
    id: string;
};

export type CubedAdvancedRuleResource = {
    name: string;
    category: string;
};

export type CubedFieldHeatmapRange = [number, number];

export type CubedFieldHeatmap = {
    default?: [number, string];
    red?: CubedFieldHeatmapRange;
    green?: CubedFieldHeatmapRange;
    amber?: CubedFieldHeatmapRange;
};

export type CubedField = {
    id: string;
    rawName: string;
    displayName: string;
    dataType: number;
    defaultOrderDir?: string;
    description?: string;
    isDimension?: boolean;
    fixedWidth?: number;
    displayProportion?: boolean;
    lookupTerm?: string;
    heatmap?: CubedFieldHeatmap;
    reverseHeatmap?: boolean;
    reverseVariance?: boolean;
    alwaysShowPercentages?: boolean;
    flipYAxis?: boolean;
    ignoreTotal?: boolean;
};

export type CubedAnnotationResource = {
    category: string;
    id: string;
    displayName: string;
    type: number;
};

export enum annotation_types {
    CUBED_TAG = 1,
    GOOGLE_ALGORITHM = 2,
}

export type DropdownOption = {
    label: string;
    value: number | string;
    name?: string;
};

export type TableFields = CubedField & {
    sortBy?: boolean;
    sort?: string;
    metricWidth?: string | number;
};

// Tastypie response types

export type ReportValue = {
    raw_value?: string | number;
    value?: string;
    heatmap?: string | number;
    colour?: string | number;
    subchannels?: string[];
};

export type ReportValues = { [id: string]: ReportValue };

export type ChannelSubsectionConfig = {
    tier2: CubedField;
    tier3: CubedField;
    tier4: CubedField;
    resource: CubedResource;
};
