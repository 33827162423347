import React from 'react';
import styled from 'styled-components';

const StyledFormField = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

type FormFieldProps = {
    children: React.ReactNode;
};

const FormField = ({ children }: FormFieldProps) => {
    return <StyledFormField>{children}</StyledFormField>;
};

export default FormField;
