import axios from 'axios';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CubedConfigResource } from '../../types';
import { CSV_UPLOAD_RESOURCE } from '../../configurations/resources-config';
import generateQueryKey from '../helpers/generate-query-key';
import { generateUrl, generateUrlDetail } from '../../helpers/request-builder';
import useFetchResource from './use-fetch-resource';
import { RootState } from '../../redux/store';
import { ConfigDataSuccess } from '../types';

type UsePostResourceArgs = {
    fileUploadProgressResource: CubedConfigResource;
    formData: FormData | null;
};

const useCSVUploadResource = ({ fileUploadProgressResource, formData }: UsePostResourceArgs) => {
    const [isUploading, setIsUploading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [uploadError, setUploadError] = useState(false);
    const [uploadProgress, setUploadProgress] = useState('0.00');
    const [pageError, setPageError] = useState(false);
    const [returningMessage, setReturningMessage] = useState('');
    const [uploadComplete, setUploadComplete] = useState(false);

    const queryClient = useQueryClient();
    const uploadQueryKey = generateQueryKey({ resource: CSV_UPLOAD_RESOURCE });
    const [fileUploadId, setFileUploadId] = useState<string | null>(null);

    const accountId = useSelector((state: RootState) => state.account.id);
    const accountToken = useSelector((state: RootState) => state.account.token);

    const fileUploadAlreadyInProgressParams = [
        { key: 'file_upload__account_id', value: accountId },
        { key: 'file_upload__processing', value: 1 },
    ];
    const fileUploadProcessingParams = [
        { key: 'file_upload__account_id', value: accountId },
        { key: 'file_upload__id', value: fileUploadId || '' },
    ];
    const fileUploadProgressQueryKey = generateQueryKey({
        resource: fileUploadProgressResource,
        params: fileUploadProcessingParams,
    });

    const resetStateFunction = () => {
        setIsUploading(false);
        setUploadError(false);
        setUploadProgress('0.00');
        setPageError(false);
        setReturningMessage('');
        setUploadComplete(false);
    };

    useEffect(() => {
        axios
            .get(
                generateUrl(
                    fileUploadProgressResource.category,
                    fileUploadProgressResource.id,
                    fileUploadAlreadyInProgressParams
                ),
                { withCredentials: true }
            )
            .then(response => {
                setIsLoading(false);

                if (response.data.objects.length > 0) {
                    setIsUploading(true);
                    setReturningMessage('There is currently a file upload in progress for this account.');
                    setFileUploadId(response.data.objects[0].file_upload.id);
                    setUploadProgress(response.data.objects[0].file_upload.progress);
                }
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        mutation.reset();
        queryClient.invalidateQueries({
            queryKey: fileUploadProgressQueryKey,
        });
        resetStateFunction();
    }, [formData]); // eslint-disable-line react-hooks/exhaustive-deps

    const mutation = useMutation({
        mutationFn: async () => {
            if (formData) {
                return axios({
                    method: 'POST',
                    url: generateUrlDetail(
                        accountToken,
                        CSV_UPLOAD_RESOURCE.category,
                        CSV_UPLOAD_RESOURCE.id,
                        [],
                        false
                    )!,
                    data: formData,
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .catch(error => {
                        if (error.response.data.includes('Duplicate entry')) {
                            throw new Error('duplicate entry');
                        }
                        throw new Error(error.response.data.message);
                    })
                    .finally(() => {
                        queryClient.invalidateQueries({
                            queryKey: uploadQueryKey,
                        });
                    });
            }
        },
        onSuccess: response => {
            if (response?.data?.file_upload_id) {
                setIsUploading(true);
                setUploadProgress('0.00');
                setReturningMessage('There is currently a file upload in progress for this account.');
                setPageError(false);
                setUploadError(false);
                setFileUploadId(response.data.file_upload_id);
            }
        },
        onError: error => {
            console.error('error', error.message);
            setUploadError(true);
            setIsUploading(false);
            setPageError(true);
        },
    });
    if (!isUploading && formData && uploadProgress !== '100.00' && mutation.isIdle) {
        mutation.mutate();
    }

    const fileUploadProgressQuery = useFetchResource<ConfigDataSuccess>({
        resource: fileUploadProgressResource,
        params: fileUploadProcessingParams.map(param => ({
            key: param.key,
            value: param.value ?? '',
        })),
        enabled: isUploading && fileUploadId !== null,
        refetchInterval: isUploading && 2000,
        staleTime: 0,
    });

    const fileUploadProgressResponse = {
        data: fileUploadProgressQuery.data?.objects.map(item => {
            return {
                id: item.file_upload.id,
                progress: item.file_upload.progress,
            };
        }),
        isLoading: fileUploadProgressQuery.isLoading,
    };

    if (fileUploadProgressResponse.data && fileUploadProgressResponse.data.length > 0 && isUploading) {
        if (uploadProgress !== '100.00') {
            const progress = fileUploadProgressResponse.data[0]?.progress ?? '0.00';
            if (progress !== uploadProgress) {
                setUploadProgress(progress);
            }
            if (progress === '100.00') {
                setIsUploading(false);
                setUploadComplete(true);
                setReturningMessage('The file upload was successful. Please click Done to go to previous page.');
                setFileUploadId(null);
                setPageError(false);
                setUploadError(false);
            }
        }
    }

    return {
        isLoading,
        isUploading,
        uploadComplete,
        uploadProgress,
        uploadError,
        pageError,
        returningMessage,
        resetStateFunction,
    };
};

export default useCSVUploadResource;
