// Helpers
import { unknownErrorData } from '../../helpers/errors';
import { getRawValueAsNumber, getValue } from '../../helpers/resource-data';

// Types
import { CubedField } from '../../../types';
import { ResourceData, BarGraphData } from '../../types';
import { graphColours } from '../../helpers/graph-colours';

export type UseResourceBarGraphArgs = {
    category: CubedField;
    metric: CubedField;
    resourceData: ResourceData;
};

const useResourceBarGraph = ({ category, metric, resourceData }: UseResourceBarGraphArgs): BarGraphData => {
    if (resourceData.status === 'loading' || resourceData.status === 'empty' || resourceData.status === 'error') {
        return resourceData;
    }

    if (resourceData.status === 'success') {
        const bars = resourceData.objects.map((data, index) => {
            return {
                __id: data.__id,
                name: getValue(data, category.rawName),
                value: getRawValueAsNumber(data, metric.rawName),
                colour: graphColours[index % graphColours.length].solidGraphColour,
            };
        });

        return {
            type: 'barGraph',
            status: 'success',
            metric: metric.displayName,
            bars: bars,
        };
    }

    return unknownErrorData();
};

export default useResourceBarGraph;
