import React from 'react';
import { Moment } from 'moment';
import { CubedField, CubedResource } from '../types';
import { ConfigDataSuccess, ResourceDataSuccess } from '../react-query/types';
import { UseFetchResourceArgs } from '../react-query/hooks/use-fetch-resource';
import { UseFetchInfiniteResourceArgs } from '../react-query/hooks/use-fetch-infinite-resource';

// Section dashboard configuration

export type SectionDashboardComponents = {
    [key: string]: JSX.Element;
};

export type SectionDashboardLayoutConfig = {
    sections: SectionDashboardSectionConfig[];
};

export type SectionDashboardSectionConfig = {
    title?: string | (() => string);
    menu?: () => JSX.Element;
    collapsed?: boolean;
    beforeSection?: React.ReactNode;
    beforeGrid?: React.ReactNode;
    grid: string;
};

export type SectionDashboardConfig = {
    title: string;
    layouts: {
        xs: SectionDashboardLayoutConfig;
        sm: SectionDashboardLayoutConfig;
        md: SectionDashboardLayoutConfig;
        lg: SectionDashboardLayoutConfig;
        xl: SectionDashboardLayoutConfig;
    };
    components: () => SectionDashboardComponents;
};

export enum SectionDashboardLayout {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
}

export enum RequestStatus {
    LOADING = 'loading',
    EMPTY = 'empty',
    ERROR = 'error',
    SUCCESS = 'success',
}

// Base Data
export type DataType =
    | 'resource'
    | 'resourceComparison'
    | 'combineResource'
    | 'view'
    | 'viewComparison'
    | 'bigNumber'
    | 'bigNumberComparison'
    | 'barGraph'
    | 'barGraphComparison'
    | 'barNegativeStack'
    | 'vennResourceDimension'
    | 'vennResourceData'
    | 'venn'
    | 'vennComparison'
    | 'table'
    | 'tableComparison'
    | 'tableComparisonVarianceOnly'
    | 'tableComparisonVarianceOnlyTotals'
    | 'lineGraph'
    | 'wordCloud'
    | 'pieChart'
    | 'pieChartTable'
    | 'pieChartTableComparison'
    | 'sparkLine'
    | 'sparkLineComparison'
    | 'stackedAreaChart'
    | 'stackedAreaChartComparison'
    | 'funnelChart'
    | 'metricCount'
    | 'total'
    | 'packedBubbleChart'
    | 'totalComparison'
    | 'customTableGroups'
    | 'nestedDonutChart'
    | 'nestedDonutChartComparison'
    | 'mapBubble'
    | 'histogramChart';

// Date Ranges
export type UseResourceDateRangeFixed = {
    type: 'fixed';
    startDate: Moment;
    endDate: Moment;
};

export type UseResourceDateRangeNone = {
    type: 'none';
};

export type UseResourceDateRange = UseResourceDateRangeFixed | UseResourceDateRangeNone;

// Request Data (provided to useResource)
export enum FilterOperator {
    EQUALS = 'equals',
    GREATER_THAN = 'greaterThan',
    GREATER_THAN_OR_EQUALS = 'greaterThanOrEquals',
    LESS_THAN_OR_EQUALS = 'lessThanOrEquals',
    LESS_THAN = 'lessThan',
    ICONTAINS = 'icontains',
    ISTARTS_WITH = 'startsWith',
    IENDS_WITH = 'endsWith',
    IDOES_NOT_CONTAIN = 'iDoesNotContain',
    DOES_NOT_CONTAIN = 'doesNotContain',
    IN = 'in',
    IREGEX = 'iregex',
    BOOLEAN_TRUE = '1',
    BOOLEAN_FALSE = '0',
}

export type RequestSort = {
    field: CubedField;
    sortByVariance?: boolean;
    orderByDirection: string;
};

export type RequestFilter = {
    field: CubedField;
    operator?: FilterOperator;
    value: string;
};

export type Params = { key: string; value: string | number | boolean }[];

export type SectionDashboardRequest = {
    enabled?: boolean;
    showEmpty?: boolean;
    showLoading?: boolean;
    fields?: CubedField[];
    filters?: RequestFilter[];
    orFilters?: RequestFilter[];
    tableFilters?: RequestFilter[];
    sectionFilters?: RequestFilter[];
    pageFilters?: RequestFilter[];
    groupBy?: CubedField | CubedField[];
    orderBy?: RequestSort[];
    dateRange?: UseResourceDateRange;
    graphGroupBy?: CubedField[];
    isGraph?: boolean;
    graphMetrics?: CubedField[];
    selectedFields?: CubedField[];
    ignoreDate?: boolean;
    limit?: number;
    totals?: boolean;
    offset?: number;
    rowsPerPage?: number;
    setOrderBy?: (currentFilters: RequestSort[]) => void;
    setFilters?: (currentFilters: RequestFilter[]) => void;
    increaseOffset?: () => void;
    decreaseOffset?: () => void;
    jumpToFirst?: () => void;
    jumpToLast?: (totalPages: number) => void;
    yAxisOptions?: AreaYAxisOption[];
    handleSetYAxis?: (value: string) => void;
    excludeOrderBy?: CubedField[];
    chartField?: CubedField;
    setChartField?: (chartField: CubedField) => void;
    chartMetrics?: CubedField[];
};

// Resource data (provided by useResource)
export type SuccessData<T> = T & {
    type: DataType;
    status: 'success';
    request?: SectionDashboardRequest;
    resource?: CubedResource;
};

export type ErrorData = {
    type: DataType;
    status: 'error';
    request?: SectionDashboardRequest;
    error: {
        message: string;
    };
};

export type LoadingData = {
    type: DataType;
    status: 'loading';
    request?: SectionDashboardRequest;
};

export type EmptyData = {
    type: DataType;
    status: 'empty';
    request?: SectionDashboardRequest;
};

export type OptionalData<T> = SuccessData<T> | LoadingData | ErrorData | EmptyData;

export type ResourceData = OptionalData<ResourceDataSuccess>;

// Resource comparison data (provided by useResourceComparison)

export type ResourceComparisonDataSuccess = {
    resources: ResourceData[];
};

export type ResourceComparisonData = OptionalData<ResourceComparisonDataSuccess>;

// View data (provided by useView)
export type ViewDataSuccess = {
    type: DataType;
    series: FunnelChartSeries[];
    total: number;
};

export type ViewData = OptionalData<ViewDataSuccess>;

// View comparison data (provided by useViewComparison)
export type ViewComparisonDataSuccess = {
    resources: ViewData[];
};

export type ViewComparisonData = OptionalData<ViewComparisonDataSuccess>;

// Combined resource data (provided by useResourceCombineData)

// Pending Data

type OptionalCombinedData = ResourceData;

type CombinedResourceItemPending = {
    title?: string;
    description?: string;
    resourceData: OptionalCombinedData | ResourceComparisonData;
};

export type GenericResourcePendingData = {
    [key: string]: CombinedResourceItemPending;
};

export type CombinedPendingData = {
    requests: GenericResourcePendingData;
};

// Success/Empty Data

type CombinedResourceItemSuccess = {
    title: string;
    description?: string;
    resourceData: SuccessData<ResourceDataSuccess> | EmptyData;
};

export type CombinedSuccessData = {
    [key: string]: CombinedResourceItemSuccess;
};

export type CombinedResourceSuccess = {
    requests: CombinedSuccessData;
};

export type CombinedResourceData = OptionalData<CombinedResourceSuccess>;

// Big number data (provided by useResourceBigNumber)

export type BigNumberAggregation = 'avg' | 'min' | 'max' | 'sum' | 'ratio' | 'mix' | 'difference';

export type BigNumberFormatting = 'percentage' | 'currency';

export type BigNumberDecimalRounding = {
    applyRounding: boolean;
    decimalPoints?: number;
};

export type BigNumberSuccess = {
    title: string;
    series?: number[];
    value: number;
    ratioValue?: number;
    formatting?: BigNumberFormatting;
    rounding: BigNumberDecimalRounding;
};

export type BigNumberData = OptionalData<BigNumberSuccess>;

// Big number comparison data (provided by useResourceBigNumberComparison)

export type BigNumberComparisonSuccess = {
    title: string;
    series?: number[];
    comparisonSeries?: number[];
    value: number | null;
    comparisonValue: number | null;
    ratioValue?: number;
    comparisonRatioValue?: number;
    formatting?: BigNumberFormatting;
    rounding: BigNumberDecimalRounding;
};

export type BigNumberComparisonData = OptionalData<BigNumberComparisonSuccess>;

// Bar graph data (provided by useResourceBarGraph)

export type BarGraphBar = {
    __id: string;
    name: string;
    value: number;
    colour: string;
};

export type BarGraphSuccess = {
    metric: string;
    bars: BarGraphBar[];
};

export type BarGraphData = OptionalData<BarGraphSuccess>;

// Bar Graph comparison data (provided by useResourceBarGraphComparison)

export type BarGraphComparisonSuccess = {
    metric: string;
    bars: BarGraphBar[];
    barsComparison: BarGraphBar[];
};

export type BarGraphComparisonData = OptionalData<BarGraphComparisonSuccess>;

// Bar negative stack

export type BarNegativeStackRow = {
    name: string;
    positiveValue: number;
    negativeValue: number;
};

export type BarNegativeStackSuccess = {
    totalPositive: number;
    totalNegative: number;
    rows: BarNegativeStackRow[];
};
export type BarNegativeStackData = OptionalData<BarNegativeStackSuccess>;

// Venn data (provided by useResourceVenn)

export type VennSeriesObjectData = {
    sets: string[];
    value: number;
    color?: string;
    themeColour?: WidgetTheme;
};

export type VennSeriesObject = {
    type: 'venn';
    name: string;
    data: VennSeriesObjectData[];
};

export type VennDataSuccess = {
    series: VennSeriesObject;
    title: {
        text: string;
    };
};

export type VennData = OptionalData<VennDataSuccess>;

// Venn Comparison data (provided by useResourceVennComparison)

export type VennComparisonDataSuccess = {
    type: 'vennComparison';
    series?: VennSeriesObject;
    comparisonSeries?: VennSeriesObject;
    title: {
        text: string;
    };
};

export type VennComparisonData = OptionalData<VennComparisonDataSuccess>;

// Table data (provided by useResourceTable)

export type TableColumn = {
    __id: string;
    rawName: string;
    displayName: string;
    dataType?: number;
    isDimension?: boolean;
    isVariance?: boolean;
    ignoreTotal?: boolean;
    varianceField?: CubedField;
};

export type TableRowValue = {
    rawValue: number | string;
    value: string;
};

export type TableRow = {
    __id: string;
    values: Record<string, TableRowValue>;
    colour?: string;
    title?: string;
};

export type TableSuccess = {
    totalRows: number;
    columns: TableColumn[];
    rows: TableRow[];
};

export type TableData = OptionalData<TableSuccess>;

// Table data comparison (provided by useResourceTableComparison)

export type ComparisonTableRow = Omit<TableRow, '__id'>;

export type ComparisonTableSuccess = {
    totalRows: number;
    columns: TableColumn[];
    rows: TableComparisonTableRow[];
};

export type ComparisonTableData = OptionalData<ComparisonTableSuccess>;

// Total data (provided by useResourceTotal)

export type TotalValue = {
    rawValue: number | string;
    value: string;
};

export type TotalDataValue = { __id: string; values: Record<string, TotalValue> };

export type TotalSuccess = {
    totals: TotalDataValue;
};

export type TotalData = OptionalData<TotalSuccess>;

// Total Comparison data (provided by useResourceComparisonTotal)

export type TotalComparisonSuccess = {
    totals?: TotalDataValue;
    comparisonTotals?: TotalDataValue;
    varianceTotals?: TotalDataValue;
};

export type TotalComparisonData = OptionalData<TotalComparisonSuccess>;

// Pie data (provided by useResourcePieChart)

export type PieSeriesData = {
    name: string;
    value: number;
    color: string;
};

export type PieSeries = {
    name: string;
    data: PieSeriesData[];
    total: number;
};

export type PieDataSuccess = {
    series: PieSeries[];
};

export type PieData = OptionalData<PieDataSuccess>;

// Pie/Table data (provided by useResourcePieChartTable)

type PieRatioData = {
    value: number;
    colour: string;
};

export type PieRatio = {
    ratioLeft: PieRatioData;
    ratioRight: PieRatioData;
};

export type PieTableDataSuccess = {
    totalRows: number;
    series: PieSeries[];
    columns: TableColumn[];
    rows: TableRow[];
    ratio?: PieRatio;
    limit?: number;
};

export type PieTableData = OptionalData<PieTableDataSuccess>;

// Pie/Table comparison data (provided by useResourcePieChartTableComparison)

export type TableComparisonTableRow = {
    __id: string;
    dimension: ComparisonTableRow;
    data?: ComparisonTableRow;
    comparisonData?: ComparisonTableRow;
    variance?: ComparisonTableRow;
    colour?: string;
};

export type PieTableComparisonDataSuccess = {
    totalRows: number;
    series: PieSeries[];
    columns: TableColumn[];
    rows: TableComparisonTableRow[];
    comparisonSeries: PieSeries[];
    ratio?: PieRatio;
    comparisonRatio?: PieRatio;
    limit?: number;
};

export type PieTableComparisonData = OptionalData<PieTableComparisonDataSuccess>;

// Word Cloud data

export type WordCounterSeriesData = {
    name: string;
    weight: number;
};

export type WordCountSuccess = {
    words: WordCounterSeriesData[];
};

export type WordCountData = OptionalData<WordCountSuccess>;

// Line graph data (useResourceLineGraph)

export type LineGraphMarker = {
    enabled: boolean;
    fillColor: string;
};

export type LineGraphSeriesData = {
    x: number;
    y: number;
    marker?: LineGraphMarker;
};

export type LineGraphSeries = {
    name: string;
    data: LineGraphSeriesData[];
    total: number;
    colour: string;
};

export type LineGraphSuccess = {
    metric: string;
    series: LineGraphSeries[];
};

export type LineGraphData = OptionalData<LineGraphSuccess>;

export type LineGraphLegendOrderLastValue = {
    type: 'lastValue';
};

export type LineGraphLegendOrder = LineGraphLegendOrderLastValue | undefined;

// Spark line data (provided by useResourceSparkLine)

export type SparkLineSuccess = {
    metric: CubedField;
    series: number[][];
    mostRecentTotal?: number;
};

export type SparkLineData = OptionalData<SparkLineSuccess>;

// Spark line comparison data (provided by useResourceSparkLineComparison)

export type SparkLineComparisonSuccess = {
    metric: CubedField;
    series: number[][];
    comparisonSeries: number[][];
    mostRecentTotal?: number;
};

export type SparkLineComparisonData = OptionalData<SparkLineComparisonSuccess>;

// Stacked Area Chart data (useResourceStackedAreaChart)

export type OptionalSeriesConfig = {
    title: string;
    yAxis: CubedField;
    resourceData: ResourceData;
    colour: WidgetTheme;
};

export type OptionalSeriesComparisonConfig = Omit<OptionalSeriesConfig, 'resourceData'> & {
    resourceData: ResourceComparisonData;
};

export type StackedAreaChartSeriesData = {
    x: number;
    y: number;
};

export type StackedAreaChartOptionalSeries = {
    name: string;
    data: StackedAreaChartSeriesData[];
    chartType: string;
    colour: WidgetTheme;
    visible: boolean;
};

export type StackedAreaChartAreaSeries = StackedAreaChartOptionalSeries & {
    total: number;
};

export type AreaYAxisOption = { name: string; field: CubedField; active?: boolean };

export type StackedAreaChartSuccess = {
    areaTitle: string;
    areaSeriesMetric: CubedField;
    areaSeries: StackedAreaChartAreaSeries[];
    areaYAxisOptions: AreaYAxisOption[];
    optionalSeries?: StackedAreaChartOptionalSeries[];
};

export type StackedAreaChartData = OptionalData<StackedAreaChartSuccess>;

// Stacked Area Chart Comparison data (useResourceStackedAreaChartComparison)

export type StackedAreaChartComparisonSuccess = StackedAreaChartSuccess & {
    areaComparisonSeries: StackedAreaChartAreaSeries[];
    optionalComparisonSeries?: StackedAreaChartOptionalSeries[];
};

export type StackedAreaChartComparisonData = OptionalData<StackedAreaChartComparisonSuccess>;

// Funnel Chart data (useResourceFunnelChart)

export type FunnelChartViewSeries = {
    name: string;
    y: number;
    percent: number;
    description?: string;
};

export type FunnelChartSeries = FunnelChartViewSeries & {
    color: string;
};

export type FunnelVarianceData = {
    name: string;
    colour: string;
    variance: number | null;
};

export type FunnelChartSuccess = {
    series: FunnelChartSeries[];
    comparisonSeries?: FunnelChartSeries[];
    varianceData?: FunnelVarianceData[];
    total: number;
    name: string;
};

export type FunnelChartData = OptionalData<FunnelChartSuccess>;

// Metric data (provided by useResourceMetric)

export type MetricSuccess = {
    configuredMetricType: string;
    defaultMetricsList: { [key: string]: any }[];
    metricsCount: number;
};

export type MetricData = OptionalData<MetricSuccess>;

// Packed Bubble chart

export type PackedBubbleChartSeriesData = {
    name: string;
    value: number;
};

export type PackedBubbleChartSeries = {
    categoryName: string;
    data: PackedBubbleChartSeriesData[];
    colour?: string;
    name?: string;
    total: number;
};

export type PackedBubbleChartSuccess = {
    metric: string;
    series: PackedBubbleChartSeries[];
};

export type PackedBubbleChartData = OptionalData<PackedBubbleChartSuccess>;

// Nested Donut Chart Table
export type NestedDonutCategory = {
    title: string;
    field: CubedField;
    resourceData: ResourceData | ResourceComparisonData;
    showInGraph?: boolean;
};

export type NestedDonutColumn = {
    columnFields: CubedField[];
    columnConfig: {
        rawName: string;
        displayName: string;
        flags: string[];
    }[];
};

export type NestedDonutChartTableRow = {
    __id: string;
    values: { [key: string]: Record<string, TableRowValue> };
};

export type NestedDonutChartSeries = {
    name: string;
    data: number[];
};

export type NestedDonutChartSuccess = {
    categories: string[];
    series: NestedDonutChartSeries[];
    columns: TableColumn[];
    subColumns: TableColumn[];
    tableRow: NestedDonutChartTableRow;
};

export type NestedDonutChartData = OptionalData<NestedDonutChartSuccess>;

// Nested Donut Chart Table Comparison
export type NestedDonutChartComparisonSuccess = {
    categories: string[];
    series?: NestedDonutChartSeries[];
    comparisonSeries?: NestedDonutChartSeries[];
    columns: TableColumn[];
    subColumns: TableColumn[];
    row?: NestedDonutChartTableRow;
    comparisonRow?: NestedDonutChartTableRow;
    varianceRow?: NestedDonutChartTableRow;
};

export type NestedDonutChartComparisonData = OptionalData<NestedDonutChartComparisonSuccess>;

// Map Bubble
export enum MapBubblePointType {
    COUNTRIES,
    CITIES,
}
export type MapBubbleCountrySeries = {
    name: string;
    z: number;
    code: string;
};

export type MapBubbleCitySeries = {
    name: string;
    lat: number;
    lon: number;
    z: number;
};

export type MapBubbleSuccess = {
    metric: CubedField;
    pointType: MapBubblePointType;
    series: MapBubbleCountrySeries[] | MapBubbleCitySeries[];
};

export type MapBubbleData = OptionalData<MapBubbleSuccess>;

// Histogram Chart data (provided by useResourceHistogramChart)

export type HistogramChart = {
    __id: string;
    name: string;
    y: number;
    x?: number;
    colour?: string;
};

export type HistogramChartSuccess = {
    metric: string;
    series: HistogramChart[];
    histogramColour: string;
    scatterColour: string;
};

export type HistogramChartData = OptionalData<HistogramChartSuccess>;

// Histogram Chart Comparison data (provided by useResourceHistogramChart)

export type HistogramChartComparisonSuccess = {
    metric: string;
    series: HistogramChart[];
    comparisonSeries?: HistogramChart[];
    histogramColour: string;
    scatterColour: string;
};

export type HistogramChartComparisonData = OptionalData<HistogramChartComparisonSuccess>;

// Widgets

export type WidgetTheme = {
    gradientStart: string;
    gradientEnd: string;
    splineLine: string;
    splineGradientStart: string;
    splineGradientEnd: string;
    solidGraphColour: string;
};

export type WidgetDirection = 'horizontal' | 'vertical';

export type WidgetTrend = 'negative' | 'positive';

export type WidgetMenuRadioOption = {
    label: string;
    value: string;
};

export enum WidgetColour {
    Default,
    Red,
    Orange,
    Amber,
    Yellow,
    Lime,
    Green,
    Emerald,
    Teal,
    Cyan,
    Sky,
    Blue,
    Indigo,
    Violet,
    Purple,
    Fuchsia,
    Pink,
    Rose,
    Slate,
}

export enum WidgetDialogLocation {
    Footer,
    Dropdown,
}

export enum seriesColourBooleanTrue {
    name = 'True',
    colorCode = 1, // green
}

export enum seriesColourBooleanFalse {
    name = 'False',
    colorCode = 3, // red
}

// Widget Menus

export type DropdownOption = {
    label: string;
    value: string;
    selected?: boolean;
};

export enum WidgetMenuType {
    SELECT,
    CHECKBOX,
    SECTION,
}

// Widget Menu Select
export type WidgetMenuObj = {
    type: WidgetMenuType;
    menu: JSX.Element;
    request: SectionDashboardRequest;
    checked?: boolean;
};

export type SelectOptionsRequest = { status: RequestStatus; options: DropdownOption[] };

export type SelectQuery = UseFetchResourceArgs<ConfigDataSuccess | ResourceDataSuccess, DropdownOption[]>;

export type SelectInfiniteQuery = UseFetchInfiniteResourceArgs;

export type WidgetMenuSectionMenu = WidgetMenuObj & {
    status: RequestStatus;
};

export type WidgetMenuCollection = { title?: string; menus: WidgetMenuSectionMenu[] };
