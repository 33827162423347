import { isValidUrl } from './valid-url';

export type PageTypeGroupingCSVHeaders = [
    'category',
    'content_length',
    'content_type',
    'geography',
    'homepage',
    'language',
    'market',
    'topic',
    'url'
];

type PageTypeGroupingSegment = {
    category: string;
    content_length: string;
    content_type: string;
    geography: string;
    homepage: string;
    language: string;
    market: string;
    topic: string;
    url: string;
};

type ValidatePageTypeGroupingCsvFileResponse = {
    isValidated: boolean;
    fileValidationMessage: string;
};

export const validatePageTypeGroupingCsvFile = (
    headerData: PageTypeGroupingCSVHeaders,
    data: PageTypeGroupingSegment[],
    exportedUrlSet: Set<string>
): ValidatePageTypeGroupingCsvFileResponse => {
    const csvData = data;
    const sortedHeaders: PageTypeGroupingCSVHeaders = Object.keys(headerData).sort() as PageTypeGroupingCSVHeaders;

    let errorMessage = '';
    let continueErrorCheck = true;

    for (let i = 0; i < sortedHeaders.length && continueErrorCheck; i++) {
        const header = sortedHeaders[i];

        for (let j = 0; j < csvData.length && continueErrorCheck; j++) {
            const rowData: PageTypeGroupingSegment = csvData[j];
            let isError = false;

            if (rowData[header] === undefined) {
                continueErrorCheck = false;
                isError = true;
                errorMessage = `Field is empty for ${header}.`;
            } else if (header === 'url') {
                const url = rowData[header];

                if (!exportedUrlSet.has(url)) {
                    if (url === '') {
                        continueErrorCheck = false;
                        isError = true;
                        errorMessage = `The URL is empty`;
                    } else if (!isValidUrl(url)) {
                        continueErrorCheck = false;
                        isError = true;
                        errorMessage = `The URL, '${url}' you provided is not valid - it is either newly added or recently updated.`;
                    } else if (!rowData['market']) {
                        continueErrorCheck = false;
                        isError = true;
                        errorMessage = `The URL, '${url}' you provided is missing a required market value`;
                    }
                }
            }

            if (isError) {
                break;
            }
        }

        if (!continueErrorCheck) {
            break;
        }
    }

    if (errorMessage.length > 0) {
        return {
            isValidated: false,
            fileValidationMessage: `The file validation failed: ${errorMessage}.`,
        };
    }

    return {
        isValidated: true,
        fileValidationMessage: '',
    };
};

type ValidateFileTypeResponse = {
    isValid: boolean;
    selectedFile: null;
    message: string;
};

export const validateFileType = (file: File, fileType: 'csv'): ValidateFileTypeResponse => {
    const fileExtension = (file?.name.split('.').pop() ?? '').toLowerCase();

    let result = {
        isValid: true,
        selectedFile: null,
        message: '',
    };

    if (fileExtension !== fileType) {
        result = {
            isValid: false,
            selectedFile: null,
            message: 'Sorry, this file is not in CSV format. Please upload a CSV file.',
        };
    }

    return result;
};
