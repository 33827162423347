/* eslint-disable react-hooks/exhaustive-deps */
// React Dependencies
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FieldValues } from 'react-hook-form';

// Redux
import { RootState } from '../../redux/reducers/core';
import { useDispatch, useSelector } from 'react-redux';
import { removePopup, setPopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { setModal } from '../../redux/actions/modal';

// Layouts
import { ErrorModalLayout, ModalLayout } from './modal-layout';

// Components
import CSVDownload from '../../components/csv-download';
import ConfigTable from '../../components/tables/config-table/config-table';
import Form from '../../components/forms/form';
import { TooltipPopUpSide } from '../../components/tooltip';
import validators from '../../components/forms/validation/validators';
import { FormStatus } from '../../components/forms/context/form-context';

// Queries
import { ConfigDataSuccess } from '../../react-query/types';
import useFetchResource from '../../react-query/hooks/use-fetch-resource';
import useDeleteResource from '../../react-query/hooks/use-delete-resource';
import usePostMultiResourcesWithPayload from '../../react-query/hooks/use-post-multi-resource-with-payload';
import useDeleteResourceWithPayload from '../../react-query/hooks/use-delete-resource-with-payload';
import usePatchMultiResourceWithPayload from '../../react-query/hooks/use-patch-multi-resource-with-payload';

// Configurations
import {
    CONFIG_GOALS,
    CONFIG_LITE_EVENT,
    CONFIG_LITE_PRODUCT_EVENT,
    CONFIG_MARKET,
    CONFIG_PRODUCT_EVENT,
    CONFIG_PRODUCT_LOOKBACK,
    CONFIG_PRODUCT_TYPE,
    CONFIG_SEOGD_MARKET,
} from '../../configurations/resources-config';

// Enums
import { ButtonThemes } from '../../enums/button-themes';
import { NotificationMessageType } from '../../enums/notification-types';

// Helpers
import { generatePath } from '../../helpers/request-builder';

const StyledTextWrapper = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

const StyledFormContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding: 20px;
    margin-top: 20px;
`;

const StyledFormTitle = styled.h3`
    margin: 0 0 30px 0;
`;

const StyledEditEventContainer = styled.div`
    background-color: ${props => props.theme.colours.white};
    padding-top: 20px;
    margin-top: 20px;
`;

const StyledEventTitle = styled.h3`
    margin: 0 0 10px 10px;
`;

const StyledEventP = styled.p`
    margin: 0 0 30px 10px;
`;

type Goal = {
    id: number;
    name: string;
    productType: number;
    revenueOverride: number;
    default: boolean;
    hidden: boolean;
    assignedToMarket: boolean;
    assignedMarketList: Option[];
};

type GoalData = Goal & {
    selected: boolean;
};

type GoalDataToEdit = Goal;

type Option = {
    label: string;
    value: string;
    selected?: boolean;
};

type ProductTypeOption = Option;

type ProductMarketOption = Option;

type LiteEvent = {
    id: number;
    name: string;
    selected: boolean;
};

type Data = { [key: string]: string | number | boolean };

const LayoutModalManageGoals = () => {
    const dispatch = useDispatch();

    const { account } = useSelector((state: RootState) => state);

    const downloadParams = {
        params: [{ key: 'active', value: 1 }],
        resourceGroup: 'config',
        resourceName: 'product-csv',
    };

    // Table states
    const [disableTable, setDisableTable] = useState(false);
    const [disabledGoalDataToAssignTable, setDisabledGoalDataToAssignTable] = useState(false);

    // Goals states
    const [goalsData, setGoalsData] = useState<GoalData[]>([]);
    const [goalsDataToEdit, setGoalsDataToEdit] = useState<GoalDataToEdit>();
    const [initialGoalsDataToEdit, setInitialGoalsDataToEdit] = useState<GoalDataToEdit>();
    const [goalsDataToDelete, setGoalsDataToDelete] = useState<number[]>([]);
    const [goalDataIdToAssignSaleEvent, setGoalDataIdToAssignSaleEvent] = useState<number>();

    // Product Types states
    const [productTypesOptions, setProductTypesOptions] = useState<ProductTypeOption[]>([]);

    // Product Market states
    const [productMarketOptions, setProductMarketOptions] = useState<ProductMarketOption[]>([]);
    const [initialSelectedMarketList, setInitialSelectedMarketList] = useState<ProductMarketOption[]>([]);

    // Lite Event states
    const [initialProductLiteEventsData, setInitialProductLiteEventsData] = useState<Data[]>([]);
    const [liteEventsData, setLiteEventsData] = useState<LiteEvent[]>([]);

    // Form States
    const [formWatchedFields, setFormWatchedFields] = useState<FieldValues>({});

    // Queries & Mutations
    const goalsDataQuery = useFetchResource({
        resource: CONFIG_GOALS,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(goal => {
                return {
                    id: goal.id,
                    name: goal.name,
                    productType: goal.product_type.id,
                    revenueOverride: parseInt(goal.revenue_override, 10) / 100,
                    default: goal.default,
                    hidden: goal.hidden,
                    selected: false,
                    assignedToMarket: false,
                    assignedMarketList: [],
                };
            });
        },
    });

    const productTypesDataQuery = useFetchResource({
        resource: CONFIG_PRODUCT_TYPE,
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(productType => {
                return {
                    value: productType.id,
                    label: productType.name,
                    active: productType.active,
                    selected: false,
                };
            });
        },
    });

    const seogdMarketDataQuery = useFetchResource({
        resource: CONFIG_SEOGD_MARKET,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
            { key: 'order_by', value: 'country' },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(market => {
                return {
                    value: market.id,
                    label: market.country,
                };
            });
        },
    });

    const liteEventsDataQuery = useFetchResource({
        resource: CONFIG_LITE_EVENT,
        params: [{ key: 'active', value: 1 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(event => {
                return {
                    id: event.id,
                    name: event.display_name !== '' ? event.display_name : event.name,
                    selected: false,
                };
            });
        },
    });

    const productMarketDataQuery = useFetchResource({
        resource: CONFIG_MARKET,
        enabled: initialGoalsDataToEdit ? true : false,
        params: [
            { key: 'active', value: 1 },
            { key: 'limit', value: 0 },
            { key: 'product', value: initialGoalsDataToEdit ? initialGoalsDataToEdit.id : 0 },
        ],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(market => {
                return {
                    value: market.market.id,
                    label: market.market.country,
                };
            });
        },
    });

    const liteProductEventDataQuery = useFetchResource({
        resource: CONFIG_LITE_PRODUCT_EVENT,
        enabled: goalDataIdToAssignSaleEvent ? true : false,
        params: [{ key: 'product', value: goalDataIdToAssignSaleEvent ? goalDataIdToAssignSaleEvent : 0 }],
        select: (data: ConfigDataSuccess) => {
            return data.objects.map(liteProduct => {
                return {
                    id: liteProduct.id,
                    active: liteProduct.active,
                    eventId: liteProduct.event.id,
                    sale: liteProduct.sale,
                    name:
                        liteProduct.event.display_name !== '' ? liteProduct.event.display_name : liteProduct.event.name,
                };
            });
        },
    });

    const goalsDataPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_GOALS,
        handleOnSuccess: () => {
            setGoalsDataToEdit(undefined);
            setInitialGoalsDataToEdit(undefined);
            setDisableTable(false);
            setGoalsData(
                goalsData.map(goal => {
                    return {
                        ...goal,
                        selected: false,
                    };
                })
            );

            dispatch(
                addNotification({
                    copy: 'Goals updated successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while saving your goals. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const productMarketDataAddMutation = usePostMultiResourcesWithPayload({
        resource: CONFIG_MARKET,
        handleOnSuccess: () => {
            setGoalsDataToEdit(undefined);
            setInitialGoalsDataToEdit(undefined);
            setDisableTable(false);
            setGoalsData(
                goalsData.map(goal => {
                    return {
                        ...goal,
                        selected: false,
                    };
                })
            );

            dispatch(
                addNotification({
                    copy: 'Goal Markets Added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while adding your Goal Market. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const productEventDataAddMutation = usePostMultiResourcesWithPayload({
        resource: CONFIG_PRODUCT_EVENT,
        handleOnSuccess: () => {
            liteProductEventDataQuery.refetch();

            setGoalDataIdToAssignSaleEvent(undefined);
            setDisableTable(false);
            setDisabledGoalDataToAssignTable(false);
            setGoalsData(
                goalsData.map(goal => {
                    return {
                        ...goal,
                        selected: false,
                    };
                })
            );

            dispatch(
                addNotification({
                    copy: 'Goal Markets Added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while adding your Goal Market. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const productEventDataPatchMutation = usePatchMultiResourceWithPayload({
        resource: CONFIG_PRODUCT_EVENT,
        handleOnSuccess: () => {
            liteProductEventDataQuery.refetch();

            setGoalDataIdToAssignSaleEvent(undefined);
            setDisableTable(false);
            setDisabledGoalDataToAssignTable(false);
            setGoalsData(
                goalsData.map(goal => {
                    return {
                        ...goal,
                        selected: false,
                    };
                })
            );

            dispatch(
                addNotification({
                    copy: 'Goal Markets Added successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while adding your Goal Market. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const productMarketDataDeleteMutation = useDeleteResourceWithPayload({
        resource: CONFIG_MARKET,
        handleOnSuccess: () => {
            setGoalsDataToEdit(undefined);
            setInitialGoalsDataToEdit(undefined);
            setDisableTable(false);
            setGoalsData(
                goalsData.map(goal => {
                    return {
                        ...goal,
                        selected: false,
                    };
                })
            );

            dispatch(
                addNotification({
                    copy: 'Goal Markets deleted successfully.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your Goal Markets. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const goalsDataDeleteMutation = useDeleteResource({
        resource: CONFIG_GOALS,
        resourceIds: goalsDataToDelete.map(id => id.toString()),
        handleOnSuccess: () => {
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'Goals successfully deleted.',
                    type: NotificationMessageType.Success,
                })
            );
        },
        handleOnError: () => {
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your goals. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const productLookbackDataDeleteMutation = useDeleteResourceWithPayload({
        resource: CONFIG_PRODUCT_LOOKBACK,
        handleOnSuccess: () => {
            setDisableTable(false);
        },
        handleOnError: () => {
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your Product Lookback. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    const productEventDataDeleteMutation = useDeleteResourceWithPayload({
        resource: CONFIG_PRODUCT_EVENT,
        handleOnSuccess: () => {
            setDisableTable(false);
        },
        handleOnError: () => {
            setDisableTable(false);
            dispatch(
                addNotification({
                    copy: 'There was an issue while deleting your goals. Please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    // Fetch Goals
    useEffect(() => {
        if (goalsDataQuery.data) {
            setGoalsData(goalsDataQuery.data);
        }
    }, [goalsDataQuery.data]);

    // Fetch Product Types
    useEffect(() => {
        if (productTypesDataQuery.data) {
            const productTypes = productTypesDataQuery.data.filter(productType => productType.active === true);
            setProductTypesOptions(productTypes);
        }
    }, [productTypesDataQuery.data]);

    // Fetch Seogd Market
    useEffect(() => {
        if (seogdMarketDataQuery.data) {
            setProductMarketOptions(seogdMarketDataQuery.data);
        }
    }, [seogdMarketDataQuery.data]);

    // Fetch Product Market
    useEffect(() => {
        if (initialGoalsDataToEdit && productMarketDataQuery.data) {
            const selectedMarket: Option[] = productMarketDataQuery.data;

            const goalData = {
                ...initialGoalsDataToEdit,
                assignedToMarket: selectedMarket.length > 0,
                assignedMarketList: selectedMarket,
            };

            setInitialSelectedMarketList(selectedMarket);

            setGoalsDataToEdit(goalData);
        }
    }, [productMarketDataQuery.data, initialGoalsDataToEdit]);

    // Fetch Lite Event
    useEffect(() => {
        if (liteEventsDataQuery.data) {
            setLiteEventsData(liteEventsDataQuery.data);
        }
    }, [liteEventsDataQuery.data]);

    // Fetch Lite Product Event
    useEffect(() => {
        if (liteProductEventDataQuery.data) {
            if (liteProductEventDataQuery.data.length > 0) {
                const selectedEvent = liteEventsData.map(item => {
                    const isSelected = liteProductEventDataQuery.data
                        .filter(event => event.sale)
                        .some(event => event.eventId === item.id);
                    return {
                        ...item,
                        selected: isSelected,
                    };
                });

                setLiteEventsData(selectedEvent);
                setInitialProductLiteEventsData(liteProductEventDataQuery.data);
            }
        }
    }, [liteProductEventDataQuery.data]);

    useEffect(() => {
        setGoalsDataToDelete(goalsData.filter(goal => goal.selected).map(goal => goal.id));
    }, [goalsData]);

    // Goal Table Handlers
    const handleCheckboxChange = (rowId: number) => {
        setGoalsData(
            goalsData.map(goal => {
                if (goal.id === rowId) {
                    return {
                        ...goal,
                        selected: !goal.selected,
                    };
                }
                return goal;
            })
        );
    };

    const handleEditGoalClick = (rowId: number) => {
        const goal = goalsData.find(goal => goal.id === rowId);

        if (goal) {
            const goalData = {
                id: goal.id,
                name: goal.name,
                productType: goal.productType,
                revenueOverride: goal.revenueOverride,
                default: goal.default,
                hidden: goal.hidden,
                assignedToMarket: false,
                assignedMarketList: [],
            };
            setInitialGoalsDataToEdit(goalData);
            setFormWatchedFields(goalData);
            setDisableTable(true);
            setGoalDataIdToAssignSaleEvent(undefined);
        }
    };

    const handleAssignSaleEventClick = (rowId: number) => {
        setGoalDataIdToAssignSaleEvent(rowId);
        setGoalsDataToEdit(undefined);
        setInitialGoalsDataToEdit(undefined);
        setDisableTable(true);
    };

    // Goal Delete
    const handleSingleGoalDeleteClick = (rowId: number) => {
        setGoalsDataToDelete([rowId]);

        const handleDisardClick = () => {
            setGoalsDataToDelete([]);
            dispatch(removePopup());
        };

        const handleDelete = () => {
            const goalsDataDeletePayload = [{ key: 'product', value: rowId }];

            setDisableTable(true);
            goalsDataDeleteMutation.mutate();
            productLookbackDataDeleteMutation.mutate([goalsDataDeletePayload]);
            productEventDataDeleteMutation.mutate([goalsDataDeletePayload]);

            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete this goal?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    // Goal Bulk Delete
    const handleBulkDeleteClick = () => {
        if (goalsDataToDelete.length === 0) return;

        const handleDisardClick = () => {
            dispatch(removePopup());
        };

        const handleDelete = () => {
            setGoalsData(
                goalsData.map(data => {
                    return {
                        ...data,
                        selected: false,
                    };
                })
            );

            const goalsDataDeletePayload = goalsDataToDelete.map(id => {
                return [{ key: 'product', value: id }];
            });

            setDisableTable(true);
            goalsDataDeleteMutation.mutate();
            productLookbackDataDeleteMutation.mutate(goalsDataDeletePayload);
            productEventDataDeleteMutation.mutate(goalsDataDeletePayload);
            dispatch(removePopup());
        };

        dispatch(
            setPopup({
                title: 'Delete',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: `Are you sure you would like to delete the selected goals?`,
                },
                buttons: [
                    {
                        onClick: handleDelete,
                        value: 'YES, DELETE',
                    },
                    {
                        onClick: handleDisardClick,
                        value: 'CANCEL',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            })
        );
    };

    // Goal Edit Form Handlers
    const handleEditFormCloseClick = () => {
        // Reset Goals
        setGoalsData(goalsData.map(goal => ({ ...goal, selected: false })));
        setGoalsDataToEdit(undefined);
        setInitialGoalsDataToEdit(undefined);
        setFormWatchedFields([]);

        // Reset Table
        setDisableTable(false);
    };

    const handleSubmitForm = (data: FieldValues) => {
        const formData = data;

        if (formData && goalsDataToEdit) {
            setGoalsDataToEdit(undefined);
            setInitialGoalsDataToEdit(undefined);
            setFormWatchedFields([]);

            if (
                formData.default !== goalsDataToEdit.default ||
                formData.name !== goalsDataToEdit.name ||
                formData.productType !== goalsDataToEdit.productType ||
                Number(formData.revenueOverride) !== Number(goalsDataToEdit.revenueOverride)
            ) {
                const payload: FieldValues = {};
                payload.resource_uri = generatePath('config', 'product', goalsDataToEdit.id.toString());
                payload.name = formData.name;
                payload.display_name = formData.name;
                payload.product_type = generatePath('config', 'product-type', formData.productType);
                payload.revenue_override = Number(formData.revenueOverride) * 100;
                payload.default =
                    parseInt(formData.productType, 10) === 4 || parseInt(formData.productType, 10) === 5
                        ? false
                        : formData.default;

                goalsDataPatchMutation.mutate({ payload: [payload], resourceId: [goalsDataToEdit.id.toString()] });
            }

            if (formData.assignedToMarket && formData.assignedMarketList) {
                const initialValues = initialSelectedMarketList.map(market => market.value);
                const assignedValues = formData.assignedMarketList.map((market: Option) => market.value);

                const newlyAddedMarketList = formData.assignedMarketList.filter(
                    (item: Option) => !initialValues.includes(item.value)
                );
                const deletedMarketList = initialSelectedMarketList.filter(
                    (item: Option) => !assignedValues.includes(item.value)
                );

                if (newlyAddedMarketList.length > 0) {
                    const selectedMarketListPayload = newlyAddedMarketList.map((market: Option) => {
                        return {
                            product: generatePath('config', 'product', formData.id),
                            market: generatePath('config', 'seogd-market', market.value),
                        };
                    });
                    productMarketDataAddMutation.mutate(selectedMarketListPayload);
                }

                if (deletedMarketList.length) {
                    const deletedMarketListPayload = deletedMarketList.map((market: Option) => {
                        return [
                            { key: 'market', value: market.value },
                            { key: 'product', value: formData.id },
                        ];
                    });
                    productMarketDataDeleteMutation.mutate(deletedMarketListPayload);
                }
            } else if (!formData.assignedToMarket) {
                const deletedMarketListPayload = initialSelectedMarketList.map((market: Option) => {
                    return [
                        { key: 'market', value: market.value },
                        { key: 'product', value: formData.id },
                    ];
                });

                if (deletedMarketListPayload.length > 0) {
                    productMarketDataDeleteMutation.mutate(deletedMarketListPayload);
                }
            }
        }
    };

    // Create Goal Handler
    const handleCreateGoalClick = () => {
        dispatch(setModal('CreateGoalWizard', {}));
    };

    // Assign Sale Event Handlers
    const handleEventSaleCheckboxChange = (rowId: number) => {
        setLiteEventsData(
            liteEventsData.map(event => {
                if (event.id === rowId) {
                    return {
                        ...event,
                        selected: !event.selected,
                    };
                }

                return event;
            })
        );
    };

    const handleEventSaleApplyClick = () => {
        // Newly added events
        const newlyAddedEventList = liteEventsData.filter(
            event =>
                event.selected && !initialProductLiteEventsData.some(initialEvent => initialEvent.eventId === event.id)
        );

        // Updated events
        const updatedEventList: Data[] = [];
        initialProductLiteEventsData.forEach(initialEvent => {
            const correspondingEvent = liteEventsData.find(event => event.id === initialEvent.eventId);
            if (correspondingEvent && correspondingEvent.selected !== initialEvent.active) {
                updatedEventList.push({
                    id: initialEvent.id,
                    eventId: initialEvent.eventId,
                    active: correspondingEvent.selected,
                    sale: correspondingEvent.selected,
                });
            }
        });

        if (newlyAddedEventList.length > 0 || updatedEventList.length > 0) {
            setDisabledGoalDataToAssignTable(true);

            if (newlyAddedEventList.length > 0) {
                const newlyAddedEventListPayload = newlyAddedEventList.map(event => {
                    return {
                        event: generatePath('config', 'event', event.id.toString()),
                        product: generatePath('config', 'product', goalDataIdToAssignSaleEvent?.toString()),
                        weight: 1,
                        active: event.selected,
                        sale: event.selected,
                    };
                });
                productEventDataAddMutation.mutate(newlyAddedEventListPayload);
            }

            if (updatedEventList.length > 0) {
                const updatedEventListPayload = updatedEventList.map(event => {
                    return {
                        active: event.active,
                        sale: event.sale,
                    };
                });
                const updatedEventListIds = updatedEventList.map(event => event.id.toString());
                productEventDataPatchMutation.mutate({
                    payload: updatedEventListPayload,
                    resourceId: updatedEventListIds,
                });
            }
        }
    };

    const handleEventSaleCancelClick = () => {
        setGoalDataIdToAssignSaleEvent(undefined);
        setInitialProductLiteEventsData([]);
        setLiteEventsData(liteEventsData.map(event => ({ ...event, selected: false })));

        // Reset Table
        setDisableTable(false);
        setDisabledGoalDataToAssignTable(false);
    };

    // Watch Fields Change
    const handleWatchFieldsChange = (fields: FieldValues) => {
        setFormWatchedFields(fields);
    };

    if (goalsDataQuery.status === 'success' || goalsDataQuery.status === 'pending') {
        return (
            <ModalLayout modalHeader="Manage Goals">
                <StyledTextWrapper>
                    <p>
                        Manage and edit existing goals. You can also select multiple goals to delete. For more help see{' '}
                        <a href="https://tag.docs.withcubed.com/onboarding/general/#goals">goals</a>
                    </p>
                    <CSVDownload
                        params={downloadParams}
                        filename={`${account.token}-goals`}
                        disableDropdown={true}
                        noData={goalsData.length === 0}
                    />
                </StyledTextWrapper>

                {/* Goals Table */}
                <ConfigTable
                    status={goalsDataQuery.status}
                    isFetching={goalsDataQuery.isFetching}
                    disabled={disableTable}
                    empty={goalsData.length === 0}
                >
                    <ConfigTable.Table maxHeight="400px">
                        <ConfigTable.Header>
                            <ConfigTable.Row key="thead">
                                <ConfigTable.CellHeader />
                                <ConfigTable.CellHeader>Goal Name</ConfigTable.CellHeader>
                                <ConfigTable.CellHeader />
                            </ConfigTable.Row>
                        </ConfigTable.Header>
                        <ConfigTable.Body>
                            {goalsData.map(goal => {
                                return (
                                    <ConfigTable.Row key={goal.id}>
                                        <ConfigTable.CellCheckbox
                                            rowId={goal.id}
                                            checked={goal.selected}
                                            onCheckedChange={handleCheckboxChange}
                                        />
                                        <ConfigTable.Cell>{goal.name}</ConfigTable.Cell>
                                        <ConfigTable.CellActions>
                                            <ConfigTable.ActionDropdownItem
                                                rowId={goal.id}
                                                type="edit"
                                                onClick={handleEditGoalClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={goal.id}
                                                type="edit-secondary"
                                                label="Assign Sale Event"
                                                onClick={handleAssignSaleEventClick}
                                            />
                                            <ConfigTable.ActionDropdownItem
                                                rowId={goal.id}
                                                type="delete"
                                                onClick={handleSingleGoalDeleteClick}
                                            />
                                        </ConfigTable.CellActions>
                                    </ConfigTable.Row>
                                );
                            })}
                        </ConfigTable.Body>
                    </ConfigTable.Table>
                    <ConfigTable.ActionBar>
                        <ConfigTable.ActionBarJustifyLeft>
                            <ConfigTable.ActionButton
                                type="delete"
                                label="Delete Goals"
                                onClick={handleBulkDeleteClick}
                                isDisabled={goalsDataToDelete.length === 0}
                            />
                        </ConfigTable.ActionBarJustifyLeft>
                        <ConfigTable.ActionBarJustifyRight>
                            <ConfigTable.ActionButton
                                type="add"
                                label="Create New Goal"
                                onClick={handleCreateGoalClick}
                                isDisabled={disableTable}
                            />
                        </ConfigTable.ActionBarJustifyRight>
                    </ConfigTable.ActionBar>
                </ConfigTable>

                {/* Edit form */}
                {initialGoalsDataToEdit && (
                    <StyledFormContainer>
                        <StyledFormTitle>Edit Goals - {initialGoalsDataToEdit.name}</StyledFormTitle>
                        <Form
                            status={productMarketDataQuery.status as FormStatus}
                            onSubmit={handleSubmitForm}
                            defaultValues={goalsDataToEdit ? goalsDataToEdit : initialGoalsDataToEdit}
                            onWatchFieldsChange={handleWatchFieldsChange}
                        >
                            <Form.Body>
                                <Form.Section>
                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="name"
                                            label="Goal Name"
                                            required={true}
                                            tooltipCopy="Enter a display name for this goal."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputText
                                            name="name"
                                            placeholder="Goal Name..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="productType"
                                            label="Product Type"
                                            required={true}
                                            tooltipCopy="Select from a list of your products."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputSelect
                                            name="productType"
                                            options={productTypesOptions}
                                            placeholder="Product Type..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.InputLabel
                                            htmlFor="revenueOverride"
                                            label="Revenue Override (£)"
                                            required={false}
                                            tooltipCopy="Override revenue."
                                            tooltipPosition={TooltipPopUpSide.Top}
                                        />
                                        <Form.InputText
                                            name="revenueOverride"
                                            placeholder="Revenue Override..."
                                            validators={[validators.required]}
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.InputCheckbox
                                            name="default"
                                            label="Set Goal As Default"
                                            disabled={
                                                formWatchedFields &&
                                                (formWatchedFields.productType === '4' ||
                                                    formWatchedFields.productType === '5')
                                            }
                                        />
                                    </Form.Field>

                                    <Form.Field>
                                        <Form.InputCheckbox
                                            name="assignedToMarket"
                                            label="Assign Goal to Market"
                                            disabled={productMarketDataQuery.status !== 'success'}
                                        />
                                    </Form.Field>

                                    {formWatchedFields.assignedToMarket && (
                                        <Form.Field>
                                            <Form.InputLabel
                                                htmlFor="assignedMarketList"
                                                label="Market List"
                                                required={formWatchedFields.assignedToMarket}
                                                tooltipCopy="Select Markets for Goals."
                                                tooltipPosition={TooltipPopUpSide.Top}
                                            />
                                            <Form.InputMultiSelect
                                                name="assignedMarketList"
                                                options={productMarketOptions}
                                                placeholder="Market List..."
                                                validators={formWatchedFields.assignedToMarket && [validators.required]}
                                            />
                                        </Form.Field>
                                    )}
                                </Form.Section>
                            </Form.Body>
                            <Form.Footer>
                                <Form.InputButton
                                    value="Save"
                                    loading={
                                        goalsDataPatchMutation.isPending ||
                                        productMarketDataAddMutation.isPending ||
                                        productMarketDataDeleteMutation.isPending
                                    }
                                    disabled={
                                        goalsDataPatchMutation.isPending ||
                                        productMarketDataAddMutation.isPending ||
                                        productMarketDataDeleteMutation.isPending
                                    }
                                />
                                <Form.InputButton
                                    value="Cancel"
                                    type="button"
                                    onClick={handleEditFormCloseClick}
                                    loading={
                                        goalsDataPatchMutation.isPending ||
                                        productMarketDataAddMutation.isPending ||
                                        productMarketDataDeleteMutation.isPending
                                    }
                                    disabled={
                                        goalsDataPatchMutation.isPending ||
                                        productMarketDataAddMutation.isPending ||
                                        productMarketDataDeleteMutation.isPending
                                    }
                                />
                            </Form.Footer>
                        </Form>
                    </StyledFormContainer>
                )}

                {/* Assign sale event */}
                {goalDataIdToAssignSaleEvent && (
                    <StyledEditEventContainer>
                        <StyledEventTitle>Assign Sale Event</StyledEventTitle>
                        <StyledEventP>Choose the event(s) which signify the completion of your goal.</StyledEventP>

                        <ConfigTable
                            status={liteEventsDataQuery.status}
                            isFetching={liteProductEventDataQuery.isFetching}
                            disabled={disabledGoalDataToAssignTable}
                            empty={liteEventsData.length === 0}
                        >
                            <ConfigTable.Table maxHeight="400px">
                                <ConfigTable.Header>
                                    <ConfigTable.Row key="thead">
                                        <ConfigTable.CellHeader>Sale</ConfigTable.CellHeader>
                                        <ConfigTable.CellHeader>Events</ConfigTable.CellHeader>
                                    </ConfigTable.Row>
                                </ConfigTable.Header>
                                <ConfigTable.Body>
                                    {liteEventsData.map(event => {
                                        return (
                                            <ConfigTable.Row key={event.id}>
                                                <ConfigTable.CellCheckbox
                                                    rowId={event.id}
                                                    checked={event.selected}
                                                    onCheckedChange={handleEventSaleCheckboxChange}
                                                />
                                                <ConfigTable.Cell>{event.name}</ConfigTable.Cell>
                                            </ConfigTable.Row>
                                        );
                                    })}
                                </ConfigTable.Body>
                            </ConfigTable.Table>
                            <ConfigTable.ActionBar>
                                <ConfigTable.ActionBarJustifyLeft>{''}</ConfigTable.ActionBarJustifyLeft>
                                <ConfigTable.ActionBarJustifyRight>
                                    <ConfigTable.ActionButton
                                        type="primary"
                                        label="Apply"
                                        onClick={handleEventSaleApplyClick}
                                        isDisabled={
                                            liteEventsData.length === 0 ||
                                            productEventDataPatchMutation.isPending ||
                                            productEventDataAddMutation.isPending
                                        }
                                    />
                                    <ConfigTable.ActionButton
                                        type="primary"
                                        label="Cancel"
                                        onClick={handleEventSaleCancelClick}
                                    />
                                </ConfigTable.ActionBarJustifyRight>
                            </ConfigTable.ActionBar>
                        </ConfigTable>
                    </StyledEditEventContainer>
                )}
            </ModalLayout>
        );
    }

    // Page Error
    return <ErrorModalLayout modalHeader="Manage Goals" />;
};

export default LayoutModalManageGoals;
