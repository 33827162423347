// React Dependencies
import React, { useEffect, useState } from 'react';
import { DateRangePicker, RangeKeyDict, Range } from 'react-date-range';
import { getStaticRanges } from '../../helpers/get-static-ranges';
import { ButtonThemes } from '../../enums/button-themes';
import { DatePickerType } from '../../configurations/enums/date-picker-type';
import moment, { Moment } from 'moment';
import styled from 'styled-components';

// Custom Hooks
import { usePrevious } from '../../hooks/use-previous';
import UseComponentVisible from '../../hooks/use-component-visible';

// CSS
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// Components
import InputButton from '../inputs/input-button';
import InputButtonWrapper from '../inputs/input-button-wrapper';
import IconCalendar from '../icons/calendar';
import CubedDateRangePicker from '../../helpers/cubed-date-picker/cubed-date-range-picker';

// Redux Actions
import { setComparisonDate, setDate } from '../../redux/actions/date';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/core';

// Types
import { StaticRange } from 'react-date-range';
import { StyledDatePickerStyles } from './styles/date-picker-styles';

const stylesForStartAndEndOfMonth = (ranges: Range[] | undefined) => {
    if (!ranges || ranges.length === 0) {
        return;
    }

    const dateRange: Range = ranges[0];

    const startDate = dateRange?.startDate;
    const endDate = dateRange?.endDate;
    if (!startDate || !endDate) {
        return;
    }
    const monthsDifference =
        endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());
    if (monthsDifference > 0) {
        return `
            .rdrDayEndOfMonth .rdrInRange,
            .rdrDayEndOfMonth .rdrStartEdge {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                right: 0;
            }
            .rdrDayStartOfMonth .rdrInRange,
            .rdrDayStartOfMonth .rdrEndEdge {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                left: 0;
            }
            .rdrDayEndOfWeek.rdrDayEndOfMonth .rdrInRange,
            .rdrDayEndOfWeek.rdrDayEndOfMonth .rdrStartEdge{
                border-top-right-radius: 1.033em;
                border-bottom-right-radius: 1.033em;
                right: 0;
            }
            .rdrDayStartOfWeek.rdrDayStartOfMonth .rdrInRange,
            .rdrDayStartOfWeek.rdrDayStartOfMonth .rdrEndEdge{
                border-top-left-radius: 1.033em;
                border-bottom-left-radius: 1.033em;
                left: 0;
            }
            `;
    }
};

/* This overrides the react-date-range/dist/theme/default.css */
const StyledDateRangePicker = styled(DateRangePicker)`
    ${StyledDatePickerStyles}
    ${props => stylesForStartAndEndOfMonth(props.ranges)}
`;

const StyledCubedDateRangePicker = styled(CubedDateRangePicker)`
    ${StyledDatePickerStyles}
`;

// Styles
const StyledDatePicker = styled.div`
    position: relative;
    float: left;
    z-index: 10;
`;

const StyledDatePickerPreviewHolder = styled.div`
    overflow: hidden;
`;

const StyledDatePickerPreview = styled('div')`
    float: right;
    position: relative;
    font-weight: 300;
    color: ${props => props.theme.colours.white};
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    box-sizing: border-box;
    padding: 0 18px;
    background-color: ${props => props.theme.colours.offBlackLighter};
    cursor: pointer;
`;

const StyledCalenderIcon = styled.div`
    display: inline-block;
    margin: 3px 12px 5px 0;

    & svg {
        width: 18px;
        height: 18px;
        fill: ${props => props.theme.colours.light};
    }
`;

const StyledCalenderDate = styled.p`
    display: inline-block;
    margin-bottom: 0px;
    transform: translateY(-3px);
`;

const StyledDatePickerCalenderHolder = styled.div`
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    top: 36px;
    position: absolute;
    background-color: ${props => props.theme.datePicker.backgroundColor};
    padding: 0 0 12px 0;

    & .rdrInputRanges {
        display: none;
    }
`;

const StyledDatePickerCalender = styled.div`
    border-radius: 2px;
    position: relative;
`;

type DatePickerConfig = {
    customEndDate?: () => {};
    customStartDate?: () => {};
    disabledDays?: (date: Date) => boolean;
    datePickerType?: number;
    maxDate?: () => Moment;
};

type DatePickerProps = {
    isEnabled: boolean;
    config: DatePickerConfig;
    isComparison?: boolean;
};

type DatePickerItems = {
    ranges: Range[];
    rangeColors: string[];
    staticRanges: StaticRange[];
    onChange: (ranges: RangeKeyDict) => void;
    maxDate: Date;
    disabledDay: (date: Date) => boolean;
    dragSelectionEnabled?: boolean;
    focusedRange?: [number, 0 | 1];
};

const DatePicker = ({ isEnabled, config, isComparison = false }: DatePickerProps) => {
    const dates = useSelector((state: RootState) => state.date);
    const { startDate, endDate } = dates;

    const dispatch = useDispatch();
    const prevConfig = usePrevious(config);

    const rangeColour = ['#FF7300'];

    const {
        ref: dateRangePickerRef,
        isComponentVisible: expendDatePicker,
        setIsComponentVisible: setExpendDatePicker,
    } = UseComponentVisible(false);

    // State
    const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false);
    const [selectionRange, setSelectionRange] = useState<Range>({
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        key: 'selection',
    });
    const [staticRanges, setStaticRanges] = useState<StaticRange[]>(getStaticRanges(config?.datePickerType, dates));

    useEffect(() => {
        // if the report configuration specifies a custom start/end date, set it now
        if (config?.customStartDate || config?.customEndDate) {
            const start = config.customStartDate ? config.customStartDate() : startDate.toDate();
            const end = config.customEndDate ? config.customEndDate() : endDate.toDate();

            setSelectionRange({
                startDate: start.toDate(),
                endDate: end.toDate(),
                key: 'selection',
            });

            dispatch(setDate(start, end));
        }

        // reset calendar back to last 7 days
        // we only need to do this once after moving away from a page with a custom date
        if (!prevConfig || (prevConfig && Object.keys(prevConfig).length > 0 && Object.keys(config).length === 0)) {
            const start = isComparison ? startDate : moment().startOf('day').subtract(7, 'days');
            const end = isComparison ? endDate : moment().endOf('day').subtract(1, 'days');

            setSelectionRange({
                startDate: start.toDate(),
                endDate: end.toDate(),
                key: 'selection',
            });

            if (isComparison) {
                dispatch(setComparisonDate(start, end));
            } else {
                dispatch(setDate(start, end));
            }
        }

        // set the correct static date ranges
        applyStaticRanges();
    }, [config]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // if the dates change, set the correct static ranges
        applyStaticRanges();
    }, [dates]); // eslint-disable-line react-hooks/exhaustive-deps

    const applyStaticRanges = () => {
        const ranges = getStaticRanges(config?.datePickerType, dates);
        setStaticRanges(ranges);
    };

    const toggleCalender = () => {
        setExpendDatePicker(!expendDatePicker);
    };

    const handleSelect = (ranges: RangeKeyDict) => {
        if (config.datePickerType === DatePickerType.Day) {
            setSelectionRange({
                ...selectionRange,
                startDate: ranges.selection.startDate,
                endDate: moment(ranges.selection.startDate).endOf('day').toDate(),
            });
        } else {
            setSelectionRange(ranges.selection);
        }

        setButtonsDisabled(false);
    };

    const handleApplyClick = () => {
        let endDate = moment(selectionRange.endDate).format('ddd MMM DD YYYY 23:59:59');
        if (config?.datePickerType === DatePickerType.Year) {
            endDate = moment(selectionRange.endDate).endOf('month').format('ddd MMM DD YYYY 23:59:59');
        }

        if (isComparison) {
            dispatch(setComparisonDate(selectionRange.startDate, endDate));
        } else {
            dispatch(setDate(selectionRange.startDate, endDate));
        }

        setButtonsDisabled(true);
        setExpendDatePicker(false);
    };

    const setDisabledDays = (date: Date) => {
        if (config?.disabledDays) {
            return config.disabledDays(date);
        }

        return false;
    };

    const getDatePicker = () => {
        const maxDate = config?.maxDate ? config.maxDate().toDate() : moment().subtract(1, 'days').toDate();

        const dateRangePickerItems: DatePickerItems = {
            ranges: [selectionRange],
            staticRanges: staticRanges,
            rangeColors: rangeColour,
            onChange: handleSelect,
            maxDate: maxDate,
            disabledDay: setDisabledDays,
        };

        if (config?.datePickerType === DatePickerType.Year) {
            return <StyledCubedDateRangePicker {...dateRangePickerItems} />;
        } else {
            if (config.datePickerType === DatePickerType.Day) {
                dateRangePickerItems['dragSelectionEnabled'] = false;
                dateRangePickerItems['focusedRange'] = [0, 0];
            } else {
                dateRangePickerItems['dragSelectionEnabled'] = true;
            }

            return <StyledDateRangePicker {...dateRangePickerItems} />;
        }
    };

    if (isEnabled) {
        return (
            <StyledDatePicker>
                <StyledDatePickerPreviewHolder
                    onClick={toggleCalender}
                    data-testid={isComparison ? 'datepicker-preview-holder-comparison' : 'datepicker-preview-holder'}
                >
                    <StyledDatePickerPreview>
                        <StyledCalenderIcon>
                            <IconCalendar />
                        </StyledCalenderIcon>
                        <StyledCalenderDate>
                            {moment(selectionRange?.startDate).format('Do MMM YYYY')} →{' '}
                            {moment(selectionRange?.endDate).format('Do MMM YYYY')}
                        </StyledCalenderDate>
                    </StyledDatePickerPreview>
                </StyledDatePickerPreviewHolder>
                {expendDatePicker && (
                    <StyledDatePickerCalenderHolder ref={dateRangePickerRef} data-testid="date-picker-picker">
                        <StyledDatePickerCalender>{getDatePicker()}</StyledDatePickerCalender>
                        <InputButtonWrapper>
                            <InputButton onClick={handleApplyClick} disabled={buttonsDisabled} value="Apply" />
                            <InputButton onClick={toggleCalender} value="Cancel" buttonTheme={ButtonThemes.Secondary} />
                        </InputButtonWrapper>
                    </StyledDatePickerCalenderHolder>
                )}
            </StyledDatePicker>
        );
    }

    return null;
};

export default DatePicker;
