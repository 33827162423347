import React from 'react';
import styled from 'styled-components';

// Context
import { useFormContext } from 'react-hook-form';
import { useCubedFormContext } from '../context/form-context';

// Enums & Components
import { ButtonThemes } from '../../../enums/button-themes';
import InputButtonLoadingSpindle from '../../inputs/input-button-loading-spindle';

const StyledButton = styled.button<{ buttonTheme: ButtonThemes; disabled?: boolean; loading?: boolean }>`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    cursor: ${props => (props.disabled ? 'not-allowed!important' : 'pointer')};
    border: none;
    border-radius: 2px;
    min-width: 40px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    padding: 0px 15px;
    margin: 0;
    text-transform: uppercase;
    font-weight: ${props => props.theme.buttons[props.buttonTheme].fontWeight};
    text-decoration: none;
    background-color: ${props =>
        props.disabled || props.loading
            ? props.theme.buttons[props.buttonTheme].backgroundColourDisabled
            : props.theme.buttons[props.buttonTheme].backgroundColour};
    color: ${props =>
        props.disabled || props.loading
            ? props.theme.buttons[props.buttonTheme].colorDisabled
            : props.theme.buttons[props.buttonTheme].color};
`;

export type InputButtonProps = {
    value?: string;
    type?: 'submit' | 'button' | 'reset';
    buttonTheme?: ButtonThemes;
    disabled?: boolean;
    loading?: boolean;
    onClick?: () => void;
};

const InputButton = ({
    value = 'Submit',
    type = 'submit',
    buttonTheme = ButtonThemes.Primary,
    disabled,
    loading,
    onClick,
}: InputButtonProps) => {
    const { status, defaultValues } = useCubedFormContext();
    const {
        reset,
        formState: { isSubmitting },
    } = useFormContext();

    const isDisabled = disabled || status === 'disabled' || loading || status === 'loading';
    const isLoading = loading || status === 'loading';

    if (isLoading) {
        return (
            <StyledButton buttonTheme={buttonTheme} disabled={isDisabled} loading={loading}>
                <InputButtonLoadingSpindle buttonTheme={buttonTheme} />
                {value}
            </StyledButton>
        );
    }

    if (type === 'reset') {
        return (
            <StyledButton
                type="button"
                buttonTheme={buttonTheme}
                disabled={isDisabled}
                onClick={() => reset(defaultValues)}
            >
                {value}
            </StyledButton>
        );
    }

    if (type === 'button') {
        return (
            <StyledButton type="button" buttonTheme={buttonTheme} disabled={isDisabled} onClick={onClick}>
                {value}
            </StyledButton>
        );
    }

    return (
        <StyledButton type={type} buttonTheme={buttonTheme} disabled={isDisabled || isSubmitting}>
            {value}
        </StyledButton>
    );
};

export default InputButton;
