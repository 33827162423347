// Helpers
import dateStringToUnix from '../../helpers/date-string-to-unix';
import { getRawValueAsNumber, getValue, getRawValueAsString } from './resource-data';

// Types
import { CubedField } from '../../types';
import { ResourceData, WidgetTheme } from '../types';
import { ResourceDataObject } from '../../react-query/types';

// Configuration
import { alternativeGraphColours, graphColours } from './graph-colours';

export const vennChartDataSeries = (
    dimensionResourceData: ResourceData,
    resourceData: ResourceData,
    metric: CubedField
) => {
    let sets: never | { id: number; name: string }[] = [];
    let data: never | { sets: string[]; value: number; color?: string; themeColour?: WidgetTheme }[] = [];

    if (dimensionResourceData && 'objects' in dimensionResourceData) {
        sets = dimensionResourceData.objects.map(object => {
            return {
                id: getRawValueAsNumber(object, 'id'),
                name: getRawValueAsString(object, 'name'),
            };
        });
    }

    if (resourceData && 'objects' in resourceData) {
        const colours = [...graphColours, ...alternativeGraphColours];
        data = resourceData.objects.map((object, index) => {
            const combination = getRawValueAsString(object, 'combination')
                .trim()
                .replace('(', '')
                .replace(')', '')
                .split(',');

            return {
                sets: combination.map(item => {
                    const name = sets.find(set => set.id === parseInt(item))?.name;
                    if (name) return name;
                    return 'Unknown';
                }),
                value: getRawValueAsNumber(object, metric.rawName),
                color: colours[index % colours.length].solidGraphColour,
                themeColour: colours[index % colours.length],
            };
        });
    }

    return data;
};

export const sparkLineDataSeries = (
    dataObjects: ResourceDataObject[],
    metric: CubedField,
    dateDimension: CubedField
) => {
    const series: [number, number][] = [];
    dataObjects.forEach(data => {
        const rawValue = getRawValueAsNumber(data, metric.rawName);
        series.push([dateStringToUnix(getValue(data, dateDimension.rawName)), rawValue]);
    });

    return series;
};

export const histogramChartDataSeries = (data: ResourceData, metric: CubedField, category: CubedField) => {
    let histogramData: { __id: string; name: string; y: number; colour: any }[] = [];

    if (data && 'objects' in data) {
        histogramData = data.objects.map((data, index) => {
            return {
                __id: data.__id,
                name: getValue(data, category.rawName),
                y: getRawValueAsNumber(data, metric.rawName),
                colour: graphColours[index % graphColours.length].solidGraphColour,
            };
        });
    }

    return histogramData;
};
