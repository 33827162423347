// Types
import { CubedField } from '../../../../types';
import {
    BigNumberAggregation,
    BigNumberComparisonData,
    BigNumberDecimalRounding,
    BigNumberFormatting,
    ResourceComparisonData,
} from '../../../types';

// Helpers
import { aggregateBigNumber } from '../../../helpers/aggregate-big-number';
import { getRawValueAsNumber } from '../../../helpers/resource-data';
import { unknownErrorData } from '../../../helpers/errors';

export type useResourceBigNumberComparisonArgs = {
    resourceData: ResourceComparisonData;
    aggregationSumData?: ResourceComparisonData;
    field: CubedField;
    aggregationSumField?: CubedField;
    aggregation: BigNumberAggregation;
    formatting?: BigNumberFormatting;
    rounding?: BigNumberDecimalRounding;
    displayGraph?: boolean;
};

const useResourceBigNumberComparison = ({
    resourceData,
    aggregationSumData,
    field,
    aggregationSumField,
    aggregation = 'avg',
    formatting,
    rounding = {
        applyRounding: true,
    },
    displayGraph = true,
}: useResourceBigNumberComparisonArgs): BigNumberComparisonData => {
    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        const resource = resourceData.resources[0];
        const comparisonResource = resourceData.resources[1];

        try {
            // This Period
            const resourceValues =
                resource.status === 'success' ? resource.objects.map(x => getRawValueAsNumber(x, field.rawName)) : [];

            const aggregationSum =
                aggregationSumData &&
                aggregationSumData?.status === 'success' &&
                aggregationSumData.resources[0].status === 'success' &&
                aggregationSumData.resources[0].objects;

            const aggregationSumValues = aggregationSum
                ? aggregationSum.map(x =>
                      getRawValueAsNumber(x, (aggregationSumField && aggregationSumField.rawName) || field.rawName)
                  )
                : undefined;

            const value =
                resourceValues.length > 0
                    ? aggregateBigNumber(resourceValues, aggregation, aggregationSumValues)
                    : null;

            // Comparison Period
            const comparisonResourceValues =
                comparisonResource.status === 'success'
                    ? comparisonResource.objects.map(x => getRawValueAsNumber(x, field.rawName))
                    : [];

            const comparisonAggregationSumData =
                aggregationSumData &&
                aggregationSumData?.status === 'success' &&
                aggregationSumData.resources[1].status === 'success' &&
                aggregationSumData.resources[1].objects;

            const comparisonAggregationSumValues = comparisonAggregationSumData
                ? comparisonAggregationSumData.map(x =>
                      getRawValueAsNumber(x, (aggregationSumField && aggregationSumField.rawName) || field.rawName)
                  )
                : undefined;

            const comparisonValue =
                comparisonResourceValues.length > 0
                    ? aggregateBigNumber(comparisonResourceValues, aggregation, comparisonAggregationSumValues)
                    : null;

            return {
                type: 'bigNumberComparison',
                status: 'success',
                title: field.displayName,
                series: displayGraph && resourceValues.length > 0 ? resourceValues : undefined,
                comparisonSeries:
                    displayGraph && comparisonResourceValues.length > 0 ? comparisonResourceValues : undefined,
                value,
                comparisonValue,
                ratioValue: value && aggregationSumValues && aggregation === 'ratio' ? 100 - value : undefined,
                comparisonRatioValue:
                    comparisonValue && comparisonAggregationSumValues && aggregation === 'ratio'
                        ? 100 - comparisonValue
                        : undefined,
                formatting: formatting,
                rounding: rounding,
            };
        } catch (error) {
            const message = error instanceof Error ? error.message : String(error);
            return {
                type: 'bigNumberComparison',
                status: 'error',
                error: {
                    message: message,
                },
            };
        }
    }

    return unknownErrorData();
};

export default useResourceBigNumberComparison;
