import { DefaultTheme } from 'styled-components';

export const smallChartOptions = (theme: DefaultTheme) => {
    return {
        time: {
            useUTC: true,
        },
        chart: {
            type: 'areaspline',
            margin: 0,
            padding: 0,
            animation: false,
            backgroundColor: 'transparent',
        },
        plotOptions: {
            series: {
                color: '#ffffff',
                animation: {
                    duration: 0,
                },
                marker: {
                    enabled: false,
                },
            },
        },
        title: {
            text: '',
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            type: 'datetime',
            minPadding: 0,
            maxPadding: 0,
            style: {
                fontSize: '0.7em',
            },
        },
        yAxis: {
            gridLineWidth: 0,
            minPadding: 0,
            maxPadding: 0,
            style: {
                fontSize: '0.7em',
            },
        },
    };
};

export const largeChartOptions = (theme: DefaultTheme) => {
    return {
        chart: {
            type: 'areaspline',
            style: {
                fontFamily: 'Work Sans',
            },
            animation: false,
            marginRight: 0,
            backgroundColor: 'transparent',
        },
        plotOptions: {
            series: {
                fillOpacity: 0,
                animation: {
                    duration: 0,
                },
                marker: {
                    enabled: false,
                    symbol: 'circle',
                },
            },
        },
        xAxis: {
            type: 'datetime',
            tickInterval: 24 * 60 * 60 * 1000,
            labels: {
                enabled: true,
                style: {
                    whitespace: 'nowrap',
                    textOverflow: 'ellipsis',
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: '0.7em',
                },
            },
        },
        yAxis: {
            title: {
                text: '',
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: '0.7em',
                },
            },
            labels: {
                enabled: true,
                style: {
                    color: theme.name === 'dark' && 'rgba(255, 255, 255, 0.8)',
                    fontSize: '0.7em',
                },
            },
            gridLineColor: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.2)' : '#e6e6e6',
        },
        title: {
            text: null,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: true,
            itemStyle: {
                color: theme.name === 'dark' ? 'rgba(255, 255, 255, 0.8)' : '#6f6f6f',
                fontWeight: 400,
                width: '140px',
            },
        },
    };
};
