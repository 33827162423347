// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import { isString } from '../../helpers/validator';
import { generateUrl, generatePath } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { getRequest, delRequest, cancelRequest } from '../../redux/slices/request';

// Import Widgets
import WidgetAccordion from '../../widgets/accordion';

import { CONFIG_TIMEZONE_CURRENCY } from '../../configurations/resources-config';

// Import Components
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';

class LayoutModalManageTimezoneAndCurrency extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: false,
            closeButtonState: 'close',
            closeButtonDisabled: false,
            timezoneAndCurrencyAccordionOpen: true,
            events: [],
            timezoneOptions: [],
            currencyOptions: [],
            setPreviousOptions: {},
            timezone: '',
            currency: '',
            timezoneId: '',
            currencyId: '',
            hasCreated: false,
        };
    }

    componentDidMount() {
        const requestConfig = [
            {
                resourceGroup: 'config',
                resourceName: 'timezone',
                params: [
                    {
                        key: 'account_id',
                        value: this.props.account.id,
                    },
                    {
                        key: 'limit',
                        value: 0,
                    },
                    {
                        key: 'order_by',
                        value: 'display_name',
                    },
                ],
            },
            {
                resourceGroup: 'config',
                resourceName: 'currency',
                params: [
                    {
                        key: 'account_id',
                        value: this.props.account.id,
                    },
                    {
                        key: 'limit',
                        value: 0,
                    },
                    {
                        key: 'order_by',
                        value: 'iso',
                    },
                ],
            },
            {
                resourceGroup: CONFIG_TIMEZONE_CURRENCY.category,
                resourceName: CONFIG_TIMEZONE_CURRENCY.id,
                params: [
                    {
                        key: 'cubed_user_id',
                        value: this.props.user.id,
                    },
                ],
            },
        ];

        this.props.getRequest(requestConfig);
    }

    componentDidUpdate() {
        if (this.state.isLoading && this.props.request.isLoading === false) {
            if (!this.props.request.hasError) {
                // Manipulate the timezone to work with a select field
                const timezone = this.props.request.data[0].objects.map(zone => {
                    return {
                        id: zone.id,
                        keyValue: zone.id,
                        name: zone.display_name,
                    };
                });

                // Manipulate the currency to work with a select field
                const currency = this.props.request.data[1].objects.map(curr => {
                    return {
                        id: curr.id,
                        keyValue: curr.id,
                        name: curr.iso + ' - ' + curr.currency,
                    };
                });

                // (At very first time) If user has not already created timezone and currency record, render TimezoneAndCurrency Modal,
                // Else display users previously created Timezone and Currency record.

                try {
                    if (this.props.request.data[2].objects.length === 0) {
                        this.handleNavigateTimezoneAndCurrencyModal();
                    } else if (
                        this.props.user.id === this.props.request.data[2].objects[0].cubed_user_id &&
                        this.props.request.data[2].objects[0].has_created
                    ) {
                        this.setState({
                            setPreviousOptions: {
                                setTimezone: timezone.find(
                                    tzName => tzName.id === this.props.request.data[2].objects[0].timezone.id
                                ).name,
                                setCurrency: currency.find(
                                    currName => currName.id === this.props.request.data[2].objects[0].currency.id
                                ).name,
                            },
                            hasCreated: true,
                        });
                    }
                } catch (err) {
                    this.setState({ pageError: true });
                }

                this.setState({
                    isLoading: false,
                    timezoneOptions: timezone,
                    currencyOptions: currency,
                });
            } else {
                this.setState({
                    pageError: true,
                    isLoading: false,
                });
            }
        }

        // Checks to see if a change has been made and if so switch the button to being
        if (this.state.closeButtonState === 'close') {
            if (
                this.state.timezone !== '' ||
                this.state.timezoneId !== '' ||
                this.state.currency !== '' ||
                this.state.currencyId !== ''
            ) {
                this.setState({
                    closeButtonState: 'cancel',
                    saveChangesButtonDisabled: false,
                });
            }
        }
    }

    handleNavigateTimezoneAndCurrencyModal = () => {
        this.props.setModal('ManageTimezoneAndCurrency', {});
    };

    // CLOSE - CANCEL click handler
    onCloseClick = () => {
        this.props.removeModal();
    };

    timezoneAndCurrencyFormValidator = () => {
        let hasFormError = false;
        let errorMessageObject = {
            timezoneErrorMessage: '',
            currencyErrorMessage: '',
        };

        // Validate and ensure timezone
        if (!isString(this.state.timezone) || this.state.timezone.length === 0) {
            hasFormError = true;
            errorMessageObject.timezoneErrorMessage = 'Please select a correct Timezone';
        }

        // Validate and ensure currency
        if (!isString(this.state.currency) || this.state.currency.length === 0) {
            hasFormError = true;
            errorMessageObject.currencyErrorMessage = 'Please select a correct Currency';
        }

        if (hasFormError) {
            this.setState(errorMessageObject);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    // Makes a POST request to the API with the data entered by the user.
    saveTimezoneAndCurrency = () => {
        return new Promise(async (resolve, reject) => {
            const timezoneAndCurrencyPayload = {
                timezone: generatePath('config', 'timezone', this.state.timezoneId),
                currency: generatePath('config', 'currency', this.state.currencyId),
                cubed_user_id: this.props.user.id,
                has_created: true,
            };

            const timezoneAndCurrencyConfig = {
                method: 'POST',
                url: generateUrl(CONFIG_TIMEZONE_CURRENCY.category, CONFIG_TIMEZONE_CURRENCY.id),
                data: timezoneAndCurrencyPayload,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            try {
                const timezoneAndCurrencyResponse = await Axios(timezoneAndCurrencyConfig);

                if (timezoneAndCurrencyResponse.status !== 201) {
                    throw new Error('Status not 201');
                }
            } catch (error) {
                reject('Error adding Timezone and Currency.');
            }

            resolve('Timezone and Currency Added');
        });
    };

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick = () => {
        this.props.removePopup();
        this.handleNavigateTimezoneAndCurrencyModal();
    };

    // SecondaryAction of the close popup
    onPopupStayHereClick = () => {
        this.props.removePopup();
    };

    // Saved changes clicked handler
    onSaveChangesClick = () => {
        this.setState({
            saveChangesButtonDisabled: true,
            saveChangesButtonLoading: true,
            closeButtonDisabled: true,
        });

        if (!this.timezoneAndCurrencyFormValidator()) {
            this.setState({
                closeButtonDisabled: false,
                saveChangesButtonDisabled: false,
                saveChangesButtonLoading: false,
            });
            return;
        }

        this.saveTimezoneAndCurrency()
            .then(() => {
                this.props.addNotification({
                    copy: 'Timezone and Currency successfully Added.',
                    type: NotificationMessageType.Success,
                });
                this.setState({
                    saveChangesButtonDisabled: true,
                    saveChangesButtonLoading: false,
                    closeButtonDisabled: false,
                });
                this.handleNavigateTimezoneAndCurrencyModal();
            })
            .catch(err => {
                console.log(err, 'err');
                this.props.addNotification({
                    copy: 'There was an issue adding the Timezone and Currency, please try again later.',
                    type: NotificationMessageType.Error,
                });
            });
    };

    // Manage the input fields changes and updating the state with the new value entered by the user
    onTimezoneChange = event => {
        const selectedTimezoneId = this.state.timezoneOptions.filter(tz => tz.name === event.target.value)[0].id;
        if (event.target.type === 'select-one') {
            this.setState({
                timezoneId: selectedTimezoneId,
                timezone: event.target.value,
            });
        }
    };

    onCurrencyChange = event => {
        const selectedCurrencyId = this.state.currencyOptions.filter(curr => curr.name === event.target.value)[0].id;
        if (event.target.type === 'select-one') {
            this.setState({
                currencyId: selectedCurrencyId,
                currency: event.target.value,
            });
        }
    };

    renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE',
                onClick: this.onSaveChangesClick,
                disabled: this.state.saveChangesButtonDisabled,
                isLoading: this.state.saveChangesButtonLoading,
            },
            {
                value: 'CLOSE',
                onClick: this.onCloseClick,
                disabled: this.state.closeButtonDisabled,
                buttonTheme: ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    renderAccordion = () => {
        const accordions = [
            // If user has created record then hasCreated is true and won't allow user to change timezone and currency again.
            // In that case when hasCreated=true use first configuration by sequence, else second configuration respectively.

            this.state.hasCreated
                ? {
                      header: 'Add Timezone and Currency',
                      required: true,
                      open: this.state.timezoneAndCurrencyAccordionOpen,
                      type: 'form',
                      config: {
                          formConfig: {
                              fields: [
                                  {
                                      label: 'Timezone',
                                      type: 'text',
                                      requiredField: true,
                                      toolTipCopy: 'Select a Timezone.',
                                      inputPlaceholder: this.state.setPreviousOptions.setTimezone,
                                      disabled: true,
                                  },
                                  {
                                      label: 'Currency',
                                      type: 'text',
                                      requiredField: true,
                                      toolTipCopy: 'Select a Currency.',
                                      inputPlaceholder: this.state.setPreviousOptions.setCurrency,
                                      disabled: true,
                                  },
                              ],
                          },
                      },
                  }
                : {
                      header: 'Add Timezone and Currency',
                      required: true,
                      open: this.state.timezoneAndCurrencyAccordionOpen,
                      type: 'form',
                      config: {
                          formConfig: {
                              fields: [
                                  {
                                      label: 'Timezone',
                                      type: 'select',
                                      requiredField: true,
                                      toolTipCopy: 'Select a timezone for your account to report in.',
                                      inputKeyValue: 'timezone',
                                      inputOptions: this.state.timezoneOptions,
                                      inputOnChange: this.onTimezoneChange,
                                      errorMessage: this.state.timezoneErrorMessage,
                                  },
                                  {
                                      label: 'Currency',
                                      type: 'select',
                                      requiredField: true,
                                      toolTipCopy: 'Select the main currency for your account.',
                                      inputKeyValue: 'currency',
                                      inputOptions: this.state.currencyOptions,
                                      inputOnChange: this.onCurrencyChange,
                                      errorMessage: this.state.currencyErrorMessage,
                                  },
                              ],
                          },
                      },
                  },
        ];
        return <WidgetAccordion accordions={accordions} />;
    };

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__add-magento">
                    <this.renderModalNavigation />
                    <h2>Configure Timezone and Currency</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        if (this.state.pageError) {
            return (
                <div className="modal__side-panel__add-magento">
                    <this.renderModalNavigation />
                    <h2>Configure Timezone and Currency</h2>
                    <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email. " />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__add-magento">
                <this.renderModalNavigation />
                <h2>Configure Timezone and Currency</h2>
                <p>
                    Configure your accounts timezone and currency settings. The timezone set will control all time based
                    dimensions and time conversions. The currency set will be used for currency conversions. These
                    changes will apply to your entire account and cannot be undone.
                </p>
                <this.renderAccordion />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.account,
        request: state.request,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        getRequest: request => {
            dispatch(getRequest(request));
        },
        delRequest: () => {
            dispatch(delRequest());
        },
        cancelRequest: () => {
            dispatch(cancelRequest());
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalManageTimezoneAndCurrency);
