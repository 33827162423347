import React from 'react';
import styled, { useTheme } from 'styled-components';

// Configuration
import { barGraphConfig, columnBarGraphConfig } from '../../configurations/widget-bar-graph-config';

//Highcharts
import Highcharts from 'highcharts';
import CommonHighcharts from '../../../components/common/common-highcharts';

// Context
import { useSectionDashboard } from '../../context/section-dashboard-context';

// Components
import WidgetBase from '../base/widget-base';
import WidgetBarGraphBigNumber from '../big-numbers/widget-bar-graph-big-number';

// Types
import { BarGraphData } from '../../types';

const StyledContainer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;

export type WidgetBarGraphProps = {
    title: string;
    data: BarGraphData;
    href?: string;
};

const WidgetBarGraph = ({ title, data, href }: WidgetBarGraphProps) => {
    const { width, height } = useSectionDashboard();
    const theme = useTheme();

    const columnConfig = columnBarGraphConfig(theme);
    const barConfig = barGraphConfig(theme);

    if (data.status === 'loading') {
        return <WidgetBase title={title} loading={true} />;
    }

    if (data.status === 'empty') {
        return <WidgetBase title={title} noData={true} href={href} />;
    }

    if (data.status === 'error') {
        return <WidgetBase title={title} error={true} href={href} />;
    }

    const series = data.bars.map(bar => bar.value);
    const categories = data.bars.map(bar => bar.name);
    const colours = data.bars.map(bar => bar.colour);
    const seriesName = data.metric;

    if (width === 1 || height === 1) {
        const numberOfBoxes = Math.max(width, height);
        const dataToDisplay = series.slice(0, numberOfBoxes);
        const categoriesToDisplay = categories.slice(0, numberOfBoxes);
        const topValue = series[0];
        const direction = width > height ? 'horizontal' : 'vertical';

        return (
            <WidgetBase title={title} href={href}>
                <WidgetBarGraphBigNumber
                    data={dataToDisplay}
                    categories={categoriesToDisplay}
                    topValue={topValue}
                    direction={direction}
                    colours={colours}
                    width={width}
                />
            </WidgetBase>
        );
    }

    if (width === 2 && height > 1) {
        const config = {
            ...barConfig,
            colors: colours,
            xAxis: {
                ...barConfig.xAxis,
                categories: categories,
            },
            series: [
                {
                    data: series,
                },
            ],
        };

        return (
            <WidgetBase title={title} href={href}>
                <StyledContainer>
                    <CommonHighcharts
                        highcharts={Highcharts}
                        options={{ ...config }}
                        containerProps={{ style: { height: '100%', width: '100%' } }}
                    />
                </StyledContainer>
            </WidgetBase>
        );
    }

    const config = {
        ...columnConfig,
        colors: colours,
        xAxis: {
            ...columnConfig.xAxis,
            categories: categories,
        },
        yAxis: {
            ...columnConfig.yAxis,
            title: {
                text: data.metric,
            },
        },
        series: {
            name: seriesName,
            data: series,
        },
    };

    return (
        <WidgetBase title={title} href={href}>
            <StyledContainer>
                <CommonHighcharts
                    highcharts={Highcharts}
                    options={{ ...config }}
                    containerProps={{ style: { width: '100%', height: '100%' } }}
                />
            </StyledContainer>
        </WidgetBase>
    );
};

export default WidgetBarGraph;
