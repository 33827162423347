import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { errorHandling } from '../../helpers/request-error-handling';
import { generatePath, generateUrl } from '../../helpers/request-builder';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { setModal, removeModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import WidgetAccordion from '../../widgets/accordion';
import { ButtonThemes } from '../../enums/button-themes';
import ModalNavigation from '../../components/modal-navigation';
import { isString } from '../../helpers/validator';
import LoadingSpinner from '../../components/loading-spinner';
import WarningMessage from '../../components/warning-message';
import { MarketResponse } from '../types';
import { DropdownOption } from '../../types';

type ErrorMessageObject = {
    blacklistedKeywordErrorMessage: string;
    marketErrorMessage: string;
    keywordTypeErrorMessage: string;
};

type KeywordTypeResponse = {
    id: number;
    type: string;
    resource_uri: string;
};

type BlackListedKeywordResponse = {
    keyword: string;
    market: MarketResponse;
    type: KeywordTypeResponse;
    active: number;
    created: string;
    updated: string;
    id: number;
    resource_uri: string;
};

type DbBlackListedKeywords = {
    id: number;
    keyword: string;
};

type MapKeywords = {
    keyword: string;
    market: string;
    type: string;
};

const LayoutAddBlackListedKeywords = () => {
    const [closeButtonState, setCloseButtonState] = useState<string>('close');
    const [saveChangesButtonLoading, setSaveChangesButtonLoading] = useState<boolean>(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState<boolean>(true);
    const [closeButtonDisabled, setCloseButtonDisabled] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [pageError, setPageError] = useState<boolean>(false);

    const [marketList, setMarketList] = useState<DropdownOption[]>([]);
    const [keywordTypes, setKeywordTypes] = useState<DropdownOption[]>([]);
    const [dbBlackListedKeywords, setDbBlackListedKeywords] = useState<DbBlackListedKeywords[]>([]);

    const [market, setMarket] = useState<string>('');
    const [keywordType, setKeywordType] = useState<string>('');
    const [blacklistedKeyword, setBlacklistedKeyword] = useState<string>('');

    const [errorMessageObject, setErrorMessageObject] = useState<ErrorMessageObject>({
        blacklistedKeywordErrorMessage: '',
        marketErrorMessage: '',
        keywordTypeErrorMessage: '',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        fetchAll();
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        setSaveChangesButtonDisabled(true);
        setCloseButtonState('close');
        if (blacklistedKeyword || market || keywordType) {
            setSaveChangesButtonDisabled(false);
            setErrorMessageObject({
                blacklistedKeywordErrorMessage: '',
                marketErrorMessage: '',
                keywordTypeErrorMessage: '',
            });
            setCloseButtonState('cancel');
        }
    }, [blacklistedKeyword, keywordType, market]);

    const formValidator = () => {
        let hasFormError = false;

        let errorMessageObj = {
            blacklistedKeywordErrorMessage: '',
            marketErrorMessage: '',
            keywordTypeErrorMessage: '',
        };

        let duplicateKeywords: Array<string> = [];
        dbBlackListedKeywords.forEach(keyword => {
            mapBlacklistedKeywordsToMarket().forEach(newKeyword => {
                if (newKeyword[0].keyword === keyword.keyword) {
                    duplicateKeywords.push(newKeyword[0].keyword);
                }
            });
        });

        duplicateKeywords.join(', ');

        if (duplicateKeywords.length > 0) {
            hasFormError = true;
            errorMessageObj.blacklistedKeywordErrorMessage = `These keywords ${duplicateKeywords} already exists.`;
        }

        if (!isString(blacklistedKeyword) || blacklistedKeyword.length === 0) {
            hasFormError = true;
            errorMessageObj.blacklistedKeywordErrorMessage =
                'Please enter valid black listed keywords in comma separated format.';
        }

        if (!isString(keywordType) || keywordType.length === 0) {
            hasFormError = true;
            errorMessageObj.keywordTypeErrorMessage = 'Please select a keyword type.';
        }

        if (!isString(market) || market.length === 0) {
            hasFormError = true;
            errorMessageObj.marketErrorMessage = 'Please select a market.';
        }

        if (hasFormError) {
            setSaveChangesButtonDisabled(true);
            setErrorMessageObject(errorMessageObj);
        }

        return !hasFormError;
    };

    const handleBlackListedKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBlacklistedKeyword(event?.target?.value);
    };

    const handleMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMarket(event?.target?.value);
    };

    const handleKeywordType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeywordType(event?.target?.value);
    };

    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        dispatch(removeModal());
    };

    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    const fetchAll = () => {
        const resources = ['kwr-blacklisted-keyword', 'seogd-market', 'keyword-type'];
        const allRequests: AxiosPromise[] = [];

        resources.forEach(resource => {
            allRequests.push(
                axios({
                    method: 'GET',
                    url: generateUrl('config', resource, [
                        { key: 'active', value: 1 },
                        { key: 'limit', value: 0 },
                        ...(resource === 'seogd-market' ? [{ key: 'order_by', value: 'country' }] : []),
                    ]),
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
            );
        });
        axios
            .all(allRequests)
            .then(
                axios.spread((...response) => {
                    const blacklistedKeywords = response[0].data.objects.map((keyword: BlackListedKeywordResponse) => {
                        return {
                            id: keyword.id,
                            keyword: keyword.keyword,
                            marketId: keyword.market.id,
                            market: keyword.market.country,
                            keywordTypeId: keyword.type.id,
                            keywordType: keyword.type.type,
                            updated: keyword.updated,
                        };
                    });

                    const allMarkets = response[1].data.objects.map((data: MarketResponse) => {
                        return {
                            label: data.alpha2,
                            value: data.id,
                            name: data.country,
                            ...data,
                        };
                    });

                    const keywordTypes = response[2].data.objects.map((type: KeywordTypeResponse) => {
                        return {
                            label: type.type,
                            value: type.id,
                            name: type.type,
                        };
                    });

                    setDbBlackListedKeywords(blacklistedKeywords);
                    setMarketList(allMarkets);
                    setKeywordTypes(keywordTypes);
                    setIsLoading(false);
                })
            )
            .catch(error => {
                setIsLoading(false);
                setPageError(true);
                errorHandling(error);
            });
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            dispatch(setModal('ManageBlacklistedKeywords', {}));
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to proceed without saving your changes?',
                    },
                    buttons: [
                        {
                            value: 'DISCARD CHANGES',
                            onClick: onPopupDiscardChangesClick,
                        },
                        {
                            value: 'STAY HERE',
                            buttonTheme: ButtonThemes.Secondary,
                            onClick: onPopupStayHereClick,
                        },
                    ],
                })
            );
        }
    };

    const mapBlacklistedKeywordsToMarket = () => {
        let listOfKeywords = blacklistedKeyword.split(',');
        listOfKeywords = [...new Set(listOfKeywords.filter(keyword => /\S/.test(keyword)))];
        const marketId = marketList.filter(markets => markets.value === Number(market))[0]?.value;
        const keywordTypeId = keywordTypes.filter(type => type.value === Number(keywordType))[0]?.value;
        let payload: Array<Array<MapKeywords>>;
        payload = listOfKeywords.map(key => [
            {
                keyword: key.trim(),
                market: generatePath('config', 'seogd-market', String(marketId)),
                type: generatePath('config', 'keyword-type', String(keywordTypeId)),
            },
        ]);
        return payload;
    };

    const saveBlacklistedKeywords = () => {
        return new Promise(async (resolve, reject) => {
            try {
                const keywordRequestConfig: AxiosRequestConfig = {
                    method: 'PATCH',
                    url: generateUrl('config', 'kwr-blacklisted-keyword'),
                    data: {
                        objects: mapBlacklistedKeywordsToMarket().map(keyword => {
                            return keyword[0];
                        }),
                    },
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                };
                await axios(keywordRequestConfig).then(response => {
                    if (![200, 201, 202].includes(response.status)) {
                        throw new Error('Status not 201');
                    }
                });
            } catch (error) {
                errorHandling(error);
                reject('Error making a connection to API.');
            }
            resolve('Blacklisted keyword is added.');
        });
    };

    const handleNavigateManageBlacklistedKeywords = () => {
        dispatch(setModal('ManageBlacklistedKeywords', {}));
    };

    const onSaveChangesClick = () => {
        // If there is an error reopen the goals details tab as the error will be there
        if (!formValidator()) {
            return;
        }

        setSaveChangesButtonLoading(true);
        setSaveChangesButtonDisabled(true);
        setCloseButtonDisabled(true);

        saveBlacklistedKeywords()
            .then(() => {
                dispatch(
                    addNotification({
                        copy: 'Blacklisted keywords added successfully.',
                        type: NotificationMessageType.Success,
                    })
                );
                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
                handleNavigateManageBlacklistedKeywords();
            })
            .catch(error => {
                dispatch(
                    addNotification({
                        copy: 'There was an issue adding Blacklisted Keywords.',
                        type: NotificationMessageType.Error,
                    })
                );

                setSaveChangesButtonLoading(false);
                setSaveChangesButtonDisabled(false);
                setCloseButtonDisabled(false);
            });
    };

    const renderModalNavigation = () => {
        const modalNavigationButtons = [
            {
                value: 'SAVE CHANGES',
                onClick: onSaveChangesClick,
                disabled: saveChangesButtonDisabled,
                isLoading: saveChangesButtonLoading,
            },
            {
                value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: onCloseClick,
                disabled: closeButtonDisabled,
                buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    };

    const renderAccordion = () => {
        const accordions = [
            {
                header: 'Add Blacklisted keyword',
                required: false,
                open: true,
                type: 'form',
                intro: '',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Blacklisted Keywords:',
                                type: 'textarea',
                                requiredField: true,
                                toolTipCopy:
                                    'Enter blacklisted keywords in comma separated format. For example: keyword1, keyword2, keyword3 ...',
                                inputKeyValue: 'blacklistedKeyword',
                                inputValue: blacklistedKeyword,
                                inputOnChange: handleBlackListedKeyword,
                                errorMessage: errorMessageObject.blacklistedKeywordErrorMessage,
                            },
                            {
                                label: 'Market:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a Market',
                                inputKeyValue: 'market',
                                inputOptions: marketList,
                                inputOnChange: handleMarket,
                                errorMessage: errorMessageObject.marketErrorMessage,
                            },
                            {
                                label: 'Keyword Type:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'Select a Keyword Type',
                                inputKeyValue: 'keywordType',
                                inputOptions: keywordTypes,
                                inputOnChange: handleKeywordType,
                                errorMessage: errorMessageObject.keywordTypeErrorMessage,
                            },
                        ],
                    },
                },
            },
        ];

        return <WidgetAccordion accordions={accordions} />;
    };

    if (isLoading) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Add Blacklisted Keywords</h2>
                <LoadingSpinner />
            </div>
        );
    }

    if (pageError) {
        return (
            <div>
                {renderModalNavigation()}
                <h2>Add Blacklisted Keywords</h2>
                <WarningMessage copy="There was a server issue getting this page ready. Please try again later or contact support@cubed.email." />
            </div>
        );
    }

    return (
        <div>
            {renderModalNavigation()}
            <h2>Add Blacklisted Keywords</h2>
            <p>
                Add your blacklisted keywords and select a market and a keyword type using following fields. You can add
                multiple blacklisted keywords using comma separated format.
            </p>
            {renderAccordion()}
        </div>
    );
};

export default LayoutAddBlackListedKeywords;
