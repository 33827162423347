import React from 'react';
import styled from 'styled-components';
import Tooltip, { TooltipPopUpSide } from '../../tooltip';

const StyledLabel = styled.label`
    font-size: 0.9em;
    font-weight: 500;
    min-height: 20px;
    color: ${props => props.theme.forms.labels.color};
`;

const StyledRequiredIndicator = styled.span`
    color: ${props => props.theme.forms.errorMessage.textColor};
    margin-left: 5px;
`;

export type InputLabelProps = {
    htmlFor: string;
    label: string;
    required?: boolean;
    tooltipCopy?: string;
    tooltipPosition?: TooltipPopUpSide;
};

const InputLabel = ({
    htmlFor,
    label,
    required,
    tooltipCopy,
    tooltipPosition = TooltipPopUpSide.Left,
}: InputLabelProps) => {
    return (
        <StyledLabel htmlFor={htmlFor}>
            {label}:{required && <StyledRequiredIndicator>*</StyledRequiredIndicator>}
            {tooltipCopy && <Tooltip copy={tooltipCopy} tooltipPopUpSide={tooltipPosition} />}
        </StyledLabel>
    );
};

export default InputLabel;
