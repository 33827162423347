/* eslint-disable react-hooks/exhaustive-deps */

// React dependencies
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch } from './redux/hooks';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// NPM libraries
import Axios, { AxiosRequestConfig } from 'axios';
import { generateUrl } from './helpers/request-builder';

// View components
import ViewLogin from './views/login';
import ViewForgottenPassword from './views/forgotten-password';
import ViewAccountSelection from './views/account-selection';
import ViewLoadingSplash from './views/loading-splash';
import ViewDashboard from './views/dashboard';
import ViewError404 from './views/error_404';
import ViewUserConfiguration from './views/user-configuration';
import ViewAdminConfiguration from './views/admin-configuration';
import ViewUserPasswordReset from './views/user-password-reset';
import ViewUserInvite from './views/user-invite';
import ViewUserSignup from './views/user-signup';
import ViewSignOut from './views/sign-out';
import ViewForceUpdateDetails from './views/force-update-details';
import ViewInviteInvalid from './views/invite-invalid';

// Routes
import RouteSuperUserOnly from './components/routes/super-user-only';

// Redux dependencies
import { setUser, setUserAccounts } from './redux/actions/user';
import { setAccount } from './redux/actions/account';
import { setMeta } from './redux/actions/meta';

// Styles and Styled components
import { ThemeProvider } from 'styled-components';
import CubedThemeProvider from './styled-components/theme-context/cubed-theme-context';
import theme from './styled-components/theme.styled';
import ResetStyles from './styled-components/reset.styled';
import './sass/core.scss';
import { fetchCurrency } from './helpers/fetch-currency';

export interface IAppProps {}

const App: FunctionComponent<IAppProps> = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(
            setMeta({
                environment: process.env.REACT_APP_ENVIRONMENT,
                apiDomain: process.env.REACT_APP_API_DOMAIN,
            })
        );

        setTimeout(() => {
            checkUserAccount();
        }, 100);
    }, []);

    const checkUserAccount = async () => {
        await attemptUserLogin();
        await attemptGetAccount();

        setIsLoading(false);
    };

    const attemptUserLogin = () => {
        return new Promise(resolve => {
            const loginUrl = generateUrl('user', 'login', [], false);

            const config: AxiosRequestConfig = {
                method: 'POST',
                url: loginUrl!,
                data: {},
                withCredentials: true,
            };

            Axios(config)
                .then((res: any) => {
                    if (res.status === 200) {
                        dispatch(
                            setUser({
                                id: res.data.id,
                                username: res.data.username,
                                firstName: res.data.firstName,
                                lastName: res.data.lastName,
                                email: res.data.email,
                                staff: res.data.staff,
                                superUser: res.data.superUser,
                                strongPassword: true,
                                usernameIsEmail: true,
                                accounts: [],
                            })
                        );

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
                .catch((err: any) => {
                    resolve(false);
                });
        });
    };

    const attemptGetAccount = async () => {
        return new Promise(resolve => {
            const pathAsArray = location.pathname.split('/');
            let accountToken = '';

            if (pathAsArray.length > 1 && !/login|login|account-selection|cubed-admin/.test(pathAsArray[1])) {
                // Check if the account token is the first value
                accountToken = pathAsArray[1];
            } else if (localStorage.getItem('selectedAccount') !== null) {
                try {
                    const selectedAccount = JSON.parse(localStorage.getItem('selectedAccount') || '{}');

                    accountToken = selectedAccount.token;
                } catch (e) {
                    // Future error handling
                }
            }

            if (accountToken !== '') {
                const accountUrl = generateUrl('user', 'accounts', [], false);

                const config: AxiosRequestConfig = {
                    method: 'GET',
                    url: accountUrl!,
                    withCredentials: true,
                };

                Axios(config)
                    .then((res: any) => {
                        dispatch(setUserAccounts(res.data));

                        const selectedAccount = res.data.find((account: any) => account.token === accountToken);

                        if (selectedAccount) {
                            fetchCurrency(selectedAccount.id, dispatch);
                            dispatch(setAccount(selectedAccount));
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })
                    .catch((err: any) => {
                        console.error(err);
                        resolve(false);
                    });
            } else {
                resolve(false);
            }
        });
    };
    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <ViewLoadingSplash />
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <ResetStyles />
            <Routes>
                {/* Public Facing */}
                <Route path="/*" element={<ViewLogin />} />
                <Route path="/forgotten-password/*" element={<ViewForgottenPassword />} />
                <Route path="/user/password/reset-password/:token" element={<ViewUserPasswordReset />} />
                <Route path="/user/invite" element={<ViewUserInvite />} />
                <Route path="/user/signup" element={<ViewUserSignup />} />
                <Route path="/user/invite-invalid" element={<ViewInviteInvalid />} />

                {/* User Protected */}
                <Route path="/account-selection/*" element={<ViewAccountSelection />} />
                <Route path="/force-update-details" element={<ViewForceUpdateDetails />} />
                <Route path="/sign-out" element={<ViewSignOut />} />
                <Route path="/user" element={<ViewUserConfiguration />} />
                <Route
                    path="/cubed-admin"
                    element={
                        <RouteSuperUserOnly>
                            <ViewAdminConfiguration />
                        </RouteSuperUserOnly>
                    }
                />
                <Route path="/404" element={<ViewError404 />} />
                <Route
                    path="/:account/*"
                    element={
                        <CubedThemeProvider>
                            <ViewDashboard />
                        </CubedThemeProvider>
                    }
                />

                {/* Error Pages */}
                <Route element={<Navigate to="/404" />} />
            </Routes>
        </ThemeProvider>
    );
};

export default App;
