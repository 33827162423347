// React Dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Core Dependencies
import Axios from 'axios';
import moment from 'moment';
import Request from './../../helpers/request';
import { generateUrl, generatePath, generateUrlDetail } from '../../helpers/request-builder';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { removeModal, setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';
import { updateUser } from '../../redux/actions/user';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import LoadingSpinner from '../../components/loading-spinner';
import InputButton from '../../components/inputs/input-button';
import SimpleTable from '../../components/tables/components/simple-table';

//
import WidgetAccordion from '../../widgets/accordion';
import { isString, isDate } from '../../helpers/validator';
import InputButtonWrapper from '../../components/inputs/input-button-wrapper';

class LayoutModalManageAboveTheLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            closeButtonState: 'close',
            saveChangesButtonDisabled: true,
            atlListAccordionOpen: true,
            atlDetailsAccordionOpen: false,
            nationWide: false,
            country: [],
            city: [],
            storeRegionAdded: [].sort((a, b) => a.name.localeCompare(b.name)),
            regionSelectedOnList: [],
            refererOptions: [],
            deleteRegionDisabled: true,
            atlStartDate: '',
            atlEndDate: '',
            atlNameForEdit: '',
            atlCountryForEdit: '',
            atlCityForEdit: '',
            atlChannelForEdit: '',

            atlStartDateErrorMessage: '',
            atlEndDateErrorMessage: '',
            atlNameForEditErrorMessage: '',
            atlCountryForEditErrorMessage: '',
            atlCityForEditErrorMessage: '',
            atlChannelForEditErrorMessage: '',

            ATLList: [],
            storeATLInfo: [],
            ATLSelected: false,
            ATLConfigList: [],
            selectedEvents: [],
            atlOnEdit: [],
            editButtonDisabled: true,
            editButtonLoading: false,
            cancelButtonDisabled: true,
            deleteButtonDisabled: true,
            disableATLSelect: false,
            deleteRegions: [],
            createATLButtonDisabled: false,
            editATLConfigApplyLoading: false,
        };

        this.formValidator = this.formValidator.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.requestCityOptions = this.requestCityOptions.bind(this);
        this.getCountriesList = this.getCountriesList.bind(this);
        this.fetchATLList = this.fetchATLList.bind(this);

        this.renderATLList = this.renderATLList.bind(this);
        this.onATLListClick = this.onATLListClick.bind(this);
        this.onEditATLClick = this.onEditATLClick.bind(this);
        this.onDeleteRegionClick = this.onDeleteRegionClick.bind(this);
        this.onDeleteATLClick = this.onDeleteATLClick.bind(this);
        this.onATLNameChange = this.onATLNameChange.bind(this);
        this.onSelectCountryChange = this.onSelectCountryChange.bind(this);
        this.onSelectCityChange = this.onSelectCityChange.bind(this);
        this.onEditATLConfigsApplyClick = this.onEditATLConfigsApplyClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);
        this.onRegionDetailsAddClick = this.onRegionDetailsAddClick.bind(this);
        this.onPopupDiscardChangesClick = this.onPopupDiscardChangesClick.bind(this);
        this.onPopupStayHereClick = this.onPopupStayHereClick.bind(this);
        this.onDeleteATLConfirmed = this.onDeleteATLConfirmed.bind(this);
        this.onRegionListSelected = this.onRegionListSelected.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);

        this.handleOnCreate = this.handleOnCreate.bind(this);
        this.renderModalNavigation = this.renderModalNavigation.bind(this);
        this.renderAccordion = this.renderAccordion.bind(this);
        this.renderRegionForm = this.renderRegionForm.bind(this);
    }

    componentDidMount() {
        this.fetchATLList();
        this.fetchRefererList();
    }
    componentDidUpdate() {
        this.fetchATLList();
        this.fetchRefererList();
    }

    fetchRefererList = () => {
        if (this.state.isLoading) {
            const fetchReferer = new Request();

            fetchReferer.get('config', 'referer', [{ key: 'active', value: 1 }]).then(response => {
                const refererList = response.data.objects.map(referer => {
                    return {
                        id: referer.id,
                        name: referer.name,
                    };
                });

                this.setState({
                    refererOptions: refererList,
                    isLoading: false,
                });
            });
        }
    };

    fetchATLList() {
        if (this.state.isLoading) {
            const fetchPromise = new Request();

            fetchPromise.get('config', 'atl', [{ key: 'active', value: 1 }]).then(response => {
                const getATLList = response.data.objects.map(object => {
                    return {
                        keyValue: 'atl-option_' + object.name,
                        value: object.id,
                        name: object.name,
                    };
                });
                this.setState({
                    ATLList: getATLList,
                    isLoading: false,
                });
            });
        }
    }

    formValidator() {
        let hasFormError = false;
        let errorMessageObject = {
            atlStartDateErrorMessage: '',
            atlEndDateErrorMessage: '',
            atlNameForEditErrorMessage: '',
            atlChannelForEditErrorMessage: '',
        };

        if (!isString(this.state.atlNameForEdit) || this.state.atlNameForEdit.length === 0) {
            hasFormError = true;
            errorMessageObject.atlNameForEditErrorMessage = 'Please enter a valid name.';
        }

        if (!isDate(this.state.atlStartDate) || this.state.atlStartDate.length === 0) {
            hasFormError = true;
            errorMessageObject.atlStartDateErrorMessage = 'Please select a start date.';
        }

        if (!isDate(this.state.atlEndDate) || this.state.atlEndDate.length === 0) {
            hasFormError = true;
            errorMessageObject.atlEndDateErrorMessage = 'Please select an end date.';
        }

        if (this.state.atlStartDate > this.state.atlEndDate) {
            hasFormError = true;
            errorMessageObject.atlEndDateErrorMessage = 'Please select a start date or Please select an end date';
        }

        if (this.state.atlChannelForEdit.length === 0) {
            hasFormError = true;
            errorMessageObject.atlChannelForEditErrorMessage = 'Please select a channel.';
        }

        if (hasFormError) {
            this.setState({
                atlStartDateErrorMessage: errorMessageObject.atlStartDateErrorMessage,
                atlEndDateErrorMessage: errorMessageObject.atlEndDateErrorMessage,
                atlNameForEditErrorMessage: errorMessageObject.atlNameForEditErrorMessage,
                atlChannelForEditErrorMessage: errorMessageObject.atlChannelForEditErrorMessage,
            });
        }
        return !hasFormError;
    }

    dropDownValidator() {
        let hasFormError = false;
        let errorMessageObject = {
            atlCountryForEditErrorMessage: '',
            atlCityForEditErrorMessage: '',
        };

        if (this.state.atlCountryForEdit.length === 0) {
            hasFormError = true;
            errorMessageObject.atlCountryForEditErrorMessage = 'Please select a country.';
        }

        if (this.state.atlCityForEdit.length === 0) {
            hasFormError = true;
            errorMessageObject.atlCityForEditErrorMessage = 'Please select a city.';
        }

        if (hasFormError) {
            this.setState({
                atlCountryForEditErrorMessage: errorMessageObject.atlCountryForEditErrorMessage,
                atlCityForEditErrorMessage: errorMessageObject.atlCityForEditErrorMessage,
            });
        }
        return !hasFormError;
    }

    onDeleteATLClick() {
        this.props.setPopup({
            title: 'Delete',
            iconType: 'warning',
            contentType: 'simple',
            config: {
                copy: 'Are you sure you would like to delete this ATL configuration?',
            },
            buttons: [
                {
                    onClick: this.onDeleteATLConfirmed,
                    value: 'YES, DELETE',
                    buttonTheme: ButtonThemes.Red,
                },
                {
                    onClick: this.onPopupStayHereClick,
                    value: 'CANCEL',
                    buttonTheme: ButtonThemes.Secondary,
                },
            ],
        });
    }

    onDeleteATLConfirmed() {
        return new Promise(async (resolve, reject) => {
            let requests = [];

            this.state.selectedEvents.forEach(event => {
                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrlDetail('config', `atl`, event.value),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );

                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrl('config', `atl-config`, [{ key: 'atl', value: event.value }]),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );

                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrl('config', `atl-region`, [{ key: 'atl_config', value: event.value }]),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            });
            Axios.all(requests)
                .then(() => {
                    resolve('Request successful');
                    this.props.removePopup();
                    this.props.addNotification({
                        copy: 'Delete ATL successful.',
                        type: NotificationMessageType.Success,
                    });
                    this.setState({ isLoading: true });
                    this.fetchATLList();
                })
                .catch(() => {
                    reject('Error making a connection to API.');
                    this.props.removePopup();
                    this.props.addNotification({
                        copy: 'There was an issue deleting the details, please try again later.',
                        type: NotificationMessageType.Error,
                    });
                });
        });
    }

    sendRequest() {
        this.setState({ editATLConfigApplyLoading: true });
        return new Promise(async (resolve, reject) => {
            const atlID = this.state.atlOnEdit[0].value;
            const refererId = this.state.refererOptions.filter(
                referer => referer.name === this.state.atlChannelForEdit
            )[0].id;
            const requests = [];

            if (this.state.storeATLInfo.name !== this.state.atlNameForEdit) {
                requests.push(
                    Axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'atl', atlID),
                        data: {
                            name: this.state.atlNameForEdit,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            }

            if (
                this.state.storeATLInfo.configs[0].startDate !== this.state.atlStartDate ||
                this.state.storeATLInfo.configs[0].endDate !== this.state.atlEndDate ||
                this.state.storeATLInfo.configs[0]?.referer?.id !== refererId
            ) {
                requests.push(
                    Axios({
                        method: 'PATCH',
                        url: generateUrlDetail('config', 'atl-config', this.state.storeATLInfo.configs[0].id),
                        data: {
                            base_activity_start_date: this.state.atlStartDate,
                            base_activity_end_date: this.state.atlEndDate,
                            atl: generatePath('config', 'atl', atlID),
                            active: 1,
                            referer: generatePath('config', 'referer', refererId),
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            }

            if (
                this.state.storeATLInfo.nationWide === true &&
                this.state.storeATLInfo.nationWide === this.state.nationWide
            ) {
                if (JSON.stringify(this.state.storeATLInfo.regions) !== JSON.stringify(this.state.storeRegionAdded)) {
                    if (this.state.deleteRegions.length > 0) {
                        this.state.deleteRegions.forEach(region => {
                            requests.push(
                                Axios({
                                    method: 'DELETE',
                                    url: generateUrlDetail('config', `atl-region`, region.id),
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                })
                            );
                        });
                    }

                    this.state.storeRegionAdded.forEach(region => {
                        if (region.id === '') {
                            requests.push(
                                Axios({
                                    method: 'POST',
                                    url: generateUrl('config', 'atl-region'),
                                    data: {
                                        atl_config: generatePath('config', `atl`, atlID),
                                        country_name: region.country,
                                        city_name: region.city,
                                        nationwide: !this.state.nationWide,
                                    },
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                })
                            );
                        }
                    });
                }
            } else if (
                this.state.storeATLInfo.nationWide === false &&
                this.state.storeATLInfo.nationWide !== this.state.nationWide
            ) {
                if (this.state.storeATLInfo.nationWide === false) {
                    requests.push(
                        Axios({
                            method: 'DELETE',
                            url: generateUrl('config', `atl-region`, [{ key: 'atl_config', value: atlID }]),
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        })
                    );
                }
                if (JSON.stringify(this.state.storeATLInfo.regions) !== JSON.stringify(this.state.storeRegionAdded)) {
                    this.state.storeRegionAdded.forEach(region => {
                        if (region.id === '') {
                            requests.push(
                                Axios({
                                    method: 'POST',
                                    url: generateUrl('config', 'atl-region'),
                                    data: {
                                        atl_config: generatePath('config', `atl`, atlID),
                                        country_name: region.country,
                                        city_name: region.city,
                                        nationwide: !this.state.nationWide,
                                    },
                                    withCredentials: true,
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                })
                            );
                        }
                    });
                }
            } else if (
                this.state.storeATLInfo.nationWide === true &&
                this.state.storeATLInfo.nationWide !== this.state.nationWide
            ) {
                requests.push(
                    Axios({
                        method: 'DELETE',
                        url: generateUrl('config', `atl-region`, [{ key: 'atl_config', value: atlID }]),
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );

                requests.push(
                    Axios({
                        method: 'POST',
                        url: generateUrl('config', 'atl-region'),
                        data: {
                            atl_config: generatePath('config', `atl`, atlID),
                            country_name: '',
                            city_name: '',
                            nationwide: !this.state.nationWide,
                        },
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                );
            }

            Axios.all(requests)
                .then(() => {
                    resolve('Request successful');
                    this.props.addNotification({
                        copy: 'The ATL it was updated successfully.',
                        type: NotificationMessageType.Success,
                    });
                    this.onAccordionCloseClick();
                    this.fetchATLList();
                })
                .catch(() => {
                    reject('Error making a connection to API.');
                    this.props.addNotification({
                        copy: 'There was an issue updating the details, please try again later.',
                        type: NotificationMessageType.Error,
                    });
                })
                .finally(() => {
                    this.setState({ editATLConfigApplyLoading: false });
                });
        });
    }

    onEditATLConfigsApplyClick() {
        this.setState({ editATLConfigApplyLoading: true });
        if (this.formValidator()) {
            if (this.state.nationWide && this.state.storeRegionAdded.length === 0) {
                this.props.addNotification({
                    copy: 'The region list is empty, please add a region to submit the form.',
                    type: NotificationMessageType.Error,
                });
            } else {
                this.sendRequest();
            }
        } else {
            this.setState({
                saveChangesButtonDisabled: false,
                editATLConfigApplyLoading: false,
            });
        }
    }

    onAccordionCloseClick = () => {
        this.setState({
            isLoading: true,
            closeButtonState: 'close',
            saveChangesButtonDisabled: true,
            atlListAccordionOpen: true,
            atlDetailsAccordionOpen: true,
            nationWide: false,
            country: [],
            city: [],
            storeRegionAdded: [],
            deleteRegionDisabled: true,
            atlStartDate: '',
            atlEndDate: '',
            atlNameForEdit: '',
            atlCountryForEdit: '',
            atlCityForEdit: '',
            atlNameForEditErrorMessage: '',
            atlStartDateErrorMessage: '',
            atlEndDateErrorMessage: '',
            atlCountryForEditErrorMessage: '',
            atlCityForEditErrorMessage: '',
            atlChannelForEditErrorMessage: '',
            ATLList: [],
            storeATLInfo: [],
            ATLSelected: false,
            ATLConfigList: [],
            selectedEvents: [],
            atlOnEdit: [],
            editButtonDisabled: true,
            cancelButtonDisabled: true,
            deleteButtonDisabled: true,
            disableATLSelect: false,
            deleteRegions: [],
        });
    };

    // PrimaryAction of the close popup
    onPopupDiscardChangesClick() {
        this.props.removePopup();
        this.props.removeModal();
    }

    // SecondaryAction of the close popup
    onPopupStayHereClick() {
        this.props.removePopup();
    }

    handleOnCreate() {
        this.props.setModal('SetupAboveTheLine', {});
    }

    onCheckboxChange() {
        if (!this.state.nationWide) {
            this.setState({
                city: [],
                atlCountryForEdit: '',
                atlCityForEdit: '',
            });
        }

        this.setState({
            nationWide: !this.state.nationWide,
            closeButtonState: 'cancel',
            saveChangesButtonDisabled: false,
        });

        if (this.state.country.length === 0) {
            this.getCountriesList();
        }
    }

    requestCityOptions(country) {
        const fetchPromise = new Request();

        fetchPromise
            .get('config', 'region-list', [
                { key: 'active', value: 1 },
                { key: 'country_name', value: country },
            ])
            .then(response => {
                const formattedCity = response.data.objects
                    .filter(
                        (item, index, self) =>
                            item.city_name !== '(Not Set)' &&
                            self.findIndex(i => i.city_name === item.city_name) === index
                    )
                    .map(object => {
                        return {
                            keyValue: 'city-option_' + object.city_name,
                            value: object.city_name,
                            name: object.city_name,
                        };
                    });
                this.setState({
                    city: formattedCity.sort((a, b) => a.name.localeCompare(b.name)),
                });
            });
    }

    checkCloseButtonState = () => {
        if (this.state.closeButtonState === 'close') {
            this.setState({
                closeButtonState: 'cancel',
                saveChangesButtonDisabled: false,
            });
        }
    };

    // Manage the input fields changes and updating the state with the new value entered by the user
    onATLNameChange(event) {
        this.checkCloseButtonState();

        this.setState({
            atlNameForEdit: event.target.value,
        });
    }
    onSelectCountryChange(event) {
        this.checkCloseButtonState();
        this.requestCityOptions(event.target.value);

        this.setState({
            atlCountryForEdit: event.target.value,
        });
    }
    onSelectCityChange(event) {
        this.checkCloseButtonState();

        this.setState({
            atlCityForEdit: event.target.value,
        });
    }

    handleChangeActivityStartDate = event => {
        this.setState({ atlStartDate: event.target.value });
    };

    handleChangeActivityEndDate = event => {
        this.setState({ atlEndDate: event.target.value });
    };

    onRefererInputChange = event => {
        const selectedRefererId = this.state.refererOptions.filter(referer => referer.name === event.target.value)[0]
            .name;
        this.setState({
            atlChannelForEdit: selectedRefererId,
        });
    };

    onCloseClick() {
        if (this.state.closeButtonState === 'close') {
            this.props.removeModal();
        } else {
            this.props.setPopup({
                title: 'Unsaved Changes',
                iconType: 'warning',
                contentType: 'simple',
                config: {
                    copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                },
                buttons: [
                    {
                        onClick: this.onPopupDiscardChangesClick,
                        value: 'DISCARD CHANGES',
                    },
                    {
                        onClick: this.onPopupStayHereClick,
                        value: 'STAY HERE',
                        buttonTheme: ButtonThemes.Secondary,
                    },
                ],
            });
        }
    }

    getCountriesList() {
        const fetchPromise = new Request();

        fetchPromise.get('config', 'region-list', [{ key: 'active', value: 1 }]).then(response => {
            const formattedCountry = response.data.objects
                .filter(
                    (item, index, self) =>
                        item.country_name !== '(Not Set)' &&
                        self.findIndex(i => i.country_name === item.country_name) === index
                )
                .map(object => {
                    return {
                        keyValue: 'country-option_' + object.country_name,
                        value: object.country_name,
                        name: object.country_name,
                    };
                });
            this.setState({
                country: formattedCountry.sort((a, b) => a.name.localeCompare(b.name)),
            });
        });
    }

    onRegionListSelected(event) {
        const selectedCity = event.currentTarget.getAttribute('data-value');
        let selectedEvents = [];

        if (this.state.deleteRegions.filter(region => region.city === selectedCity).length > 0) {
            selectedEvents = this.state.deleteRegions.filter(region => region.city !== selectedCity);
            this.setState({
                deleteRegions: selectedEvents,
                deleteRegionDisabled: selectedEvents === 0,
            });
        } else {
            selectedEvents = this.state.deleteRegions.concat(
                this.state.storeRegionAdded.filter(region => region.city === selectedCity)
            );
        }

        let deleteRegionDisabled = true;

        if (selectedEvents.length > 0) {
            deleteRegionDisabled = false;
        }

        this.setState({
            deleteRegions: selectedEvents,
            deleteRegionDisabled: deleteRegionDisabled,
        });
    }

    onRegionDetailsAddClick(event) {
        if (this.dropDownValidator()) {
            if (this.state.closeButtonState === 'close') {
                this.setState({
                    closeButtonState: 'cancel',
                    saveChangesButtonDisabled: false,
                });
            }
            this.setState(prevState => ({
                storeRegionAdded: [
                    ...prevState.storeRegionAdded,
                    {
                        id: '',
                        country: this.state.atlCountryForEdit,
                        city: this.state.atlCityForEdit,
                    },
                ],
                atlCityForEdit: '',
                atlCountryForEditErrorMessage: '',
                atlCityForEditErrorMessage: '',
            }));
        }
    }

    onDeleteRegionClick() {
        if (this.state.closeButtonState === 'close') {
            this.setState({
                closeButtonState: 'cancel',
                saveChangesButtonDisabled: false,
            });
        }

        this.setState({
            storeRegionAdded: this.state.storeRegionAdded.filter(region => !this.state.deleteRegions.includes(region)),
        });
    }

    onATLListClick(event) {
        if (this.state.disableATLSelect === true) {
            return;
        }
        const selectedEventId = parseInt(event.currentTarget.getAttribute('data-value'));
        let selectedEvents = [];

        if (this.state.selectedEvents.filter(event => event.value === selectedEventId).length > 0) {
            selectedEvents = this.state.selectedEvents.filter(event => event.value !== selectedEventId);
            this.setState({
                selectedEvents: selectedEvents,
                editButtonDisabled: selectedEvents === 0,
                deleteButtonDisabled: selectedEvents === 0,
            });
        } else {
            selectedEvents = this.state.selectedEvents.concat(
                this.state.ATLList.filter(event => event.value === selectedEventId)
            );
        }

        let editButtonDisabled = true;
        let deleteButtonDisabled = true;

        if (selectedEvents.length > 0) {
            editButtonDisabled = false;
            deleteButtonDisabled = false;
        }

        if (selectedEvents.length > 1) {
            editButtonDisabled = true;
            deleteButtonDisabled = false;
        }

        this.setState({
            selectedEvents: selectedEvents,
            editButtonDisabled: editButtonDisabled,
            deleteButtonDisabled: deleteButtonDisabled,
        });
    }

    onEditATLClick = () => {
        this.setState({ editButtonLoading: true });
        const fetchPromise = new Request();
        const atlID = this.state.selectedEvents[0].value;

        let isNationWide = false;
        let configList = [];
        let regionList = [];

        fetchPromise.get('config', 'atl-config', [{ key: 'atl', value: atlID }]).then(configs => {
            if (configs.data.objects[0]) {
                configList = configs.data.objects.map(config => {
                    return {
                        id: config.id,
                        startDate: moment(config.base_activity_start_date).format('YYYY-MM-DD'),
                        endDate: moment(config.base_activity_end_date).format('YYYY-MM-DD'),
                        channel: config?.referer?.name,
                    };
                });
            }

            fetchPromise.get('config', 'atl-region', [{ key: 'atl_config', value: atlID }]).then(regions => {
                if (regions.data.objects[0]) {
                    isNationWide = !regions.data.objects[0].nationwide;
                    regionList = regions.data.objects.map(region => {
                        return {
                            id: region.id,
                            country: region.country_name,
                            city: region.city_name,
                        };
                    });
                }

                if (isNationWide) {
                    this.getCountriesList();
                } else {
                    regionList = [];
                }

                this.setState({
                    ATLSelected: true,
                    atlListAccordionOpen: false,
                    atlDetailsAccordionOpen: true,
                    editButtonDisabled: true,
                    editButtonLoading: false,
                    cancelButtonDisabled: false,
                    deleteButtonDisabled: true,
                    disableATLSelect: true,
                    atlNameForEdit: this.state.selectedEvents[0].name,
                    atlStartDate: configList[0].startDate,
                    atlEndDate: configList[0].endDate,
                    atlChannelForEdit: configList[0].channel,
                    nationWide: isNationWide,
                    storeRegionAdded: regionList,
                    atlOnEdit: [].concat(this.state.selectedEvents.filter(event => event.value === atlID)),
                    storeATLInfo: {
                        name: this.state.selectedEvents[0].name,
                        configs: configList,
                        nationWide: isNationWide,
                        regions: regionList,
                    },
                });
            });
        });
    };

    renderModalNavigation() {
        const modalNavigationButtons = [
            {
                value: this.state.closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                onClick: this.onCloseClick,
                disabled: false,
                buttonTheme:
                    this.state.closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
            },
        ];

        return <ModalNavigation buttons={modalNavigationButtons} />;
    }

    renderATLList() {
        const ATLRows = this.state.ATLList.map(event => {
            const rowProperty = {
                selected: this.state.selectedEvents.includes(event),
                disabled: this.state.disableATLSelect === true,
            };

            return {
                columns: [
                    {
                        copy: event.name,
                    },
                ],
                keyValue: `ATL__${event.name}`,
                dataValue: event.value,
                rowProperty,
                onClick: this.onATLListClick,
            };
        });

        const header = {
            columns: [
                {
                    title: 'Select ATL',
                },
            ],
        };

        return <SimpleTable header={header} rows={ATLRows} hasIcons={true} isScrollable={true} />;
    }

    renderAccordion() {
        const accordions = [
            {
                header: 'Manage above the line',
                required: true,
                open: this.state.atlDetailsAccordionOpen,
                type: 'form',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'ATL Name:',
                                type: 'text',
                                requiredField: true,
                                toolTipCopy: 'Enter a name for ATL, for example, TV.',
                                inputKeyValue: 'edit-atl__atl-name',
                                inputPlaceholder: 'ATL name...',
                                inputValue: this.state.atlNameForEdit,
                                inputOnChange: this.onATLNameChange,
                                errorMessage: this.state.atlNameForEditErrorMessage,
                            },
                            {
                                label: 'Base Activity Start Date:',
                                type: 'datePicker',
                                requiredField: true,
                                toolTipCopy:
                                    'The start date for a period in time where no ATL was not running. We use this period for modelling a baseline.',
                                inputValue: this.state.atlStartDate,
                                inputOnChange: this.handleChangeActivityStartDate,
                                errorMessage: this.state.atlStartDateErrorMessage,
                            },
                            {
                                label: 'Base Activity End Date:',
                                type: 'datePicker',
                                requiredField: true,
                                toolTipCopy:
                                    'The end date for a period in time where no ATL was not running. We use this period for modelling a baseline.',
                                inputValue: this.state.atlEndDate,
                                inputOnChange: this.handleChangeActivityEndDate,
                                errorMessage: this.state.atlEndDateErrorMessage,
                            },
                            {
                                label: 'Channel:',
                                type: 'select',
                                requiredField: true,
                                toolTipCopy: 'The channel associated with the ATL reporting.',
                                customPlaceholder: '',
                                inputOptions: this.state.refererOptions,
                                inputValue: this.state.atlChannelForEdit,
                                inputOnChange: this.onRefererInputChange,
                                errorMessage: this.state.atlChannelForEditErrorMessage,
                            },
                            {
                                label: 'Regional Tick-box:',
                                type: 'checkbox',
                                toolTipCopy:
                                    'If your ATL activity is regional, tick this box. If left un-ticked your ATL activity will be assumed nationwide.',
                                checked: this.state.nationWide,
                                inputOnChange: this.onCheckboxChange,
                            },
                        ],
                        buttons: [
                            {
                                value: 'APPLY',
                                isLoading: this.state.editATLConfigApplyLoading,
                                onClick: this.onEditATLConfigsApplyClick,
                                disabled: this.state.editATLConfigApplyDisabled,
                            },
                            {
                                value: 'CANCEL',
                                buttonTheme: ButtonThemes.Secondary,
                                onClick: this.onAccordionCloseClick,
                                disabled: this.state.editATLoConfigCancelDisabled,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    }

    renderRegionForm() {
        const regionRows = this.state.storeRegionAdded.map(region => {
            const rowProperty = {
                selected: this.state.deleteRegions.includes(region),
            };

            return {
                columns: [
                    {
                        copy: region.country,
                    },
                    {
                        copy: region.city,
                    },
                ],
                keyValue: `region__${region.city}`,
                dataValue: region.city,
                rowProperty,
                onClick: this.onRegionListSelected,
            };
        });

        const accordions = [
            {
                header: 'Multiple regions',
                required: false,
                open: this.state.nationWide,
                type: 'formSimpleTable',
                intro: '<p>Please add a country to submit the form. </p>',
                config: {
                    formConfig: {
                        fields: [
                            {
                                label: 'Country:',
                                type: 'select',
                                requiredField: false,
                                inputKeyValue: 'edit-atl__country',
                                inputOptions: this.state.country,
                                customPlaceholder: '',
                                inputValue: this.state.atlCountryForEdit,
                                inputOnChange: this.onSelectCountryChange,
                                errorMessage: this.state.atlCountryForEditErrorMessage,
                            },
                            {
                                label: 'City:',
                                type: 'select',
                                requiredField: false,
                                inputKeyValue: 'edit-atl__city',
                                inputOptions: this.state.city,
                                inputValue: this.state.atlCityForEdit,
                                inputOnChange: this.onSelectCityChange,
                                errorMessage: this.state.atlCityForEditErrorMessage,
                            },
                        ],
                        buttons: [
                            {
                                value: 'ADD',
                                onClick: this.onRegionDetailsAddClick,
                            },
                        ],
                        columns: 2,
                    },
                    tableConfig: {
                        header: {
                            columns: [
                                {
                                    title: 'Country',
                                },
                                {
                                    title: 'City',
                                },
                            ],
                        },
                        rows: regionRows,
                        isScrollable: true,
                    },
                    optionalButtonConfig: {
                        buttons: [
                            {
                                value: 'DELETE',
                                buttonTheme: ButtonThemes.Red,
                                onClick: this.onDeleteRegionClick,
                                disabled: this.state.deleteRegionDisabled,
                            },
                        ],
                    },
                },
            },
        ];
        return <WidgetAccordion accordions={accordions} />;
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="modal__side-panel__create-event-wizard">
                    <this.renderModalNavigation />
                    <h2>Manage Above the Line</h2>
                    <LoadingSpinner />
                </div>
            );
        }

        return (
            <div className="modal__side-panel__manage-atl manage-modals">
                <this.renderModalNavigation />
                <h2>Manage Above the Line</h2>
                <p>Manage previously configured ATL configurations below.</p>
                <div className="modal__side-panel__manage-atl__atl">
                    <this.renderATLList />
                    <InputButtonWrapper>
                        <InputButton
                            value="ADD ATL CONFIG"
                            disabled={!this.state.cancelButtonDisabled}
                            onClick={this.handleOnCreate}
                        />
                        <InputButton
                            value="EDIT"
                            disabled={this.state.editButtonDisabled}
                            isLoading={this.state.editButtonLoading}
                            onClick={this.onEditATLClick}
                        />
                        <InputButton
                            value="DELETE"
                            buttonTheme={ButtonThemes.Red}
                            disabled={this.state.deleteButtonDisabled}
                            onClick={this.onDeleteATLClick}
                        />
                    </InputButtonWrapper>

                    {this.state.ATLSelected && <this.renderAccordion />}
                    {this.state.nationWide && <this.renderRegionForm />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta,
        account: state.account,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeModal: () => {
            dispatch(removeModal());
        },
        setModal: (type, config) => {
            dispatch(setModal(type, config));
        },
        setPopup: popup => {
            dispatch(setPopup(popup));
        },
        removePopup: () => {
            dispatch(removePopup());
        },
        updateUser: user => {
            dispatch(updateUser(user));
        },
        addNotification: notification => {
            dispatch(addNotification(notification));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutModalManageAboveTheLine);
