import React from 'react';
import styled from 'styled-components';

// Images
import IconPieChart from '../../../components/icons/pie-chart';
import IconLineChart from '../../../components/icons/line-chart';
import IconBarColumnChart from '../../../components/icons/bar-column-chart';
import IconBarHorizontalChart from '../../../components/icons/bar-horizontal-chart';
import IconTrendLineChart from '../../../components/icons/trend-line-chart';
import IconDoughnutChart from '../../../components/icons/doughnut-chart';

const svgImageStyles = () => {
    return `
        width: 20px;
        height: 20px;
        position: relative;
        top: 2px;
    `;
};

const StyledImage = styled(IconPieChart)`
    ${svgImageStyles}
`;
const StyledLineChartImage = styled(IconLineChart)`
    ${svgImageStyles}
`;
const StyledBarColumnChartImage = styled(IconBarColumnChart)`
    ${svgImageStyles}
`;
const StyledBarHorizontalChartImage = styled(IconBarHorizontalChart)`
    ${svgImageStyles}
`;
const StyledDonutChartImage = styled(IconDoughnutChart)`
    ${svgImageStyles}
`;
const StyledTrendLineChartImage = styled(IconTrendLineChart)`
    ${svgImageStyles}
`;

const StyledImageButton = styled.button`
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    background-color: ${props =>
        (props.chartType === props.selectedChartType && props.theme.colours.selectionGrey) ||
        props.theme.colours.white};
    margin-left: 0 auto;
    border-radius: 9px;
    padding: 1px;
    margin: 0.5px;
    :hover {
        background-color: ${props => props.theme.colours.selectionGrey};
        border-radius: 9px;
        padding: 1px;
    }
`;

const ChartSelectorButton = ({ chartType, selectedChartType, onClick }) => {
    const chartImageComponents = {
        line: StyledLineChartImage,
        areaSpline: StyledLineChartImage,
        pie: StyledImage,
        bar: StyledBarColumnChartImage,
        barHorizontal: StyledBarHorizontalChartImage,
        donut: StyledDonutChartImage,
        trendLine: StyledTrendLineChartImage,
    };

    const renderChartImage = chartType => {
        const ChartImage = chartImageComponents[chartType];
        return ChartImage ? <ChartImage /> : null;
    };

    return (
        <StyledImageButton
            data-testid={chartType + ' chart'}
            onClick={onClick}
            selectedChartType={selectedChartType}
            chartType={chartType}
        >
            {renderChartImage(chartType)}
        </StyledImageButton>
    );
};

export default ChartSelectorButton;
