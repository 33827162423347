import { v4 as uuidv4 } from 'uuid';
import { CubedField } from '../../../../types';
import { calculateVariance, formatVariance } from '../../../helpers/variance-helpers';
import { unknownErrorData } from '../../../helpers/errors';
import { alternativeGraphColours, graphColours } from '../../../helpers/graph-colours';
import { getRawValueAsNumber, getValue } from '../../../helpers/resource-data';
import { getValue as getDimensionValue } from '../../../helpers/table-data';
import {
    PieTableComparisonData,
    TableComparisonTableRow,
    PieSeries,
    ResourceComparisonData,
    ErrorData,
    PieSeriesData,
} from '../../../types';
import DataTypes from '../../../../filter-bar/enums/data-types';

export type UseResourcePieChartTableComparisonArgs = {
    resourceData: ResourceComparisonData;
    chartDimension: CubedField;
    mixField?: CubedField;
};

const useResourcePieChartTableComparison = ({
    resourceData,
    chartDimension,
    mixField,
}: UseResourcePieChartTableComparisonArgs): PieTableComparisonData => {
    const status = resourceData.status;

    if (status === 'loading' || status === 'empty' || status === 'error') {
        return resourceData;
    }

    if (status === 'success') {
        if (!resourceData.request?.chartField) {
            throw new Error('Expected chartField in the request');
        }

        try {
            const chartField = resourceData.request.chartField;

            const resource = resourceData.resources[0];
            const comparisonResource = resourceData.resources[1];

            let series: PieSeries[] = [];
            let comparisonSeries: PieSeries[] = [];

            let columns = [
                {
                    __id: uuidv4(),
                    rawName: mixField ? mixField.rawName : chartField.rawName,
                    displayName: 'This Period',
                    dataType: chartField.dataType,
                    ignoreTotal: mixField && mixField.ignoreTotal,
                },
                {
                    __id: uuidv4(),
                    rawName: chartDimension.rawName,
                    displayName: chartDimension.displayName,
                    isDimension: true,
                    dataType: chartDimension.dataType,
                },
                {
                    __id: uuidv4(),
                    rawName: 'variance',
                    displayName: 'Variance %',
                    isVariance: true,
                    dataType: DataTypes.FLOAT,
                    varianceField: chartField,
                },
                {
                    __id: uuidv4(),
                    rawName: mixField ? mixField.rawName : chartField.rawName,
                    displayName: 'Comparison Period',
                    dataType: chartField.dataType,
                    ignoreTotal: mixField && mixField.ignoreTotal,
                },
            ];

            let rows: TableComparisonTableRow[] = [];

            if (resource.status === 'success' && chartDimension) {
                // Build the pie series
                const total =
                    resource.objects.length > 0 &&
                    resource.objects.map(data => getRawValueAsNumber(data, chartField.rawName)).reduce((a, b) => a + b);

                const seriesData = resource.objects.map((object, index) => {
                    const colour = graphColours[index % graphColours.length].solidGraphColour;

                    return {
                        name: getValue(object, chartDimension.rawName),
                        value: getRawValueAsNumber(object, chartField.rawName),
                        color: colour,
                    };
                });

                seriesData.sort((a, b) => b.value - a.value);

                series = [
                    {
                        name: chartDimension.rawName,
                        data: seriesData,
                        total: total || 0,
                    },
                ];

                // Build the table rows
                rows = resource.objects.map(object => {
                    return {
                        __id: object.__id,
                        dimension: {
                            values: { [chartDimension.rawName]: object.values[chartDimension.rawName] },
                        },
                        data: {
                            values: {
                                [mixField ? mixField.rawName : chartField.rawName]:
                                    object.values[mixField ? mixField.rawName : chartField.rawName],
                            },
                        },
                    };
                });
            }

            if (comparisonResource.status === 'success' && chartDimension) {
                const comparisonSeriesData: PieSeriesData[] = [];

                // Build the table rows
                comparisonResource.objects.forEach((object, index) => {
                    // If there is already a row for this dimension, add it to the existing row. Otherwise, add a new row

                    const dimension = object.values[chartDimension.rawName].value;

                    const rowIndex = rows.findIndex(row =>
                        row.dimension.values ? row.dimension.values[chartDimension.rawName].value === dimension : ''
                    );

                    if (rowIndex === -1) {
                        if (resource.status === 'empty') {
                            rows.push({
                                __id: object.__id,
                                dimension: {
                                    values: { [chartDimension.rawName]: object.values[chartDimension.rawName] },
                                },
                                comparisonData: {
                                    values: {
                                        [mixField ? mixField.rawName : chartField.rawName]:
                                            object.values[mixField ? mixField.rawName : chartField.rawName],
                                    },
                                },
                            });
                        }
                    } else {
                        rows[rowIndex] = {
                            ...rows[rowIndex],
                            comparisonData: {
                                values: {
                                    [mixField ? mixField.rawName : chartField.rawName]:
                                        object.values[mixField ? mixField.rawName : chartField.rawName],
                                },
                            },
                        };
                    }

                    // Add the matched row to the comparison series
                    const name = getValue(object, chartDimension?.rawName);
                    let colour = alternativeGraphColours[index % alternativeGraphColours.length]?.solidGraphColour;
                    const matchingSeriesColour = series[0]?.data.find(item => item.name === name)?.color;
                    const value = getRawValueAsNumber(object, chartField?.rawName);

                    if (resource.status === 'success') {
                        if (matchingSeriesColour) {
                            colour = matchingSeriesColour;

                            comparisonSeriesData.push({
                                name: name,
                                value: value,
                                color: colour,
                            });
                        }
                    } else if (resource.status === 'empty') {
                        comparisonSeriesData.push({
                            name: name,
                            value: value,
                            color: colour,
                        });
                    }

                    comparisonSeriesData.sort((a, b) => b.value - a.value);
                });

                comparisonSeries = [
                    {
                        name: chartDimension.rawName,
                        data: comparisonSeriesData,
                        total: comparisonSeriesData.reduce((a, b) => a + b.value, 0),
                    },
                ];
            }

            if (rows.length > 0 && chartDimension.rawName) {
                // Add row colours to match graph
                rows = rows.map(row => {
                    const dimensionToMatch = getDimensionValue(row.dimension, chartDimension.rawName);
                    const matchedGraphSeries = series[0]?.data.find(series => series.name === dimensionToMatch);
                    const matchedGraphComparisonSeries = comparisonSeries[0]?.data.find(
                        series => series.name === dimensionToMatch
                    );

                    return {
                        ...row,
                        colour: matchedGraphSeries?.color || matchedGraphComparisonSeries?.color,
                    };
                });

                // Calculate the variance for each table row
                rows = rows.map(row => {
                    if (row.data?.values && row.comparisonData?.values) {
                        const value = row.data.values[mixField ? mixField.rawName : chartField.rawName].rawValue;
                        const comparisonValue =
                            row.comparisonData.values[mixField ? mixField.rawName : chartField.rawName].rawValue;
                        const variance = calculateVariance(+value, +comparisonValue);
                        return {
                            ...row,
                            variance: {
                                values: {
                                    variance: {
                                        rawValue: variance || 'N/A',
                                        value: formatVariance(variance),
                                    },
                                },
                            },
                        };
                    }
                    return row;
                });
            }

            return {
                type: 'pieChartTableComparison',
                status: 'success',
                request: resourceData.request,
                series: series,
                comparisonSeries: comparisonSeries,
                columns: columns,
                rows: rows,
                totalRows:
                    (resourceData.resources[0].status === 'success' && resourceData.resources[0].meta.total_count) ||
                    comparisonSeries.length,
            };
        } catch (e) {
            return {
                type: 'pieChartTableComparison',
                status: 'error',
                request: resourceData.request,
            } as ErrorData;
        }
    }

    return unknownErrorData();
};

export default useResourcePieChartTableComparison;
