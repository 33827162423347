// React Dependencies
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Core Dependencies
import { isDomain } from '../../helpers/validator';
import { ButtonThemes } from '../../enums/button-themes';

// Redux Actions
import { setModal } from '../../redux/actions/modal';
import { setPopup, removePopup } from '../../redux/actions/popup';
import { addNotification } from '../../redux/actions/notification';
import { NotificationMessageType } from '../../enums/notification-types';

// Widget Dependencies
import WidgetAccordion from '../../widgets/accordion';

// Component Dependencies
import ModalNavigation from '../../components/modal-navigation';
import usePostResource from '../../react-query/hooks/use-post-resource';
import { CONFIG_DOMAINS } from '../../configurations/resources-config';

const LayoutModalAddDomain = () => {
    const dispatch = useDispatch();

    const [closeButtonState, setCloseButtonState] = useState('close');
    const [closeButtonDisabled, setCloseButtonDisabled] = useState(false);
    const [saveChangesButtonDisabled, setSaveChangesButtonDisabled] = useState(true);
    const [domainDetailsAccordionOpen, setDomainDetailsAccordionOpen] = useState(true);
    const [domainName, setDomainName] = useState('');
    const [domainNameErrorMessage, setDomainNameErrorMessage] = useState('');

    const domainPostMutation = usePostResource({
        resource: CONFIG_DOMAINS,
        data: {
            domain: domainName,
        },
        handleOnSuccess: () => {
            dispatch(
                addNotification({
                    copy: 'A domain was successfully added.',
                    type: NotificationMessageType.Success,
                })
            );
            handleNavigateManageEventModal();
        },
        handleOnError: error => {
            if (error && error.includes('duplicate')) {
                setCloseButtonDisabled(false);
                setSaveChangesButtonDisabled(false);
                setDomainNameErrorMessage('The domain you are trying to add already exists.');
                return;
            }

            setCloseButtonDisabled(false);
            setSaveChangesButtonDisabled(false);
            setDomainNameErrorMessage('');

            dispatch(
                addNotification({
                    copy: 'There was an issue trying to add your domain, please try again later.',
                    type: NotificationMessageType.Error,
                })
            );
        },
    });

    useEffect(() => {
        if (closeButtonState === 'close') {
            if (domainName !== '') {
                setCloseButtonState('cancel');
                setSaveChangesButtonDisabled(false);
            }
        }
    }, [domainName]); // eslint-disable-line react-hooks/exhaustive-deps

    // Checks if the form fields have valid details
    const formValidator = () => {
        let hasFormError = false;
        let errorMessageObject = {
            domainNameErrorMessage: '',
        };

        // Validate and ensure goal-details__product-name and check that it is populated
        if (!isDomain(domainName) || domainName.length === 0) {
            hasFormError = true;
            errorMessageObject.domainNameErrorMessage = 'Please enter a valid Domain Name.';
        }

        if (hasFormError) {
            setDomainNameErrorMessage(errorMessageObject.domainNameErrorMessage);
        }

        // Flip the value to say the form is valid instead of if it has an error
        return !hasFormError;
    };

    // PrimaryAction of the close popup
    const onPopupDiscardChangesClick = () => {
        dispatch(removePopup());
        handleNavigateManageEventModal();
    };

    // SecondaryAction of the close popup
    const onPopupStayHereClick = () => {
        dispatch(removePopup());
    };

    // Manage the input fields changes and updating the state with the new value entered by the user
    const onGoalDetailsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDomainName(event.target.value);
    };

    const onSaveChangesClick = () => {
        setCloseButtonDisabled(true);
        setSaveChangesButtonDisabled(true);
        setDomainNameErrorMessage('');

        // If there is an error reopen the goals details tab as the error will be there
        if (!formValidator()) {
            setDomainDetailsAccordionOpen(true);
            setCloseButtonDisabled(false);
            setSaveChangesButtonDisabled(false);

            return;
        }

        domainPostMutation.mutate();
    };

    const handleNavigateManageEventModal = () => {
        dispatch(setModal('ManageDomains', {}));
    };

    const onCloseClick = () => {
        if (closeButtonState === 'close') {
            handleNavigateManageEventModal();
        } else {
            dispatch(
                setPopup({
                    title: 'Unsaved Changes',
                    iconType: 'warning',
                    contentType: 'simple',
                    config: {
                        copy: 'Are you sure you would like to leave? You have unsaved changes. Doing so will result in your changes being lost.',
                    },
                    buttons: [
                        {
                            onClick: onPopupDiscardChangesClick,
                            value: 'DISCARD DOMAIN',
                        },
                        {
                            onClick: onPopupStayHereClick,
                            value: 'STAY HERE',
                            style: 'secondary',
                        },
                    ],
                })
            );
        }
    };

    return (
        <div>
            <ModalNavigation
                buttons={[
                    {
                        value: 'SAVE',
                        onClick: onSaveChangesClick,
                        disabled: saveChangesButtonDisabled,
                        isLoading: domainPostMutation.isPending,
                    },
                    {
                        value: closeButtonState === 'cancel' ? 'CANCEL' : 'CLOSE',
                        onClick: onCloseClick,
                        disabled: closeButtonDisabled,
                        buttonTheme: closeButtonState === 'cancel' ? ButtonThemes.RedSecondary : ButtonThemes.Secondary,
                    },
                ]}
            />
            <h2>Add a Domain</h2>
            <p>
                Enter your account domain below. The Cubed tag will only accept data for domains configured in your
                account.
            </p>
            <WidgetAccordion
                accordions={[
                    {
                        header: 'Domain Details',
                        required: true,
                        open: domainDetailsAccordionOpen,
                        type: 'form',
                        config: {
                            formConfig: {
                                fields: [
                                    {
                                        label: 'Domain Name:',
                                        type: 'text',
                                        requiredField: true,
                                        toolTipCopy: 'Enter the domain name that the Cubed Tag will be firing on.',
                                        inputKeyValue: 'domain-details__domain-name',
                                        inputPlaceholder: 'Domain Name...',
                                        inputValue: domainName,
                                        inputOnChange: onGoalDetailsChange,
                                        errorMessage: domainNameErrorMessage,
                                    },
                                ],
                            },
                        },
                    },
                ]}
            />
        </div>
    );
};

export default LayoutModalAddDomain;
